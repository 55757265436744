"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THREE_MB_IN_BYTES = void 0;
exports.useValidateUploads = useValidateUploads;
var react_1 = require("react");
var template_1 = require("@shared/components/blocks/contexts/template");
var i18n_1 = require("@shared/util/i18n");
var provider_1 = require("../billing/banners/provider");
var provider_2 = require("../billing/upgradeModal/provider");
exports.THREE_MB_IN_BYTES = 3145728;
function useValidateUploads(_a) {
    var showUpgradeModal = _a.showUpgradeModal;
    var template = (0, react_1.useContext)(template_1.CardTemplateContext).template;
    var isExternalForm = (template === null || template === void 0 ? void 0 : template.context) === 'create-external';
    var billing = (0, react_1.useContext)(provider_1.BillingContext);
    var showModal = (0, react_1.useContext)(provider_2.UpgradeModalContext).show;
    return (0, react_1.useCallback)(function (files) {
        var filesResult = files.map(function (file) {
            if (!(billing === null || billing === void 0 ? void 0 : billing.isPaidPlan) && file.size > exports.THREE_MB_IN_BYTES) {
                return undefined;
            }
            return file;
        });
        if (filesResult.some(function (result) { return result === undefined; }) && !!showUpgradeModal) {
            var modalOptions = isExternalForm
                ? {
                    title: (0, i18n_1.i18n)('card.blocks.image.upgradeModalExternal.title'),
                    description: (0, i18n_1.i18n)('card.blocks.image.upgradeModalExternal.description'),
                    hideUpgradeButton: true,
                }
                : {
                    title: (0, i18n_1.i18n)('card.blocks.image.upgradeModal.title'),
                    description: (0, i18n_1.i18n)('card.blocks.image.upgradeModal.description'),
                };
            showModal(modalOptions);
        }
        return filesResult;
    }, [showUpgradeModal, billing, isExternalForm, showModal]);
}
