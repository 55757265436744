"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionHeader = MBCardCollectionHeader;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var header_1 = require("@shared/scenes/cards/collection/components/header");
var emoji_1 = require("@shared/scenes/channel/detailsForm/utils/emoji");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var hooks_2 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_3 = require("../hooks");
function MBCardCollectionHeader(_a) {
    var channelSlug = _a.channelSlug, template = _a.template, templates = _a.templates, templateSlug = _a.templateSlug, onNavigateToTemplate = _a.onNavigateToTemplate;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: channelSlug });
    var viewTemplate = (0, hooks_3.useCollectionViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, channelSlug, templateSlug).viewTemplate;
    var viewTemplates = (0, hooks_1.useAllViewTemplates)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, 'COLLECTION').viewTemplates;
    var selectableTemplates = !(0, lodash_1.isEmpty)(templates)
        ? templates === null || templates === void 0 ? void 0 : templates.map(function (t) {
            return { slug: t.slug, title: t.title, context: t.context };
        })
        : viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.map(function (vt) {
            return { title: vt.name, slug: vt.id };
        });
    var channelName = channel === null || channel === void 0 ? void 0 : channel.name;
    var nameWithoutEmoji = (0, react_1.useMemo)(function () { return (channelName ? (0, emoji_1.getNameWithoutEmoji)(channelName) : ''); }, [channelName]);
    var navigateToChannelDetails = (0, useNavigator_1.useNavigateTo)('ChannelInfos', { channelSlug: channelSlug });
    var selectedTemplate = !(0, lodash_1.isNil)(template)
        ? { title: template.title, slug: template.slug }
        : { title: viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name, slug: viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id };
    return (react_1.default.createElement(header_1.MBCardCollectionHeader, { channelName: nameWithoutEmoji, selectedTemplate: selectedTemplate, isDesktop: isDesktop, selectableTemplates: selectableTemplates !== null && selectableTemplates !== void 0 ? selectableTemplates : [], onSelectTemplate: onNavigateToTemplate, onChannelDetails: navigateToChannelDetails }));
}
