"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultComponentTemplateOptions = exports.scheduledAutomationOption = exports.relatedCardLookupOption = exports.STATIC_COMPONENT_IDS = void 0;
exports.getLabelForComponentType = getLabelForComponentType;
exports.getIconForComponentType = getIconForComponentType;
var lodash_1 = require("lodash");
var utils_1 = require("@shared/components/blocks/lib/tags/utils");
var i18n_1 = require("@shared/util/i18n");
var ComponentTypeUtils_1 = require("../ComponentTypeUtils");
var ComponentTemplates_1 = require("./ComponentTemplates");
var AuditTagFieldOption_1 = require("./options/AuditTagFieldOption");
var BasicFieldOption_1 = require("./options/BasicFieldOption");
var BatchDateFieldOption_1 = require("./options/BatchDateFieldOption");
var FileFieldOption_1 = require("./options/FileFieldOption");
var RelatedCardFieldOption_1 = require("./options/RelatedCardFieldOption");
var RelatedCardLookupFieldOption_1 = require("./options/RelatedCardLookupFieldOption");
var ReservedIdFieldOption_1 = require("./options/ReservedIdFieldOption");
var ScheduledAutomationFieldOption_1 = require("./options/ScheduledAutomationFieldOption");
var SubformFieldOption_1 = require("./options/SubformFieldOption");
var TagFieldOption_1 = require("./options/TagFieldOption");
var TextFieldOption_1 = require("./options/TextFieldOption");
var TimeTrackerFieldOption_1 = require("./options/TimeTrackerFieldOption");
var MAX_SIGNED_INT = Math.pow(2, 31) - 1;
/**
 * Some components have special meaning depending on their id (e.g: 'name' represents the card's title,
 * used across the system. 'startDate' represents the card's start date, used across the system as well)
 */
exports.STATIC_COMPONENT_IDS = {
    NAME: 'name',
    START_DATE: 'startDate',
    SEQUENCE_NUMBER: 'sequenceNumber',
    CREATED_AT: 'createdAt',
    ASSIGNEES: 'assignees',
};
function getLabelForComponentType(componentType, options) {
    if ('TAG' === componentType) {
        return options.multiselect
            ? (0, i18n_1.i18n)('workflowTemplate.component.type.label.multiSelect')
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.singleSelect');
    }
    if ('AUDIT_TAG' === componentType) {
        return options.multiselect
            ? (0, i18n_1.i18n)('workflowTemplate.component.type.label.multiSelect')
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.singleSelect');
    }
    if ('TEXT' === componentType) {
        return options.multiline
            ? (0, i18n_1.i18n)('workflowTemplate.component.type.label.longText')
            : (0, i18n_1.i18n)('workflowTemplate.component.type.label.shortText');
    }
    return ComponentTypeUtils_1.COMPONENT_TYPE_LABEL_MAP.get(componentType);
}
function getIconForComponentType(componentType, options) {
    if ('TAG' === componentType) {
        return options.multiselect ? 'list' : 'single-select-list';
    }
    if ('AUDIT_TAG' === componentType) {
        return options.multiselect ? 'list' : 'single-select-list';
    }
    if ('TEXT' === componentType) {
        return options.multiline ? 'multi-line' : 'title-t';
    }
    return ComponentTypeUtils_1.COMPONENT_TYPE_ICON_MAP.get(componentType);
}
var assigneeLabel = (0, i18n_1.i18n)('workflowTemplate.component.label.assignee');
var assigneeFieldOption = new ReservedIdFieldOption_1.ReservedIdFieldOption(assigneeLabel, getIconForComponentType('PERSON'), {
    id: exports.STATIC_COMPONENT_IDS.ASSIGNEES,
    type: 'PERSON',
    icon: getIconForComponentType('PERSON'),
    label: assigneeLabel,
    placeholder: assigneeLabel,
    required: false,
});
var dueDateLabel = (0, i18n_1.i18n)('workflowTemplate.component.label.dueDate');
var dueDateFieldOption = new ReservedIdFieldOption_1.ReservedIdFieldOption(dueDateLabel, getIconForComponentType('DATE'), {
    id: exports.STATIC_COMPONENT_IDS.START_DATE,
    type: 'DATE',
    icon: getIconForComponentType('DATE'),
    label: dueDateLabel,
    placeholder: dueDateLabel,
    required: false,
    allowEntityBatchCreation: false,
});
var multiSelectFieldOption = new TagFieldOption_1.TagFieldOption(getLabelForComponentType('TAG', { multiselect: true }), getIconForComponentType('TAG', { multiselect: true }), {
    type: 'TAG',
    icon: getIconForComponentType('TAG', { multiselect: true }),
    label: getLabelForComponentType('TAG', { multiselect: true }),
    placeholder: getLabelForComponentType('TAG', { multiselect: true }),
    multiselect: true,
    addAdditionalValues: true,
    required: false,
    options: [
        {
            label: 'Option 1',
            value: 'option-1',
            backgroundColor: utils_1.COLORS[0],
            archived: false,
        },
        {
            label: 'Option 2',
            value: 'option-2',
            backgroundColor: utils_1.COLORS[1],
            archived: false,
        },
    ],
});
var singleSelectFieldOption = new TagFieldOption_1.TagFieldOption(getLabelForComponentType('TAG', { multiselect: false }), getIconForComponentType('TAG', { multiselect: false }), {
    type: 'TAG',
    icon: getIconForComponentType('TAG', { multiselect: false }),
    label: getLabelForComponentType('TAG', { multiselect: false }),
    placeholder: getLabelForComponentType('TAG', { multiselect: false }),
    multiselect: false,
    addAdditionalValues: true,
    required: false,
    options: [
        {
            label: 'Option 1',
            value: 'option-1',
            backgroundColor: utils_1.COLORS[0],
            archived: false,
        },
        {
            label: 'Option 2',
            value: 'option-2',
            backgroundColor: utils_1.COLORS[1],
            archived: false,
        },
    ],
});
var combinedTagsOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('COMBINED_TAGS'), getIconForComponentType('COMBINED_TAGS'), {
    type: 'COMBINED_TAGS',
    icon: getIconForComponentType('COMBINED_TAGS'),
    label: getLabelForComponentType('COMBINED_TAGS'),
    componentIds: [],
});
var personOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('PERSON'), getIconForComponentType('PERSON'), {
    type: 'PERSON',
    icon: getIconForComponentType('PERSON'),
    label: getLabelForComponentType('PERSON'),
    placeholder: getLabelForComponentType('PERSON'),
    required: false,
});
var datePickerLabel = (0, i18n_1.i18n)('workflowTemplate.component.label.datePicker');
var datePickerOption = new BasicFieldOption_1.BasicFieldOption(datePickerLabel, getIconForComponentType('DATE'), {
    type: 'DATE',
    icon: getIconForComponentType('DATE'),
    label: datePickerLabel,
    placeholder: datePickerLabel,
    required: false,
    allowEntityBatchCreation: false,
});
var emailOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('EMAIL'), getIconForComponentType('EMAIL'), {
    type: 'EMAIL',
    icon: getIconForComponentType('EMAIL'),
    label: (0, i18n_1.i18n)('workflowTemplate.component.label.email'),
    placeholder: (0, i18n_1.i18n)('workflowTemplate.component.placeholder.email'),
    required: false,
});
var shortTextOption = new TextFieldOption_1.TextFieldOption(getLabelForComponentType('TEXT', { multiline: false }), getIconForComponentType('TEXT', { multiline: false }), {
    type: 'TEXT',
    icon: getIconForComponentType('TEXT', { multiline: false }),
    label: (0, i18n_1.i18n)('workflowTemplate.component.label.text'),
    placeholder: (0, i18n_1.i18n)('workflowTemplate.component.placeholder.text'),
    required: false,
    multiline: false,
    maxlength: 255,
});
var longTextOption = new TextFieldOption_1.TextFieldOption(getLabelForComponentType('TEXT', { multiline: true }), getIconForComponentType('TEXT', { multiline: true }), {
    type: 'TEXT',
    icon: getIconForComponentType('TEXT', { multiline: true }),
    label: (0, i18n_1.i18n)('workflowTemplate.component.label.text'),
    placeholder: (0, i18n_1.i18n)('workflowTemplate.component.placeholder.text'),
    required: false,
    multiline: true,
    maxlength: MAX_SIGNED_INT,
});
var numberOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('NUMBER'), getIconForComponentType('NUMBER'), {
    type: 'NUMBER',
    icon: getIconForComponentType('NUMBER'),
    label: getLabelForComponentType('NUMBER'),
    placeholder: getLabelForComponentType('NUMBER'),
    required: false,
    format: 'FLOAT',
});
var imageUploadOption = new FileFieldOption_1.FileFieldOption('Image Upload', 'camera', {
    type: 'FILE',
    icon: 'camera',
    label: (0, i18n_1.i18n)('workflowTemplate.component.label.image'),
    placeholder: (0, i18n_1.i18n)('workflowTemplate.component.placeholder.image'),
    acceptedContentTypes: ['image/*'],
    required: false,
});
var fileUploadOption = new FileFieldOption_1.FileFieldOption('File Upload', getIconForComponentType('FILE'), {
    type: 'FILE',
    icon: getIconForComponentType('FILE'),
    label: getLabelForComponentType('FILE'),
    placeholder: (0, i18n_1.i18n)('workflowTemplate.component.placeholder.file'),
    required: false,
});
var addressFieldOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('ADDRESS'), getIconForComponentType('ADDRESS'), {
    type: 'ADDRESS',
    icon: getIconForComponentType('ADDRESS'),
    label: getLabelForComponentType('ADDRESS'),
    placeholder: getLabelForComponentType('ADDRESS'),
    required: false,
});
var shareLocationFieldOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('GEOLOCATION'), getIconForComponentType('GEOLOCATION'), {
    type: 'GEOLOCATION',
    icon: getIconForComponentType('GEOLOCATION'),
    label: getLabelForComponentType('GEOLOCATION'),
    placeholder: getLabelForComponentType('GEOLOCATION'),
    required: false,
});
var signatureOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('SIGNATURE'), getIconForComponentType('SIGNATURE'), {
    type: 'SIGNATURE',
    label: getLabelForComponentType('SIGNATURE'),
    placeholder: getLabelForComponentType('SIGNATURE'),
    icon: getIconForComponentType('SIGNATURE'),
    required: false,
});
var todoListOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('TODO'), getIconForComponentType('TODO'), {
    type: 'TODO',
    icon: getIconForComponentType('TODO'),
    label: getLabelForComponentType('TODO'),
    placeholder: 'Add to-dos...',
    required: false,
});
var relatedCardOption = new RelatedCardFieldOption_1.RelatedCardFieldOption(getLabelForComponentType('RELATED_CARD'), getIconForComponentType('RELATED_CARD'), {
    type: 'RELATED_CARD',
    icon: getIconForComponentType('RELATED_CARD'),
    label: getLabelForComponentType('RELATED_CARD'),
    placeholder: getLabelForComponentType('RELATED_CARD'),
    required: false,
});
exports.relatedCardLookupOption = new RelatedCardLookupFieldOption_1.RelatedCardLookupFieldOption(getLabelForComponentType('RELATED_CARD_LOOKUP'), getIconForComponentType('RELATED_CARD_LOOKUP'), {
    type: 'RELATED_CARD_LOOKUP',
    icon: getIconForComponentType('RELATED_CARD_LOOKUP'),
    label: getLabelForComponentType('RELATED_CARD_LOOKUP'),
    placeholder: getLabelForComponentType('RELATED_CARD_LOOKUP'),
    required: false,
});
exports.scheduledAutomationOption = new ScheduledAutomationFieldOption_1.ScheduledAutomationFieldOption(getLabelForComponentType('SCHEDULED_AUTOMATION'), getIconForComponentType('SCHEDULED_AUTOMATION'), {
    type: 'SCHEDULED_AUTOMATION',
    icon: getIconForComponentType('SCHEDULED_AUTOMATION'),
    label: getLabelForComponentType('SCHEDULED_AUTOMATION'),
    placeholder: getLabelForComponentType('SCHEDULED_AUTOMATION'),
    required: false,
});
var subFormOption = new SubformFieldOption_1.SubformFieldOption(getLabelForComponentType('SUBFORM'), getIconForComponentType('SUBFORM'), {
    type: 'SUBFORM',
    icon: getIconForComponentType('SUBFORM'),
    label: getLabelForComponentType('SUBFORM'),
    placeholder: getLabelForComponentType('SUBFORM'),
    required: false,
    workflowTemplateIds: [],
});
var timeTrackerOption = new TimeTrackerFieldOption_1.TimeTrackerFieldOption(getLabelForComponentType('TIME_TRACKER'), getIconForComponentType('TIME_TRACKER'), {
    type: 'TIME_TRACKER',
    icon: getIconForComponentType('TIME_TRACKER'),
    label: getLabelForComponentType('TIME_TRACKER'),
    startButtonLabel: (0, i18n_1.i18n)('workflowTemplate.component.type.label.timeTrackerStart'),
    endButtonLabel: (0, i18n_1.i18n)('workflowTemplate.component.type.label.timeTrackerEnd'),
    requiresGeolocation: false,
    autoStart: false,
});
var referencedInOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('REFERENCED_IN'), getIconForComponentType('REFERENCED_IN'), {
    type: 'REFERENCED_IN',
});
var inputButtonOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('INPUT_BUTTON'), getIconForComponentType('INPUT_BUTTON'), {
    type: 'INPUT_BUTTON',
    buttonText: (0, i18n_1.i18n)('builder.inputButtonOptions.defaultButtonText'),
});
var entityBatch = {
    title: getLabelForComponentType('ENTITY_BATCH'),
    icon: getIconForComponentType('ENTITY_BATCH'),
    component: {
        type: 'ENTITY_BATCH',
        label: getLabelForComponentType('ENTITY_BATCH'),
        icon: getIconForComponentType('ENTITY_BATCH'),
        id: 'entityBatch',
        isServerManaged: true,
        readonly: true,
        locked: true,
    },
    matchesComponent: function (component, _reservedIds) {
        return component.type === 'ENTITY_BATCH';
    },
    canBeAdded: function (components) {
        return (0, lodash_1.isNil)(components.find(function (c) { return c.type === 'ENTITY_BATCH'; }));
    },
};
var batchDateLabel = (0, i18n_1.i18n)('workflowTemplate.component.type.label.dateAndRepeat');
var batchDateOption = new BatchDateFieldOption_1.BatchDateFieldOption((0, i18n_1.i18n)('workflowTemplate.component.label.batchDate'), getIconForComponentType('DATE'), {
    type: 'DATE',
    icon: getIconForComponentType('DATE'),
    label: batchDateLabel,
    placeholder: batchDateLabel,
    required: false,
    allowEntityBatchCreation: true,
}, [entityBatch]);
var suggestedSection = {
    title: (0, i18n_1.i18n)('workflowTemplate.component.section.suggested'),
    subformsOnly: false,
    items: [
        assigneeFieldOption,
        dueDateFieldOption,
        multiSelectFieldOption,
        singleSelectFieldOption,
        batchDateOption,
    ],
};
var basicSection = {
    title: 'Basic',
    subformsOnly: false,
    items: [
        personOption,
        datePickerOption,
        emailOption,
        shortTextOption,
        longTextOption,
        numberOption,
        imageUploadOption,
        fileUploadOption,
        addressFieldOption,
        shareLocationFieldOption,
        signatureOption,
        relatedCardOption,
        exports.relatedCardLookupOption,
        exports.scheduledAutomationOption,
        todoListOption,
        subFormOption,
        combinedTagsOption,
        timeTrackerOption,
        referencedInOption,
        inputButtonOption,
    ],
};
var auditTagOption = new AuditTagFieldOption_1.AuditTagFieldOption(getLabelForComponentType('AUDIT_TAG', { multiselect: false }), getIconForComponentType('AUDIT_TAG', { multiselect: false }), {
    type: 'AUDIT_TAG',
    icon: getIconForComponentType('AUDIT_TAG', { multiselect: false }),
    label: getLabelForComponentType('AUDIT_TAG', { multiselect: false }),
    placeholder: getLabelForComponentType('AUDIT_TAG', { multiselect: false }),
    required: false,
    multiselect: false,
    addAdditionalValues: true,
    options: [
        {
            label: 'Option 1',
            value: 'option-1',
            backgroundColor: utils_1.COLORS[0],
            archived: false,
        },
        {
            label: 'Option 2',
            value: 'option-2',
            backgroundColor: utils_1.COLORS[1],
            archived: false,
        },
    ],
});
var auditTagMultiSelectOption = new AuditTagFieldOption_1.AuditTagFieldOption(getLabelForComponentType('AUDIT_TAG', { multiselect: true }), getIconForComponentType('AUDIT_TAG', { multiselect: true }), {
    type: 'AUDIT_TAG',
    icon: getIconForComponentType('AUDIT_TAG', { multiselect: true }),
    label: getLabelForComponentType('AUDIT_TAG', { multiselect: true }),
    placeholder: getLabelForComponentType('AUDIT_TAG', { multiselect: true }),
    required: false,
    multiselect: true,
    addAdditionalValues: true,
    options: [
        {
            label: 'Option 1',
            value: 'option-1',
            backgroundColor: utils_1.COLORS[0],
            archived: false,
        },
        {
            label: 'Option 2',
            value: 'option-2',
            backgroundColor: utils_1.COLORS[1],
            archived: false,
        },
    ],
});
var auditTextOption = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('AUDIT_TEXT'), getIconForComponentType('AUDIT_TEXT'), {
    type: 'AUDIT_TEXT',
    icon: getIconForComponentType('AUDIT_TEXT'),
    label: getLabelForComponentType('AUDIT_TEXT'),
    placeholder: getLabelForComponentType('AUDIT_TEXT'),
    required: false,
    multiline: true,
    maxlength: MAX_SIGNED_INT,
});
var auditCheckboxComponent = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('AUDIT_CHECKBOX'), getIconForComponentType('AUDIT_CHECKBOX'), {
    type: 'AUDIT_CHECKBOX',
    icon: getIconForComponentType('AUDIT_CHECKBOX'),
    label: getLabelForComponentType('AUDIT_CHECKBOX'),
    placeholder: getLabelForComponentType('AUDIT_CHECKBOX'),
    required: false,
});
var staticTextComponent = new BasicFieldOption_1.BasicFieldOption(getLabelForComponentType('STATIC_TEXT'), getIconForComponentType('STATIC_TEXT'), {
    type: 'STATIC_TEXT',
    text: getLabelForComponentType('STATIC_TEXT'),
});
var qaSection = {
    title: (0, i18n_1.i18n)('workflowTemplate.component.section.questionAnswerField'),
    subformsOnly: true,
    items: [
        auditTagOption,
        auditTagMultiSelectOption,
        auditTextOption,
        auditCheckboxComponent,
        staticTextComponent,
    ],
};
var nonUserCreatedFieldOptions = [];
exports.defaultComponentTemplateOptions = new ComponentTemplates_1.ComponentTemplates({
    templateSections: [suggestedSection, basicSection, qaSection],
    reservedIds: [
        exports.STATIC_COMPONENT_IDS.ASSIGNEES,
        exports.STATIC_COMPONENT_IDS.START_DATE,
        exports.STATIC_COMPONENT_IDS.SEQUENCE_NUMBER,
        exports.STATIC_COMPONENT_IDS.CREATED_AT,
    ],
    nonEditableLabelComponentIds: [],
    nonEditablePlaceholderComponentIds: [
        exports.STATIC_COMPONENT_IDS.CREATED_AT,
        exports.STATIC_COMPONENT_IDS.SEQUENCE_NUMBER,
    ],
    nonUserCreatedFieldOptions: nonUserCreatedFieldOptions,
});
