"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFormFieldBase = MBFormFieldBase;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var base_1 = require("@shared/components/animation/base");
var styles_1 = require("@shared/styles");
function TitleAndPlaceholder(_a) {
    var title = _a.title, placeholder = _a.placeholder;
    if (!!placeholder) {
        if (!!title && typeof title === 'string') {
            return (react_1.default.createElement(components_1.MBView, null,
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: styles_1.Colors.v2.gray30 }, placeholder),
                react_1.default.createElement(components_1.MBTextBody1, null, title)));
        }
        if (!!title && (0, react_1.isValidElement)(title)) {
            return (react_1.default.createElement(components_1.MBView, null,
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: styles_1.Colors.v2.gray30 }, placeholder),
                title));
        }
        return react_1.default.createElement(components_1.MBTextBody1, { color: styles_1.Colors.v2.gray30 }, placeholder);
    }
    if (!!title && typeof title === 'string') {
        return react_1.default.createElement(components_1.MBText, { size: "large" }, title);
    }
    if (!!title && (0, react_1.isValidElement)(title)) {
        return title;
    }
    return null;
}
/*
    This is the base component for a normal form row.
    Icon to the right, content (string or React element)
    in the middle and an optional clear button.
*/
function MBFormFieldBase(_a) {
    var icon = _a.icon, title = _a.title, placeholder = _a.placeholder, rightButton = _a.rightButton, clearVisible = _a.clearVisible, onPress = _a.onPress, onClear = _a.onClear, label = _a.label, _b = _a.color, color = _b === void 0 ? styles_1.Colors.v2.green : _b;
    return (react_1.default.createElement(components_1.MBListRow, { image: (function () {
            if (!icon) {
                return undefined;
            }
            if ((0, react_1.isValidElement)(icon)) {
                return react_1.default.createElement(components_1.MBView, { fill: true, center: true, children: icon });
            }
            return (react_1.default.createElement(components_1.MBView, { fill: true, center: true },
                react_1.default.createElement(components_1.MBIcon, { noPadding: true, color: color, source: icon })));
        })(), title: (function () {
            if (!!rightButton) {
                return (react_1.default.createElement(components_1.MBView, { row: true, fill: true, centerVertically: true },
                    react_1.default.createElement(components_1.MBView, { flex: true },
                        react_1.default.createElement(TitleAndPlaceholder, { title: title, placeholder: placeholder })),
                    rightButton));
            }
            return (react_1.default.createElement(components_1.MBView, { centerVertically: true },
                label && (react_1.default.createElement(base_1.MBAnimation, { style: { marginBottom: (0, components_1.grid)(0.5) }, translateY: { from: 3, to: 0 }, opacity: { from: 0, to: 1 }, duration: 200 },
                    react_1.default.createElement(components_1.MBView, null,
                        react_1.default.createElement(components_1.MBTextOverlineBold, { color: styles_1.Colors.v2.gray30 }, label)))),
                react_1.default.createElement(TitleAndPlaceholder, { title: title, placeholder: placeholder })));
        })(), icon: !!clearVisible && react_1.default.createElement(components_1.MBClearButton, { onPress: onClear }), onPress: onPress, isHidingPressableIndicator: true }));
}
