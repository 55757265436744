"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardUpdateRoute = exports.RETURN_CHANNEL_SLUG_QUERY = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var toast_1 = require("@shared/components/toast");
var CardEntityEditForm_1 = require("@shared/scenes/entity/edit/components/CardEntityEditForm");
var useEditCardEntity_1 = require("@shared/scenes/entity/edit/hooks/useEditCardEntity");
var constants_1 = require("@shared/styles/constants");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/navigation/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var optionsButton_1 = require("../../details/containers/optionsButton");
var hooks_3 = require("../../hooks");
var container_1 = require("./container");
var hooks_4 = require("./hooks");
exports.RETURN_CHANNEL_SLUG_QUERY = 'returnChannelSlug';
var CardUpdateRoute = function (props) {
    var isNoCodeUpdate = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.NO_CODE_UPDATE_FORM);
    if (isNoCodeUpdate) {
        return react_1.default.createElement(NoCodeUpdateRoute, __assign({}, props));
    }
    return react_1.default.createElement(LowCodeUpdateRoute, __assign({}, props));
};
exports.CardUpdateRoute = CardUpdateRoute;
function LowCodeUpdateRoute(_a) {
    var _b;
    var params = _a.params, location = _a.location;
    var channel = (0, hooks_1.useGetChannelInfo)({ channelSlug: params.channelSlug });
    var cardDefinition = (0, hooks_3.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var title = (0, hooks_4.useFormTitle)(params.channelSlug, params.templateSlug, cardDefinition, params.cardId);
    var _c = (0, react_1.useState)(false), dirty = _c[0], setDirty = _c[1];
    var _d = (0, hooks_1.useFunctionState)(), onSubmit = _d[0], setOnSubmit = _d[1];
    var submitButtonTitle = (0, hooks_4.useFormSubmitButtonTitle)(params.channelSlug, cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id);
    var _e = (0, useNavigator_1.useNavigator)(), goBack = _e.goBack, replace = _e.replace;
    var fieldOverrides = (0, react_1.useMemo)(function () {
        if (!location.query.fields) {
            return undefined;
        }
        try {
            return JSON.parse(location.query.fields);
        }
        catch (error) {
            return undefined;
        }
    }, [location.query.fields]);
    var returnTo = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(location.query[exports.RETURN_CHANNEL_SLUG_QUERY])) {
            return undefined;
        }
        return {
            channelSlug: location.query[exports.RETURN_CHANNEL_SLUG_QUERY],
            cardId: location.query.returnCardId,
        };
    }, [location.query]);
    var onBack = (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isNil)(returnTo)) {
            goBack();
        }
        else {
            replace((0, lodash_1.isNil)(returnTo === null || returnTo === void 0 ? void 0 : returnTo.cardId) ? 'CardCreate' : 'CardUpdate', __assign(__assign({}, returnTo), { restoreSavedData: true }));
        }
    }, [goBack, replace, returnTo]);
    var showSaveButton = (0, hooks_4.useShouldShowUpdateSubmitButton)(dirty, channel === null || channel === void 0 ? void 0 : channel.id);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
            showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }),
            react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { showEdit: false, cardId: params.cardId, channelSlug: params.channelSlug })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
                showSaveButton && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }),
                react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { showEdit: false, cardId: params.cardId, channelSlug: params.channelSlug })), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(container_1.MBCardUpdateForm, { cardId: params.cardId, channelSlug: params.channelSlug, templateSlug: params.templateSlug, workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, fieldOverrides: fieldOverrides, returnTo: returnTo, restoreSavedData: !!((_b = location.query) === null || _b === void 0 ? void 0 : _b.restoreSavedData), formDirty: dirty, onDirty: setDirty, onSubmitFormButtonCallback: setOnSubmit }))));
}
function NoCodeUpdateRoute(_a) {
    var params = _a.params;
    var entityId = params.cardId, channelSlug = params.channelSlug, templateSlug = params.templateSlug;
    var channel = (0, hooks_1.useGetChannelInfo)({ channelSlug: channelSlug });
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var onBack = (0, react_1.useCallback)(function () {
        goBack();
    }, [goBack]);
    var _b = (0, useEditCardEntity_1.useEditCardEntity)({
        entityId: entityId,
        workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
        viewTemplateId: templateSlug,
    }), components = _b.components, fields = _b.fields, updateField = _b.updateField, errors = _b.errors, isDirty = _b.isDirty, onPressSave = _b.onPressSave, status = _b.status, viewTemplateTitle = _b.viewTemplateTitle, isLoading = _b.isLoading;
    var isSaveSuccessful = status === 'success';
    var showUnsavedChangesWarning = !isSaveSuccessful && isDirty;
    (0, hooks_2.useUnsavedChangesNavigationWarning)(showUnsavedChangesWarning);
    (0, react_1.useEffect)(function () {
        if (isSaveSuccessful) {
            goBack();
            toast.show({
                icon: 'check-filled',
                message: (0, i18n_1.i18n)('card.update.success'),
            });
        }
    }, [isSaveSuccessful, goBack, toast]);
    var submitButtonTitle = (0, i18n_1.i18n)('card.update.submit');
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: viewTemplateTitle, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
            isDirty && react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onPressSave }),
            react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { showEdit: false, cardId: params.cardId, channelSlug: params.channelSlug })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: viewTemplateTitle, rightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
                react_1.default.createElement(SaveButton, { channel: channel, isDirty: isDirty, onPressSave: onPressSave, isLoading: isLoading }),
                react_1.default.createElement(optionsButton_1.MBCardDetailsOptionsButton, { showEdit: false, cardId: params.cardId, channelSlug: params.channelSlug })), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(CardEntityEditForm_1.CardEntityEditForm, { fields: fields, updateField: updateField, components: components, errors: errors, workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, entityId: entityId }))));
}
function SaveButton(_a) {
    var _b;
    var channel = _a.channel, isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading;
    var showSaveButton = (0, hooks_4.useShouldShowUpdateSubmitButton)(isDirty, (_b = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _b !== void 0 ? _b : -1);
    if (!showSaveButton) {
        return null;
    }
    return react_1.default.createElement(components_1.MBHeaderButton, { title: (0, i18n_1.i18n)('common.save'), onPress: onPressSave, loading: isLoading });
}
