"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroupControls = useGroupControls;
var react_1 = require("react");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var uuid = __importStar(require("@shared/util/uuid"));
function useGroupControls() {
    var _a = (0, ControlsProvider_1.useControlsState)(), availableGroups = _a.availableGroups, selectedGroups = _a.selectedGroups, groups = _a.groups;
    var _b = (0, ControlsProvider_1.useControlsActions)(), setSelectedGroups = _b.setSelectedGroups, setGroupOption = _b.setGroupOption, resetGroups = _b.resetGroups;
    var onAddGroup = (0, react_1.useCallback)(function (filterOption) {
        var newFilterOption = __assign(__assign({}, filterOption), { uuid: uuid.v4() });
        setSelectedGroups(function (prev) { return __spreadArray(__spreadArray([], prev, true), [[newFilterOption]], false); });
        setGroupOption(newFilterOption);
    }, [setGroupOption, setSelectedGroups]);
    var onAddFilter = (0, react_1.useCallback)(function (filterOption, index) {
        var newFilterOption = __assign(__assign({}, filterOption), { uuid: uuid.v4() });
        setSelectedGroups(function (prev) {
            return prev.map(function (group, i) { return (i === index ? __spreadArray(__spreadArray([], group, true), [newFilterOption], false) : group); });
        });
        setGroupOption(newFilterOption, index);
    }, [setGroupOption, setSelectedGroups]);
    var onUpdateFilter = (0, react_1.useCallback)(function (filterOption, index) {
        setSelectedGroups(function (prev) {
            return prev.map(function (group, i) {
                if (i === index) {
                    return group.map(function (item) { return (item.uuid === filterOption.uuid ? filterOption : item); });
                }
                else {
                    return group;
                }
            });
        });
        setGroupOption(filterOption, index);
    }, [setGroupOption, setSelectedGroups]);
    var onDeleteFilter = (0, react_1.useCallback)(function (filterOption, index) {
        setSelectedGroups(function (prev) {
            var updatedGroups = prev
                // remove the filter from the group at this index
                .map(function (group, i) {
                if (i === index) {
                    return group.filter(function (item) { return item.uuid !== filterOption.uuid; });
                }
                else {
                    return group;
                }
            })
                // if the group is now empty, remove it
                .filter(function (group) { return group.length > 0; });
            // calling this inside setSelectedGroups so we can make this callback
            // stateless
            if (prev[index].length === 1) {
                setGroupOption(undefined, index);
            }
            else {
                setGroupOption(filterOption, index);
            }
            return updatedGroups;
        });
    }, [setGroupOption, setSelectedGroups]);
    var onDeleteGroup = (0, react_1.useCallback)(function (index) {
        setSelectedGroups(function (prev) { return prev.filter(function (_, i) { return i !== index; }); });
        setGroupOption(undefined, index);
    }, [setGroupOption, setSelectedGroups]);
    var onDeleteAllGroups = (0, react_1.useCallback)(function () {
        setSelectedGroups([]);
        resetGroups();
    }, [resetGroups, setSelectedGroups]);
    return (0, react_1.useMemo)(function () { return ({
        onAddGroup: onAddGroup,
        onAddFilter: onAddFilter,
        onUpdateFilter: onUpdateFilter,
        onDeleteFilter: onDeleteFilter,
        onDeleteGroup: onDeleteGroup,
        onDeleteAllGroups: onDeleteAllGroups,
        selectedGroups: selectedGroups,
        availableGroups: availableGroups,
        setGroupOption: setGroupOption,
        groups: groups,
    }); }, [
        onAddGroup,
        onAddFilter,
        onUpdateFilter,
        onDeleteFilter,
        onDeleteGroup,
        onDeleteAllGroups,
        selectedGroups,
        availableGroups,
        setGroupOption,
        groups,
    ]);
}
