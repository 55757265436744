"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBusinessForm = MBBusinessForm;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var styles_1 = require("@shared/styles");
var analytics_1 = require("@shared/util/analytics");
var hooks_1 = require("@shared/util/analytics/hooks");
var useGooglePlacesAutocomplete_1 = require("@shared/util/googleMaps/useGooglePlacesAutocomplete");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/navigation/hooks");
function MBBusinessForm(_a) {
    var _this = this;
    var cta = _a.cta, header = _a.header, footer = _a.footer, initialName = _a.name, initialImageUrl = _a.imageUrl, _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, _c = _a.showHeaderBackground, showHeaderBackground = _c === void 0 ? true : _c, _d = _a.showUnsavedChangesWarning, showUnsavedChangesWarning = _d === void 0 ? true : _d, _e = _a.showGooglePlacesAutocomplete, showGooglePlacesAutocomplete = _e === void 0 ? false : _e, _f = _a.disableImageUpload, disableImageUpload = _f === void 0 ? false : _f, onUploadedImage = _a.onUploadedImage, onSubmit = _a.onSubmit, onStartSelectImage = _a.onStartSelectImage, onSelectedImage = _a.onSelectedImage;
    var _g = (0, react_1.useState)(initialName), name = _g[0], setName = _g[1];
    var _h = (0, react_1.useState)(false), dirty = _h[0], setDirty = _h[1];
    var _j = (0, react_1.useState)(initialImageUrl), imageUrl = _j[0], setImageUrl = _j[1];
    var _k = (0, react_1.useState)(), nameErrorMessage = _k[0], setNameErrorMessage = _k[1];
    var _l = (0, react_1.useState)(false), loading = _l[0], setLoading = _l[1];
    var _m = (0, react_1.useState)(false), focused = _m[0], setFocused = _m[1];
    var focus = (0, react_1.useCallback)(function () { return setFocused(true); }, []);
    var blur = (0, react_1.useCallback)(function () { return setFocused(false); }, []);
    var submit = (0, react_1.useCallback)(function (inputName, inputImageUrl) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!inputName) {
                        setNameErrorMessage((0, i18n_1.i18n)('business.form.name.errorMessage'));
                        return [2 /*return*/];
                    }
                    react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss();
                    setNameErrorMessage(undefined);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, onSubmit({ name: inputName, imageUrl: inputImageUrl })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setNameErrorMessage, onSubmit, name, imageUrl]);
    var handleSubmit = (0, react_1.useCallback)(function () { return submit(name, imageUrl); }, [submit, name, imageUrl]);
    var suggestions = (0, useGooglePlacesAutocomplete_1.useGooglePlacesAutocomplete)(showGooglePlacesAutocomplete && dirty ? name : undefined, { type: 'establishment', limit: 3 }).suggestions;
    var selectAutocompleteSuggestion = (0, react_1.useCallback)(function (suggestion) {
        analytics_1.MBAnalytics.track('BusinessForm::SelectGooglePlaceSuggestion', suggestion);
        setDirty(false);
        setName(suggestion.title);
        submit(suggestion.title, imageUrl);
    }, [onSubmit, imageUrl]);
    var handleChangeName = (0, react_1.useCallback)(function (updatedName) {
        setName(updatedName);
        setDirty(true);
    }, []);
    // Need to update the imageUrl when it changes, otherwise "edited"
    // below will always be true after having uploaded an image. The reason
    // is because the imageUrl the GraphQL layer returns is from imgix
    (0, react_1.useEffect)(function () { return setImageUrl(initialImageUrl); }, [initialImageUrl]);
    var selectImage = (0, react_1.useCallback)(function (selectedImageUrl) {
        setImageUrl(selectedImageUrl);
        onUploadedImage === null || onUploadedImage === void 0 ? void 0 : onUploadedImage();
    }, [onUploadedImage]);
    var edited = name !== initialName || imageUrl !== initialImageUrl;
    (0, hooks_2.useUnsavedChangesNavigationWarning)(edited && showUnsavedChangesWarning);
    var focusOnSuggestions = showGooglePlacesAutocomplete && focused;
    return (react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", webContentStyle: { minHeight: '100%' }, nativeContentContainerStyle: { minHeight: '100%' }, onScrollBeginDrag: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss },
        !focusOnSuggestions && (react_1.default.createElement(components_1.MBView, { center: true, padding: 2, style: !!showHeaderBackground && { backgroundColor: styles_1.Colors.v2.tertiaryCoolBlue20 } },
            header,
            react_1.default.createElement(components_1.MBSelectAvatarImage, { imageUrl: imageUrl, description: (0, i18n_1.i18n)('business.form.imageUrl.title'), onUploadedImage: !disableImageUpload ? selectImage : undefined, onStartSelectImage: onStartSelectImage, onSelectedImage: onSelectedImage, placeholder: react_1.default.createElement(components_1.MBImage, { fill: true, source: require('@shared/assets/images/v2/placeholder-edit-business.png') }) }))),
        react_1.default.createElement(components_1.MBView, { padding: 2, paddingTop: 0 },
            react_1.default.createElement(formField_1.MBFormField, { required: true, title: (0, i18n_1.i18n)('business.form.name.title'), error: nameErrorMessage, style: styles.capWidth, children: function (props) { return (react_1.default.createElement(components_1.MBTextInput, __assign({}, props, { autoCapitalize: "words", returnKeyType: "done", autoFocus: autoFocus, value: name, textContentType: "name", placeholder: (0, i18n_1.i18n)('business.form.name.placeholder'), onFocus: (0, hooks_1.useTrackEventWithCallback)('Business::NameInput::Focus', focus, { name: name }), onBlur: (0, hooks_1.useTrackEventWithCallback)('Business::NameInput::Blur', blur, { name: name }), onChangeText: handleChangeName, onSubmitEditing: handleSubmit }))); } })),
        !focusOnSuggestions && footer,
        suggestions.map(function (suggestion) { return (react_1.default.createElement(components_1.MBListRow, { key: suggestion.googlePlaceId, title: suggestion.title, subtitle: suggestion.subtitle, isHidingPressableIndicator: true, onPress: function () { return selectAutocompleteSuggestion(suggestion); } })); }),
        react_1.default.createElement(components_1.MBView, { flex: true }),
        react_1.default.createElement(components_1.MBView, { padding: 2 },
            react_1.default.createElement(components_1.MBButtonCappedWidth, { title: cta, onPress: handleSubmit, loading: loading, testID: "business-form-submit-button" })),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
}
var styles = react_native_1.StyleSheet.create({
    capWidth: {
        width: '100%',
        maxWidth: 320,
        alignSelf: 'center',
    },
});
