"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBItemTypeSheet = MBItemTypeSheet;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var provider_1 = require("@shared/util/navigation/stack/provider");
var tagOptions_1 = require("../../contexts/tagOptions");
var customTagOptions_1 = require("./customTagOptions");
var options_1 = require("./options");
var tagOptions_2 = require("./tagOptions");
function MBItemTypeSheet(_a) {
    var containerRef = _a.containerRef, availableOptions = _a.availableOptions, onTagGroupChange = _a.onTagGroupChange, selectedTagGroup = _a.selectedTagGroup, selectedLabel = _a.selectedLabel, selectedType = _a.selectedType, onTypeChange = _a.onTypeChange, onCreateCustom = _a.onCreateCustom, onReturnFromCustom = _a.onReturnFromCustom, onClose = _a.onClose;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var tagsContext = (0, tagOptions_1.useTagOptionGroups)();
    var nav = (0, provider_1.useNavStack)([{ name: 'list' }]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var goToList = function () { return nav.push({ name: 'list' }); };
        var goToOptionsFromCustom = function () {
            onReturnFromCustom();
            nav.push({ name: 'options' });
        };
        switch (nav.currentRoute.name) {
            case 'list':
                return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.responseType') }));
            case 'options':
                return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: selectedLabel, leftIcon: "arrow-point-left", leftIconSize: (0, components_1.grid)(4), onLeftIconPress: goToList, leftIconColor: colors.foregroundInactive }));
            case 'custom':
                return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: (0, i18n_1.i18n)('channel.options.fragmentTemplates.fieldTypeLabel.customSingleSelect'), leftIcon: "arrow-point-left", leftIconSize: (0, components_1.grid)(4), onLeftIconPress: goToOptionsFromCustom, leftIconColor: colors.foregroundInactive }));
            default:
                return null;
        }
    }, [colors, nav, onReturnFromCustom, selectedLabel]);
    var content = (0, react_1.useMemo)(function () {
        var onPressTags = function () { return nav.push({ name: 'options' }); };
        var onPressCreateCustom = function () {
            onCreateCustom();
            nav.push({ name: 'custom' });
        };
        switch (nav.currentRoute.name) {
            case 'list':
                return (react_1.default.createElement(options_1.MBFieldOptions, { selectedTagGroup: selectedTagGroup, onPressTags: onPressTags, selectedOption: selectedType, options: availableOptions, onChange: onTypeChange }));
            case 'options':
                return (react_1.default.createElement(tagOptions_2.MBFieldTagOptions, { selectedOption: selectedTagGroup, onChange: onTagGroupChange, onCreateCustom: onPressCreateCustom }));
            case 'custom':
                return react_1.default.createElement(customTagOptions_1.MBCustomTagOptions, { selectedTagGroup: selectedTagGroup });
            default:
                return null;
        }
    }, [
        availableOptions,
        nav,
        onCreateCustom,
        onTagGroupChange,
        onTypeChange,
        selectedTagGroup,
        selectedType,
    ]);
    return (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, renderTitleBar: renderTitleBar, layout: {
            offset: { x: (0, components_1.grid)(2), y: 0 },
            minWidth: 320,
            minHeight: (0, components_1.grid)(38),
        }, onClose: onClose },
        react_1.default.createElement(tagOptions_1.TagOptionsContext.Provider, { value: tagsContext }, content)));
}
