"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLaunchPortalSession = exports.useLaunchCheckoutSession = void 0;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
var toast_1 = require("@shared/components/toast");
var config_1 = require("@shared/config");
var i18n_1 = require("@shared/util/i18n");
var sentry_1 = require("@shared/util/sentry");
var useLaunchCheckoutSession = function () {
    var apolloClient = (0, client_1.useApolloClient)();
    var toastContext = (0, react_1.useContext)(toast_1.ToastContext);
    var stripe = (0, react_1.useMemo)(function () { return Stripe(config_1.Config.STRIPE_API_KEY); }, []);
    return (0, react_1.useCallback)(function (lineItems) { return __awaiter(void 0, void 0, void 0, function () {
        var successUrl, cancelUrl, data, sessionId, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    successUrl = "".concat(window.location.href, "?return=paymentSuccess");
                    cancelUrl = "".concat(window.location.href, "?return=paymentCancel");
                    return [4 /*yield*/, apolloClient.mutate({
                            mutation: api_1.Mutations.billing.createCheckoutSession,
                            variables: { input: { lineItems: lineItems, successUrl: successUrl, cancelUrl: cancelUrl } },
                        })];
                case 1:
                    data = (_b.sent()).data;
                    sessionId = (_a = data === null || data === void 0 ? void 0 : data.createCheckoutSession) === null || _a === void 0 ? void 0 : _a.sessionId;
                    if (!sessionId) {
                        throw new Error("could not create checkout session for lineItems: ".concat(JSON.stringify(lineItems)));
                    }
                    return [4 /*yield*/, stripe.redirectToCheckout({ sessionId: sessionId })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    sentry_1.MBSentry.exception({ error: error_1 });
                    toastContext.show({
                        style: 'warning',
                        message: (0, i18n_1.i18n)('billing.failureMessage'),
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [apolloClient, toastContext, stripe]);
};
exports.useLaunchCheckoutSession = useLaunchCheckoutSession;
var useLaunchPortalSession = function () {
    var apolloClient = (0, client_1.useApolloClient)();
    var toastContext = (0, react_1.useContext)(toast_1.ToastContext);
    return (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var returnUrl, data, portalSessionUrl, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    returnUrl = "".concat(window.location.href, "?return=portal");
                    return [4 /*yield*/, apolloClient.mutate({
                            mutation: api_1.Mutations.billing.createPortalSession,
                            variables: {
                                input: { returnUrl: returnUrl },
                            },
                        })];
                case 1:
                    data = (_b.sent()).data;
                    portalSessionUrl = (_a = data === null || data === void 0 ? void 0 : data.createPortalSession) === null || _a === void 0 ? void 0 : _a.portalSessionUrl;
                    if (!portalSessionUrl) {
                        throw new Error("could not create portal session");
                    }
                    window.location.href = portalSessionUrl;
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    sentry_1.MBSentry.exception({ error: error_2 });
                    toastContext.show({
                        style: 'warning',
                        message: (0, i18n_1.i18n)('billing.portalMessage'),
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [apolloClient, toastContext]);
};
exports.useLaunchPortalSession = useLaunchPortalSession;
