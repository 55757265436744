"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.megaModalRouteMapping = exports.browserRouteMapping = void 0;
// @ts-strict-ignore
var lodash_1 = __importStar(require("lodash"));
var route_web_1 = require("@shared/scenes/cards/form/update/route.web");
var prepareJSONparams = function (object) { return JSON.stringify(object); };
exports.browserRouteMapping = {
    Home: function () { return "/"; },
    CreateAdditionalBusiness: function () { return "/newbusiness"; },
    BaseChannelsTab: function () { return "/channels"; },
    Channel: function (_a) {
        var channelSlug = _a.channelSlug, selectedMessage = _a.selectedMessage;
        return !!selectedMessage
            ? "/".concat(channelSlug, "/messages/").concat(selectedMessage.id, "/").concat(selectedMessage.createdAt)
            : "/".concat(channelSlug);
    },
    ChannelInfos: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/cards/infos");
    },
    AdvancedChannelOptions: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/cards/infos/advanced");
    },
    ChannelEdit: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/cards/infos/edit");
    },
    ChannelUserList: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/members");
    },
    ChannelNotificationSettings: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/notifications");
    },
    ChannelAddPeople: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/add");
    },
    ChannelChat: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug);
    },
    ChannelCardList: function (_a) {
        var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug;
        return "/".concat(channelSlug, "/cards").concat(!!templateSlug ? "/t/".concat(templateSlug) : '');
    },
    WorkspaceSectionAddPeople: function (_a) {
        var workspaceSectionId = _a.workspaceSectionId;
        return "/section/".concat(workspaceSectionId, "/add");
    },
    ChannelFragmentTemplatesList: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/fragment-templates");
    },
    ChannelFragmentTemplatesEdit: function (_a) {
        var channelSlug = _a.channelSlug, templateId = _a.templateId;
        return "/".concat(channelSlug, "/fragment-templates/").concat(templateId);
    },
    ChannelFragmentTemplatesCreate: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/fragment-templates/create");
    },
    CardCreate: function (_a) {
        var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, fieldOverrides = _a.fieldOverrides, cardDefinitionId = _a.cardDefinitionId, restoreSavedData = _a.restoreSavedData;
        var path = "/".concat(channelSlug, "/cards/create");
        if (!lodash_1.default.isNil(templateSlug)) {
            path += "/t/".concat(templateSlug);
        }
        if (!lodash_1.default.isNil(cardDefinitionId)) {
            path += "/c/".concat(cardDefinitionId);
        }
        var urlParams = {};
        if (!lodash_1.default.isNil(fieldOverrides)) {
            urlParams['fields'] = prepareJSONparams(fieldOverrides);
        }
        if (!lodash_1.default.isNil(restoreSavedData)) {
            urlParams['restoreSavedData'] = 'true';
        }
        if (!lodash_1.default.isEmpty(urlParams)) {
            var query = new URLSearchParams(urlParams);
            path += "/?".concat(query.toString());
        }
        return path;
    },
    CardDetails: function (_a) {
        var cardId = _a.cardId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, fromAllCards = _a.fromAllCards;
        return fromAllCards
            ? "/all-cards/".concat(channelSlug, "/").concat(cardId)
            : !!templateSlug
                ? "/".concat(channelSlug, "/cards/").concat(cardId, "/t/").concat(templateSlug)
                : "/".concat(channelSlug, "/cards/").concat(cardId);
    },
    CardDetailsNoAccess: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/cards/no-access");
    },
    CardHistory: function (_a) {
        var cardId = _a.cardId, channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/cards/").concat(cardId, "/history");
    },
    CardUpdate: function (_a) {
        var cardId = _a.cardId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, fieldOverrides = _a.fieldOverrides, restoreSavedData = _a.restoreSavedData, returnChannelSlug = _a.returnChannelSlug, returnCardId = _a.returnCardId;
        var path = "/".concat(channelSlug, "/cards/").concat(cardId, "/update");
        if (!!templateSlug) {
            path += "/t/".concat(templateSlug);
        }
        var urlParams = {};
        if (!lodash_1.default.isNil(fieldOverrides)) {
            urlParams['fields'] = prepareJSONparams(fieldOverrides);
        }
        if (!lodash_1.default.isNil(restoreSavedData)) {
            urlParams['restoreSavedData'] = 'true';
        }
        if (!lodash_1.default.isNil(returnChannelSlug)) {
            urlParams[route_web_1.RETURN_CHANNEL_SLUG_QUERY] = returnChannelSlug;
        }
        if (!lodash_1.default.isNil(returnCardId)) {
            urlParams['returnCardId'] = returnCardId;
        }
        if (!lodash_1.default.isEmpty(urlParams)) {
            var query = new URLSearchParams(urlParams);
            path += "/?".concat(query.toString());
        }
        return path;
    },
    CardBulkUpdate: function (_a) {
        var channelSlug = _a.channelSlug, cardId = _a.cardId, workflowTemplateId = _a.workflowTemplateId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId, allOrUpcoming = _a.allOrUpcoming;
        return "/".concat(channelSlug, "/cards/").concat(cardId, "/update/").concat(workflowTemplateId, "/bulk/").concat(recurringEntitiesScheduleId, "/").concat(allOrUpcoming);
    },
    NewMessageContainer: function () { return "/channel/create"; },
    MessageStart: function () { return "/channel/create"; },
    MessageGroupCreate: function () { return "/channel/create/new"; },
    MessageGroupName: function () { return "/channel/create/details"; },
    CreateChannelChatPreview: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/channel/create/".concat(channelSlug);
    },
    CreateChannelInviteContacts: function () { return "/channel/create/new"; },
    CreateChannelWorkflowLibrary: function () { return "/channel/create/workflow"; },
    Login: function () { return "/signup"; },
    LoginExistingAccount: function (_a) {
        var _b = _a === void 0 ? {} : _a, error = _b.error;
        return ((0, lodash_1.isNil)(error) ? '/signin' : "/signin?error=".concat(error));
    },
    LoginVerificationCode: function () { return "/verify"; },
    LoginBusiness: function () { return "/signin/biz"; },
    LoginBusinessUserList: function () { return "/auth"; },
    LoginBusinessUserCreate: function () { return "/auth/user/create"; },
    LoginSamlAuthentication: function (_a) {
        var code = _a.code;
        return "/saml-auth?code=".concat(code);
    },
    OnboardingProfile: function () { return "/onboarding/profile"; },
    OnboardingCreateOrJoinBusiness: function () { return "/onboarding/createOrJoin"; },
    OnboardingCreateBusiness: function () { return "/onboarding/business"; },
    OnboardingSelectWorkflows: function () { return "/onboarding/workflows"; },
    OnboardingCreateDirectMessages: function () { return "/onboarding/dms"; },
    OnboardingLastStep: function () { return "/onboarding/lastStep"; },
    OnboardingInviteContacts: function () { return "/onboarding/invite"; },
    OnboardingDone: function () { return "/onboarding/done"; },
    BaseCardsTab: function () { return "/all-cards"; },
    DashboardTab: function () { return '/dashboard'; },
    WorkflowEdit: function (_a) {
        var channelSlug = _a.channelSlug, workflowId = _a.workflowId, queryString = _a.queryString;
        return lodash_1.default.isNil(queryString)
            ? "/".concat(channelSlug, "/workflows/").concat(workflowId, "/edit")
            : "/".concat(channelSlug, "/workflows/").concat(workflowId, "/edit?originWorkflowTemplateId=").concat(queryString.originWorkflowTemplateId, "&originComponentId=").concat(queryString.originComponentId);
    },
    WorkflowCreate: function (_a) {
        var channelSlug = _a.channelSlug, queryString = _a.queryString;
        return "/".concat(channelSlug, "/workflows/create?originWorkflowTemplateId=").concat(queryString.originWorkflowTemplateId, "&originComponentId=").concat(queryString.originComponentId, "&name=").concat(queryString.name);
    },
    SharedCardLinkViewTemplate: function (_a) {
        var channelSlug = _a.channelSlug, viewTemplateId = _a.viewTemplateId;
        return "/".concat(channelSlug, "/sharedLinks/viewTemplates/").concat(viewTemplateId);
    },
    SubformsList: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/subforms");
    },
    ViewTemplateList: function (_a) {
        var channelSlug = _a.channelSlug;
        return "/".concat(channelSlug, "/viewTemplates");
    },
    ViewTemplateEdit: function (_a) {
        var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId;
        return "/workflowTemplates/".concat(workflowTemplateId, "/viewTemplates/").concat(viewTemplateId);
    },
};
exports.megaModalRouteMapping = {
    ProfileEdit: function () { return '/profile'; },
    NotificationSettings: function () { return '/notifications'; },
    BaseToolsTab: function () { return '/links'; },
    LinkCreate: function () { return '/links/create'; },
    Billing: function () { return '/billing'; },
    BusinessEdit: function () { return '/business'; },
    BusinessSettings: function () { return '/settings'; },
    Directory: function () { return '/directory'; },
    DirectoryProfile: function (_a) {
        var userId = _a.userId;
        return "/directory/user/".concat(Number(userId));
    },
    UserGroups: function () { return '/user-groups'; },
    UserGroupsProfile: function (_a) {
        var groupId = _a.groupId;
        return "/user-groups/group/".concat(groupId);
    },
    InviteContacts: function () { return '/invite'; },
};
