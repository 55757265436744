"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFragmentTemplateItem = MBFragmentTemplateItem;
// @ts-strict-ignore
var formik_1 = require("formik");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var lib_1 = require("use-debounce/lib");
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var itemType_1 = require("../itemType");
function MBFragmentTemplateItem(_a) {
    var index = _a.index, readOnly = _a.readOnly;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, formik_1.useFormikContext)(), handleChange = _b.handleChange, values = _b.values, setFieldValue = _b.setFieldValue, handleBlur = _b.handleBlur;
    var fieldDescriptor = "template.items.".concat(index);
    var itemValue = values.template.items[index];
    var isLastField = index === values.template.items.length - 1;
    var displayClear = !!itemValue.label && !isLastField && !readOnly;
    var updateSettings = (0, react_1.useCallback)(function (updatedSettings) {
        setFieldValue("".concat(fieldDescriptor, ".settings"), updatedSettings);
    }, [setFieldValue, fieldDescriptor]);
    var _c = (0, react_1.useState)(itemValue.label), localLabel = _c[0], setLocalLabel = _c[1];
    var _setFieldLabel = (0, react_1.useCallback)(function (value) {
        handleChange("".concat(fieldDescriptor, ".label"))(value);
    }, [fieldDescriptor, handleChange]);
    var setFieldLabel = (0, lib_1.useDebouncedCallback)(_setFieldLabel, 500)[0];
    var onChangeText = (0, react_1.useCallback)(function (value) {
        setLocalLabel(value);
        setFieldLabel(value);
    }, [setFieldLabel]);
    return (react_1.default.createElement(components_1.MBView, { style: [styles.container, { borderBottomColor: colors.backgroundInactive }], fullWidth: true },
        react_1.default.createElement(formField_1.MBFormField, { required: true, children: function (props) { return (react_1.default.createElement(components_1.MBView, { row: true, fullWidth: true, centerVertically: true, style: [styles.labelContainer] },
                react_1.default.createElement(components_1.MBTouchableOpacity, { style: styles.inputContainer, activeOpacity: 1, onPress: function () { } },
                    react_1.default.createElement(components_1.MBTextInput, { autoFocus: true, editable: !readOnly, autoCapitalize: "sentences", returnKeyType: "done", value: localLabel, placeholder: (0, i18n_1.i18n)('channel.options.fragmentTemplates.addField'), style: [styles.input], onBlur: function (e) {
                            handleBlur("".concat(fieldDescriptor, ".label"))(e);
                            props.onBlur();
                        }, onChangeText: onChangeText })),
                displayClear && (react_1.default.createElement(components_1.MBClearButton, { onPress: function () { return setFieldValue("".concat(fieldDescriptor, ".label"), ''); } })))); } }),
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: styles.bottomBar },
            react_1.default.createElement(components_1.MBView, { style: styles.typeContainer, row: true },
                react_1.default.createElement(itemType_1.MBItemType, { itemName: itemValue.name, disabled: readOnly, value: itemValue.fieldType, onChange: handleChange("".concat(fieldDescriptor, ".fieldType")), settings: itemValue.settings, onSettingsChange: updateSettings })))));
}
var styles = react_native_1.StyleSheet.create({
    labelContainer: {
        justifyContent: 'space-between',
    },
    labelRow: {
        paddingHorizontal: (0, components_1.grid)(2),
    },
    typeContainer: {
        minWidth: '50%',
    },
    inputContainer: __assign({ width: '90%', display: 'flex', alignItems: 'center' }, react_native_1.Platform.select({
        web: {},
        default: {
            marginTop: (0, components_1.grid)(1),
        },
    })),
    input: {
        width: '100%',
        borderWidth: 0,
        padding: (0, components_1.grid)(1),
    },
    container: {
        marginBottom: (0, components_1.grid)(2),
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: (0, components_1.grid)(2),
        paddingHorizontal: (0, components_1.grid)(2),
        borderBottomWidth: 1,
    },
    bottomBar: {
        marginTop: (0, components_1.grid)(1),
    },
});
