"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelOptions = MBChannelOptions;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/scenes/channel/userList/hooks");
var hooks_3 = require("@shared/scenes/workflows/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var PermissionsContext_1 = require("@shared/util/permissions/PermissionsContext");
var index_1 = require("../components/index");
var hooks_4 = require("../hooks");
function MBChannelOptions(_a) {
    var _this = this;
    var _b, _c;
    var slug = _a.slug;
    var _d = (0, useNavigator_1.useNavigator)(), navigate = _d.navigate, reset = _d.reset;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var channel = (_b = (0, client_1.useQuery)(api_1.Queries.channel.details, { variables: { slug: slug } }).data) === null || _b === void 0 ? void 0 : _b.channel;
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var workflowTemplate = (0, hooks_3.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var reports = (0, hooks_4.useChannelReports)({
        cardDefinitionId: cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id,
        workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
    });
    var externalFormTemplate = (_c = cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.templates.find(function (uiTemplate) {
        var context = uiTemplate.template.context;
        return context === 'create-external';
    })) === null || _c === void 0 ? void 0 : _c.template;
    var globalAbility = (0, react_1.useContext)(PermissionsContext_1.PermissionsContext);
    var leaveChannel = (0, hooks_2.useLeaveChannel)(slug);
    var onLeaveChannel = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, leaveChannel()];
                case 1:
                    _a.sent();
                    reset('Home');
                    return [2 /*return*/];
            }
        });
    }); }, [leaveChannel, reset]);
    var updatePinnedSortKey = (0, hooks_4.useUpdatePinnedSortKey)(slug);
    var openReportLink = (0, hooks_4.useOpenReportLink)(channel === null || channel === void 0 ? void 0 : channel.id);
    var navigateToWorkflowEdit = (0, react_1.useCallback)(function () { return navigate('WorkflowEdit', { workflowId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, channelSlug: slug }); }, [channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, navigate, slug]);
    var navigateToUserList = (0, react_1.useCallback)(function () { return navigate('ChannelUserList', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToNotifications = (0, react_1.useCallback)(function () { return navigate('ChannelNotificationSettings', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToAddUsers = (0, react_1.useCallback)(function () { return navigate('ChannelAddPeople', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToFragmentTemplates = (0, react_1.useCallback)(function () { return navigate('ChannelFragmentTemplatesList', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToSubforms = (0, react_1.useCallback)(function () { return navigate('SubformsList', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToViewTemplates = (0, react_1.useCallback)(function () { return navigate('ViewTemplateList', { channelSlug: slug }); }, [navigate, slug]);
    var navigateToAdvancedOptions = (0, react_1.useCallback)(function () { return navigate('AdvancedChannelOptions', { channelSlug: slug }); }, [navigate, slug]);
    if (!channel) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return (react_1.default.createElement(index_1.MBChannelOptions, { channel: channel, reports: reports, isDesktop: isDesktop, externalFormTemplate: externalFormTemplate, workflowTemplate: workflowTemplate, ability: globalAbility, onUpdatePinned: updatePinnedSortKey, onOpenReport: openReportLink, onLeaveChannel: onLeaveChannel, onOpenUserList: navigateToUserList, onStartAddingUsers: navigateToAddUsers, onNotificationSettings: navigateToNotifications, onFragmentTemplates: navigateToFragmentTemplates, onWorkflowEdit: navigateToWorkflowEdit, onSubforms: navigateToSubforms, onViewTemplates: navigateToViewTemplates, onAdvancedChannelOptions: navigateToAdvancedOptions }));
}
