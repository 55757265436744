"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateCardButtonDesktop = MBCreateCardButtonDesktop;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var splitButton_1 = require("@shared/components/button/splitButton");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var workflow_1 = require("@shared/util/workflow");
var hooks_1 = require("../hooks");
var definitionListDesktop_1 = require("./definitionListDesktop");
function MBCreateCardButtonDesktop(_a) {
    var channelSlug = _a.channelSlug;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerRef = (0, react_1.useRef)();
    var _b = (0, react_1.useState)(false), showingSheet = _b[0], setShowingSheet = _b[1];
    var hideSheet = (0, react_1.useCallback)(function () { return setShowingSheet(false); }, []);
    var showSheet = (0, react_1.useCallback)(function () { return setShowingSheet(true); }, []);
    var _c = (0, hooks_1.useCreateCardButton)({ channelSlug: channelSlug }), availableDefinitions = _c.availableDefinitions, onCreateTemplate = _c.onCreateTemplate, onCreateWithDefinition = _c.onCreateWithDefinition, onCreateWithRootDefinition = _c.onCreateWithRootDefinition, channel = _c.channel;
    var onNewTemplate = (0, react_1.useCallback)(function () {
        hideSheet();
        onCreateTemplate();
    }, [hideSheet, onCreateTemplate]);
    var onSelectDefinition = (0, react_1.useCallback)(function (definition) {
        hideSheet();
        onCreateWithDefinition(definition);
    }, [hideSheet, onCreateWithDefinition]);
    var onSelectRootDefinition = (0, react_1.useCallback)(function () { return onCreateWithRootDefinition(); }, [onCreateWithRootDefinition]);
    var button = (0, workflow_1.hasVirtualCardDefinition)(channel) ? (react_1.default.createElement(components_1.MBButton, { title: react_1.default.createElement(components_1.MBTextBody1Bold, { color: colors.button.foreground }, (0, i18n_1.i18n)('common.new')), leftIcon: "plus-small-heavy", onPress: onSelectRootDefinition, leftIconSize: (0, components_1.grid)(2), leftIconStyle: { marginRight: (0, components_1.grid)(2) } })) : (react_1.default.createElement(splitButton_1.MBSplitButton, { title: react_1.default.createElement(components_1.MBTextBody1Bold, { color: colors.button.foreground }, (0, i18n_1.i18n)('common.new')), leftIcon: "plus-small-heavy", rightIcon: react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: !showingSheet, rotate: { from: '0deg', to: '-180deg' } },
            react_1.default.createElement(components_1.MBIconV2, { color: colors.button.foreground, name: "arrow-point-down-heavy", size: (0, components_1.grid)(2) })), onRightIconPress: showSheet, onPress: onSelectRootDefinition, leftIconSize: (0, components_1.grid)(2), leftIconStyle: { marginRight: (0, components_1.grid)(2) } }));
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef }),
        button,
        showingSheet && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { onClose: hideSheet, containerRef: containerRef, initialSize: "full", layout: {
                offset: { x: -180, y: 22 },
                minHeight: (0, components_1.grid)(48),
            } },
            react_1.default.createElement(definitionListDesktop_1.MBDefinitionList, { channelName: channel === null || channel === void 0 ? void 0 : channel.name, onNewTemplate: onNewTemplate, onSelectDefinition: onSelectDefinition, availableDefinitions: availableDefinitions })))));
}
