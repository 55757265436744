"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonOptions = exports.MultiValue = exports.OptionWithoutCheckbox = exports.Option = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var profile_1 = require("@shared/components/image/user/profile");
var Pill_1 = require("@shared/components/pills/Pill");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var useGetPersonDataFromSelection_1 = require("./hooks/useGetPersonDataFromSelection");
var MultiSelect_1 = require("./multiSelect/MultiSelect");
var Option_1 = require("./multiSelect/Option");
var Selection_1 = require("./multiSelect/Selection");
var Option = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var data = props.data;
    var isEmptyFilter = data.value === 'empty';
    return (react_1.default.createElement(Option_1.Option, __assign({}, props, { icon: isEmptyFilter ? 'person' : null, styles: isEmptyFilter ? { paddingLeft: (0, view_1.grid)(3.5) } : null }),
        isEmptyFilter && react_1.default.createElement(typography_1.MBTextBody1, { style: { marginLeft: (0, view_1.grid)(1) } }, data.label),
        !isEmptyFilter && (react_1.default.createElement(Pill_1.Pill, { backgroundColor: "transparent", round: true, noBorder: true },
            react_1.default.createElement(react_native_1.View, { style: { display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' } },
                react_1.default.createElement(react_native_1.View, { style: {
                        width: (0, view_1.grid)(3),
                        height: (0, view_1.grid)(3),
                        display: 'flex',
                        borderRadius: (0, view_1.grid)(1.5),
                        overflow: 'hidden',
                        marginRight: (0, view_1.grid)(2),
                    } },
                    react_1.default.createElement(profile_1.MBUserImage, { user: data.user, size: "small", style: { width: (0, view_1.grid)(3), height: (0, view_1.grid)(3) } })),
                react_1.default.createElement(typography_1.MBTextBody1, { style: {
                        maxWidth: 195,
                        // @ts-ignore
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        // @ts-ignore
                        display: 'block',
                    }, numberOfLines: 1 }, data.label))))));
};
exports.Option = Option;
var OptionWithoutCheckbox = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(exports.Option, __assign({}, props, { showCheckbox: false }), children));
};
exports.OptionWithoutCheckbox = OptionWithoutCheckbox;
var MultiValue = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var data = props.data;
    var isEmptyFilter = data.value === 'empty';
    return (react_1.default.createElement(Selection_1.Selection, __assign({}, props),
        react_1.default.createElement(Pill_1.Pill, { backgroundColor: "white", round: true, onRemove: function () {
                return props.setValue(__spreadArray([], props.getValue().filter(function (v) { return v.value !== data.value; }), true), 'deselect-option');
            } },
            react_1.default.createElement(react_native_1.View, { style: { display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' } },
                !isEmptyFilter && (react_1.default.createElement(react_native_1.View, { style: {
                        width: (0, view_1.grid)(2),
                        height: (0, view_1.grid)(2),
                        display: 'flex',
                        borderRadius: (0, view_1.grid)(1),
                        overflow: 'hidden',
                        marginRight: (0, view_1.grid)(1),
                    } },
                    react_1.default.createElement(profile_1.MBUserImage, { user: data.user, size: "tiny", style: { width: (0, view_1.grid)(2), height: (0, view_1.grid)(2) } }))),
                react_1.default.createElement(typography_1.MBTextBody1, { style: {
                        maxWidth: 170,
                        // @ts-ignore
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        // @ts-ignore
                        display: react_native_1.Platform.OS === 'web' ? 'block' : null,
                    }, numberOfLines: 1 }, data.label)))));
};
exports.MultiValue = MultiValue;
var PersonOptions = function (_a) {
    var _b;
    var filter = _a.filter, setOption = _a.setOption, selectedOptions = _a.selectedOptions, includeEmptyOption = _a.includeEmptyOption;
    var _c = (0, react_1.useState)([]), options = _c[0], setOptions = _c[1];
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var users = (0, hooks_1.useChannelUsers)(channel === null || channel === void 0 ? void 0 : channel.id);
    var selections = ((_b = selectedOptions.get(filter.uuid)) === null || _b === void 0 ? void 0 : _b.selection) || [];
    var defaultSelectionsWithData = (0, useGetPersonDataFromSelection_1.useGetPersonDataFromSelection)(selections);
    var onChange = function (selected) {
        setOption(filter, 'updateSelections', selected);
    };
    (0, react_1.useEffect)(function () {
        setOptions(__spreadArray(__spreadArray([], (includeEmptyOption
            ? [
                {
                    value: 'empty',
                    label: (0, i18n_1.i18n)('card.actions.filters.emptyLabel', { label: filter.label }),
                },
            ]
            : []), true), ((users === null || users === void 0 ? void 0 : users.map(function (u) { return ({
            value: u.id,
            label: u.fullName,
            user: u,
        }); })) || []), true));
        // workaround: `users` ref changes every render, so using length as dep for now
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users === null || users === void 0 ? void 0 : users.length]);
    // prevent render until data is available since react-select doesn't update defaults
    if (!(0, lodash_1.isEmpty)(selections) && (0, lodash_1.isEmpty)(defaultSelectionsWithData)) {
        return null;
    }
    return (react_1.default.createElement(MultiSelect_1.OpenView, { componentId: filter.id, options: options, onChange: onChange, selectedOptions: defaultSelectionsWithData.selections, components: { Option: exports.Option, MultiValue: exports.MultiValue } }));
};
exports.PersonOptions = PersonOptions;
