"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultPersonSelectionContainer = DefaultPersonSelectionContainer;
// @ts-strict-ignore
var schemas_1 = require("@coast/schemas");
var react_1 = __importStar(require("react"));
var WorkflowTemplateDataProvider_1 = require("@shared/scenes/workflows/context/WorkflowTemplateDataProvider");
var context_1 = require("@shared/scenes/workflows/context/context");
var DefaultPersonSelection_1 = require("./DefaultPersonSelection");
var DefaultPersonSelectionHooks_1 = require("./hooks/DefaultPersonSelectionHooks");
function DefaultPersonSelectionContainer(_a) {
    var _b, _c;
    var component = _a.component;
    var users = (0, react_1.useContext)(WorkflowTemplateDataProvider_1.WorkflowTemplateDataContext).users;
    var dispatch = (0, context_1.useWorkflowDispatch)();
    function newUserIdDefaults(userId) {
        var _a, _b, _c, _d;
        if ((_b = (_a = component.default) === null || _a === void 0 ? void 0 : _a.userIds) === null || _b === void 0 ? void 0 : _b.includes(userId)) {
            return component.default.userIds.filter(function (item) { return item !== userId; });
        }
        return [userId].concat((_d = (_c = component.default) === null || _c === void 0 ? void 0 : _c.userIds) !== null && _d !== void 0 ? _d : []);
    }
    function onSelectPerson(userId) {
        var userIds = newUserIdDefaults(userId);
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { default: { token: undefined, userIds: userIds } }),
        });
    }
    function onMeSelected() {
        var _a;
        dispatch({
            type: 'updateComponent',
            args: __assign(__assign({}, component), { default: {
                    token: ((_a = component.default) === null || _a === void 0 ? void 0 : _a.token) === schemas_1.Token.ME ? undefined : schemas_1.Token.ME,
                    userIds: [],
                } }),
        });
    }
    var hooks = (0, DefaultPersonSelectionHooks_1.useDefaultPersonSelectionHooks)(users, {
        token: (_b = component.default) === null || _b === void 0 ? void 0 : _b.token,
        userIds: (_c = component.default) === null || _c === void 0 ? void 0 : _c.userIds,
    }, onSelectPerson, onMeSelected);
    return react_1.default.createElement(DefaultPersonSelection_1.DefaultPersonSelection, { hooks: hooks });
}
