"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBVideoPreviewModal = MBVideoPreviewModal;
var linking_1 = require("@shared/util/linking");
function MBVideoPreviewModal(_a) {
    var onClose = _a.onClose, videoUri = _a.videoUri, visible = _a.visible;
    if (visible) {
        linking_1.MBLinking.open(videoUri);
        onClose();
    }
    return null;
}
