"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIXED_WIDTH = void 0;
exports.MBMultiSelectModal = MBMultiSelectModal;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var edit_1 = require("@shared/components/multiSelect/edit");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var provider_1 = require("@shared/util/navigation/stack/provider");
var index_1 = require("./index");
exports.FIXED_WIDTH = { width: (0, components_1.grid)(46) };
var initialRoutes = [{ name: 'select' }];
function MBMultiSelectModal(_a) {
    var _this = this;
    var _b, _c;
    var children = _a.children, label = _a.label, onOpen = _a.onOpen, onClose = _a.onClose, _d = _a.itemsEditable, itemsEditable = _d === void 0 ? false : _d, editMode = _a.editMode, itemLabel = _a.itemLabel, itemLabelPlural = _a.itemLabelPlural, onArchive = _a.onArchive, onUnarchive = _a.onUnarchive, onChangeLabel = _a.onChangeLabel, trigger = _a.trigger, multiSelectProps = __rest(_a, ["children", "label", "onOpen", "onClose", "itemsEditable", "editMode", "itemLabel", "itemLabelPlural", "onArchive", "onUnarchive", "onChangeLabel", "trigger"]);
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var colorTheme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _e = (0, react_1.useState)(false), visible = _e[0], setVisible = _e[1];
    var containerRef = (0, react_1.useRef)(null);
    var nav = (0, provider_1.useNavStack)(initialRoutes);
    var handleOpen = (0, react_1.useCallback)(function () {
        setVisible(true);
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    }, [onOpen]);
    var handleClose = (0, react_1.useCallback)(function () {
        setVisible(false);
        nav.reset(initialRoutes);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }, [onClose, nav]);
    (0, hooks_1.useExecuteOnTrigger)(trigger, handleOpen);
    var title = (0, react_1.useMemo)(function () {
        if (nav.currentRoute.name === 'manage') {
            return (0, i18n_1.i18n)('multiSelect.manage', { item: itemLabelPlural });
        }
        if (nav.currentRoute.name === 'edit') {
            return (0, i18n_1.i18n)('multiSelect.edit', { item: itemLabel });
        }
        return label;
    }, [nav.currentRoute, itemLabelPlural, itemLabel, label]);
    var handleGearPress = (0, react_1.useMemo)(function () {
        if (nav.currentRoute.name === 'select') {
            return function () { return nav.push({ name: 'manage' }); };
        }
        return undefined;
    }, [nav]);
    var handleBack = (0, react_1.useMemo)(function () {
        if (nav.currentRoute.name === 'manage' || nav.currentRoute.name === 'edit') {
            return function () { return nav.pop(); };
        }
        return undefined;
    }, [nav]);
    var handleEdit = (0, react_1.useCallback)(function (value) {
        nav.push({ name: 'edit', params: { value: value } });
    }, [nav]);
    var handleArchive = (0, react_1.useCallback)(function (value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onArchive(value)];
                case 1:
                    _a.sent();
                    handleBack();
                    return [2 /*return*/];
            }
        });
    }); }, [handleBack, onArchive]);
    var handleUnarchive = (0, react_1.useCallback)(function (value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onUnarchive(value)];
                case 1:
                    _a.sent();
                    handleBack();
                    return [2 /*return*/];
            }
        });
    }); }, [handleBack, onUnarchive]);
    var handleChangeLabel = (0, react_1.useCallback)(function (updatedValue, updatedLabel) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onChangeLabel(updatedValue, updatedLabel)];
                case 1:
                    _a.sent();
                    handleBack();
                    return [2 /*return*/];
            }
        });
    }); }, [handleBack, onChangeLabel]);
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var exitIconProps = isDesktop
            ? null
            : {
                rightIcon: 'exit',
                rightIconColor: colorTheme.foregroundActive,
                onRightIconPress: handleClose,
            };
        if (!itemsEditable) {
            return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, __assign({ title: title }, exitIconProps));
        }
        if (nav.currentRoute.name === 'select') {
            return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, __assign({ title: title, leftIcon: "gear", leftIconColor: colorTheme.foregroundInactive, leftTitlePosition: (0, components_1.grid)(8), onLeftIconPress: handleGearPress }, exitIconProps)));
        }
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: title, leftIcon: "arrow-point-left", onLeftIconPress: handleBack, leftTitlePosition: (0, components_1.grid)(8) }));
    }, [
        colorTheme.foregroundActive,
        colorTheme.foregroundInactive,
        handleBack,
        handleClose,
        handleGearPress,
        isDesktop,
        itemsEditable,
        nav,
        title,
    ]);
    var fixedWidth = (0, contentWrapper_1.useIsDesktop)() ? exports.FIXED_WIDTH : null;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef, onPress: handleOpen, style: { maxWidth: '100%' } }, children),
        visible && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { containerRef: containerRef, onClose: handleClose, renderTitleBar: renderTitleBar, layout: {
                offset: { x: isDesktop ? (0, components_1.grid)(10) : (0, components_1.grid)(2), y: 0 },
                minHeight: (0, components_1.grid)(48),
            } }, nav.currentRoute.name === 'edit' ? (react_1.default.createElement(edit_1.MBMultiSelectEdit, __assign({}, multiSelectProps, { onArchive: handleArchive, onUnarchive: handleUnarchive, onChangeLabel: handleChangeLabel, value: (_b = nav.currentRoute.params) === null || _b === void 0 ? void 0 : _b.value, isArchived: multiSelectProps.archivedValues.includes((_c = nav.currentRoute.params) === null || _c === void 0 ? void 0 : _c.value) }))) : (react_1.default.createElement(components_1.MBView, { style: fixedWidth, fill: true },
            react_1.default.createElement(index_1.MBMultiSelect, __assign({}, multiSelectProps, { onClose: handleClose, editMode: nav.currentRoute.name === 'manage', onEdit: handleEdit }))))))));
}
