"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBWorkflowCreate = MBWorkflowCreate;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var channel_1 = require("@shared/components/blocks/contexts/channel");
var toast_1 = require("@shared/components/toast");
var context_1 = require("@shared/scenes/workflows/edit/context");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/navigation/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var WorkflowTemplateDataProvider_1 = require("../context/WorkflowTemplateDataProvider");
var context_2 = require("../context/context");
var components_1 = require("../edit/components");
var hooks_3 = require("../hooks");
var hooks_4 = require("./hooks");
function MBWorkflowCreate(_a) {
    var _this = this;
    var _b;
    var channelSlug = _a.channelSlug, onDirty = _a.onDirty, onSubmitFormButtonCallback = _a.onSubmitFormButtonCallback, name = _a.name, workflowTemplateId = _a.workflowTemplateId, subformComponentId = _a.subformComponentId, workspaceDetails = _a.workspaceDetails;
    var _c = (0, react_1.useState)(__assign(__assign({}, hooks_4.initialWorkflowTemplate), { name: name })), workflowTemplate = _c[0], setWorkflowTemplate = _c[1];
    var submitting = (0, react_1.useRef)(false);
    var newSubformSaved = (0, react_1.useRef)(false);
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var channel = (0, hooks_1.useChannel)(channelSlug);
    var _d = (0, hooks_3.useCreateWorkflowTemplate)(true), createWorkflow = _d[0], mutationLoading = _d[1].loading;
    var updateSubformComponentWorkflows = (0, hooks_3.useUpdateSubformComponentWorkflows)(workflowTemplateId, subformComponentId);
    var onSubmit = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var newWorkflowTemplate, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    submitting.current = true;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, createWorkflow(workflowTemplate)];
                case 2:
                    newWorkflowTemplate = _a.sent();
                    if (!subformComponentWorkflowsNeedUpdate(workflowTemplateId, subformComponentId)) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateSubformComponentWorkflows(newWorkflowTemplate.id)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    newSubformSaved.current = true;
                    submitting.current = false;
                    toast.show({
                        message: (0, i18n_1.i18n)('workflowTemplate.successfulUpdate'),
                    });
                    goBack();
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    console.error(e_1);
                    submitting.current = false;
                    toast.show({
                        style: 'warning',
                        message: (0, i18n_1.i18n)('workflowTemplate.errorUpdate'),
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        createWorkflow,
        workflowTemplate,
        workflowTemplateId,
        subformComponentId,
        toast,
        updateSubformComponentWorkflows,
        goBack,
    ]);
    var isDirty = (0, react_1.useMemo)(function () { return !(0, lodash_1.isEqual)(workflowTemplate, hooks_4.initialWorkflowTemplate); }, [workflowTemplate]);
    (0, hooks_2.useUnsavedChangesNavigationWarning)(!newSubformSaved.current && !submitting.current);
    (0, react_1.useEffect)(function () {
        onSubmitFormButtonCallback === null || onSubmitFormButtonCallback === void 0 ? void 0 : onSubmitFormButtonCallback(onSubmit);
    }, [onSubmit, onSubmitFormButtonCallback]);
    (0, react_1.useEffect)(function () {
        onDirty === null || onDirty === void 0 ? void 0 : onDirty(isDirty);
    }, [isDirty, onDirty]);
    var users = (_b = channel === null || channel === void 0 ? void 0 : channel.users) === null || _b === void 0 ? void 0 : _b.map(function (user) {
        return {
            id: user.id,
            userName: user.username,
            fullName: user.fullName,
            imageUrl: user.imageUrl,
        };
    });
    return (react_1.default.createElement(channel_1.CardChannelContext.Provider, { value: channel },
        react_1.default.createElement(context_1.BuilderComponentOpenProvider, null,
            react_1.default.createElement(WorkflowTemplateDataProvider_1.WorkflowTemplateDataProvider, { data: { users: users, channelId: channel === null || channel === void 0 ? void 0 : channel.id } },
                react_1.default.createElement(context_2.WorkflowTemplateProvider, { initialWorkflowTemplate: workflowTemplate, onUpdate: setWorkflowTemplate },
                    react_1.default.createElement(components_1.MBWorkflowEdit, { workflowTemplateId: workflowTemplateId, loading: mutationLoading, workspaceDetails: workspaceDetails, isSubform: true, createMode: true }))))));
}
function subformComponentWorkflowsNeedUpdate(workflowTemplateId, subformComponentId) {
    return !(0, lodash_1.isNil)(workflowTemplateId) && !(0, lodash_1.isNil)(subformComponentId);
}
