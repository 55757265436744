"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFlatList = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var recyclerlistview_1 = require("recyclerlistview");
var components_1 = require("@shared/components");
/*
    A basic view to render a list of items.

    TODO(fant): figure out how to pass inferred ItemType to flatlist props
*/
var MBFlatList = /** @class */ (function (_super) {
    __extends(MBFlatList, _super);
    function MBFlatList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            width: null,
            height: null,
            dataProvider: new recyclerlistview_1.DataProvider(function (a, b) { return _this.props.keyExtractor(a) !== _this.props.keyExtractor(b); }),
        };
        _this.layoutProvider = new recyclerlistview_1.LayoutProvider(function (index) { return index; }, function (_a, itemSize) {
            itemSize.width = _this.itemWidth();
            // expected height is the same as expected width for now
            itemSize.height = _this.itemWidth();
        });
        // Note(fant): we floor item width, because JS rounding issues might make
        // eg 33.33 + 33.33 + 33.33 > 100 which would make a 3 col list shown in
        // 2 cols that are 1/3 width.
        _this.itemWidth = function () { return Math.floor(_this.state.width / _this.props.numColumns); };
        _this.onSize = function (_a) {
            var width = _a.width, height = _a.height;
            return _this.setState({ width: width, height: height });
        };
        // We wrap the content in an MBView that we set a fixed width to. The reason is
        // that we want the width to be fixed (100% if 1 column, 50% if 2 columns etc).
        // But RecyclerListView supports either (1) fixed width and height (set in
        // layoutProvider) or (2) dynamic width and height (forceNonDeterministicRendering).
        // We want fixed width and dynamic height.
        _this.renderItem = function (index, item) { return (React.createElement(components_1.MBView, { style: { width: _this.itemWidth() } }, _this.props.renderItem({ item: item, index: index }))); };
        return _this;
    }
    // This lifecycle method replaces componentDidMount and componentWillReceiveProps
    // https://reactjs.org/blog/2018/03/27
    //      /update-on-async-rendering.html#new-lifecycle-getderivedstatefromprops
    MBFlatList.getDerivedStateFromProps = function (props, state) {
        return {
            dataProvider: state.dataProvider.cloneWithRows(props.data),
        };
    };
    MBFlatList.prototype.scrollToTop = function () {
        this.recyclerListView['_scrollComponent']['_scrollViewRef'].scrollTo({ y: 0 });
    };
    MBFlatList.prototype.render = function () {
        var _this = this;
        // forceNonDeterministicRendering makes the size of the rows dynamic
        // https://github.com/Flipkart/recyclerlistview/issues/110
        return (React.createElement(components_1.MBView, { fullWidth: true, fullHeight: true, onSize: this.onSize },
            React.createElement(components_1.MBView, { style: { width: this.state.width, height: this.state.height } }, !!this.state.width && !!this.state.height && (React.createElement(recyclerlistview_1.RecyclerListView, { ref: function (ref) { return (_this.recyclerListView = ref); }, dataProvider: this.state.dataProvider, layoutProvider: this.layoutProvider, rowRenderer: this.renderItem, onEndReached: this.props.onEndReached, forceNonDeterministicRendering: true })))));
    };
    MBFlatList.defaultProps = { numColumns: 1 };
    return MBFlatList;
}(React.PureComponent));
exports.MBFlatList = MBFlatList;
