"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionTemplateSwitcher = MBCardCollectionTemplateSwitcher;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var TEMPLATE_TITLE_DESKTOP_MAX_WIDTH = 180;
function MBCardCollectionTemplateSwitcher(_a) {
    var selectedTemplate = _a.selectedTemplate, isDesktop = _a.isDesktop, _b = _a.arrowDirection, arrowDirection = _b === void 0 ? 'down' : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var TemplateTitleComponent = isDesktop ? typography_1.MBTextBody1Bold : typography_1.MBTextBody2;
    var titleStyle = (0, react_1.useMemo)(function () {
        if (isDesktop) {
            return { maxWidth: TEMPLATE_TITLE_DESKTOP_MAX_WIDTH };
        }
    }, [isDesktop]);
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
        react_1.default.createElement(TemplateTitleComponent, { color: colors.foregroundActive, numberOfLines: 1, style: titleStyle }, selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.title),
        react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
        react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: arrowDirection === 'down', rotate: { from: '0deg', to: '-180deg' } },
            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "arrow-point-down-heavy", size: 16 }))));
}
