"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNewChannelDetailsNextButton = MBNewChannelDetailsNextButton;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var containers_1 = require("@shared/scenes/workflowLibrary/containers");
var alert_1 = require("@shared/util/alert");
var analytics_1 = require("@shared/util/analytics");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var useChannelDetails_1 = require("../../detailsForm/hooks/useChannelDetails");
var emoji_1 = require("../../detailsForm/utils/emoji");
var hooks_1 = require("../hooks");
var getChannelNameFromParticipantNames = function (participants) {
    var namesToShow = participants
        .map(function (user) { return user.fullName.split(' ')[0]; })
        .slice(0, MAX_NAME_COUNT);
    var otherUserCount = participants.length - namesToShow.length;
    return (0, i18n_1.i18n)('channel.create.defaultChannelName', {
        names: namesToShow.join(', '),
        count: otherUserCount,
    });
};
var computeChannelName = function (name, workflowListItem, participants) {
    if (!name || name.trim().length === 0) {
        return workflowListItem
            ? workflowListItem.name
            : getChannelNameFromParticipantNames(participants);
    }
    return name.trim();
};
var MAX_NAME_COUNT = 7;
function MBNewChannelDetailsNextButton() {
    var _this = this;
    var _a = (0, useChannelDetails_1.useChannelDetails)(), name = _a.name, description = _a.description, emoji = _a.emoji, imageUrl = _a.imageUrl, isImageUploading = _a.isImageUploading;
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var users = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.selectedUsers; });
    var accessLevel = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.accessLevel; });
    var addWorkflow = (0, containers_1.useAddWorkflow)();
    var workflowListItem = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.workflowListItem; });
    var data = (0, client_1.useQuery)(api_1.Queries.user.me).data;
    var currentUser = data === null || data === void 0 ? void 0 : data.me;
    var createChannel = (0, hooks_1.useCreateChannel)();
    var navigator = (0, useNavigator_1.useNavigator)();
    var onCreateChannel = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var channelName, userIds, channels, dbName, channel;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    channelName = computeChannelName(name, workflowListItem, __spreadArray([currentUser], users, true));
                    userIds = users.map(function (u) { return u.id; });
                    if (!workflowListItem) return [3 /*break*/, 2];
                    return [4 /*yield*/, addWorkflow({
                            item: workflowListItem,
                            nameOverride: channelName,
                            userIds: userIds,
                            accessLevelType: accessLevel.type,
                        })];
                case 1:
                    channels = _a.sent();
                    analytics_1.MBAnalytics.track('CreateWorkflow::New::Created', {
                        name: channelName,
                        workflowLibraryId: workflowListItem.id,
                    });
                    navigator.replace('Channel', { channelSlug: channels[0].slug });
                    return [3 /*break*/, 4];
                case 2:
                    dbName = (0, emoji_1.mergeNameWithEmoji)(channelName, emoji);
                    return [4 /*yield*/, createChannel({
                            name: dbName,
                            imageUrl: imageUrl,
                            description: description,
                            type: 'CARD_GENERIC',
                        }, userIds, accessLevel.type)];
                case 3:
                    channel = _a.sent();
                    analytics_1.MBAnalytics.track('CreateChannel::New::Created', {
                        name: name,
                        description: description,
                        businessId: businessId,
                        userIds: userIds,
                        imageUrl: imageUrl,
                        cardDefinitionId: undefined,
                        channelSlug: channel.slug,
                    });
                    navigator.replace('Channel', { channelSlug: channel.slug });
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        name,
        workflowListItem,
        currentUser,
        users,
        addWorkflow,
        navigator,
        emoji,
        createChannel,
        imageUrl,
        description,
        accessLevel,
        businessId,
    ]);
    var handlePress = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isImageUploading) return [3 /*break*/, 1];
                    alert_1.MBAlert.alert({
                        message: (0, i18n_1.i18n)('channel.options.imageUploadingWarning'),
                        confirmText: (0, i18n_1.i18n)('common.ok'),
                    });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, onCreateChannel()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [isImageUploading, onCreateChannel]);
    return react_1.default.createElement(components_1.MBHeaderButton, { title: (0, i18n_1.i18n)('common.create'), onPress: handlePress });
}
