"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSearchResultList = MBSearchResultList;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var noSearchResults_1 = require("../components/noSearchResults");
var util_1 = require("../util");
var resultRows_1 = require("./resultRows");
// TODO(fant): move this into a component
function MBSearchResultList(_a) {
    var query = _a.query;
    var channels = (0, util_1.useChannels)(query);
    var users = (0, util_1.useDirectory)(query);
    var searchResults = (0, util_1.useSearchQuery)(query);
    var scrollRef = React.useRef(null);
    // Whenever the search query updates, we scroll to the top
    React.useEffect(function () {
        var _a;
        (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollToTop();
    }, [query]);
    // Note(fant): we don't show channels or users until both have loaded.
    // Otherwise the UI flickers. Most of the time, the user query is faster,
    // which would cause users to show and shortly after push down the users
    // to make place for the channels. This is only an issue during the first
    // search because no data has then yet been fetched.
    if (!channels || !users || !searchResults) {
        return React.createElement(components_1.MBView, { fill: true, center: true, children: React.createElement(components_1.MBLoadingIndicator, null) });
    }
    // Note(fant): we want to make sure that search is set (aka query has been fetched).
    // Otherwise MBNoSearchResults will flicker after searching for the first time.
    if (!!channels &&
        !channels.length &&
        !!users &&
        !users.length &&
        !!searchResults &&
        !searchResults.length) {
        return React.createElement(noSearchResults_1.MBNoSearchResults, { query: query });
    }
    return (React.createElement(components_1.MBScrollView, { ref: scrollRef, nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag" },
        !!users.length && React.createElement(components_1.MBListHeader, { title: (0, i18n_1.i18n)('search.results.people') }),
        users.map(function (user, index) { return (React.createElement(resultRows_1.MBSearchResultUserRow, { key: user.item.id, highlight: user.highlight, user: user.item, index: index })); }),
        !!channels.length && React.createElement(components_1.MBListHeader, { title: (0, i18n_1.i18n)('search.results.channels') }),
        channels.map(function (channel, index) { return (React.createElement(resultRows_1.MBSearchResultChannelRow, { key: channel.item.slug, highlight: channel.highlight, channel: channel.item, index: index })); }),
        !!searchResults.length && React.createElement(components_1.MBListHeader, { title: (0, i18n_1.i18n)('search.results.messagesAndCards') }),
        !!searchResults.length &&
            searchResults.map(function (result, index) {
                switch (result.type) {
                    case 'CARD':
                        return React.createElement(resultRows_1.MBSearchResultCardRow, { key: result.id, index: index, searchResult: result });
                    case 'MESSAGE':
                        // TODO(fant): some message search results return null channel (ch13008)
                        if (!result.channel) {
                            return null;
                        }
                        return (React.createElement(resultRows_1.MBSearchResultMessageRow, { key: result.id, index: index, searchResult: result }));
                    default:
                        return null;
                }
            })));
}
