"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBVerifyCode = MBVerifyCode;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var formField_1 = require("@shared/components/form/formField");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var codeInput_1 = require("./codeInput");
var help_1 = require("./help");
function MBVerifyCode(_a) {
    var _this = this;
    var title = _a.title, isDesktop = _a.isDesktop, emailOrPhoneNumber = _a.emailOrPhoneNumber, onSubmit = _a.onSubmit;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = (0, react_1.useState)(false), submitting = _c[0], setSubmitting = _c[1];
    var submit = (0, react_1.useCallback)(function (code) { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setSubmitting(true);
                    react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss();
                    return [4 /*yield*/, onSubmit(code)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    // TODO(fant): use error messages from backend instead of assuming the invalid code error
                    setErrorMessage((0, i18n_1.i18n)('auth.verifyCode.invalidCodeErrorMessage'));
                    return [3 /*break*/, 4];
                case 3:
                    setSubmitting(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onSubmit]);
    var clearErrorMessage = (0, react_1.useCallback)(function () { return setErrorMessage(undefined); }, [setErrorMessage]);
    var align = isDesktop ? 'center' : 'left';
    return (react_1.default.createElement(react_1.default.Fragment, null,
        isDesktop ? react_1.default.createElement(components_1.MBView, { paddingBottom: 4 }) : react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }),
        react_1.default.createElement(typography_1.MBTextH1, { align: align, color: colors.accent }, title),
        react_1.default.createElement(components_1.MBView, { paddingBottom: 4 }),
        react_1.default.createElement(components_1.MBView, { center: true },
            react_1.default.createElement(typography_1.MBTextBody1, { color: colors.foregroundActive }, (0, i18n_1.i18n)('auth.verifyCode.instructions')),
            react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive }, emailOrPhoneNumber),
            react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }),
            react_1.default.createElement(formField_1.MBFormField, { error: errorMessage, children: function (props) { return (react_1.default.createElement(codeInput_1.MBVerifyCodeInput, __assign({}, props, { length: 6, autoFocus: true, onSubmit: submit, onChangeText: clearErrorMessage }))); } })),
        react_1.default.createElement(components_1.MBView, null, submitting && react_1.default.createElement(components_1.MBLoadingIndicator, { absolute: true, paddingTop: 3 })),
        react_1.default.createElement(components_1.MBView, { flex: !isDesktop, paddingBottom: 4 }),
        react_1.default.createElement(help_1.MBVerifyCodeHelp, null),
        react_1.default.createElement(components_1.MBNativeKeyboardSpacer, null)));
}
