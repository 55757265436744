"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectAvatarImage = MBSelectAvatarImage;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var UploadActions = __importStar(require("@shared/scenes/upload/store/actions"));
var util_1 = require("@shared/scenes/upload/util");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
function MBSelectAvatarImage(_a) {
    var _this = this;
    var imageUrl = _a.imageUrl, placeholder = _a.placeholder, description = _a.description, onStartSelectImage = _a.onStartSelectImage, onSelectedImage = _a.onSelectedImage, onUploadedImage = _a.onUploadedImage;
    var client = (0, client_1.useApolloClient)();
    var _b = (0, react_1.useState)(), uploadingImageUrl = _b[0], setUploadingImageUrl = _b[1];
    var uploading = !!uploadingImageUrl;
    var selectAndUploadImage = (0, react_1.useCallback)(function () {
        // Prevent this function from returning a promise so that the
        // description button doesn't show a loading indicator when pressed
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var image, uploadedImageUrl, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onStartSelectImage === null || onStartSelectImage === void 0 ? void 0 : onStartSelectImage();
                        return [4 /*yield*/, util_1.MBUploadUtil.selectImage()];
                    case 1:
                        image = _a.sent();
                        if (!image) {
                            return [2 /*return*/];
                        }
                        onSelectedImage === null || onSelectedImage === void 0 ? void 0 : onSelectedImage();
                        setUploadingImageUrl(image.url);
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, UploadActions.uploadFile(client, image)];
                    case 3:
                        uploadedImageUrl = _a.sent();
                        return [4 /*yield*/, onUploadedImage(uploadedImageUrl)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        setUploadingImageUrl(undefined);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); })();
    }, [setUploadingImageUrl, onStartSelectImage, onSelectedImage, onUploadedImage]);
    (0, react_1.useEffect)(function () {
        // without this effect the spinner would be hidden and the old image would flash
        // on the screen for a moment before the new imageUrl value is updated in the apollo
        // cache and propagated here via props
        // this ensures the uploading spinner is hidden in the same render as when the new
        // imageUrl value is available
        setUploadingImageUrl(undefined);
    }, [imageUrl]);
    var content = (function () {
        if (uploading) {
            return react_1.default.createElement(components_1.MBImage, { fill: true, source: { uri: uploadingImageUrl } });
        }
        if (!!imageUrl && !imageUrl.includes('imgur.com')) {
            return react_1.default.createElement(components_1.MBImage, { fill: true, source: { uri: imageUrl } });
        }
        return placeholder;
    })();
    return (react_1.default.createElement(components_1.MBView, { center: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: selectAndUploadImage, disabled: !onUploadedImage, style: styles_2.Styles.selectAvatarImageContainer },
            react_1.default.createElement(components_1.MBView, { fill: true, style: styles_2.Styles.selectAvatarImage },
                content,
                uploading && (react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, style: [styles_1.SharedStyles.absoluteCover, styles_1.SharedStyles.backdrop] }))),
            !!onUploadedImage && (react_1.default.createElement(components_1.MBBoxedIcon, { size: "small", source: require('@shared/assets/icons/v2/camera.png'), backgroundColor: styles_1.Colors.v2.purple, style: [styles_1.SharedStyles.bottomRight, styles_1.SharedStyles.shadow, styles_2.Styles.selectAvatarEditIcon] }))),
        !!description && !!onUploadedImage && (react_1.default.createElement(components_1.MBView, { paddingTop: "small" },
            react_1.default.createElement(components_1.MBButton, { title: description, theme: "transparent", onPress: selectAndUploadImage })))));
}
