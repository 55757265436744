"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCardDefinition = exports.getWorkflowImageUrl = void 0;
exports.hasWorkflowTemplate = hasWorkflowTemplate;
exports.hasVirtualCardDefinition = hasVirtualCardDefinition;
exports.isNoCodeCard = isNoCodeCard;
exports.prepareIdInput = prepareIdInput;
exports.getViewTemplateId = getViewTemplateId;
exports.toComponentOneOf = toComponentOneOf;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var getWorkflowImageUrl = function (association) {
    var _a, _b;
    if (!(0, lodash_1.isNil)(association === null || association === void 0 ? void 0 : association.cardDefinition)) {
        return association.cardDefinition.imageUrl;
    }
    return (_b = (_a = association.workflowTemplate) === null || _a === void 0 ? void 0 : _a.channelDefaultConfiguration) === null || _b === void 0 ? void 0 : _b.imageUrl;
};
exports.getWorkflowImageUrl = getWorkflowImageUrl;
var getCardDefinition = function (association) {
    var _a;
    if (!(0, lodash_1.isNil)(association === null || association === void 0 ? void 0 : association.cardDefinition)) {
        return association.cardDefinition;
    }
    return (_a = association === null || association === void 0 ? void 0 : association.workflowTemplate) === null || _a === void 0 ? void 0 : _a.cardDefinition;
};
exports.getCardDefinition = getCardDefinition;
function hasWorkflowTemplate(association) {
    return !(0, lodash_1.isNil)(association === null || association === void 0 ? void 0 : association.workflowTemplate);
}
function hasVirtualCardDefinition(item) {
    return !(0, lodash_1.isNil)(item === null || item === void 0 ? void 0 : item.workflowTemplateId);
}
function isNoCodeCard(card) {
    return (0, lodash_1.isNil)(card.cardDefinitionId) && !(0, lodash_1.isNil)(card.workflowTemplateId);
}
function prepareIdInput(id) {
    return (0, lodash_1.isNil)(id) ? undefined : id;
}
/**
 * The viewTemplateId is mapped to the template's slug in the backend.
 * This information is leaked here but we'll contain it here until we remove
 * UITemplates and we can map directly to WorkflowTemplate constructs
 */
function getViewTemplateId(template) {
    return template === null || template === void 0 ? void 0 : template.slug;
}
function toComponentOneOf(item) {
    if (!(0, lodash_1.isNil)(item)) {
        if ((0, hooks_1.isAddressComponent)(item)) {
            return { addressComponent: item };
        }
        if ((0, hooks_1.isAuditCheckboxComponent)(item)) {
            return { auditCheckboxComponent: item };
        }
        if ((0, hooks_1.isAuditTagComponent)(item)) {
            return { auditTagComponent: item };
        }
        if ((0, hooks_1.isAuditTextComponent)(item)) {
            return { auditTextComponent: item };
        }
        if ((0, hooks_1.isCombinedTagsComponent)(item)) {
            return { combinedTagsComponent: item };
        }
        if ((0, hooks_1.isDateComponent)(item)) {
            return { dateComponent: item };
        }
        if ((0, hooks_1.isEmailComponent)(item)) {
            return { emailComponent: item };
        }
        if ((0, hooks_1.isFileComponent)(item)) {
            return { fileComponent: item };
        }
        if ((0, hooks_1.isGeolocationComponent)(item)) {
            return { geolocationComponent: item };
        }
        if ((0, hooks_1.isNumberComponent)(item)) {
            return { numberComponent: item };
        }
        if ((0, hooks_1.isPersonComponent)(item)) {
            return { personComponent: item };
        }
        if ((0, hooks_1.isRelatedCardComponent)(item)) {
            return { relatedCardComponent: item };
        }
        if ((0, hooks_1.isRelatedCardLookupComponent)(item)) {
            return { relatedCardLookupComponent: item };
        }
        if ((0, hooks_1.isScheduledAutomationComponent)(item)) {
            return { scheduledAutomationComponent: item };
        }
        if ((0, hooks_1.isSignatureComponent)(item)) {
            return { signatureComponent: item };
        }
        if ((0, hooks_1.isSubformComponent)(item)) {
            return { subformComponent: item };
        }
        if ((0, hooks_1.isTextComponent)(item)) {
            return { textComponent: item };
        }
        if ((0, hooks_1.isTagComponent)(item)) {
            return { tagComponent: item };
        }
        if ((0, hooks_1.isTodoComponent)(item)) {
            return { todoComponent: item };
        }
        if ((0, hooks_1.isStaticTextComponent)(item)) {
            return { staticTextComponent: item };
        }
        if ((0, hooks_1.isInputButtonComponent)(item)) {
            return { inputButtonComponent: item };
        }
        if ((0, hooks_1.isTimeTrackerComponent)(item)) {
            return { timeTrackerComponent: item };
        }
        if ((0, hooks_1.isReferencedInComponent)(item)) {
            return { referencedInComponent: item };
        }
        if ((0, hooks_1.isEntityBatchComponent)(item)) {
            return { entityBatchComponent: item };
        }
    }
    return null;
}
