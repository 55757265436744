"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaveNewEntity = useSaveNewEntity;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useSaveNewEntity(workflowTemplateId) {
    var _a = (0, client_1.useMutation)(api_1.Mutations.entity.createWorkflowEntity), createEntityMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var _c = (0, react_1.useState)([]), errors = _c[0], setErrors = _c[1];
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(error)) {
            // parse remote errors
            setErrors([{ description: error.message }]);
        }
    }, [error]);
    var saveEntity = (0, react_1.useCallback)(function (fields) {
        if ((0, lodash_1.isNil)(workflowTemplateId)) {
            return Promise.reject('Workflow template ID is required');
        }
        var localErrors = [];
        Object.keys(fields).forEach(function (componentId) {
            var _a;
            var localError = (_a = fields[componentId]) === null || _a === void 0 ? void 0 : _a.validate();
            if (!(0, lodash_1.isNil)(localError)) {
                localErrors.push({ componentId: componentId, description: localError });
            }
        });
        setErrors(localErrors);
        if (!(0, lodash_1.isEmpty)(localErrors)) {
            return Promise.reject('Local validation');
        }
        var jsonFields = {};
        for (var property in fields) {
            var field = fields[property];
            jsonFields[property] = (0, lodash_1.isNil)(field) ? null : field.toFieldJson();
        }
        return createEntityMutation({
            variables: {
                input: {
                    fields: jsonFields,
                    workflowTemplateId: workflowTemplateId,
                },
            },
        });
    }, [createEntityMutation, workflowTemplateId]);
    return {
        loading: loading,
        errors: errors,
        saveEntity: saveEntity,
    };
}
