"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintProvider = PrintProvider;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_to_print_1 = require("react-to-print");
var PrintContext_1 = require("./PrintContext");
function PrintProvider(_a) {
    var children = _a.children;
    var contentRef = (0, react_1.useRef)();
    var onPrint = (0, react_to_print_1.useReactToPrint)({
        content: function () { return contentRef.current; },
        pageStyle: "\n      @page {\n        size: auto;\n        margin: 20mm;\n      }\n      @media print {\n        body {\n          -webkit-print-color-adjust: exact;\n          display: flex;\n          justify-content: center;\n        }\n      }\n    ",
    });
    return react_1.default.createElement(PrintContext_1.PrintContext.Provider, { value: { contentRef: contentRef, onPrint: onPrint } }, children);
}
