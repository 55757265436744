"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBTypingUsersBar = MBTypingUsersBar;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("../bubble/styles");
var styles_2 = require("./styles");
/*
    Create a "A, B and C are typing" string from a user list
*/
function toString(names) {
    if (names.length === 1) {
        return "".concat(names[0], " is typing...");
    }
    if (names.length === 2) {
        return "".concat(names[0], " and ").concat(names[1], " are typing...");
    }
    if (names.length === 3) {
        return "".concat(names[0], ", ").concat(names[1], " and ").concat(names[2], " are typing...");
    }
    var numOthers = names.length - 2; // 2 is the number of mentioned users
    return "".concat(names[0], ", ").concat(names[1], " and ").concat(numOthers, " others are typing...");
}
/*
    Thin bar listing all users that are currently typing
*/
function MBTypingUsersBar(_a) {
    var users = _a.users;
    // Note(fant): we don't want to show ourselves as typing
    var names = users.filter(function (user) { return !user.isMe; }).map(function (u) { return u.fullName; });
    if (!names.length) {
        return null;
    }
    return (React.createElement(components_1.MBView, { row: true, style: styles_2.Styles.bar, paddingHorizontal: "small" },
        React.createElement(components_1.MBView, { style: styles_1.Styles.userImagePlaceholder }),
        React.createElement(components_1.MBText, { size: "small", numberOfLines: 1 }, toString(names))));
}
