"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockRecurrence = MBCardInputBlockRecurrence;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var utils_1 = require("@shared/components/blocks/utils");
var styles_1 = require("@shared/styles");
var base_1 = require("../input/base");
var utils_2 = require("../input/utils");
var hooks_1 = require("./hooks");
var picker_1 = require("./picker");
var utils_3 = require("./utils");
function MBCardInputBlockRecurrence(_a) {
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = values[block.name];
    var string = (0, react_1.useMemo)(function () {
        if (!value) {
            return null;
        }
        return (0, utils_3.toString)(value);
    }, [value]);
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var containerRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(false), pickerVisible = _b[0], setPickerVisible = _b[1];
    var showPicker = (0, react_1.useCallback)(function () { return setPickerVisible(true); }, []);
    var hidePicker = (0, react_1.useCallback)(function () { return setPickerVisible(false); }, []);
    (0, hooks_1.usePresubmitRecurrenceUpdateScopePrompt)(block, containerRef);
    (0, hooks_1.useResetRecurrenceIfNoDateValue)(block);
    (0, hooks_1.useResetRecurrenceIfNotAvailable)(block);
    var available = (0, hooks_1.useIsRecurrenceFieldAvailable)(block);
    if (!available) {
        return null;
    }
    if (!block.dateFieldName) {
        return (react_1.default.createElement(TypoComponent, { color: colors.error.foreground }, "Warning: cannot render recurrence input without a referenced date. Did you forget to specify block.dateFieldName?"));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTouchableOpacity, { ref: containerRef, onPress: showPicker },
            react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderRight: utils_2.renderClearButton },
                react_1.default.createElement(TypoComponent, { color: value ? colors.foregroundActive : colors.foregroundInactive }, value ? string : block.placeholder))),
        pickerVisible && (react_1.default.createElement(picker_1.MBRecurrencePicker, { block: block, onClose: hidePicker, containerRef: containerRef }))));
}
