"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailEditField = EmailEditField;
exports.validateInput = validateInput;
var EmailValidator = __importStar(require("email-validator"));
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var EmailField_1 = require("@shared/data/entity/fields/EmailField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var EditFieldContainer_1 = require("./EditFieldContainer");
function EmailEditField(_a) {
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error;
    var _c = (0, react_1.useState)((_b = field === null || field === void 0 ? void 0 : field.jsonBlob.join(', ')) !== null && _b !== void 0 ? _b : ''), value = _c[0], setValue = _c[1];
    var _d = (0, react_1.useState)(error), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = (0, hooks_2.useBoolean)(false), isFocused = _e[0], onFocus = _e[1], onBlur = _e[2];
    var isValid = (0, react_1.useMemo)(function () { return validateInput(value); }, [value]);
    (0, react_1.useEffect)(function () {
        setErrorMessage(isValid ? undefined : (0, i18n_1.i18n)('workflowTemplate.component.error.email.invalid'));
    }, [isValid]);
    (0, react_1.useEffect)(function () {
        var emails = getEmailsFromInput(value);
        var updatedField = isValid ? new EmailField_1.EmailField(emails) : null;
        updateField(component.id, updatedField);
    }, [value, isValid, updateField, component.id]);
    var onClear = (0, react_1.useCallback)(function () {
        setValue('');
    }, [setValue]);
    var hasValue = !(0, lodash_1.isEmpty)(value);
    var isReadOnly = !!component.readonly;
    var showClearButton = hasValue && !isReadOnly;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasValue, onClear: showClearButton ? onClear : undefined },
        react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, inputMode: "email", textContentType: "emailAddress", value: value, onChangeText: setValue, placeholder: (0, hooks_1.getComponentPlaceholder)(component), onBlur: onBlur, onFocus: onFocus, editable: !isReadOnly }),
        !(0, lodash_1.isNil)(errorMessage) && !isFocused && (react_1.default.createElement(components_1.MBView, { paddingTop: 0.5 },
            react_1.default.createElement(components_1.MBTextBody2, { color: styles_1.Colors.v2.actionWarmRed }, errorMessage)))));
}
function validateInput(input) {
    if ((0, lodash_1.isEmpty)(input.trim())) {
        return true;
    }
    var emails = getEmailsFromInput(input);
    return emails.every(function (email) { return EmailValidator.validate(email); });
}
function getEmailsFromInput(input) {
    return input.replace(/ /g, '').split(',').filter(Boolean);
}
