"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionHeader = MBCardCollectionHeader;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var hook_1 = require("@shared/util/actionSheet/hook");
var i18n_1 = require("@shared/util/i18n");
var templateSwitcher_1 = require("./templateSwitcher");
function MBCardCollectionHeader(_a) {
    var channelName = _a.channelName, selectedTemplate = _a.selectedTemplate, isDesktop = _a.isDesktop, selectableTemplates = _a.selectableTemplates, onSelectTemplate = _a.onSelectTemplate, onChannelDetails = _a.onChannelDetails;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var verticalGap = isDesktop ? (0, components_1.grid)(1) : 0;
    var showTemplateSwitcherActionSheet = (0, hook_1.useActionSheet)({
        webHorizontalAlignment: 'center',
        webPosition: function (parent) { return ({
            position: 'absolute',
            width: 200,
            top: parent.top + parent.height - verticalGap,
            left: parent.left,
            right: window.innerWidth - parent.right,
        }); },
        options: __spreadArray(__spreadArray([], selectableTemplates.map(function (t) { return ({
            title: t.title,
            onPress: function () { return onSelectTemplate(t.slug); },
        }); }), true), [
            {
                title: (0, i18n_1.i18n)('channel.details'),
                onPress: onChannelDetails,
                hidden: isDesktop,
            },
        ], false),
    });
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: react_native_1.Platform.OS !== 'android' && { alignItems: 'center' }, onPress: showTemplateSwitcherActionSheet },
        react_1.default.createElement(typography_1.MBTextBody1Bold, { color: colors.foregroundActive, numberOfLines: 1 }, channelName),
        react_1.default.createElement(templateSwitcher_1.MBCardCollectionTemplateSwitcher, { isDesktop: isDesktop, selectedTemplate: selectedTemplate })));
}
