"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.MBCardInputBlockQuestion = MBCardInputBlockQuestion;
// @ts-strict-ignore
var schemas_1 = require("@coast/schemas");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var InputBlockMedia_1 = require("@shared/components/blocks/lib/inputFile/InputBlockMedia");
var hooks_1 = require("@shared/components/blocks/lib/inputFile/hooks");
var actionButton_1 = require("@shared/components/blocks/lib/inputQuestion/actionButton");
var checkbox_1 = require("@shared/components/blocks/lib/inputQuestion/checkbox");
var file_1 = require("@shared/components/blocks/lib/inputQuestion/file");
var notes_1 = require("@shared/components/blocks/lib/inputQuestion/notes");
var singleSelect_1 = require("@shared/components/blocks/lib/inputQuestion/singleSelect");
var textArea_1 = require("@shared/components/blocks/lib/inputQuestion/textArea");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var ChannelPermissionsContext_1 = require("@shared/util/permissions/ChannelPermissionsContext");
var hooks_3 = require("@shared/util/permissions/hooks");
function MBCardInputBlockQuestion(_a) {
    var _b, _c;
    var block = _a.block;
    var mode = block.mode || 'creation';
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var channelId = (0, react_1.useContext)(ChannelPermissionsContext_1.ChannelPermissionsContext).channelId;
    var canUpdate = (0, hooks_3.usePermission)({ action: 'update', subject: 'Card', channelId: channelId }).enabled;
    var _d = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _d.setFieldValue, values = _d.values, submitForm = _d.submitForm;
    var actionButtonRef = (0, react_1.useRef)();
    var _e = (0, hooks_2.useTrigger)(), notesTrigger = _e[0], onNotesPress = _e[1];
    var _f = (0, hooks_2.useTrigger)(), answerTrigger = _f[0], onAnswerPress = _f[1];
    var maxImageWidth = isDesktop ? 300 : react_native_1.Dimensions.get('window').width / 3;
    var maxImageHeight = (maxImageWidth * 2) / 3;
    var minImageWidth = maxImageWidth / 3;
    var fieldName = block.name;
    var persistForm = (0, react_1.useCallback)(function () {
        if (mode === 'completion') {
            submitForm();
        }
    }, [mode, submitForm]);
    var onChange = (0, react_1.useCallback)(function (value) {
        setFieldValue(fieldName, value);
        persistForm();
    }, [fieldName, persistForm, setFieldValue]);
    var notesFieldName = (0, schemas_1.makeTupleFieldName)(fieldName, 'notes');
    var notesValue = values[notesFieldName];
    var onNotesChange = (0, react_1.useCallback)(function (value) {
        setFieldValue(notesFieldName, value);
        persistForm();
    }, [setFieldValue, notesFieldName, persistForm]);
    var fileFieldName = (0, schemas_1.makeTupleFieldName)(fieldName, 'file');
    var fileValue = (_b = values[fileFieldName]) !== null && _b !== void 0 ? _b : [];
    var onFileAdded = (0, react_1.useCallback)(function (file) {
        var newValues = __spreadArray([], fileValue, true);
        newValues.push(file);
        setFieldValue(fileFieldName, newValues);
        persistForm();
    }, [fileValue, setFieldValue, fileFieldName, persistForm]);
    var onFileRemoved = (0, react_1.useCallback)(function (file) {
        var newValues = fileValue.filter(function (value) {
            return value.url !== file.url;
        });
        setFieldValue(fileFieldName, newValues);
        persistForm();
    }, [fileValue, setFieldValue, fileFieldName, persistForm]);
    var _g = (0, hooks_1.useFileUpload)({
        onFileAdded: onFileAdded,
        onFileRemoved: onFileRemoved,
    }), showFilePicker = _g.showFilePicker, uploading = _g.uploading;
    return (react_1.default.createElement(components_1.MBView, { style: exports.styles.container },
        block.fieldType === 'questionSingleSelect' && (react_1.default.createElement(singleSelect_1.MBCardInputBlockQuestionSingleSelect, { fieldName: fieldName, label: block.label, trigger: answerTrigger, disabled: !canUpdate, max: (_c = block.validation) === null || _c === void 0 ? void 0 : _c.max, onChange: onChange })),
        block.fieldType === 'questionCheckbox' && (react_1.default.createElement(checkbox_1.MBCardInputBlockQuestionCheckbox, { fieldName: fieldName, label: block.label, disabled: !canUpdate, onChange: onChange })),
        block.fieldType === 'questionTextArea' && (react_1.default.createElement(textArea_1.MBCardInputBlockQuestionTextArea, { fieldName: fieldName, label: block.label, trigger: answerTrigger, disabled: !canUpdate, bottomSheetRef: actionButtonRef, onChange: onChange })),
        !!(fileValue === null || fileValue === void 0 ? void 0 : fileValue.length) && (react_1.default.createElement(components_1.MBView, { paddingBottom: 1 },
            react_1.default.createElement(components_1.MBView, { style: { borderRadius: (0, components_1.grid)(1), overflow: 'hidden' } },
                react_1.default.createElement(InputBlockMedia_1.InputBlockMedia, { files: fileValue, maxHeight: maxImageHeight, maxWidth: maxImageWidth, minWidth: minImageWidth, compactFileView: false, editable: canUpdate, onRemoved: onFileRemoved })))),
        (notesValue === null || notesValue === void 0 ? void 0 : notesValue.length) > 0 && (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !canUpdate, onPress: onNotesPress },
            react_1.default.createElement(components_1.MBView, { style: exports.styles.textarea, paddingVertical: 2, paddingHorizontal: 1 },
                react_1.default.createElement(components_1.MBTextBody1, null, notesValue)))),
        canUpdate && (react_1.default.createElement(components_1.MBView, { row: true },
            block.fieldType !== 'questionCheckbox' && (react_1.default.createElement(actionButton_1.MBQuestionInputActionButton, { label: (0, i18n_1.i18n)('card.blocks.question.answerButtonLabel'), onPress: onAnswerPress, icon: "pencil", innerRef: actionButtonRef })),
            react_1.default.createElement(components_1.MBView, { paddingRight: 1 }),
            react_1.default.createElement(file_1.MBQuestionFile, { label: (0, i18n_1.i18n)('card.blocks.question.addFile'), onPress: showFilePicker, uploading: uploading }),
            react_1.default.createElement(components_1.MBView, { paddingRight: 1 }),
            react_1.default.createElement(notes_1.MBQuestionNotes, { label: (0, i18n_1.i18n)('card.blocks.question.notes'), placeholder: (0, i18n_1.i18n)('card.blocks.question.notesPlaceholder'), value: notesValue, onChange: onNotesChange, trigger: notesTrigger })))));
}
exports.styles = react_native_1.StyleSheet.create({
    container: {
        marginVertical: (0, components_1.grid)(1),
        paddingTop: (0, components_1.grid)(2),
        paddingHorizontal: (0, components_1.grid)(1),
        marginHorizontal: (0, components_1.grid)(2),
        borderTopWidth: 1,
        borderTopColor: '#E6E6E6',
    },
    textarea: {
        backgroundColor: '#F7F7F7',
        borderRadius: (0, components_1.grid)(1),
        padding: (0, components_1.grid)(1),
        paddingLeft: (0, components_1.grid)(2),
        paddingRight: (0, components_1.grid)(2),
        marginBottom: (0, components_1.grid)(1),
    },
});
