"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDirectoryTabs = MBDirectoryTabs;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var tabview_1 = require("@shared/components/tabview");
var brandPanel_1 = require("@shared/scenes/directory/components/brandPanel");
var inviteRow_1 = require("@shared/scenes/directory/components/inviteRow");
var userRow_1 = require("@shared/scenes/directory/components/userRow");
var i18n_1 = require("@shared/util/i18n");
var context_1 = require("../containers/context");
var TAB_LABELS;
(function (TAB_LABELS) {
    TAB_LABELS["MEMBERS"] = "members";
    TAB_LABELS["PENDING"] = "pending";
    TAB_LABELS["DEACTIVATED"] = "deactivated";
})(TAB_LABELS || (TAB_LABELS = {}));
function MBDirectoryTabs(_a) {
    var staff = _a.staff, pending = _a.pending, deactivated = _a.deactivated;
    var _b = (0, react_1.useContext)(context_1.DirectoryContext), business = _b.business, hideBrandPanel = _b.hideBrandPanel, isBusinessAdmin = _b.isBusinessAdmin, onChangeBusinessName = _b.onChangeBusinessName, onChangeBusinessImage = _b.onChangeBusinessImage;
    return (react_1.default.createElement(tabview_1.MBTabView, { labels: Object.values(TAB_LABELS) }, function (label) {
        switch (label) {
            case TAB_LABELS.MEMBERS:
                return (react_1.default.createElement(components_1.MBScrollView, { nativeKeyboardDismissMode: "on-drag" },
                    !hideBrandPanel && (react_1.default.createElement(brandPanel_1.MBBrandPanel, { business: business, staffCount: staff.length, onSubmitName: isBusinessAdmin && onChangeBusinessName, onSubmitAvatar: isBusinessAdmin && onChangeBusinessImage })),
                    !!hideBrandPanel && react_1.default.createElement(components_1.MBView, { paddingBottom: "small" }),
                    react_1.default.createElement(inviteRow_1.MBInviteRow, null),
                    staff.map(function (user) { return (react_1.default.createElement(userRow_1.MBDirectoryUserRow, { key: user.id, user: user })); })));
            case TAB_LABELS.PENDING:
                if (pending.length > 0) {
                    return (react_1.default.createElement(components_1.MBScrollView, { nativeKeyboardDismissMode: "on-drag" },
                        react_1.default.createElement(components_1.MBView, { paddingBottom: "small" },
                            react_1.default.createElement(components_1.MBListHeader, { subtitle: (0, i18n_1.i18n)('directory.subtitle.pending', {
                                    count: pending.length,
                                    name: business.name,
                                }) })),
                        pending.map(function (user) { return (react_1.default.createElement(userRow_1.MBDirectoryUserRow, { key: user.id, user: user })); })));
                }
                return (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('directory.emptyState.pending.title'), description: (0, i18n_1.i18n)('directory.emptyState.pending.message', { name: business.name }), image: require('@shared/assets/images/v2/envelope.png') }));
            case TAB_LABELS.DEACTIVATED:
                if (deactivated.length > 0) {
                    return (react_1.default.createElement(components_1.MBScrollView, { nativeKeyboardDismissMode: "on-drag" },
                        react_1.default.createElement(components_1.MBView, { paddingBottom: "small" },
                            react_1.default.createElement(components_1.MBListHeader, { subtitle: (0, i18n_1.i18n)('directory.subtitle.deactivated', {
                                    count: deactivated.length,
                                    name: business.name,
                                }) })),
                        deactivated.map(function (user) { return (react_1.default.createElement(userRow_1.MBDirectoryUserRow, { key: user.id, user: user })); })));
                }
                return (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('directory.emptyState.deactivated.title'), description: (0, i18n_1.i18n)('directory.emptyState.deactivated.message', {
                        name: business.name,
                    }), image: require('@shared/assets/images/v2/avatar.png') }));
        }
    }));
}
