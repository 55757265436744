"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowComponentWrapper = WorkflowComponentWrapper;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var label_1 = require("@shared/components/blocks/utils/label");
var styles_1 = require("@shared/styles");
var hooks_1 = require("../../hooks");
var WorkflowComponentStyles_1 = require("./WorkflowComponentStyles");
function WorkflowComponentWrapper(_a) {
    var children = _a.children, extraChildren = _a.extraChildren, paddingHorizontal = _a.paddingHorizontal, iconPad = _a.iconPad, component = _a.component, showLabel = _a.showLabel, _b = _a.showIcon, showIcon = _b === void 0 ? true : _b, error = _a.error;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    if (!(0, hooks_1.isInputComponent)(component)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { paddingHorizontal: paddingHorizontal, paddingVertical: 1, fullWidth: true, style: WorkflowComponentStyles_1.WorkflowComponentStyles.label },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { flexGrow: 1 } },
            !(0, lodash_1.isNil)(component.icon) && showIcon && (react_1.default.createElement(components_1.MBView, { paddingRight: iconPad, style: { flexShrink: 0, flexGrow: 0 } },
                react_1.default.createElement(components_1.MBIconV2, { color: !(0, lodash_1.isNil)(error) ? colors.error.foreground : colors.accent, name: component.icon, size: 40 }))),
            react_1.default.createElement(components_1.MBView, { flex: true, style: { flexDirection: 'column', flexShrink: 0, flexGrow: 1 } },
                showLabel && (react_1.default.createElement(components_1.MBView, null,
                    react_1.default.createElement(label_1.MBBlockLabel, { label: component.label }))),
                children)),
        extraChildren && (react_1.default.createElement(components_1.MBView, { paddingLeft: showIcon ? 40 / 8 + iconPad : 0 }, extraChildren)),
        !(0, lodash_1.isNil)(error) && (react_1.default.createElement(components_1.MBView, { paddingTop: 0, padding: 2 },
            react_1.default.createElement(components_1.MBTextBody2, { color: styles_1.Colors.v2.actionWarmRed }, error)))));
}
