"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditRecurringEntity = useEditRecurringEntity;
exports.getScheduledDate = getScheduledDate;
var lodash_1 = require("lodash");
var react_1 = require("react");
var DateField_1 = require("@shared/data/entity/fields/DateField");
var EntityBatchField_1 = require("@shared/data/entity/fields/EntityBatchField");
var Field_1 = require("@shared/data/entity/fields/Field");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var useEditFields_1 = require("./useEditFields");
var useUpdateRecurringEntity_1 = require("./useUpdateRecurringEntity");
function useEditRecurringEntity(_a) {
    var entityId = _a.entityId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId, workflowTemplateId = _a.workflowTemplateId, allOrUpcoming = _a.allOrUpcoming;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var editFields = (0, useEditFields_1.useEditFields)(entityId, workflowTemplate);
    var fields = editFields.fields, modifiedFields = editFields.modifiedFields, isDirty = editFields.isDirty, updateField = editFields.updateField;
    var update = (0, useUpdateRecurringEntity_1.useUpdateRecurringEntity)(workflowTemplate);
    var _b = useStatus(editFields, update), status = _b[0], setStatus = _b[1];
    var supportedComponents = useSupportedComponents({ workflowTemplate: workflowTemplate, fields: fields });
    var shouldUpdateAllEntities = allOrUpcoming === 'all';
    var onPressSave = useOnPressSave({
        fields: fields,
        modifiedFields: modifiedFields,
        supportedComponents: supportedComponents,
        setStatus: setStatus,
        update: update,
        workflowTemplateId: workflowTemplateId,
        recurringEntitiesScheduleId: recurringEntitiesScheduleId,
        shouldUpdateAllEntities: shouldUpdateAllEntities,
    });
    return (0, react_1.useMemo)(function () {
        return {
            components: supportedComponents,
            errors: update.errors,
            fields: fields,
            updateField: updateField,
            status: status,
            onPressSave: onPressSave,
            isDirty: isDirty,
            isLoading: status === 'loading',
        };
    }, [onPressSave, status, supportedComponents, fields, update.errors, updateField, isDirty]);
}
function useStatus(fields, update) {
    var _a = (0, react_1.useState)('loading'), status = _a[0], setStatus = _a[1];
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (fields.loading || update.loading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(fields.error) && !(0, lodash_1.isEmpty)(update.errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [fields.error, fields.loading, update.errors, update.loading]);
    return [status, setStatus];
}
function useOnPressSave(_a) {
    var _this = this;
    var fields = _a.fields, modifiedFields = _a.modifiedFields, supportedComponents = _a.supportedComponents, setStatus = _a.setStatus, update = _a.update, workflowTemplateId = _a.workflowTemplateId, recurringEntitiesScheduleId = _a.recurringEntitiesScheduleId, shouldUpdateAllEntities = _a.shouldUpdateAllEntities;
    return (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var confirmationStrings, confirmed, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((0, lodash_1.isNil)(fields) || (0, lodash_1.isNil)(modifiedFields) || (0, lodash_1.isNil)(workflowTemplateId)) {
                        return [2 /*return*/];
                    }
                    confirmationStrings = getConfirmationStrings({
                        modifiedFields: modifiedFields,
                        supportedComponents: supportedComponents,
                        shouldUpdateAllEntities: shouldUpdateAllEntities,
                    });
                    return [4 /*yield*/, alert_1.MBAlert.confirm(confirmationStrings)];
                case 1:
                    confirmed = _a.sent();
                    if (!confirmed) {
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, save({
                            fields: fields,
                            modifiedFields: modifiedFields,
                            update: update,
                            workflowTemplateId: workflowTemplateId,
                            recurringEntitiesScheduleId: recurringEntitiesScheduleId,
                            shouldUpdateAllEntities: shouldUpdateAllEntities,
                        })];
                case 3:
                    _a.sent();
                    setStatus('success');
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    setStatus('error');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        fields,
        modifiedFields,
        workflowTemplateId,
        shouldUpdateAllEntities,
        supportedComponents,
        update,
        recurringEntitiesScheduleId,
        setStatus,
    ]);
}
function getConfirmationStrings(_a) {
    var modifiedFields = _a.modifiedFields, supportedComponents = _a.supportedComponents, shouldUpdateAllEntities = _a.shouldUpdateAllEntities;
    var title = (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.title');
    var messageHeader = shouldUpdateAllEntities
        ? (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.messageHeader.allCards')
        : (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.messageHeader.upcomingCards');
    var fieldListHeader = (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.fieldListHeader');
    var modifiedList = Object.keys(modifiedFields)
        .map(function (key) { return getComponentLabel(key, supportedComponents); })
        .map(function (label) { return "\u2022 ".concat(label); });
    var message = __spreadArray([messageHeader, '', fieldListHeader], modifiedList, true).join('\n');
    var confirmText = (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.confirmButton');
    var cancelText = (0, i18n_1.i18n)('card.bulkUpdate.saveConfirmation.cancelButton');
    return { title: title, message: message, confirmText: confirmText, cancelText: cancelText };
}
function save(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var fromDate, input;
        var fields = _b.fields, modifiedFields = _b.modifiedFields, update = _b.update, workflowTemplateId = _b.workflowTemplateId, recurringEntitiesScheduleId = _b.recurringEntitiesScheduleId, shouldUpdateAllEntities = _b.shouldUpdateAllEntities;
        return __generator(this, function (_c) {
            fromDate = shouldUpdateAllEntities ? undefined : getScheduledDate(fields);
            input = {
                fields: modifiedFields,
                workflowTemplateId: workflowTemplateId,
                recurringEntitiesScheduleId: recurringEntitiesScheduleId,
                fromDate: fromDate,
            };
            return [2 /*return*/, update.updateRecurringEntity(input)];
        });
    });
}
function getScheduledDate(fields) {
    var batchDateFieldId = getBatchDateFieldId(fields);
    if ((0, lodash_1.isNil)(batchDateFieldId)) {
        return undefined;
    }
    var batchDateField = (0, Field_1.castField)(fields[batchDateFieldId], DateField_1.DateField);
    return batchDateField === null || batchDateField === void 0 ? void 0 : batchDateField.date;
}
function useSupportedComponents(_a) {
    var workflowTemplate = _a.workflowTemplate, fields = _a.fields;
    // filter out unsupported components and mark the batch date field as readonly
    return (0, react_1.useMemo)(function () {
        var _a;
        var components = (_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) !== null && _a !== void 0 ? _a : [];
        var batchDateFieldId = getBatchDateFieldId(fields);
        return components
            .filter(hooks_1.isInputComponent)
            .filter(isComponentSupported)
            .map(function (component) {
            if (component.id === batchDateFieldId) {
                return __assign(__assign({}, component), { readonly: true });
            }
            else {
                return component;
            }
        });
    }, [fields, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components]);
}
function getBatchDateFieldId(fields) {
    var _a;
    if ((0, lodash_1.isNil)(fields)) {
        return undefined;
    }
    var batchField = Object.values(fields).find(function (field) { return (field === null || field === void 0 ? void 0 : field.fieldType) === 'EntityBatch'; });
    if (!(0, EntityBatchField_1.isEntityBatchField)(batchField)) {
        return undefined;
    }
    var ref = (_a = batchField.refs) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === batchField.id; });
    return ref === null || ref === void 0 ? void 0 : ref.dateComponentId;
}
function isComponentSupported(component) {
    switch (component.type) {
        case 'ADDRESS':
        case 'DATE':
        case 'EMAIL':
        case 'FILE':
        case 'NUMBER':
        case 'PERSON':
        case 'RELATED_CARD':
        case 'SUBFORM':
        case 'TAG':
        case 'TEXT':
        case 'TODO':
            return true;
        // other types we don't support yet
        case 'AUDIT_CHECKBOX':
        case 'AUDIT_TAG':
        case 'AUDIT_TEXT':
        case 'COMBINED_TAGS':
        case 'ENTITY_BATCH':
        case 'GEOLOCATION':
        case 'INPUT_BUTTON':
        case 'REFERENCED_IN':
        case 'RELATED_CARD_LOOKUP':
        case 'SCHEDULED_AUTOMATION':
        case 'SIGNATURE':
        case 'STATIC_TEXT':
        case 'TIME_TRACKER':
        default:
            return false;
    }
}
function getComponentLabel(componentId, components) {
    var _a;
    var match = components.find(function (component) { return component.id === componentId; });
    return (_a = match === null || match === void 0 ? void 0 : match.label) !== null && _a !== void 0 ? _a : componentId;
}
