"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkflowEntity = toCreateWorkflowEntity;
exports.isCreateableField = isCreateableField;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function toCreateWorkflowEntity(entity, workflowTemplate) {
    var allFields = entity.allFields, workflowTemplateId = entity.workflowTemplateId;
    var fields = {};
    allFields.forEach(function (value, key) {
        var _a;
        if (!(0, lodash_1.isNil)(workflowTemplate) && isCreateableField(key, workflowTemplate)) {
            fields[key] = (_a = value === null || value === void 0 ? void 0 : value.toFieldJson()) !== null && _a !== void 0 ? _a : undefined;
        }
    });
    return {
        fields: fields,
        workflowTemplateId: workflowTemplateId,
    };
}
function isCreateableField(fieldKey, workflowTemplate) {
    var _a;
    var component = (_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.id === fieldKey; });
    return (!(0, lodash_1.isNil)(component) &&
        (0, hooks_1.isInputComponent)(component) &&
        !(0, hooks_1.isRelatedCardLookupComponent)(component) &&
        component.isServerManaged !== true);
}
