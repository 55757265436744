"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserTypingRemovalScheduler = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var api_1 = require("@shared/api");
var client_1 = require("@shared/api/client");
var config_1 = require("@shared/config");
/*
    This file contains logic to remove typing users X seconds after they've
    last typed. This logic is handled by the client; the server just pushes
    isTyping WS updates whenever a user types something.

    Examples:
     1. A types => waits for 5 seconds => A should no longer be seen as typing
     2. A types => waits for 2 seconds => A types => waits for 3 seconds => A should still be typing
*/
var MBUserTypingRemovalScheduler = /** @class */ (function () {
    function MBUserTypingRemovalScheduler() {
        var _this = this;
        // { [channel.slug]: { [user.id]: timeout } }
        this.timeouts = {};
        // Note: we set remove as prop on the class (instead of function)
        // so that we can access "this" from the class' context instead of
        // the caller's context
        this.remove = function (user, channelSlug) {
            _this.clear(user, channelSlug);
            var data = client_1.apolloClient.readQuery({
                query: api_1.Queries.channel.chatThread,
                variables: { slug: channelSlug },
            });
            if (!data || !data.channel) {
                return;
            }
            client_1.apolloClient.writeQuery({
                data: __assign(__assign({}, data), { channel: __assign(__assign({}, data.channel), { isTyping: data.channel.isTyping.filter(function (u) { return u.id !== user.id; }) }) }),
                query: api_1.Queries.channel.chatThread,
                variables: { slug: channelSlug },
            });
        };
    }
    MBUserTypingRemovalScheduler.prototype.clear = function (user, channelSlug) {
        var _a;
        if (!(0, lodash_1.isNil)((_a = this.timeouts[channelSlug]) === null || _a === void 0 ? void 0 : _a[user.id])) {
            clearTimeout(this.timeouts[channelSlug][user.id]);
            this.timeouts[channelSlug][user.id] = undefined;
        }
    };
    MBUserTypingRemovalScheduler.prototype.schedule = function (user, channelSlug) {
        this.clear(user, channelSlug);
        var timeout = setTimeout(this.remove, config_1.Config.IS_TYPING_TIMEOUT_DURATION, 
        // these two parameters will be sent to this.remove
        // https://stackoverflow.com/a/13148281/9117995
        user, channelSlug);
        // Store the timeout id from memory
        if (!this.timeouts[channelSlug]) {
            this.timeouts[channelSlug] = {};
        }
        this.timeouts[channelSlug][user.id] = timeout;
    };
    return MBUserTypingRemovalScheduler;
}());
exports.MBUserTypingRemovalScheduler = MBUserTypingRemovalScheduler;
