"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBottomSheetTitleBar = void 0;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
// number of grid units to allow for left and right icon/button
var HORIZONTAL_TITLE_OFFSET = 13;
var DEFAULT_ICON_SIZE = 5;
var MBBottomSheetTitleBar = function (_a) {
    var title = _a.title, leftIcon = _a.leftIcon, leftIconColor = _a.leftIconColor, leftIconSize = _a.leftIconSize, leftTitlePosition = _a.leftTitlePosition, onLeftIconPress = _a.onLeftIconPress, rightIcon = _a.rightIcon, rightIconColor = _a.rightIconColor, rightIconSize = _a.rightIconSize, rightButtonText = _a.rightButtonText, rightTitlePosition = _a.rightTitlePosition, titleSize = _a.titleSize, onRightIconPress = _a.onRightIconPress, _b = _a.borderBottomWidth, borderBottomWidth = _b === void 0 ? 1 : _b;
    var colorTheme = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(react_native_1.View, { style: [
            {
                borderBottomWidth: borderBottomWidth,
                borderColor: colorTheme.section.border,
            },
            styles_1.SharedStyles.centerChildren,
        ] },
        react_1.default.createElement(components_1.MBView, { style: { height: (0, components_1.grid)(8) }, center: true, row: true, fullWidth: true },
            leftIcon && onLeftIconPress && (react_1.default.createElement(react_native_1.View, { style: { position: 'absolute', left: 0, paddingHorizontal: (0, components_1.grid)(2), zIndex: 2 } },
                react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onLeftIconPress },
                    react_1.default.createElement(components_1.MBIconV2, { name: leftIcon, size: leftIconSize !== null && leftIconSize !== void 0 ? leftIconSize : (0, components_1.grid)(DEFAULT_ICON_SIZE), color: leftIconColor || colorTheme.foregroundActive })))),
            react_1.default.createElement(react_native_1.View, { style: {
                    position: 'absolute',
                    left: !(0, lodash_1.isNil)(leftIcon) && !(0, lodash_1.isNil)(rightIcon)
                        ? (0, components_1.grid)(HORIZONTAL_TITLE_OFFSET)
                        : leftTitlePosition !== null && leftTitlePosition !== void 0 ? leftTitlePosition : (0, components_1.grid)(2),
                    right: !(0, lodash_1.isNil)(leftIcon) && !(0, lodash_1.isNil)(rightIcon)
                        ? (0, components_1.grid)(HORIZONTAL_TITLE_OFFSET)
                        : rightTitlePosition !== null && rightTitlePosition !== void 0 ? rightTitlePosition : (0, components_1.grid)(2),
                    alignItems: 'center',
                } },
                react_1.default.createElement(components_1.MBTextH4Semibold, { style: titleSize && { fontSize: titleSize }, numberOfLines: 1 }, title)),
            rightIcon && onRightIconPress && (react_1.default.createElement(react_native_1.View, { style: { position: 'absolute', right: 0, paddingRight: (0, components_1.grid)(2) } },
                react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onRightIconPress },
                    react_1.default.createElement(components_1.MBIconV2, { name: rightIcon, size: rightIconSize !== null && rightIconSize !== void 0 ? rightIconSize : (0, components_1.grid)(DEFAULT_ICON_SIZE), color: rightIconColor || colorTheme.foregroundActive })))),
            onRightIconPress && rightButtonText && !rightIcon && (react_1.default.createElement(react_native_1.View, { style: { position: 'absolute', right: (0, components_1.grid)(2) } },
                react_1.default.createElement(components_1.MBButton, { size: "small", theme: "normal", onPress: onRightIconPress, title: rightButtonText }))))));
};
exports.MBBottomSheetTitleBar = MBBottomSheetTitleBar;
