"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLowCodeViewTemplate = isLowCodeViewTemplate;
var lodash_1 = require("lodash");
function isLowCodeViewTemplate(_a) {
    var viewTemplate = _a.viewTemplate, _b = _a.shouldUseNoCodeBoardView, shouldUseNoCodeBoardView = _b === void 0 ? false : _b;
    switch (viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.subtype) {
        case 'TABLE':
        case 'LIST':
            return false;
        case 'CALENDAR':
            return (0, lodash_1.isNil)(viewTemplate.calendarDateComponentId);
        case 'BOARD':
            return !shouldUseNoCodeBoardView;
        default:
            return true;
    }
}
