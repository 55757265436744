"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardOverflowButton = CardOverflowButton;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var CardExport_1 = require("@shared/scenes/cards/details/containers/CardExport");
var ShareSheet_1 = require("@shared/scenes/cards/details/containers/ShareSheet");
var styles_1 = require("@shared/styles");
var useOverflowMenu_1 = require("../../../listView/hooks/useOverflowMenu");
function CardOverflowButton(_a) {
    var entity = _a.entity, workflowTemplate = _a.workflowTemplate, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? styles_1.Colors.v2.gray05 : _b, openEntity = _a.openEntity, _c = _a.webHorizontalAlignment, webHorizontalAlignment = _c === void 0 ? 'right' : _c, style = _a.style;
    var _d = (0, react_1.useState)(false), showShareSheet = _d[0], setShowShareSheet = _d[1];
    var _e = (0, react_1.useState)(false), showDownloadSheet = _e[0], setShowDownloadSheet = _e[1];
    var anchorRef = (0, react_1.useRef)();
    var _f = (0, useOverflowMenu_1.useOverflowMenu)({
        entity: entity,
        workflowTemplate: workflowTemplate,
        onShare: function () { return setShowShareSheet(true); },
        onDownload: function () { return setShowDownloadSheet(true); },
        openEntity: openEntity,
        webHorizontalAlignment: webHorizontalAlignment,
    }), showOverflowMenu = _f.showOverflowMenu, shouldShowOverflowMenu = _f.shouldShowOverflowMenu;
    if (!shouldShowOverflowMenu) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { ref: anchorRef, style: style },
        react_1.default.createElement(components_1.MBIconButton, { source: require('@shared/assets/icons/v2/dots.png'), size: "small", inverted: true, onPress: function () { return showOverflowMenu(anchorRef.current); }, backgroundColor: backgroundColor }),
        showShareSheet && (react_1.default.createElement(ShareSheet_1.ShareSheet, { containerRef: anchorRef, card: { id: entity.id, workflowTemplateId: workflowTemplate.id }, onCloseSheet: function () { return setShowShareSheet(false); } })),
        showDownloadSheet && (react_1.default.createElement(CardExport_1.CardExport, { containerRef: anchorRef, card: { id: entity.id, workflowTemplateId: workflowTemplate.id }, onCloseSheet: function () { return setShowDownloadSheet(false); } }))));
}
