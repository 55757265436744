"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTotalUnreadCount = useTotalUnreadCount;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var FORCE_UPDATE_DELAY = 10000;
function useTotalUnreadCount() {
    var _this = this;
    var _a, _b;
    var _c = (0, client_1.useQuery)(api_1.Queries.business.allWithUnreadCount, {
        fetchPolicy: 'network-only',
    }), data = _c.data, refetch = _c.refetch, subscribeToMore = _c.subscribeToMore;
    var refreshUnread = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetch()];
                case 1:
                    _a.sent();
                    subscribeToMore({
                        document: api_1.Subscriptions.business.unreadCount,
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [refetch, subscribeToMore]);
    var refresh = (0, react_1.useMemo)(function () { return (0, lodash_1.throttle)(refreshUnread, FORCE_UPDATE_DELAY); }, [refreshUnread]);
    (0, react_1.useEffect)(function () {
        // after network connection is restored, refetch to get latest data
        // after a delay to ensure subscription is firing again
        // https://cdn.dribbble.com/users/202376/screenshots/2013355/media/f35b793067ba6650b5e75c6f0f7838ba.gif
        var onlineHandler = function () {
            setTimeout(function () {
                refresh();
            }, FORCE_UPDATE_DELAY);
        };
        window.addEventListener('online', onlineHandler);
        return function () { return window.removeEventListener('online', onlineHandler); };
    }, [refresh]);
    (0, react_1.useEffect)(function () {
        return subscribeToMore({
            document: api_1.Subscriptions.business.unreadCount,
        });
    }, [subscribeToMore]);
    var businesses = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.businesses;
    return (0, lodash_1.sum)((_b = businesses === null || businesses === void 0 ? void 0 : businesses.map(function (b) { var _a; return (_a = b === null || b === void 0 ? void 0 : b.unreadCount) !== null && _a !== void 0 ? _a : 0; })) !== null && _b !== void 0 ? _b : [0]);
}
