"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateCardTag = useCreateCardTag;
exports.useUpdateCardTag = useUpdateCardTag;
exports.useArchiveCardTag = useArchiveCardTag;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var hooks_1 = require("@shared/components/blocks/lib/tags/hooks");
var utils_1 = require("@shared/components/blocks/lib/tags/utils");
function useCreateCardTag(fieldName) {
    var _this = this;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var options = (0, hooks_1.useTagOptions)(fieldName).options;
    var createStringTag = (0, client_1.useMutation)(api_1.Mutations.cards.createCardFieldStringTag)[0];
    return (0, react_1.useCallback)(function (label) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, createStringTag({
                        awaitRefetchQueries: true,
                        refetchQueries: getRefetchQueries(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId),
                        variables: {
                            input: {
                                fieldName: fieldName,
                                channelId: channel === null || channel === void 0 ? void 0 : channel.id,
                                colorName: (0, utils_1.generateTagColor)(options.length),
                                label: label.substr(0, utils_1.MAX_TAG_LENGTH),
                                sortKey: String(Date.now()),
                            },
                        },
                    })];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, (_a = res.data) === null || _a === void 0 ? void 0 : _a.tag];
            }
        });
    }); }, [createStringTag, channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, fieldName, options.length]);
}
function useUpdateCardTag() {
    var _this = this;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var updateStringTag = (0, client_1.useMutation)(api_1.Mutations.cards.updateCardFieldStringTag)[0];
    return (0, react_1.useCallback)(function (id, label) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, updateStringTag({
                        awaitRefetchQueries: true,
                        refetchQueries: getRefetchQueries(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId),
                        variables: {
                            input: { id: id, label: label },
                        },
                    })];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, (_a = res.data) === null || _a === void 0 ? void 0 : _a.tag];
            }
        });
    }); }, [updateStringTag, channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId]);
}
function useArchiveCardTag() {
    var _this = this;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var archiveStringTag = (0, client_1.useMutation)(api_1.Mutations.cards.archiveCardFieldStringTag)[0];
    return (0, react_1.useCallback)(function (id, revert) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, archiveStringTag({
                        awaitRefetchQueries: true,
                        refetchQueries: getRefetchQueries(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId),
                        variables: {
                            input: { id: id, revert: revert },
                        },
                    })];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, (_a = res.data) === null || _a === void 0 ? void 0 : _a.tag];
            }
        });
    }); }, [archiveStringTag, channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId]);
}
function getRefetchQueries(channelId, workflowTemplateId) {
    var queries = [
        {
            query: api_1.Queries.card.listCardFieldStringTags,
            variables: {
                input: { channelId: channelId },
                skip: (0, lodash_1.isNil)(channelId),
            },
        },
    ];
    if (!(0, lodash_1.isNil)(workflowTemplateId)) {
        queries.push({
            query: api_1.Queries.workflow.getWorkflowTemplate,
            variables: { id: workflowTemplateId },
        });
    }
    return queries;
}
