"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleGrid = SimpleGrid;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scrollview_1 = require("@shared/components/scrollview");
function SimpleGrid(_a) {
    var cellRenderer = _a.cellRenderer, columnWidth = _a.columnWidth, fixedRowCount = _a.fixedRowCount, cells = _a.cells;
    var renderRow = (0, react_1.useCallback)(function (rowIndex) { return (react_1.default.createElement(components_1.MBView, { row: true, style: __assign({ justifyContent: 'flex-end' }, (rowIndex === cells.length - 1 && { flex: 1 })), key: rowIndex }, cells[rowIndex].map(function (_, columnIndex) {
        return cellRenderer({
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            key: "".concat(columnIndex),
            style: { width: columnWidth },
        });
    }))); }, [cellRenderer, cells, columnWidth]);
    var _b = (0, react_1.useMemo)(function () {
        var allIndexes = cells.map(function (_, index) { return index; });
        return [allIndexes.slice(0, fixedRowCount), allIndexes.slice(fixedRowCount)];
    }, [cells, fixedRowCount]), fixedCellsIndexes = _b[0], scrollableCellsIndexes = _b[1];
    var scrollableRef = (0, react_1.useRef)();
    var _c = (0, react_1.useState)(0), containerMarginRight = _c[0], setContainerMarginRight = _c[1];
    var _d = (0, react_1.useState)(0), containerMinHeight = _d[0], setContainerMinHeight = _d[1];
    (0, react_1.useEffect)(function () {
        var _a;
        var scrollView = (_a = scrollableRef.current) === null || _a === void 0 ? void 0 : _a.scrollview;
        if (!scrollView) {
            return;
        }
        var element = scrollView;
        var scrollBarWidth = element.offsetWidth - element.clientWidth;
        setContainerMarginRight(scrollBarWidth * -1);
        setContainerMinHeight(element.clientHeight);
    }, [scrollableRef, cells]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        fixedCellsIndexes.map(renderRow),
        react_1.default.createElement(scrollview_1.MBScrollView, { webContentStyle: { marginRight: containerMarginRight, minHeight: containerMinHeight }, ref: scrollableRef }, scrollableCellsIndexes.map(renderRow))));
}
