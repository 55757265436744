"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBusinessRow = MBBusinessRow;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var notificationBadge_1 = require("@shared/components/notificationBadge");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
/*
    A simple row showing a business list item
*/
function MBBusinessRow(_a) {
    var business = _a.business, isShowingUnreadCount = _a.isShowingUnreadCount, isSelected = _a.isSelected, isShowingUserCount = _a.isShowingUserCount, otherProps = __rest(_a, ["business", "isShowingUnreadCount", "isSelected", "isShowingUserCount"]);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBListRow, __assign({ image: react_1.default.createElement(components_1.MBView, { fill: true },
            react_1.default.createElement(components_1.MBBusinessImage, { business: business }),
            !!isShowingUnreadCount && !!business.unreadCount && (react_1.default.createElement(notificationBadge_1.MBNotificationBadge, { count: business.unreadCount, style: styles_2.Styles.notificationBadge }))), icon: (function () {
            // If we haven't set the isSelected prop, don't render a checkmark
            if (typeof isSelected === 'undefined') {
                return undefined;
            }
            if (isSelected) {
                return (react_1.default.createElement(components_1.MBIcon, { source: require('@shared/assets/icons/v2/task-filled.png'), color: styles_1.Colors.v2.green, noPadding: true }));
            }
            return (react_1.default.createElement(components_1.MBIcon, { color: styles_1.Colors.v2.green30, noPadding: true, source: require('@shared/assets/icons/v2/unfilled-circle.png') }));
        })(), title: react_1.default.createElement(components_1.MBText, { color: colors.foregroundActive, bold: !!isShowingUnreadCount && !!business.unreadCount }, business.name), subtitle: isShowingUserCount ? "Staff members \u2022 ".concat(business.userCount) : undefined }, otherProps)));
}
