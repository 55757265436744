"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCreateChannelOverview = MBCreateChannelOverview;
var lodash_1 = require("lodash");
var React = __importStar(require("react"));
var react_1 = require("react");
var react_native_1 = require("react-native");
var use_layout_animation_1 = require("use-layout-animation");
var components_1 = require("@shared/components");
var inviteContactsRow_1 = require("@shared/scenes/contacts/components/inviteContactsRow");
var i18n_1 = require("@shared/util/i18n");
var userList_1 = require("../containers/userList");
var channelList_1 = require("./channelList");
function MBCreateChannelOverview(_a) {
    var onCreateNewGroup = _a.onCreateNewGroup, onCreateNewWorkflow = _a.onCreateNewWorkflow, onPressUser = _a.onPressUser, onPressChannel = _a.onPressChannel, onInviteContacts = _a.onInviteContacts, allChannels = _a.channels;
    var _b = React.useState(''), query = _b[0], setQuery = _b[1];
    // Order channels like in the main channel list
    var sortedChannels = (0, react_1.useMemo)(function () {
        return (0, lodash_1.sortBy)(allChannels, function (channel) { return -new Date(channel.updatedAt).getTime(); });
    }, [allChannels]);
    var channels = (0, react_1.useMemo)(function () {
        return sortedChannels.filter(function (channel) {
            // We filter out DMs since we will show a list of all
            // users in the business just above the channel list
            return channel.type !== 'DM' && channel.name.toLowerCase().includes(query.toLowerCase());
        });
    }, [sortedChannels, query]);
    (0, use_layout_animation_1.useLayoutAnimation)([channels, !!query]);
    return (React.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag" },
        React.createElement(components_1.MBView, { padding: "small" },
            React.createElement(components_1.MBSearchBar, { onChangeText: setQuery, autoFocus: true, placeholder: (0, i18n_1.i18n)('channel.create.searchChannelsPlaceholder') })),
        !query && (React.createElement(React.Fragment, null,
            React.createElement(components_1.MBAddRow, { title: (0, i18n_1.i18n)('channel.create.newGroup'), icon: require('@shared/assets/icons/v2/new-group.png'), onPress: onCreateNewGroup }),
            React.createElement(components_1.MBAddRow, { title: (0, i18n_1.i18n)('channel.create.newWorkflow'), icon: require('@shared/assets/icons/v2/book-open.png'), onPress: onCreateNewWorkflow }),
            react_native_1.Platform.OS !== 'web' && React.createElement(inviteContactsRow_1.MBInviteContactsRow, { onPress: onInviteContacts }))),
        React.createElement(userList_1.MBUserList, { query: query, onPressUser: onPressUser }),
        React.createElement(channelList_1.MBChannelList, { title: (0, i18n_1.i18n)('search.results.channels'), channels: channels, onPressChannel: onPressChannel })));
}
