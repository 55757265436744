"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUsers = useUsers;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var PAGE_SIZE = 50;
function useUsers(_a) {
    var _b, _c, _d;
    var input = _a.input, customCacheKey = _a.customCacheKey, skip = _a.skip;
    var queryInput = (0, react_1.useMemo)(function () {
        var _a;
        return ({
            limit: (_a = input.limit) !== null && _a !== void 0 ? _a : PAGE_SIZE,
            offset: 0,
            ids: input.ids,
            name: input.name,
        });
    }, [input]);
    var _e = (0, client_1.useQuery)(api_1.Queries.user.listUsers, {
        variables: { input: queryInput, customCacheKey: customCacheKey !== null && customCacheKey !== void 0 ? customCacheKey : '' },
        skip: skip,
    }), data = _e.data, loading = _e.loading, fetchMore = _e.fetchMore;
    var handleFetchMore = (0, react_1.useCallback)(function (ids) {
        var _a, _b, _c;
        if (!(0, lodash_1.isEmpty)(ids)) {
            fetchMore({
                variables: {
                    input: __assign(__assign({}, queryInput), { ids: ids }),
                    customCacheKey: customCacheKey,
                },
            });
        }
        else {
            fetchMore({
                variables: {
                    input: __assign(__assign({}, queryInput), { offset: (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.listUsers) === null || _a === void 0 ? void 0 : _a.users) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0 }),
                    customCacheKey: customCacheKey,
                },
            });
        }
    }, [(_b = data === null || data === void 0 ? void 0 : data.listUsers) === null || _b === void 0 ? void 0 : _b.users, fetchMore, queryInput, customCacheKey]);
    return (0, react_1.useMemo)(function () {
        var _a, _b;
        return ({
            users: (_a = data === null || data === void 0 ? void 0 : data.listUsers) === null || _a === void 0 ? void 0 : _a.users,
            hasMore: (_b = data === null || data === void 0 ? void 0 : data.listUsers) === null || _b === void 0 ? void 0 : _b.hasMore,
            loading: loading,
            loadMore: handleFetchMore,
        });
    }, [(_c = data === null || data === void 0 ? void 0 : data.listUsers) === null || _c === void 0 ? void 0 : _c.users, (_d = data === null || data === void 0 ? void 0 : data.listUsers) === null || _d === void 0 ? void 0 : _d.hasMore, loading, handleFetchMore]);
}
