"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaveEntityFields = useSaveEntityFields;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var useUpdateRecurringEntity_1 = require("./useUpdateRecurringEntity");
function useSaveEntityFields(entityId, workflowTemplate) {
    var _a = (0, client_1.useMutation)(api_1.Mutations.entity.updateWorkflowEntity), updateEntityMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var _c = (0, react_1.useState)([]), errors = _c[0], setErrors = _c[1];
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(error)) {
            // parse remote errors
            setErrors([{ description: error.message }]);
        }
    }, [error]);
    var saveEntity = (0, react_1.useCallback)(function (fields) {
        var localErrors = (0, useUpdateRecurringEntity_1.validateFields)(fields);
        setErrors(localErrors);
        if (!(0, lodash_1.isEmpty)(localErrors)) {
            return Promise.reject('Local validation');
        }
        var entityFields = (0, useUpdateRecurringEntity_1.getJsonFields)(fields, workflowTemplate);
        return updateEntityMutation({
            variables: {
                input: {
                    id: entityId,
                    fields: entityFields,
                },
            },
            refetchQueries: [
                { query: api_1.Queries.workflow.getWorkflowEntity, variables: { input: { id: entityId } } },
            ],
        });
    }, [entityId, updateEntityMutation, workflowTemplate]);
    return {
        loading: loading,
        errors: errors,
        saveEntity: saveEntity,
    };
}
