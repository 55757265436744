"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderToggle = exports.renderError = exports.renderClearButton = exports.renderLabel = exports.renderIcon = exports.isInputBold = exports.getTextInputSize = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var theme_1 = require("@shared/components/blocks/contexts/theme");
var label_1 = require("@shared/components/blocks/utils/label");
var styles_1 = require("@shared/styles");
var layoutConfig_1 = require("../../contexts/layoutConfig");
var getTextInputSize = function (textStyle) {
    switch (textStyle) {
        case 'h3':
            return 'xlarge';
        case 'h4':
            return 'large';
        case 'body1':
        case 'body1bold':
        default:
            return 'normal';
    }
};
exports.getTextInputSize = getTextInputSize;
var BOLD_TYPES = [
    'body1bold',
    'body2bold',
    'overlinebold',
    'caption1bold',
    'caption2bold',
];
var isInputBold = function (textStyle) { return BOLD_TYPES.includes(textStyle); };
exports.isInputBold = isInputBold;
function BlockIcon(_a) {
    var name = _a.name, icon = _a.icon;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var cardTheme = (0, react_1.useContext)(theme_1.CardThemeContext);
    var errors = (0, react_1.useContext)(form_1.CardFormContext).errors;
    var size = (0, layoutConfig_1.useLayoutConfig)().blockIcon.size;
    var error = errors[name];
    return (react_1.default.createElement(components_1.MBIconV2, { color: (function () {
            if (!!error) {
                return colors.error.foreground;
            }
            if (cardTheme === 'stale') {
                return colors.foregroundInactive;
            }
            return colors.accent;
        })(), name: icon, size: size }));
}
var renderIcon = function (icon, name) {
    return !!icon && react_1.default.createElement(BlockIcon, { name: name, icon: icon });
};
exports.renderIcon = renderIcon;
function Label(_a) {
    var block = _a.block;
    var values = (0, react_1.useContext)(form_1.CardFormContext).values;
    var value = values[block.name];
    if (!block.label) {
        return null;
    }
    if (value === undefined || value === null || value === '') {
        return null;
    }
    return react_1.default.createElement(label_1.MBBlockLabel, { label: block.label });
}
var renderLabel = function (block) { return react_1.default.createElement(Label, { block: block }); };
exports.renderLabel = renderLabel;
function ClearButton(_a) {
    var block = _a.block, onClear = _a.onClear;
    var _b = (0, react_1.useContext)(form_1.CardFormContext), values = _b.values, setFieldValue = _b.setFieldValue;
    var value = values[block.name];
    var onChange = (0, react_1.useCallback)(function () {
        setFieldValue(block.name, null);
        onClear === null || onClear === void 0 ? void 0 : onClear();
    }, [block.name, setFieldValue, onClear]);
    if (value) {
        return react_1.default.createElement(components_1.MBClearButton, { onPress: onChange });
    }
    return null;
}
var renderClearButton = function (block, onClear) { return (react_1.default.createElement(ClearButton, { block: block, onClear: onClear })); };
exports.renderClearButton = renderClearButton;
function Error(_a) {
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var errors = (0, react_1.useContext)(form_1.CardFormContext).errors;
    var error = errors[block.name];
    if (Boolean(error)) {
        return (react_1.default.createElement(components_1.MBView, { paddingTop: 0, padding: 2 },
            react_1.default.createElement(components_1.MBTextBody2, { color: colors.error.foreground }, error)));
    }
    return null;
}
var renderError = function (block) { return react_1.default.createElement(Error, { block: block }); };
exports.renderError = renderError;
var renderToggle = function (value, onChange) { return (react_1.default.createElement(components_1.Switch, { value: value, onValueChange: onChange })); };
exports.renderToggle = renderToggle;
