"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBrandPanel = MBBrandPanel;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function MBBrandPanel(_a) {
    var business = _a.business, staffCount = _a.staffCount, onChangeName = _a.onChangeName, onSubmitAvatar = _a.onSubmitAvatar, onSubmitName = _a.onSubmitName;
    var _b = (0, react_1.useState)(business.name), name = _b[0], setName = _b[1];
    var _c = (0, react_1.useState)(business.imageUrl), imageUrl = _c[0], setImageUrl = _c[1];
    // only fire this when business.name changes (i.e. switching business)
    (0, react_1.useEffect)(function () {
        if (name !== business.name) {
            setName(business.name);
        }
    }, [business.name]);
    (0, react_1.useEffect)(function () {
        if (imageUrl !== business.imageUrl) {
            setImageUrl(business.imageUrl);
        }
    }, [business.imageUrl]);
    var _d = (0, react_1.useState)(false), isEditing = _d[0], setIsEditing = _d[1];
    var handleFinishEdit = (0, react_1.useCallback)(function () {
        setIsEditing(false);
        !!onSubmitName && onSubmitName(name);
    }, [name]);
    var handleStartEdit = (0, react_1.useCallback)(function () { return setIsEditing(true); }, []);
    var handleChangeName = (0, react_1.useCallback)(function (updatedName) {
        setName(updatedName);
        onChangeName && onChangeName(updatedName);
    }, [setName, onChangeName]);
    var handleChangeAvatar = (0, react_1.useCallback)(function (updatedImageUrl) {
        setImageUrl(updatedImageUrl);
        !!onSubmitAvatar && onSubmitAvatar(updatedImageUrl);
    }, [setImageUrl, onSubmitAvatar]);
    return (react_1.default.createElement(components_1.MBView, { center: true, fullWidth: true, paddingBottom: "large" },
        react_1.default.createElement(components_1.MBView, { paddingTop: "large", paddingBottom: "small" },
            react_1.default.createElement(components_1.MBSelectAvatarImage, { imageUrl: imageUrl, onUploadedImage: !!onSubmitAvatar ? handleChangeAvatar : undefined, placeholder: react_1.default.createElement(components_1.MBBusinessImage, { business: business, style: styles_1.SharedStyles.full }) })),
        isEditing ? (react_1.default.createElement(components_1.MBTextInput, { size: "xlarge", align: "center", noPadding: true, autoFocus: true, selectTextOnFocus: true, value: name, onBlur: handleFinishEdit, onChangeText: handleChangeName })) : (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !onSubmitName && !onChangeName, onPress: handleStartEdit },
            react_1.default.createElement(components_1.MBText, { size: "xlarge" }, name))),
        !!staffCount && (react_1.default.createElement(components_1.MBView, { paddingTop: "tiny" },
            react_1.default.createElement(components_1.MBText, { size: "small", color: styles_1.Colors.v2.gray50 }, (0, i18n_1.i18n)('directory.subtitle.staff', { count: staffCount }))))));
}
