"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeolocationContent = GeolocationContent;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var typography_1 = require("@shared/components/typography");
var styles_1 = require("@shared/styles");
var date_1 = require("@shared/util/date");
var hooks_1 = require("@shared/util/hooks");
var mapPreview_1 = require("./mapPreview");
function GeolocationContent(_a) {
    var geolocation = _a.geolocation, collapsed = _a.collapsed, onClear = _a.onClear;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var users = (0, hooks_1.useAllBusinessUsers)();
    var user = (0, react_1.useMemo)(function () { return (!!geolocation ? users === null || users === void 0 ? void 0 : users.find(function (u) { return u.id === geolocation.userId; }) : undefined); }, [geolocation, users]);
    if (collapsed) {
        return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { width: '100%' } },
            react_1.default.createElement(components_1.MBView, { style: { marginRight: 10 } },
                react_1.default.createElement(mapPreview_1.GeolocationMapPreview, { width: (0, components_1.grid)(5), height: (0, components_1.grid)(5), lat: geolocation.coords.lat, lon: geolocation.coords.lon, dropShadow: false, onClear: onClear })),
            react_1.default.createElement(components_1.MBView, { fill: true },
                react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5, fill: true },
                    react_1.default.createElement(components_1.MBUserImage, { size: "tiny", user: user, style: {
                            width: (0, components_1.grid)(2),
                            height: (0, components_1.grid)(2),
                            borderRadius: (0, components_1.grid)(1),
                            overflow: 'hidden',
                            marginRight: (0, components_1.grid)(0.5),
                        } }),
                    react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive, numberOfLines: 1, style: {
                            // @ts-ignore
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: react_native_1.Platform.OS === 'web' ? 'block' : null,
                        } }, user === null || user === void 0 ? void 0 : user.fullName)),
                react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive }, (0, date_1.formatDateTime)(geolocation.date)))));
    }
    return (react_1.default.createElement(components_1.MBView, { style: { width: '100%' } },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5 },
            react_1.default.createElement(components_1.MBUserImage, { size: "tiny", user: user, style: {
                    width: (0, components_1.grid)(2),
                    height: (0, components_1.grid)(2),
                    borderRadius: (0, components_1.grid)(1),
                    overflow: 'hidden',
                    marginRight: (0, components_1.grid)(1),
                } }),
            react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive }, user === null || user === void 0 ? void 0 : user.fullName)),
        react_1.default.createElement(typography_1.MBTextBody2, { color: colors.foregroundActive }, (0, date_1.formatDateTime)(geolocation.date)),
        react_1.default.createElement(components_1.MBView, { paddingTop: 0.5 }),
        react_1.default.createElement(mapPreview_1.GeolocationMapPreview, { lat: geolocation.coords.lat, lon: geolocation.coords.lon, onClear: onClear })));
}
