"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFlags = exports.USER_GROUPS = exports.PERMISSIONS_REFETCH_TIMER = exports.COMPONENT_HIDE = exports.DELETE_WORKSPACE_SECTION = exports.DATA_FRESHNESS = exports.NO_CODE_CREATE_FORM = exports.NO_CODE_UPDATE_FORM = exports.AUTOMATIONS_IN_CARD_FORM = exports.MULTIPLE_FILTER_GROUPS = exports.BOARD_VIEW = exports.TIME_TRACKER_COMPONENT_GEOLOCATION = exports.ABSOLUTE_TIMEOUT = exports.INACTIVITY_TIMEOUT = exports.PAYWALL_EXPERIMENT = void 0;
exports.useLaunchDarklyIdentity = useLaunchDarklyIdentity;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var uuid = __importStar(require("@shared/util/uuid"));
// all flags here
exports.PAYWALL_EXPERIMENT = 'paywall-experiment';
exports.INACTIVITY_TIMEOUT = 'inactivity-timeout';
exports.ABSOLUTE_TIMEOUT = 'absolute-timeout';
exports.TIME_TRACKER_COMPONENT_GEOLOCATION = 'time-tracker-component-geolocation';
exports.BOARD_VIEW = 'board-view';
exports.MULTIPLE_FILTER_GROUPS = 'multi-filter-groups';
exports.AUTOMATIONS_IN_CARD_FORM = 'automations-in-card-form';
exports.NO_CODE_UPDATE_FORM = 'no-code-entity-update-form';
exports.NO_CODE_CREATE_FORM = 'no-code-entity-create-form';
exports.DATA_FRESHNESS = 'data-freshness';
exports.DELETE_WORKSPACE_SECTION = 'delete-workspace-section';
exports.COMPONENT_HIDE = 'component-hide';
exports.PERMISSIONS_REFETCH_TIMER = 'permissions-refetch-timer';
exports.USER_GROUPS = 'user-groups';
// set default flag state here
exports.defaultFlags = (_a = {},
    _a[exports.PAYWALL_EXPERIMENT] = '', // redacted or banner
    _a[exports.INACTIVITY_TIMEOUT] = 0,
    _a[exports.ABSOLUTE_TIMEOUT] = 0,
    _a[exports.TIME_TRACKER_COMPONENT_GEOLOCATION] = false,
    _a[exports.BOARD_VIEW] = false,
    _a[exports.MULTIPLE_FILTER_GROUPS] = false,
    _a[exports.AUTOMATIONS_IN_CARD_FORM] = false,
    _a[exports.NO_CODE_UPDATE_FORM] = false,
    _a[exports.NO_CODE_CREATE_FORM] = false,
    _a[exports.DATA_FRESHNESS] = {
        enabled: false,
        logPrefix: '',
        maxRetries: 1,
        refetchThrottleMs: 1000,
        sleepDetectorIntervalMs: 1000,
        sleepDetectorTriggerRefetchMs: 1000,
    },
    _a[exports.DELETE_WORKSPACE_SECTION] = false,
    _a[exports.COMPONENT_HIDE] = false,
    _a[exports.PERMISSIONS_REFETCH_TIMER] = 0,
    _a[exports.USER_GROUPS] = false,
    _a);
var anonUserKey = uuid.v4();
function useLaunchDarklyIdentity() {
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var profile = (0, client_1.useQuery)(api_1.Queries.user.profile, {
        variables: { businessId: businessId !== null && businessId !== void 0 ? businessId : -1 },
        skip: (0, lodash_1.isNil)(businessId),
    }).data;
    var personalDetails = (0, client_1.useQuery)(api_1.Queries.user.myPersonalDetails).data;
    var context = (0, react_1.useMemo)(function () {
        if (businessId && (profile === null || profile === void 0 ? void 0 : profile.me) && (personalDetails === null || personalDetails === void 0 ? void 0 : personalDetails.me)) {
            var business = profile.me.businesses.find(function (b) { return (b === null || b === void 0 ? void 0 : b.id) === businessId; });
            var user = __assign(__assign({}, profile.me), personalDetails.me);
            // Note: our React Native version (0.68) + supported LD SDK (6.3.3)
            // requires us to use a user not contexts
            var ldUser = {
                key: String(user.id),
                firstName: user.firstName,
                lastName: user.lastName,
                custom: {
                    businessId: business === null || business === void 0 ? void 0 : business.id,
                    businessName: business === null || business === void 0 ? void 0 : business.name,
                },
            };
            return ldUser;
        }
        else {
            return {
                key: "anon-".concat(anonUserKey),
                anonymous: true,
            };
        }
    }, [businessId, profile, personalDetails]);
    return context;
}
