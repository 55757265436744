"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBusinessSettings = MBBusinessSettings;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/cards/allCardsList/hooks");
var component_1 = require("./component");
var VALID_WORKSPACE_TYPES = ['CARD_GENERIC', 'CHANNEL'];
function MBBusinessSettings() {
    var _this = this;
    var _a = (0, hooks_1.useChannelList)(), allChannels = _a.channels, refetchChannels = _a.refetch;
    var updateChannel = (0, client_1.useMutation)(api_1.Mutations.channel.updateChannelV2)[0];
    var channels = (0, react_1.useMemo)(function () {
        return allChannels === null || allChannels === void 0 ? void 0 : allChannels.filter(function (c) { return VALID_WORKSPACE_TYPES.includes(c.type); }).sort(function (a, z) { return Number(z.autoAddUsers) - Number(a.autoAddUsers); });
    }, [allChannels]);
    var onSaveAutoAddedChannels = (0, react_1.useCallback)(function (updatedChannels) { return __awaiter(_this, void 0, void 0, function () {
        var currentChannelIdsWithAutoAdd, channelsToToggleAutoAdd;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentChannelIdsWithAutoAdd = updatedChannels.map(function (_a) {
                        var id = _a.id;
                        return id;
                    });
                    channelsToToggleAutoAdd = channels.filter(function (_a) {
                        var id = _a.id, autoAddUsers = _a.autoAddUsers;
                        var toEnable = currentChannelIdsWithAutoAdd.includes(id) && !autoAddUsers;
                        var toDisable = !!autoAddUsers && !currentChannelIdsWithAutoAdd.includes(id);
                        return toDisable || toEnable;
                    });
                    return [4 /*yield*/, Promise.all(channelsToToggleAutoAdd.map(function (_a) {
                            var id = _a.id, autoAddUsers = _a.autoAddUsers;
                            return updateChannel({
                                variables: {
                                    input: {
                                        id: id,
                                        autoAddUsers: !autoAddUsers,
                                    },
                                },
                            });
                        }))];
                case 1:
                    _a.sent();
                    refetchChannels();
                    return [2 /*return*/];
            }
        });
    }); }, [channels, refetchChannels, updateChannel]);
    if (!channels) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    return react_1.default.createElement(component_1.MBBusinessSettings, { channels: channels, onSaveAutoAddedChannels: onSaveAutoAddedChannels });
}
