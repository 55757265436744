"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallBanner = CallBanner;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var audio_1 = require("@shared/scenes/calling/audio");
var leaveButton_1 = require("@shared/scenes/calling/leaveButton");
var muteButton_1 = require("@shared/scenes/calling/muteButton");
var participant_1 = require("@shared/scenes/calling/participant");
var index_1 = require("@shared/scenes/calling/provider/index");
var screen_1 = require("@shared/scenes/calling/screen");
var screenButton_1 = require("@shared/scenes/calling/screenButton");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var BANNER_HEIGHT = 56;
var MAX_USERS_SHOWN = 10;
var ACTIVE_STATES = ['STATE_JOINING', 'STATE_JOINED', 'STATE_LEAVING'];
function CallBanner() {
    var _a = (0, react_1.useContext)(index_1.CallContext), state = _a.state, leaveCall = _a.leaveCall, participants = _a.participants, muted = _a.muted, toggleMute = _a.toggleMute, audioTracks = _a.audioTracks, startScreenShare = _a.startScreenShare, stopScreenShare = _a.stopScreenShare, screenTrack = _a.screenTrack;
    var active = ACTIVE_STATES.includes(state);
    var height = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    (0, react_1.useEffect)(function () {
        if (active) {
            react_native_1.Animated.timing(height, {
                useNativeDriver: false,
                toValue: BANNER_HEIGHT,
                duration: 1000,
            }).start();
        }
        else {
            react_native_1.Animated.timing(height, {
                useNativeDriver: false,
                toValue: 0,
                duration: 500,
            }).start();
        }
    }, [active, height]);
    var joinedParticipants = (0, react_1.useMemo)(function () {
        return participants.filter(function (participant) { return !!participant.joinedAt; });
    }, [participants]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.Animated.View, { testID: "electron-drag-region", style: {
                height: height,
                minHeight: height,
                maxHeight: height,
                overflow: 'hidden',
                flex: 1,
            } },
            audioTracks.map(function (audio) { return (react_1.default.createElement(audio_1.AudioTrack, { key: audio.sessionId, track: audio.audioTrack })); }),
            react_1.default.createElement(react_native_1.Animated.View, { style: {
                    transform: [
                        {
                            translateY: height.interpolate({
                                inputRange: [0, BANNER_HEIGHT],
                                outputRange: [-BANNER_HEIGHT, 0],
                            }),
                        },
                    ],
                    height: BANNER_HEIGHT,
                    minHeight: BANNER_HEIGHT,
                    backgroundColor: styles_1.Colors.v2.actionFreshGreen,
                    overflow: 'hidden',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                } },
                react_1.default.createElement(components_1.MBView, { paddingRight: 2 },
                    state === 'STATE_JOINING' && (react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('calling.banner.joiningCall'))),
                    state === 'STATE_JOINED' && (react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('calling.banner.activeCall'))),
                    state === 'STATE_LEAVING' && (react_1.default.createElement(components_1.MBTextBody2Bold, null, (0, i18n_1.i18n)('calling.banner.leavingCall')))),
                (state === 'STATE_JOINING' || state === 'STATE_JOINED') && (react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 9 },
                    joinedParticipants.slice(0, MAX_USERS_SHOWN).map(function (participant, i) { return (react_1.default.createElement(participant_1.Participant, { participant: participant, key: i })); }),
                    (joinedParticipants === null || joinedParticipants === void 0 ? void 0 : joinedParticipants.length) > MAX_USERS_SHOWN && (react_1.default.createElement(components_1.MBView, { row: true, center: true },
                        react_1.default.createElement(components_1.MBTextCaption1Bold, null, (0, i18n_1.i18n)('calling.banner.overflow', {
                            count: joinedParticipants.length - MAX_USERS_SHOWN,
                        })))))),
                state === 'STATE_JOINED' && react_1.default.createElement(leaveButton_1.LeaveCallButton, { onPress: leaveCall }),
                state === 'STATE_JOINED' && react_1.default.createElement(muteButton_1.MuteButton, { onPress: toggleMute, muted: muted }),
                state === 'STATE_JOINED' && (react_1.default.createElement(screenButton_1.ShareScreenButton, { onStart: startScreenShare, onStop: stopScreenShare, screenTrack: screenTrack })))),
        state === 'STATE_JOINED' && react_1.default.createElement(screen_1.ScreenShare, { screenTrack: screenTrack })));
}
