"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResolveAssignees = exports.useLegacyChannelUsers = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var hooks_1 = require("@shared/util/hooks");
var useLegacyChannelUsers = function (channelSlug) {
    var channel = (0, hooks_1.useChannel)(channelSlug);
    var users = channel === null || channel === void 0 ? void 0 : channel.users;
    return (0, react_1.useMemo)(function () { return users || []; }, [users]);
};
exports.useLegacyChannelUsers = useLegacyChannelUsers;
var useResolveAssignees = function (value) {
    var users = (0, hooks_1.useAllBusinessUsers)();
    return (0, react_1.useMemo)(function () { return users.filter(function (user) { return !!(value === null || value === void 0 ? void 0 : value.includes(user.id)); }); }, [users, value]);
};
exports.useResolveAssignees = useResolveAssignees;
