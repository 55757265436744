"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEndDateInputBlock = exports.createStartDateInputBlock = void 0;
exports.MBCardInputBlockDateRange = MBCardInputBlockDateRange;
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("./input/hooks");
var inputDate_1 = require("./inputDate");
var createStartDateInputBlock = function (block) {
    var _a, _b, _c;
    return ({
        type: 'input',
        name: (_a = block.startDateFieldName) !== null && _a !== void 0 ? _a : 'startDate',
        fieldType: block.mode,
        placeholder: (_b = block.startDateFieldLabel) !== null && _b !== void 0 ? _b : (0, i18n_1.i18n)('card.blocks.dateRangeInput.startDateLabel'),
        textStyle: 'body1',
        required: true,
        label: (_c = block.startDateFieldLabel) !== null && _c !== void 0 ? _c : (0, i18n_1.i18n)('card.blocks.dateRangeInput.startDateLabel'),
        defaultTime: block.defaultTime,
        validation: {
            message: (0, i18n_1.i18n)('card.blocks.dateRangeInput.startDateError'),
        },
        autoFocus: false,
        icon: 'calendar',
    });
};
exports.createStartDateInputBlock = createStartDateInputBlock;
var createEndDateInputBlock = function (block) {
    var _a, _b, _c;
    return ({
        type: 'input',
        name: (_a = block.endDateFieldName) !== null && _a !== void 0 ? _a : 'endDate',
        fieldType: block.mode,
        placeholder: (_b = block.endDateFieldLabel) !== null && _b !== void 0 ? _b : (0, i18n_1.i18n)('card.blocks.dateRangeInput.endDateLabel'),
        textStyle: 'body1',
        required: true,
        label: (_c = block.endDateFieldLabel) !== null && _c !== void 0 ? _c : (0, i18n_1.i18n)('card.blocks.dateRangeInput.endDateLabel'),
        defaultTime: block.defaultTime,
        validation: {
            message: (0, i18n_1.i18n)('card.blocks.dateRangeInput.endDateError'),
        },
        autoFocus: false,
        icon: 'calendar',
    });
};
exports.createEndDateInputBlock = createEndDateInputBlock;
function MBCardInputBlockDateRange(_a) {
    var _b, _c, _d, _e;
    var block = _a.block;
    var startDateInputBlock = (0, react_1.useMemo)(function () { return (0, exports.createStartDateInputBlock)(block); }, [block]);
    var endDateInputBlock = (0, react_1.useMemo)(function () { return (0, exports.createEndDateInputBlock)(block); }, [block]);
    var _f = (0, react_1.useContext)(form_1.CardFormContext), values = _f.values, setFieldValue = _f.setFieldValue;
    var startDate = values[startDateInputBlock.name];
    var endDate = values[endDateInputBlock.name];
    var setStartDate = (0, react_1.useCallback)(function (value) { return setFieldValue(startDateInputBlock.name, value); }, [startDateInputBlock.name, setFieldValue]);
    var setEndDate = (0, react_1.useCallback)(function (value) { return setFieldValue(endDateInputBlock.name, value); }, [endDateInputBlock.name, setFieldValue]);
    (0, hooks_1.useDateRangeDefaultValues)(startDate, setStartDate, endDate, setEndDate, (_c = (_b = block.delta) === null || _b === void 0 ? void 0 : _b.unit) !== null && _c !== void 0 ? _c : 'day', (_e = (_d = block.delta) === null || _d === void 0 ? void 0 : _d.count) !== null && _e !== void 0 ? _e : 1);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(inputDate_1.MBCardInputBlockDate, { block: startDateInputBlock }),
        react_1.default.createElement(inputDate_1.MBCardInputBlockDate, { block: endDateInputBlock })));
}
