"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBWorkflowTagOptions = void 0;
var lodash_1 = require("lodash");
var rc_menu_1 = require("rc-menu");
var react_1 = __importStar(require("react"));
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var menuRow_1 = require("@shared/scenes/cards/bulk/components/menuRow");
var tagOptions_1 = require("@shared/scenes/channel/fragmentTemplates/contexts/tagOptions");
var singleSelect_1 = require("@shared/scenes/workflows/views/components/singleSelect");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../../hooks");
var TagOptionRow_1 = require("./TagOptionRow");
var ComponentDefaultItemRow_1 = require("./componentDefaults/row/ComponentDefaultItemRow");
var DefaultSelection_1 = require("./componentDefaults/selection/DefaultSelection");
var defaultOptions_1 = require("./defaultOptions");
var divider_1 = require("./divider");
var cursorPointerStyle = { cursor: 'pointer' };
var MBWorkflowTagOptions = function (_a) {
    var workflowTemplateId = _a.workflowTemplateId, component = _a.component;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), showArchived = _b[0], setShowArchived = _b[1];
    var isAuditTag = component.type === 'AUDIT_TAG';
    var _c = (0, hooks_1.useSetComponentField)(component, 'addAdditionalValues'), addAdditionalValues = _c[0], setAddAdditionalValues = _c[1];
    var _d = (0, hooks_1.useTagOptions)(component), onAddOption = _d.onAddOption, onUpdateOption = _d.onUpdateOption, onArchiveOption = _d.onArchiveOption, onUnarchiveOption = _d.onUnarchiveOption, onReorderOption = _d.onReorderOption, onSetTagGroup = _d.onSetTagGroup, canArchiveOption = _d.canArchiveOption, options = _d.options, archivedOptions = _d.archivedOptions;
    var onDragEnd = (0, react_1.useCallback)(function (result) {
        if (!result.destination) {
            return;
        }
        onReorderOption(result.source.index, result.destination.index);
    }, [onReorderOption]);
    var renderDraggableOptionRow = (0, react_1.useCallback)(function (provided, snapshot, rubric) {
        var _a;
        var index = rubric.source.index;
        var option = options[index];
        var style = __assign(__assign({}, provided.draggableProps.style), (snapshot.isDragging && {
            backgroundColor: colors.background,
            boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
            borderRadius: (0, components_1.grid)(1),
            opacity: snapshot.isDragging ? 0.5 : 1,
        }));
        return (react_1.default.createElement("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { style: style, ref: provided.innerRef }),
            react_1.default.createElement(TagOptionRow_1.TagOptionRow, { tagForegroundColor: colors.foregroundActive, tagBackgroundColor: styles_1.Colors.v2[(_a = option.backgroundColor) !== null && _a !== void 0 ? _a : 'tagGray'], onPressArchive: canArchiveOption ? function () { return onArchiveOption(option.value); } : function () { } },
                react_1.default.createElement(components_1.MBView, { fill: true, paddingHorizontal: 1 },
                    react_1.default.createElement(components_1.TextInput, { autoFocus: index === options.length - 1, value: option.label, onChangeText: function (newLabel) { return onUpdateOption(option.value, newLabel); }, style: {
                            backgroundColor: colors.backgroundInactive,
                            borderRadius: 8,
                            padding: (0, components_1.grid)(1),
                        }, onKeyPress: function (_a) {
                            var nativeEvent = _a.nativeEvent;
                            if (nativeEvent.key === 'Enter') {
                                onAddOption();
                            }
                        } })))));
    }, [canArchiveOption, colors, onAddOption, onArchiveOption, onUpdateOption, options]);
    var tagOptionsPreview = (0, react_1.useMemo)(function () {
        var MAX_TAGS = 3;
        var tagsToShow = options.slice(0, MAX_TAGS).filter(function (_a) {
            var label = _a.label;
            return label;
        });
        var extraTagsText = options.length > MAX_TAGS ? "+ ".concat(options.length - MAX_TAGS) : null;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Tags, { tags: tagsToShow }),
            !(0, lodash_1.isNil)(extraTagsText) && (react_1.default.createElement(components_1.MBView, { centerVertically: true },
                react_1.default.createElement(components_1.MBTextBody2, null, extraTagsText)))));
    }, [options]);
    var onSelectTagGroup = (0, react_1.useCallback)(function (tagGroup) {
        var tagOptions = tagOptions_1.defaultTagGroups[tagGroup];
        onSetTagGroup(tagOptions.options);
    }, [onSetTagGroup]);
    var tagGroupSelector = function (_a) {
        var onFinishSelectGroup = _a.onFinishSelectGroup;
        return (react_1.default.createElement(singleSelect_1.MBSingleSelect, { title: (0, i18n_1.i18n)('workflowTemplate.tagSetOptions'), options: Object.keys(tagOptions_1.defaultTagGroups), onSelectOption: function (option) {
                onSelectTagGroup(option);
                onFinishSelectGroup();
            }, getLabel: function (tagGroup) {
                var _a, _b;
                var tags = (_b = (_a = tagOptions_1.defaultTagGroups[tagGroup]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.map(function (tagOption) {
                    var _a;
                    return ({
                        label: tagOption.label,
                        backgroundColor: (_a = tagOption.theme) === null || _a === void 0 ? void 0 : _a.backgroundColor,
                    });
                });
                return (react_1.default.createElement(components_1.MBView, { flex: true, row: true, wrap: true, style: { flexWrap: 'wrap' } },
                    react_1.default.createElement(Tags, { tags: tags })));
            } }));
    };
    return (react_1.default.createElement(defaultOptions_1.MBWorkflowDefaultComponentOptions, { component: component, extraOptions: !isAuditTag ? (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1, row: true },
            react_1.default.createElement(components_1.MBIconV2, { name: "tag", color: colors.foregroundActive, size: (0, components_1.grid)(3) }),
            react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 },
                react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('workflowTemplate.allowTagEditing'))),
            react_1.default.createElement(components_1.Switch, { value: addAdditionalValues, onValueChange: function () { return setAddAdditionalValues(!addAdditionalValues); } }))) : undefined, extraSections: function (_a) {
            var closeSubmenus = _a.closeSubmenus;
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.responseOptions'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "options", style: { zIndex: 999 }, title: react_1.default.createElement(components_1.MBView, { style: cursorPointerStyle, row: true, centerVertically: true, paddingHorizontal: 2, paddingVertical: 1 },
                        react_1.default.createElement(components_1.MBView, { flex: true, row: true, wrap: true, style: { flexWrap: 'wrap' } }, tagOptionsPreview),
                        react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right", color: colors.foregroundActive, size: (0, components_1.grid)(3) })) },
                    react_1.default.createElement(components_1.MBView, null,
                        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2 },
                            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.responseOptions'))),
                        react_1.default.createElement(components_1.MBScrollView, { style: [{ maxHeight: 288 }, styles.shadow] },
                            react_1.default.createElement(react_1.default.Fragment, null,
                                react_1.default.createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd },
                                    react_1.default.createElement(react_beautiful_dnd_1.Droppable, { droppableId: "options", renderClone: renderDraggableOptionRow, children: function (provided) { return (react_1.default.createElement("div", { ref: provided.innerRef },
                                            options.map(function (option, index) { return (react_1.default.createElement(components_1.MBView, { key: option.value },
                                                react_1.default.createElement(react_beautiful_dnd_1.Draggable, { draggableId: option.value, index: index, children: renderDraggableOptionRow }))); }),
                                            provided.placeholder)); } })),
                                react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return setShowArchived(!showArchived); } },
                                    react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 2, centerVertically: true, row: true },
                                        react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('workflowTemplate.archived', { count: archivedOptions === null || archivedOptions === void 0 ? void 0 : archivedOptions.length })),
                                        react_1.default.createElement(components_1.MBView, { fill: true }),
                                        react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: !showArchived, rotate: { from: '0deg', to: '-180deg' } },
                                            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "arrow-point-down", size: (0, components_1.grid)(3) })))),
                                showArchived &&
                                    (archivedOptions === null || archivedOptions === void 0 ? void 0 : archivedOptions.map(function (archivedOption, index) { return (react_1.default.createElement(TagOptionRow_1.TagOptionRow, { key: index, tagForegroundColor: colors.input.label, onPressArchive: function () { return onUnarchiveOption(archivedOption.value); } },
                                        react_1.default.createElement(components_1.MBView, { fill: true, paddingHorizontal: 1 },
                                            react_1.default.createElement(components_1.TextInput, { value: archivedOption.label, style: {
                                                    backgroundColor: colors.backgroundInactive,
                                                    borderRadius: 8,
                                                    padding: (0, components_1.grid)(1),
                                                    fontStyle: 'italic',
                                                }, editable: false })))); })))),
                        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1, centerVertically: true },
                            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onAddOption },
                                react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 1, paddingLeft: 1 },
                                    react_1.default.createElement(components_1.MBIconV2, { size: (0, components_1.grid)(3), name: "plus", color: colors.foregroundActive }),
                                    react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                                    react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, (0, i18n_1.i18n)('card.blocks.tagsInput.createNewTagLabel'))))))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "tagSets", style: { zIndex: 999, padding: 0 }, title: react_1.default.createElement(menuRow_1.MBMenuRow, { style: cursorPointerStyle, title: (0, i18n_1.i18n)('workflowTemplate.tagSetOptions'), paddingVertical: 1, paddingRight: 2, image: react_1.default.createElement(components_1.MBIconV2, { name: "tag", color: colors.foregroundActive, size: (0, components_1.grid)(3) }), skipWrappingIcon: true, icon: react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right", color: colors.foregroundActive, size: (0, components_1.grid)(3) }) }) }, tagGroupSelector({ onFinishSelectGroup: closeSubmenus })),
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingTop: 1 },
                    react_1.default.createElement(divider_1.Divider, null)),
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.defaultRow.rowHeader'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "defaultValues", style: { zIndex: 999 }, title: react_1.default.createElement(ComponentDefaultItemRow_1.ComponentDefaultItemRow, { component: component }) },
                    react_1.default.createElement(DefaultSelection_1.DefaultSelection, { workflowTemplateId: workflowTemplateId, component: component })),
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.firstCardOverrideRow.rowHeader'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "overrideFirstCardValue", style: { zIndex: 999 }, title: react_1.default.createElement(ComponentDefaultItemRow_1.ComponentDefaultItemRow, { component: component, overridePath: "firstRecurringEntityDefault" }) },
                    react_1.default.createElement(DefaultSelection_1.DefaultSelection, { workflowTemplateId: workflowTemplateId, component: component, overridePath: "firstRecurringEntityDefault" })),
                react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.upcomingCardOverrideRow.rowHeader'))),
                react_1.default.createElement(rc_menu_1.SubMenu, { key: "overrideUpcomingCardValue", style: { zIndex: 999 }, title: react_1.default.createElement(ComponentDefaultItemRow_1.ComponentDefaultItemRow, { component: component, overridePath: "upcomingRecurringEntityDefault" }) },
                    react_1.default.createElement(DefaultSelection_1.DefaultSelection, { workflowTemplateId: workflowTemplateId, component: component, overridePath: "upcomingRecurringEntityDefault" }))));
        } }));
};
exports.MBWorkflowTagOptions = MBWorkflowTagOptions;
exports.MBWorkflowTagOptions.displayName = 'MBWorkflowTagOptions';
var styles = react_native_1.StyleSheet.create({
    shadow: {
        boxShadow: 'inset 0px -15px 10px -15px rgba(0,0,0,0.1), inset 0px 15px 10px -15px rgba(0,0,0,0.1)',
        marginBottom: 0,
    },
});
var Tags = function (_a) {
    var tags = _a.tags;
    return (react_1.default.createElement(react_1.default.Fragment, null, tags.map(function (tag) {
        var _a;
        return (react_1.default.createElement(tag_1.Tag, { key: tag.value, color: tag.backgroundColor, textStyle: components_1.MBTypographyStyles.body2, label: (_a = tag.label) !== null && _a !== void 0 ? _a : '' }));
    })));
};
