"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomBatch = CustomBatch;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var rrule_1 = require("rrule");
var components_1 = require("@shared/components");
var MBView_1 = require("@shared/components/view/MBView");
var styles_1 = require("@shared/styles");
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var NativeDropdownButton_1 = require("../../../../../components/dropdown/NativeDropdownButton");
var MonthOptions_1 = require("./MonthOptions");
var WeekOptions_1 = require("./WeekOptions");
var useCustomBatch_1 = require("./hooks/useCustomBatch");
function CustomBatch(_a) {
    var rruleOptions = _a.rruleOptions, setRruleOptions = _a.setRruleOptions;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), showFreqMenu = _b[0], setShowFreqMenu = _b[1];
    var containerRef = (0, react_1.useRef)(null);
    var _c = (0, useCustomBatch_1.useCustomBatch)({
        rruleOptions: rruleOptions,
        setRruleOptions: setRruleOptions,
    }), frequencyLabel = _c.frequencyLabel, interval = _c.interval, freqOptions = _c.freqOptions, handleIntervalChange = _c.handleIntervalChange;
    var handleFreqMenuOpen = function () {
        var _a;
        setShowFreqMenu(true);
        actionSheet_1.MBActionSheet.show({
            parent: (_a = containerRef.current) !== null && _a !== void 0 ? _a : undefined,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'left',
            onClose: function () { return setShowFreqMenu(false); },
            options: freqOptions,
        });
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTextBody1Bold, null, (0, i18n_1.i18n)('workflowTemplate.component.dateAndRepeat.every')),
        react_1.default.createElement(react_native_1.View, { style: {
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginVertical: (0, MBView_1.grid)(1.5),
            } },
            react_1.default.createElement(react_native_1.View, { style: { marginRight: (0, MBView_1.grid)(2), flexShrink: 0, flexGrow: 0 } },
                react_1.default.createElement(components_1.MBIconV2, { name: "repeat", size: 35, color: colors.accent })),
            react_1.default.createElement(components_1.MBTextInput, { value: String(interval !== null && interval !== void 0 ? interval : ''), onChangeText: handleIntervalChange, noPadding: true, style: {
                    width: (0, MBView_1.grid)(6.5),
                    height: (0, MBView_1.grid)(5.5),
                    paddingHorizontal: (0, MBView_1.grid)(2),
                    backgroundColor: styles_1.Colors.v2.backgroundGrayLight,
                    borderWidth: 0,
                } }),
            react_1.default.createElement(NativeDropdownButton_1.NativeDropdownButton, { containerRef: containerRef, onPress: handleFreqMenuOpen, disabled: !showFreqMenu }, frequencyLabel)),
        (rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.freq) === rrule_1.RRule.WEEKLY && (react_1.default.createElement(WeekOptions_1.WeekOptions, { rruleOptions: rruleOptions, setRruleOptions: setRruleOptions })),
        (rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.freq) === rrule_1.RRule.MONTHLY && (react_1.default.createElement(MonthOptions_1.MonthOptions, { rruleOptions: rruleOptions, setRruleOptions: setRruleOptions }))));
}
