"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = Tag;
var react_1 = __importStar(require("react"));
var render_1 = require("@shared/components/blocks/contexts/render");
var Pill_1 = require("@shared/components/pills/Pill");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
function Tag(_a) {
    var label = _a.label, color = _a.color, archived = _a.archived, style = _a.style, textStyle = _a.textStyle;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var isCalendar = renderContext === 'calendar';
    var baseViewStyle = isCalendar ? { margin: 0, marginRight: (0, view_1.grid)(1) } : {};
    var baseTextStyle = isCalendar ? typography_1.MBTypographyStyles.body2 : {};
    return (react_1.default.createElement(Pill_1.Pill, { round: true, italic: archived, backgroundColor: archived ? 'backgroundGrayLight' : color, style: [baseViewStyle, style], textStyle: [baseTextStyle, textStyle] }, label));
}
