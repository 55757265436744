"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAssigneeUserIcon = MBAssigneeUserIcon;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var render_1 = require("@shared/components/blocks/contexts/render");
var styles = react_native_1.StyleSheet.create({
    border: {
        borderColor: 'white',
    },
    userBack: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    userFront: {
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
});
var detailsAndFormStyles = react_native_1.StyleSheet.create({
    container: {
        width: 40,
        height: 40,
    },
    content: {
        width: 30,
        height: 30,
    },
    contentBorderRadius: {
        borderRadius: 15,
        overflow: 'hidden',
    },
    borderWidth: {
        borderWidth: 2,
    },
    smallUser: {
        width: 20,
        height: 20,
        borderRadius: 10,
        overflow: 'hidden',
    },
});
var listStyles = react_native_1.StyleSheet.create({
    container: {
        width: 30,
        height: 30,
    },
    content: {
        width: 24,
        height: 24,
    },
    contentBorderRadius: {
        borderRadius: 12,
        overflow: 'hidden',
    },
    borderWidth: {
        borderWidth: 2,
    },
    smallUser: {
        width: 16,
        height: 16,
        borderRadius: 8,
        overflow: 'hidden',
    },
});
var calendarStyles = react_native_1.StyleSheet.create({
    container: {
        width: 20,
        height: 20,
    },
    content: {
        width: 16,
        height: 16,
    },
    contentBorderRadius: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    borderWidth: {
        borderWidth: 1,
    },
    smallUser: {
        width: 12,
        height: 12,
        borderRadius: 6,
        overflow: 'hidden',
    },
});
function MBAssigneeUserIcon(_a) {
    var users = _a.users;
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var contextSpecificStyle = (function () {
        if (renderContext === 'calendar') {
            return calendarStyles;
        }
        if (['list', 'taskList', 'agenda', 'table'].includes(renderContext)) {
            return listStyles;
        }
        return detailsAndFormStyles;
    })();
    if (users.length === 1) {
        return (react_1.default.createElement(components_1.MBView, { center: true, style: [contextSpecificStyle.container] },
            react_1.default.createElement(components_1.MBView, { style: [
                    contextSpecificStyle.content,
                    contextSpecificStyle.contentBorderRadius,
                    contextSpecificStyle.borderWidth,
                    styles.border,
                ] },
                react_1.default.createElement(components_1.MBUserImage, { user: users[0], size: renderContext === 'calendar' ? 'tiny' : 'small' }))));
    }
    var otherUsers = users.filter(function (u) { return !u.isMe; });
    var me = users.find(function (u) { return u.isMe; });
    // If current user is among assignees, always show that on top
    var front = !!me ? me : users[0];
    var back = !!me ? otherUsers[0] : users[1];
    return (react_1.default.createElement(components_1.MBView, { center: true, style: contextSpecificStyle.container },
        react_1.default.createElement(components_1.MBView, { style: contextSpecificStyle.content },
            react_1.default.createElement(components_1.MBView, { style: [
                    contextSpecificStyle.smallUser,
                    contextSpecificStyle.borderWidth,
                    styles.border,
                    styles.userBack,
                ] },
                react_1.default.createElement(components_1.MBUserImage, { size: "tiny", user: back })),
            react_1.default.createElement(components_1.MBView, { style: [
                    contextSpecificStyle.smallUser,
                    contextSpecificStyle.borderWidth,
                    styles.border,
                    styles.userFront,
                ] },
                react_1.default.createElement(components_1.MBUserImage, { size: "tiny", user: front })))));
}
