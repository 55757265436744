"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveCardWithConfirmation = useRemoveCardWithConfirmation;
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_native_1 = require("react-native");
var toast_1 = require("@shared/components/toast");
var actionSheet_1 = require("@shared/util/actionSheet");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("../hooks");
function useRemoveCardWithConfirmation(card, onRemoved) {
    var removeCard = (0, hooks_1.useRemoveCard)();
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    return (0, react_1.useCallback)(function (anchor) {
        var _a;
        function remove(scope) {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, removeCard(card.id, scope)];
                        case 1:
                            _b.sent();
                            onRemoved === null || onRemoved === void 0 ? void 0 : onRemoved();
                            toast === null || toast === void 0 ? void 0 : toast.show({
                                icon: 'check-filled',
                                message: (0, i18n_1.i18n)('card.details.deleteCompleted', { card: (_a = card.fields) === null || _a === void 0 ? void 0 : _a.name }),
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (!(0, lodash_1.isNil)(card.recurrence)) {
            actionSheet_1.MBActionSheet.show({
                parent: anchor,
                title: (0, i18n_1.i18n)('card.details.deleteScopeActionSheet.title'),
                webHorizontalAlignment: 'right',
                options: [
                    {
                        title: (0, i18n_1.i18n)('card.details.deleteScopeActionSheet.currentOnly'),
                        onPress: function () { return void remove('ENTITY'); },
                    },
                    {
                        title: (0, i18n_1.i18n)('card.details.deleteScopeActionSheet.currentAndUpcoming'),
                        onPress: function () { return void remove('ENTITY_UPCOMING'); },
                    },
                ],
            });
        }
        else if (react_native_1.Platform.OS === 'web') {
            actionSheet_1.MBActionSheet.show({
                parent: anchor,
                title: (0, i18n_1.i18n)('card.details.deleteConfirmation.title'),
                webHorizontalAlignment: 'right',
                options: [
                    {
                        title: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                        destructive: true,
                        onPress: function () { return void remove('ENTITY'); },
                    },
                    {
                        title: (0, i18n_1.i18n)('common.cancel'),
                    },
                ],
            });
        }
        else {
            alert_1.MBAlert.confirm({
                title: (0, i18n_1.i18n)('card.details.deleteConfirmation.title'),
                message: (0, i18n_1.i18n)('card.details.deleteConfirmation.message', { card: (_a = card.fields) === null || _a === void 0 ? void 0 : _a.name }),
                confirmText: (0, i18n_1.i18n)('card.details.deleteConfirmation.confirm'),
                confirmStyle: 'destructive',
                onConfirm: function () { return void remove('ENTITY'); },
            });
        }
    }, [removeCard, onRemoved, card, toast]);
}
