"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateRecurringEntity = useUpdateRecurringEntity;
exports.validateFields = validateFields;
exports.getJsonFields = getJsonFields;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var Entity_1 = require("@shared/data/entity/Entity");
function useUpdateRecurringEntity(workflowTemplate) {
    var _this = this;
    var _a = (0, client_1.useMutation)(api_1.Mutations.entity.updateRecurringEntities), updateRecurringEntityMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var _c = (0, react_1.useState)([]), errors = _c[0], setErrors = _c[1];
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(error)) {
            // parse remote errors
            setErrors([{ description: error.message }]);
        }
    }, [error]);
    var updateRecurringEntity = (0, react_1.useCallback)(function (_a) { return __awaiter(_this, void 0, void 0, function () {
        var localErrors, entityFields;
        var fields = _a.fields, inputProps = __rest(_a, ["fields"]);
        return __generator(this, function (_b) {
            localErrors = validateFields(fields);
            setErrors(localErrors);
            if (!(0, lodash_1.isEmpty)(localErrors)) {
                return [2 /*return*/, Promise.reject('Local validation')];
            }
            entityFields = getJsonFields(fields, workflowTemplate);
            return [2 /*return*/, updateRecurringEntityMutation({
                    variables: {
                        input: __assign({ entityFields: entityFields }, inputProps),
                    },
                })];
        });
    }); }, [updateRecurringEntityMutation, workflowTemplate]);
    return (0, react_1.useMemo)(function () {
        return {
            loading: loading,
            errors: errors,
            updateRecurringEntity: updateRecurringEntity,
        };
    }, [loading, errors, updateRecurringEntity]);
}
function validateFields(fields) {
    return Object.entries(fields).reduce(function (memo, _a) {
        var componentId = _a[0], field = _a[1];
        var description = field === null || field === void 0 ? void 0 : field.validate();
        if ((0, lodash_1.isNil)(description)) {
            return memo;
        }
        else {
            return __spreadArray(__spreadArray([], memo, true), [{ componentId: componentId, description: description }], false);
        }
    }, []);
}
function getJsonFields(fields, workflowTemplate) {
    return Object.entries(fields).reduce(function (memo, _a) {
        var _b;
        var componentId = _a[0], field = _a[1];
        if (!(0, Entity_1.isCreateableField)(componentId, workflowTemplate)) {
            return memo;
        }
        return __assign(__assign({}, memo), (_b = {}, _b[componentId] = (0, lodash_1.isNil)(field) ? null : field.toFieldJson(), _b));
    }, {});
}
