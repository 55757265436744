"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBQuestionNotes = MBQuestionNotes;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var actionButton_1 = require("@shared/components/blocks/lib/inputQuestion/actionButton");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
function MBQuestionNotes(_a) {
    var label = _a.label, placeholder = _a.placeholder, value = _a.value, onChange = _a.onChange, trigger = _a.trigger;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerRef = (0, react_1.useRef)();
    var _b = (0, react_1.useState)(false), sheetVisible = _b[0], setSheetVisible = _b[1];
    var _c = (0, react_1.useState)(value), text = _c[0], setText = _c[1];
    var hideSheet = (0, react_1.useCallback)(function () { return setSheetVisible(false); }, []);
    var hideSheetAndSave = (0, react_1.useCallback)(function () {
        setText(text);
        onChange(text);
        hideSheet();
    }, [hideSheet, onChange, text]);
    var showSheet = (0, react_1.useCallback)(function () { return setSheetVisible(true); }, []);
    (0, hooks_1.useExecuteOnTrigger)(trigger, showSheet);
    var notesForm = (0, react_1.useMemo)(function () {
        return (react_1.default.createElement(components_1.MBView, { fill: true, padding: 2 },
            react_1.default.createElement(components_1.MBAutosizeTextInput, { style: { backgroundColor: colors.backgroundInactive, borderRadius: (0, components_1.grid)(1) }, onChangeText: setText, value: text, placeholder: placeholder, autoFocus: true, padding: "normal" }),
            react_1.default.createElement(components_1.MBView, { paddingBottom: 2 }),
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.ok'), onPress: hideSheetAndSave })));
    }, [colors.backgroundInactive, hideSheetAndSave, placeholder, text]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(actionButton_1.MBQuestionInputActionButton, { label: label, onPress: showSheet, icon: "multi-line", innerRef: containerRef }),
        sheetVisible && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "half", onClose: hideSheet, title: label, containerRef: containerRef, layout: {
                offset: { x: 0, y: 0 },
                minWidth: 320,
                minHeight: (0, components_1.grid)(48),
            } }, notesForm))));
}
