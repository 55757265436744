"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNewChannelDetails = MBNewChannelDetails;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var useChannelDetails_1 = require("@shared/scenes/channel/detailsForm/hooks/useChannelDetails");
var analytics_1 = require("@shared/util/analytics");
var details_1 = require("../components/details");
var Actions = __importStar(require("../store/actions"));
function MBNewChannelDetails() {
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedUsers = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.selectedUsers; });
    var workflowListItem = (0, react_redux_1.useSelector)(function (state) { return state.channelCreate.workflowListItem; });
    var removeSelectedUser = (0, react_1.useCallback)(function (user) {
        dispatch(Actions.removeSelectedUser(user));
        analytics_1.MBAnalytics.track('CreateChannel::New::DeselectUser', { userId: user.id, source: 'details' });
    }, [dispatch]);
    var updateName = (0, useChannelDetails_1.useChannelDetails)().updateName;
    (0, react_1.useEffect)(function () {
        updateName((workflowListItem === null || workflowListItem === void 0 ? void 0 : workflowListItem.name) || '');
    }, [workflowListItem, updateName]);
    return (react_1.default.createElement(details_1.MBNewChannelDetails, { users: selectedUsers, isWorkflow: !!workflowListItem, onRemoveUser: removeSelectedUser }));
}
