"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListStyles = exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
var dimensions_1 = require("@shared/util/dimensions");
var index_1 = require("./index");
var pickReactionSize = 48;
var pickReactionSpacing = 4;
exports.UnvalidatedStyles = {
    get pickerWidth() {
        var reactionsWidth = index_1.reactions.length * pickReactionSize;
        // num reactions - 1 because we have no spacing (padding)
        // at the start or end if the list
        var spacingWidth = (index_1.reactions.length - 1) * pickReactionSpacing;
        return reactionsWidth + spacingWidth + pickReactionSpacing;
    },
    get pickerHeight() {
        return pickReactionSize + 2 * pickReactionSpacing;
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    reactionButtonShadow: __assign(__assign({}, styles_1.SharedUnvalidatedStyles.shadow), { shadowOpacity: 0.7, paddingHorizontal: 6, borderRadius: 16, 
        // background color makes the shadow apply to background instead of its content
        backgroundColor: 'white' }),
    reactionButtonPadding: {
        paddingHorizontal: 10,
    },
    reactionButtonIcon: {
        width: 36,
        height: 36,
        padding: 0,
    },
    // Emojis are really hard to align. This is inspired by the npm
    // module emoji-mart does it. They use lineHeight: 1.15 on web
    // but we can't do that because 1.15 => 1.15px using react-native-web.
    // Instead we provide it as a pixel value
    stackedReactionEmoji: react_native_1.Platform.select({
        web: {
            fontSize: 20,
            textAlign: 'center',
            lineHeight: 20 * 1.15,
            // After switching the font to SF Pro, Safari
            // renders the heart emoji in a weird way.
            // Changing the font family like this solves it.
            fontFamily: '-apple-system',
        },
        default: {
            fontSize: 20,
            textAlign: 'center',
        },
    }),
    pickReactionEmoji: __assign({ fontSize: 24 }, react_native_1.Platform.select({
        web: {
            width: 24,
            height: 24,
            textAlign: 'center',
            // Note(fant): what is this? See the
            // comment for stackedReactionEmoji
            lineHeight: 24 * 1.15,
        },
    })),
    pickerWebHoverIndicator: {
        backgroundColor: styles_1.Colors.v2.green10,
        borderRadius: 999,
    },
    stackedReaction: {
        height: 30,
        borderRadius: 30 / 1,
        borderWidth: 2,
        borderColor: styles_1.Colors.v2.gray05,
        backgroundColor: 'white',
        marginRight: 4,
    },
    stackedReactionSelected: {
        borderColor: styles_1.Colors.v2.green30,
        borderWidth: 1,
    },
    pickReaction: {
        width: pickReactionSize,
        height: pickReactionSize,
        borderRadius: pickReactionSize / 2,
    },
    pickReactionSelectedImageWrapper: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    pickReactionSelectedImage: {
        width: 14,
        height: 14,
        // make the check filled
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pickReactionMarginLeft: { marginLeft: pickReactionSpacing },
    picker: __assign({ marginHorizontal: pickReactionSpacing, 
        // Note: we don't set the picker width because we want that to animate
        // width: UnvalidatedStyles.pickerWidth,
        backgroundColor: 'white', overflow: 'visible' }, styles_1.SharedUnvalidatedStyles.shadow),
});
exports.ListStyles = react_native_1.StyleSheet.create({
    container: {
        maxHeight: dimensions_1.MBDimensions.height / 2,
    },
    image: {
        width: 32,
        height: 32,
        borderRadius: 16,
        overflow: 'hidden',
    },
    emoji: {
        fontSize: 24,
    },
});
