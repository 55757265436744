"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNotifyBarWithDetailsModal = void 0;
exports.MBNotifyBarWithDetailsModalDataWrapper = MBNotifyBarWithDetailsModalDataWrapper;
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var barWithDetailsModal_1 = require("../components/barWithDetailsModal");
var Actions = __importStar(require("../store/actions"));
function MBNotifyBarWithDetailsModalDataWrapper(_a) {
    var users = _a.users, onHide = _a.onHide, onSend = _a.onSend;
    if (!users) {
        return null;
    }
    return React.createElement(barWithDetailsModal_1.MBNotifyBarWithDetailsModal, { users: users, onHide: onHide, onSend: onSend });
}
var mapStateToProps = function (state, ownProps) {
    var prompt = state.notifyPendingUsers.promptFromChannelSlug[ownProps.channelSlug];
    if (!prompt) {
        return { users: null };
    }
    return { users: prompt.users };
};
var mapDispatchToProps = function (_a, ownProps) { return ({
    onHide: function () { return Actions.hidePendingUsersPrompt(ownProps.channelSlug); },
    onSend: function () { return Actions.startAutomaticallySendingMessagesToPendingUsers(ownProps.channelSlug); },
}); };
exports.MBNotifyBarWithDetailsModal = (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(MBNotifyBarWithDetailsModalDataWrapper);
