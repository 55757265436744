"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBFieldOptions = MBFieldOptions;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var tagOptions_1 = require("../../contexts/tagOptions");
var tagOptions_2 = require("./tagOptions");
var OPTIONS_WITH_TAGS = ['questionSingleSelect'];
var optionHasTags = function (option) { return OPTIONS_WITH_TAGS.includes(option); };
function MBFieldOptions(_a) {
    var options = _a.options, onChange = _a.onChange, selectedOption = _a.selectedOption, onPressTags = _a.onPressTags, selectedTagGroup = _a.selectedTagGroup;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var availableTagGroups = (0, tagOptions_1.useTagOptionGroups)().availableTagGroups;
    var shouldDisplayCallToTags = (0, react_1.useCallback)(function (currentOption) {
        return currentOption === selectedOption && optionHasTags(selectedOption);
    }, [selectedOption]);
    var onPressOption = (0, react_1.useCallback)(function (value) {
        if (optionHasTags(value)) {
            onPressTags();
        }
        onChange(value);
    }, [onChange, onPressTags]);
    return (react_1.default.createElement(components_1.MBView, { fullWidth: true, paddingRight: 1, paddingVertical: 1, style: isDesktop && { maxWidth: tagOptions_2.MAX_WIDTH_SHEET } },
        react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always" }, options.map(function (_a) {
            var label = _a.label, icon = _a.icon, value = _a.value;
            return (react_1.default.createElement(react_1.Fragment, { key: label },
                react_1.default.createElement(components_1.MBSelectableRow, { radio: true, radioStyle: { alignItems: 'flex-end' }, selected: selectedOption === value, onPress: function () { return onPressOption(value); }, skipWrappingIcon: true, image: react_1.default.createElement(components_1.MBIconV2, { name: icon, size: (0, components_1.grid)(4), color: colors.foregroundInactive }), imageStyle: {}, title: react_1.default.createElement(components_1.MBTextBody1Bold, null, label) }),
                shouldDisplayCallToTags(value) && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPressTags },
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 2, paddingVertical: 1 },
                        react_1.default.createElement(components_1.MBView, { row: true, fill: true, style: styles.tagsContainer },
                            react_1.default.createElement(tagOptions_2.MBFieldTags, { tags: availableTagGroups[selectedTagGroup].options })),
                        react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right", size: (0, components_1.grid)(4), color: colors.foregroundInactive }))))));
        }))));
}
var styles = react_native_1.StyleSheet.create({
    tagsContainer: { flexWrap: 'wrap' },
});
