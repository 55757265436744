"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagEditField = TagEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var modal_1 = require("@shared/components/multiSelect/modal");
var Pill_1 = require("@shared/components/pills/Pill");
var TagField_1 = require("@shared/data/entity/fields/TagField");
var i18n_1 = require("@shared/util/i18n");
var EditFieldContainer_1 = require("./EditFieldContainer");
function TagEditField(_a) {
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField;
    var componentOptions = (0, react_1.useMemo)(function () { var _a; return (_a = component.options) !== null && _a !== void 0 ? _a : []; }, [component]);
    var options = (0, react_1.useMemo)(function () { var _a; return (_a = componentOptions.filter(function (option) { return !option.archived; })) !== null && _a !== void 0 ? _a : []; }, [componentOptions]);
    var archived = (0, react_1.useMemo)(function () { var _a; return (_a = componentOptions.filter(function (option) { return !!option.archived; })) !== null && _a !== void 0 ? _a : []; }, [componentOptions]);
    var optionValues = (0, react_1.useMemo)(function () { return options.map(function (option) { return option.value; }); }, [options]);
    var archivedValues = (0, react_1.useMemo)(function () { return archived.map(function (option) { return option.value; }); }, [archived]);
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a, _b; return (_b = (_a = componentOptions.find(function (option) { return option.value === value; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ''; }, [componentOptions]);
    var selectedTags = (0, react_1.useMemo)(function () {
        var _a;
        var selected = (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : [];
        return selected.map(function (id) { return componentOptions.find(function (item) { return item.value === id; }); });
    }, [field === null || field === void 0 ? void 0 : field.jsonBlob, componentOptions]);
    var onChange = (0, react_1.useCallback)(function (selection) {
        updateField(component.id, new TagField_1.TagField(selection, component.id, options));
    }, [component.id, options, updateField]);
    var renderItem = (0, react_1.useCallback)(function (value) {
        var option = componentOptions.find(function (item) { return item.value === value; });
        return react_1.default.createElement(SelectModalItem, { option: option });
    }, [componentOptions]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if ((0, lodash_1.isNil)(label)) {
            return false;
        }
        if (exact) {
            return label.toLowerCase() === (query === null || query === void 0 ? void 0 : query.toLowerCase());
        }
        return label.toLowerCase().includes(query === null || query === void 0 ? void 0 : query.toLowerCase());
    }, [labelExtractor]);
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var hasTags = selectedTags.length > 0;
    var isReadOnly = !!component.readonly;
    if (isReadOnly) {
        return react_1.default.createElement(TagFieldSummary, { component: component, selectedTags: selectedTags, hasTags: hasTags });
    }
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (_b = component.label) !== null && _b !== void 0 ? _b : '', onChange: onChange, selectAll: true, selectAllLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.selectAllLabel'), renderItem: renderItem, values: optionValues, selectedValues: selectedTags === null || selectedTags === void 0 ? void 0 : selectedTags.map(function (t) { return t.value; }), labelExtractor: labelExtractor, match: match, archivedValues: archivedValues, itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), itemsEditable: false, max: component.multiselect ? undefined : 1 },
        react_1.default.createElement(TagFieldSummary, { component: component, selectedTags: selectedTags, hasTags: hasTags, onClear: onClear })));
}
function TagFieldSummary(_a) {
    var component = _a.component, selectedTags = _a.selectedTags, onClear = _a.onClear, hasTags = _a.hasTags;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasTags, showPlaceholder: !hasTags, onClear: onClear }, hasTags && (react_1.default.createElement(components_1.MBView, { style: styles.tagList }, selectedTags.map(function (tag) { return (react_1.default.createElement(tag_1.Tag, { key: tag.value, label: tag.label, color: tag.backgroundColor, archived: !!tag.archived, style: styles.pill })); })))));
}
function SelectModalItem(_a) {
    var option = _a.option;
    if ((0, lodash_1.isNil)(option)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, flex: true },
        react_1.default.createElement(Pill_1.Pill, { key: option === null || option === void 0 ? void 0 : option.value, backgroundColor: option === null || option === void 0 ? void 0 : option.backgroundColor, round: true, style: styles.pill }, option === null || option === void 0 ? void 0 : option.label)));
}
var styles = react_native_1.StyleSheet.create({
    tagList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingVertical: (0, components_1.grid)(0),
        // cheat rounded tags left a bit (optical alignment)
        marginLeft: -(0, components_1.grid)(1),
    },
    pill: {
        marginLeft: (0, components_1.grid)(0.5),
    },
});
