"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactKey = exports.SignInContext = void 0;
exports.PersistentSignInProvider = PersistentSignInProvider;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var storage_1 = require("@shared/util/storage");
exports.SignInContext = (0, react_1.createContext)(null);
exports.contactKey = 'PersistentSignInProvider.v1.contact';
var inviteCodeKey = 'PersistentSignInProvider.v1.inviteCode';
function PersistentSignInProvider(_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(''), contact = _b[0], setContact = _b[1];
    var _c = (0, react_1.useState)(undefined), inviteCode = _c[0], setInviteCode = _c[1];
    (0, react_1.useEffect)(function () {
        storage_1.MBStorage.get(exports.contactKey).then(function (value) { return !!value && !contact && setContact(value); });
        storage_1.MBStorage.get(inviteCodeKey).then(function (value) { return !!value && !inviteCode && setInviteCode(value); });
    }, []);
    (0, react_1.useEffect)(function () {
        if (!!contact) {
            storage_1.MBStorage.set(exports.contactKey, contact);
        }
    }, [contact]);
    (0, react_1.useEffect)(function () {
        if (!!inviteCode) {
            storage_1.MBStorage.set(inviteCodeKey, inviteCode);
        }
    }, [inviteCode]);
    var value = (0, react_1.useMemo)(function () { return ({ contact: contact, setContact: setContact, inviteCode: inviteCode, setInviteCode: setInviteCode }); }, [contact, setContact, inviteCode, setInviteCode]);
    return react_1.default.createElement(exports.SignInContext.Provider, { value: value }, children);
}
