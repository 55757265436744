"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditFields = useEditFields;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var buildFieldRecord_1 = require("../../util/buildFieldRecord");
var isFieldRecordModified_1 = require("../../util/isFieldRecordModified");
function useEditFields(entityId, workflowTemplate) {
    var _a = (0, client_1.useQuery)(api_1.Queries.workflow.getWorkflowEntity, {
        variables: { input: { id: entityId } },
        skip: (0, lodash_1.isNil)(entityId),
    }), data = _a.data, loading = _a.loading, error = _a.error;
    var _b = (0, react_1.useState)(null), originalFields = _b[0], setOriginalFields = _b[1];
    var _c = (0, react_1.useState)(null), fields = _c[0], setFields = _c[1];
    var modifiedFields = (0, react_1.useMemo)(function () { return (0, isFieldRecordModified_1.getModifiedFields)(originalFields, fields); }, [originalFields, fields]);
    var updateField = function (componentId, field) {
        setFields(function (previousFields) {
            var _a;
            var previousField = previousFields === null || previousFields === void 0 ? void 0 : previousFields[componentId];
            if ((0, isFieldRecordModified_1.isFieldModified)(previousField, field)) {
                return __assign(__assign({}, previousFields), (_a = {}, _a[componentId] = field, _a));
            }
            else {
                return previousFields;
            }
        });
    };
    var isDirty = !(0, lodash_1.isNil)(modifiedFields) && Object.keys(modifiedFields).length > 0;
    (0, react_1.useEffect)(function () {
        var _a, _b;
        var entity = (_a = data === null || data === void 0 ? void 0 : data.workflowEntity) === null || _a === void 0 ? void 0 : _a.entity;
        var refs = (_b = data === null || data === void 0 ? void 0 : data.workflowEntity) === null || _b === void 0 ? void 0 : _b.refs;
        var components = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components;
        if (entity && refs && components) {
            var fieldRecord = (0, buildFieldRecord_1.buildFieldRecord)(entity, components, refs, null);
            setOriginalFields(fieldRecord);
            setFields(fieldRecord);
        }
    }, [data, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components]);
    return {
        fields: fields,
        originalFields: originalFields,
        modifiedFields: modifiedFields,
        updateField: updateField,
        loading: loading,
        isDirty: isDirty,
        error: error === null || error === void 0 ? void 0 : error.message,
    };
}
