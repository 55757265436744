"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardCollectionWebHeader = CardCollectionWebHeader;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var headerButton_1 = require("@shared/scenes/cards/actions/headerButton");
var isLowCodeViewTemplate_1 = require("@shared/scenes/cards/collection/components/isLowCodeViewTemplate");
var header_1 = require("@shared/scenes/cards/collection/containers/header");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var hooks_2 = require("@shared/util/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
function CardCollectionWebHeader(_a) {
    var _b;
    var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _c = (0, useNavigator_1.useNavigator)(), navigate = _c.navigate, replace = _c.replace;
    var navigateToTemplate = (0, react_1.useCallback)(function (slug) { return replace('ChannelCardList', { channelSlug: channelSlug, templateSlug: slug }); }, [replace, channelSlug]);
    var navigateToChat = (0, react_1.useCallback)(function () { return replace('ChannelChat', { channelSlug: channelSlug }); }, [replace, channelSlug]);
    var navigateToHome = (0, react_1.useCallback)(function () { return navigate('Home'); }, [navigate]);
    var channel = (0, hooks_2.useChannel)(channelSlug);
    var unreadCount = (_b = channel === null || channel === void 0 ? void 0 : channel.unreadMessagesCount) !== null && _b !== void 0 ? _b : 0;
    var shouldUseNoCodeBoardView = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.BOARD_VIEW);
    var viewTemplate = (0, hooks_1.useViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, templateSlug).viewTemplate;
    var isLowCodeView = (0, isLowCodeViewTemplate_1.isLowCodeViewTemplate)({ viewTemplate: viewTemplate, shouldUseNoCodeBoardView: shouldUseNoCodeBoardView });
    return (react_1.default.createElement(components_1.MBWebNavigationHeader, { onPressLeft: navigateToHome, title: react_1.default.createElement(header_1.MBCardCollectionHeader, { channelSlug: channelSlug, templateSlug: templateSlug, onNavigateToTemplate: navigateToTemplate }), rightButton: react_1.default.createElement(components_1.MBView, { row: true },
            !(0, lodash_1.isNil)(viewTemplate) && (react_native_1.Platform.OS !== 'web' || isLowCodeView) && (react_1.default.createElement(headerButton_1.MBCardActionsHeaderButton, null)),
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: navigateToChat },
                react_1.default.createElement(components_1.MBIconWithNotificationBadge, { name: "chat", color: colors.foregroundActive, count: unreadCount }))) }));
}
