"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserImage = MBUserImage;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("../styles");
function toInitials(name) {
    // 1. Get initials
    // https://stackoverflow.com/a/8279881/9117995
    var match = name.match(/\b(\w)/g);
    // Note: this will return null if the name is empty.
    if (!match) {
        return '';
    }
    // 2. Join first 3 initials into a string
    return match.join('').slice(0, 2).toUpperCase();
}
function MBUserImage(_a) {
    var user = _a.user, style = _a.style, _b = _a.size, size = _b === void 0 ? 'normal' : _b;
    if (!user) {
        return (React.createElement(components_1.MBView, { center: true, style: [style || styles_1.SharedStyles.fill, { backgroundColor: styles_1.Colors.v2.gray10 }] }));
    }
    if (!user.imageUrl || user.imageUrl.includes('imgur.com')) {
        var backgroundColor = styles_1.Colors.v2.avatar.user[user.id % styles_1.Colors.v2.avatar.user.length];
        return (React.createElement(components_1.MBView, { center: true, style: [style || styles_1.SharedStyles.fill, { backgroundColor: backgroundColor }] },
            React.createElement(components_1.MBText, { bold: true, color: styles_1.Colors.v2.purple, style: [
                    size === 'tiny' && styles_2.Styles.userPlaceholderTextTiny,
                    size === 'small' && styles_2.Styles.userPlaceholderTextSmall,
                    size === 'large' && styles_2.Styles.userPlaceholderTextLarge,
                ] }, toInitials(user.fullName))));
    }
    return React.createElement(components_1.MBImage, { style: style || styles_1.SharedStyles.fill, source: { uri: user.imageUrl } });
}
