"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockImage = MBCardInputBlockImage;
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var utils_1 = require("./input/utils");
var FileInput_1 = require("./inputFile/FileInput");
function MBCardInputBlockImage(_a) {
    var block = _a.block;
    var _b = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _b.setFieldValue, values = _b.values, errors = _b.errors;
    var error = errors[block.name];
    var imageUri = values[block.name];
    var acceptedContentTypes = (0, react_1.useMemo)(function () { return ['image/*']; }, []);
    var onFileAdded = (0, react_1.useCallback)(function (file) {
        var newValue = file.url;
        setFieldValue(block.name, newValue);
    }, []);
    var onFileRemoved = (0, react_1.useCallback)(function (_) {
        setFieldValue(block.name, null);
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(FileInput_1.FileInput, { label: block.label, error: !!error, placeholder: block.placeholder, icon: block.icon, acceptedContentTypes: acceptedContentTypes, onFileAdded: onFileAdded, onFileRemoved: onFileRemoved, files: (0, react_1.useMemo)(function () { return (!!imageUri ? [{ name: '', contentType: 'image/unknown', url: imageUri }] : []); }, [imageUri]) }),
        (0, utils_1.renderError)(block)));
}
