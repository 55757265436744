"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSelectUsersForChannel = MBSelectUsersForChannel;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var use_layout_animation_1 = require("use-layout-animation");
var components_1 = require("@shared/components");
var permissionLevelRow_1 = require("@shared/scenes/channel/addUsers/components/permissionLevelRow");
var horizontalUserList_1 = require("@shared/scenes/channel/create/components/horizontalUserList");
var userList_1 = require("@shared/scenes/channel/create/components/userList");
var inviteContactsRow_1 = require("@shared/scenes/contacts/components/inviteContactsRow");
var i18n_1 = require("@shared/util/i18n");
function MBSelectUsersForChannel(_a) {
    var users = _a.users, selectedUsers = _a.selectedUsers, onToggleSelectedUser = _a.onToggleSelectedUser, onToggleAllUsers = _a.onToggleAllUsers, onInviteContacts = _a.onInviteContacts, accessLevel = _a.accessLevel, accessLevels = _a.accessLevels, setAccessLevel = _a.setAccessLevel;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, react_1.useState)(''), query = _b[0], setQuery = _b[1];
    (0, use_layout_animation_1.useLayoutAnimation)([selectedUsers]);
    var filteredUsers = (0, react_1.useMemo)(function () {
        return users === null || users === void 0 ? void 0 : users.filter(function (user) {
            if (user.isMe) {
                // filter out the current user
                return false;
            }
            if (!query) {
                // if not searching, show all users
                return true;
            }
            return user.fullName.toLowerCase().includes(query.toLowerCase());
        });
    }, [users, query]);
    if (!users) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, null);
    }
    if (!(users === null || users === void 0 ? void 0 : users.length)) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('channel.addUsers.emptyPlaceholder.title'), description: (0, i18n_1.i18n)('channel.addUsers.emptyPlaceholder.description'), image: require('@shared/assets/images/v2/avatar.png'), imageSize: (0, components_1.grid)(12), testID: "select-channel-users-empty-state" }),
            react_1.default.createElement(inviteContactsRow_1.MBInviteContactsRow, { onPress: onInviteContacts }),
            react_1.default.createElement(components_1.MBView, { paddingTop: 4 })));
    }
    return (react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", style: { height: '100%' } },
        react_1.default.createElement(components_1.MBView, { paddingVertical: 2, paddingHorizontal: 1, testID: "select-channel-users-list" },
            react_1.default.createElement(components_1.MBSearchBar, { onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('channel.create.searchUsersPlaceholder') })),
        react_1.default.createElement(horizontalUserList_1.MBHorizontalUserList, { users: selectedUsers, onRemove: onToggleSelectedUser }),
        react_1.default.createElement(components_1.MBView, { paddingTop: 1 }),
        accessLevel && (react_1.default.createElement(permissionLevelRow_1.MBPermissionLevelRow, { accessLevel: accessLevel, accessLevels: accessLevels, onUpdateAccessLevel: setAccessLevel, isDesktop: isDesktop })),
        react_1.default.createElement(userList_1.MBUserList, { users: filteredUsers, isAllUsersSelected: users.length === selectedUsers.length, selectedUsers: selectedUsers, onPressUser: onToggleSelectedUser, onToggleAllUsers: onToggleAllUsers }),
        react_1.default.createElement(components_1.MBView, { paddingTop: 3 }),
        react_1.default.createElement(inviteContactsRow_1.MBInviteContactsRow, { onPress: onInviteContacts })));
}
