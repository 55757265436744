"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBProductUpdateBanner = MBProductUpdateBanner;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var analytics_1 = require("@shared/util/analytics");
var linking_1 = require("@shared/util/linking");
function MBProductUpdateBanner(_a) {
    var productUpdate = _a.productUpdate, _b = _a.icon, icon = _b === void 0 ? react_1.default.createElement(components_1.MBIconV2, { name: "coast-symbol", color: styles_1.Colors.v2.green, size: (0, components_1.grid)(5) }) : _b, onDismiss = _a.onDismiss;
    (0, react_1.useEffect)(function () {
        analytics_1.MBAnalytics.track('ProductUpdates::Shown', { slug: productUpdate.slug });
    }, [productUpdate]);
    var handleDismiss = (0, react_1.useCallback)(function () {
        analytics_1.MBAnalytics.track('ProductUpdates::Dismiss', { slug: productUpdate.slug });
        onDismiss();
    }, [productUpdate, onDismiss]);
    var handleLink = (0, react_1.useCallback)(function () {
        analytics_1.MBAnalytics.track('ProductUpdates::FollowLink', {
            slug: productUpdate.slug,
            url: productUpdate.link.url,
        });
        linking_1.MBLinking.open(productUpdate.link.url);
    }, [productUpdate]);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: { padding: (0, components_1.grid)(1) }, disabled: !productUpdate.link, onPress: handleLink },
        react_1.default.createElement(components_1.MBView, { style: { backgroundColor: styles_1.Colors.v2.green10, borderRadius: (0, components_1.grid)(1) }, padding: 2, row: true },
            icon,
            react_1.default.createElement(components_1.MBView, { flex: true, paddingLeft: 1 },
                react_1.default.createElement(components_1.MBView, { row: true, style: { justifyContent: 'flex-start' } },
                    react_1.default.createElement(components_1.MBView, { flex: true },
                        react_1.default.createElement(components_1.MBTextBody1Bold, null, productUpdate.title)),
                    react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handleDismiss },
                        react_1.default.createElement(components_1.MBIconV2, { name: "exit-small", color: styles_1.Colors.v2.purple, size: (0, components_1.grid)(3) }))),
                react_1.default.createElement(components_1.MBView, { paddingVertical: 1 },
                    react_1.default.createElement(components_1.MBTextBody2, null, productUpdate.message)),
                productUpdate.link && react_1.default.createElement(components_1.MBTextBody2Bold, null, productUpdate.link.title)))));
}
