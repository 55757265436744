"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserGroup = useUserGroup;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useUserGroup(groupId) {
    var _a = (0, client_1.useQuery)(api_1.Queries.user.getUserGroup, {
        variables: { id: groupId },
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return (0, react_1.useMemo)(function () { return ({
        userGroup: data === null || data === void 0 ? void 0 : data.getUserGroup,
        loading: loading,
        error: error,
    }); }, [data === null || data === void 0 ? void 0 : data.getUserGroup, loading, error]);
}
