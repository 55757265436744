"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUsersToChannelProvider = AddUsersToChannelProvider;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_2 = require("../hooks");
var context_1 = require("./context");
function useUsersInBusinessButNotInChannel(channelSlug) {
    var allUsersInBusiness = (0, hooks_1.useCurrentBusinessUsers)();
    var channel = (0, hooks_1.useChannel)(channelSlug);
    if (!allUsersInBusiness || !channel) {
        return null;
    }
    var channelUsers = channel.users;
    return allUsersInBusiness.filter(function (bu) { return !channelUsers.some(function (cu) { return cu.id === bu.id; }); });
}
function AddUsersToChannelProvider(_a) {
    var _this = this;
    var children = _a.children, channelSlug = _a.channelSlug;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var users = useUsersInBusinessButNotInChannel(channelSlug);
    var channelId = (0, hooks_1.useChannelId)(channelSlug);
    var addUsersToChannel = (0, hooks_2.useAddUsersToChannel)();
    var onAdd = (0, react_1.useCallback)(function (selectedUsers, accessLevel) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            addUsersToChannel(channelId, selectedUsers, accessLevel);
            goBack();
            toast.show({
                style: 'confirmation',
                message: (0, i18n_1.i18n)('channel.addUsers.toast', { count: selectedUsers.length }),
            });
            return [2 /*return*/];
        });
    }); }, [addUsersToChannel, goBack, channelId, toast]);
    return (react_1.default.createElement(context_1.AddUsersProvider, { users: users, onAdd: onAdd }, children));
}
