"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatVideoAttachmentWithPreview = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var modal_1 = require("@shared/components/video/preview/modal");
var index_1 = require("./index");
var FALLBACK_WIDTH = 300;
var FALLBACK_HEIGHT = 169; // 16:9
var MBChatVideoAttachmentWithPreview = function (_a) {
    var attachment = _a.attachment, style = _a.style, isSending = _a.isSending, progress = _a.progress, onLongPress = _a.onLongPress, containerWidth = _a.containerWidth;
    var _b = (0, react_1.useState)(false), isShowingPreview = _b[0], setIsShowingPreview = _b[1];
    var showDetails = (0, react_1.useCallback)(function () { return setIsShowingPreview(true); }, []);
    var hideDetails = (0, react_1.useCallback)(function () { return setIsShowingPreview(false); }, []);
    var maxWidth = containerWidth * 0.75;
    var maxHeight = react_native_1.Dimensions.get('window').height / 2;
    var ratio = attachment.width / attachment.height;
    var usableWidth = attachment.width || FALLBACK_WIDTH;
    var usableHeight = attachment.height || FALLBACK_HEIGHT;
    var effectiveWidth = Math.min(maxWidth, usableWidth);
    var effectiveHeight = Math.min(maxHeight, usableHeight);
    var isLandscape = ratio >= 1;
    var isPortrait = ratio < 1;
    if (isLandscape) {
        effectiveHeight = effectiveWidth / ratio;
    }
    if (isPortrait) {
        effectiveWidth = effectiveHeight * ratio;
    }
    if (react_native_1.Platform.OS === 'web') {
        return (react_1.default.createElement(components_1.MBView, { fill: true, style: [
                style,
                {
                    maxWidth: effectiveWidth,
                    maxHeight: effectiveHeight,
                    width: effectiveWidth,
                    height: effectiveHeight,
                },
            ], testID: "MBChatVideoAttachmentWithPreview" },
            react_1.default.createElement(index_1.MBChatVideoAttachment, { attachment: attachment, isSending: isSending, progress: progress })));
    }
    return (react_1.default.createElement(components_1.MBView, { fill: true, style: [
            style,
            {
                maxWidth: effectiveWidth,
                maxHeight: effectiveHeight,
                width: effectiveWidth,
                height: effectiveHeight,
            },
        ], testID: "MBChatVideoAttachmentWithPreview" },
        react_1.default.createElement(components_1.MBTouchableOpacity, { fill: true, disabled: isSending, activeOpacity: 0.7, onPress: showDetails, onLongPress: onLongPress, style: { width: '100%', height: '100%' } },
            react_1.default.createElement(index_1.MBChatVideoAttachment, { attachment: attachment, isSending: isSending, progress: progress })),
        react_1.default.createElement(modal_1.MBVideoPreviewModal, { visible: isShowingPreview, videoUri: attachment.url, onClose: hideDetails })));
};
exports.MBChatVideoAttachmentWithPreview = MBChatVideoAttachmentWithPreview;
