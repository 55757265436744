"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelPermissionsProvider = ChannelPermissionsProvider;
// @ts-strict-ignore
var ability_1 = require("@casl/ability");
var react_1 = __importStar(require("react"));
var ChannelPermissionsContext_1 = require("./ChannelPermissionsContext");
var PermissionsContext_1 = require("./PermissionsContext");
var hooks_1 = require("./hooks");
function checkPermission(action, subject, channelAbility, field) {
    var subjectType = channelAbility.detectSubjectType(subject);
    var rules = channelAbility.rulesFor(action, subjectType, field);
    var rule = rules.find(function (item) { return item.matchesConditions(subject); });
    return { enabled: !!rule && !rule.inverted, reason: rule === null || rule === void 0 ? void 0 : rule.reason };
}
function ChannelPermissionsProvider(_a) {
    var channelId = _a.channelId, children = _a.children;
    var caslAbility = (0, hooks_1.usePermissions)();
    var ability = (0, react_1.useMemo)(function () { return new ability_1.Ability(caslAbility, { detectSubjectType: hooks_1.detectSubjectType }); }, [caslAbility]);
    var channelAbility = (0, react_1.useMemo)(function () {
        return (0, hooks_1.getChannelAbility)(ability, channelId);
    }, [ability, channelId]);
    var createCardPermission = checkPermission('create', 'Card', channelAbility);
    var updateCardPermission = checkPermission('update', 'Card', channelAbility);
    var deleteCardPermission = checkPermission('delete', 'Card', channelAbility);
    var readThreadPermission = checkPermission('read', 'ThreadMessage', channelAbility);
    var createThreadPermission = checkPermission('create', 'ThreadMessage', channelAbility);
    return (react_1.default.createElement(ChannelPermissionsContext_1.ChannelPermissionsContext.Provider, { value: {
            channelId: channelId,
            channelAbility: channelAbility,
            canCreateCard: createCardPermission,
            canUpdateCard: updateCardPermission,
            canDeleteCard: deleteCardPermission,
            canReadThread: readThreadPermission,
            canCreateThread: createThreadPermission,
        } },
        react_1.default.createElement(PermissionsContext_1.PermissionsContext.Provider, { value: ability }, children)));
}
