"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaveCallButton = LeaveCallButton;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function LeaveCallButton(_a) {
    var onPress = _a.onPress;
    var _b = (0, react_1.useState)(false), confirm = _b[0], setConfirm = _b[1];
    var revertButtonTimerRef = (0, react_1.useRef)();
    var buttonRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        return function () { return clearTimeout(revertButtonTimerRef.current); };
    }, []);
    var handlePress = (0, react_1.useCallback)(function () {
        if (confirm) {
            onPress();
        }
        else {
            setConfirm(true);
            revertButtonTimerRef.current = global.setTimeout(function () {
                setConfirm(false);
            }, 10000);
        }
    }, [confirm, onPress]);
    (0, react_1.useEffect)(function () {
        if (buttonRef.current) {
            var node = (0, react_dom_1.findDOMNode)(buttonRef.current);
            if (node) {
                node.style.transition = 'background-color 500ms, width 100ms';
            }
        }
    }, []);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handlePress },
        react_1.default.createElement(react_native_1.View, { ref: buttonRef, style: {
                backgroundColor: confirm ? styles_1.Colors.v2.actionWarmRed : styles_1.Colors.v2.purple,
                borderRadius: (0, components_1.grid)(2),
                height: (0, components_1.grid)(4),
                marginRight: (0, components_1.grid)(4),
                alignItems: 'center',
                justifyContent: 'center',
                width: confirm ? (0, components_1.grid)(12) : (0, components_1.grid)(9),
                paddingHorizontal: (0, components_1.grid)(2),
            } },
            react_1.default.createElement(components_1.MBTextCaption1Bold, { color: styles_1.Colors.v2.white, numberOfLines: 1 }, confirm ? (0, i18n_1.i18n)('calling.leaveButton.confirmLabel') : (0, i18n_1.i18n)('calling.leaveButton.label')))));
}
