"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBWorkflowEdit = MBWorkflowEdit;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var context_1 = require("../../context/context");
var context_2 = require("../context");
var addComponent_1 = require("./addComponent");
var componentEditor_1 = require("./componentEditor");
var ADD_COMPONENT_ID = 'add-component';
function MBWorkflowEdit(_a) {
    var workflowTemplateId = _a.workflowTemplateId, loading = _a.loading, isSubform = _a.isSubform, workspaceDetails = _a.workspaceDetails;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useContext)(context_2.BuilderComponentOpenContext), componentEditorOpen = _b[0], _setComponentEditorOpen = _b[1];
    var workflowTemplate = (0, context_1.useWorkflowTemplateState)().workflowTemplate;
    var dispatch = (0, context_1.useWorkflowDispatch)();
    var components = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components;
    var name = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.name;
    (0, react_1.useEffect)(function () {
        if (!components) {
            return;
        }
        var allIds = __spreadArray([ADD_COMPONENT_ID], components.map(function (_a) {
            var id = _a.id;
            return id;
        }), true);
        if (!allIds.includes(componentEditorOpen)) {
            _setComponentEditorOpen(null);
        }
    }, [components, componentEditorOpen, _setComponentEditorOpen]);
    var setComponentEditorOpen = (0, react_1.useCallback)(function (whichComponent) {
        if (!(0, lodash_1.isNil)(whichComponent)) {
            _setComponentEditorOpen(whichComponent);
        }
        else {
            // Required to not make the close click open a new sheet
            setTimeout(function () {
                _setComponentEditorOpen(whichComponent);
            }, 150);
        }
    }, [_setComponentEditorOpen]);
    var disableOpenSheets = typeof componentEditorOpen === 'string' || loading;
    var onDragEnd = (0, react_1.useCallback)(function (result) {
        if (!result.destination) {
            return;
        }
        dispatch({
            type: 'reorderComponent',
            args: {
                source: result.source.index,
                target: result.destination.index,
            },
        });
    }, [dispatch]);
    var renderDraggableComponentEditor = (0, react_1.useCallback)(function (provided, snapshot, rubric) {
        var component = components[rubric.source.index];
        var style = __assign(__assign(__assign({}, provided.draggableProps.style), { backgroundColor: colors.background }), (snapshot.isDragging && {
            boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
            borderRadius: (0, components_1.grid)(1),
            opacity: snapshot.isDragging ? 0.5 : 1,
        }));
        return (react_1.default.createElement("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { style: style, ref: provided.innerRef }),
            react_1.default.createElement(componentEditor_1.MBWorkflowComponentEditor, { key: component.id, workflowTemplateId: workflowTemplateId, components: workflowTemplate.components, component: component, disableOpenSheet: disableOpenSheets, setShowingSheet: function (opened) { return setComponentEditorOpen(opened ? component.id : null); }, showingSheet: componentEditorOpen === component.id, workspaceDetails: workspaceDetails })));
    }, [
        components,
        colors.background,
        workflowTemplateId,
        workflowTemplate.components,
        disableOpenSheets,
        componentEditorOpen,
        workspaceDetails,
        setComponentEditorOpen,
    ]);
    return (react_1.default.createElement(components_1.MBView, { fill: true },
        react_1.default.createElement(components_1.MBView, { row: true, center: true, padding: 1, style: { backgroundColor: colors.highlightActive } },
            react_1.default.createElement(components_1.MBView, { row: true, center: true, style: { width: '70%' } },
                react_1.default.createElement(components_1.MBIconV2, { name: "info-outline", size: (0, components_1.grid)(3), color: colors.foregroundActive }),
                react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                react_1.default.createElement(components_1.MBTextBody1, { numberOfLines: 2 },
                    (0, i18n_1.i18n)('workflowTemplate.customizingFieldsMessage'),
                    react_1.default.createElement(components_1.MBTextBody1Bold, null, name)))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 8 },
            react_1.default.createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: onDragEnd },
                react_1.default.createElement(react_beautiful_dnd_1.Droppable, { droppableId: "components", renderClone: renderDraggableComponentEditor, children: function (provided) { return (react_1.default.createElement("div", { ref: provided.innerRef }, components === null || components === void 0 ? void 0 :
                        components.map(function (component, i) { return (react_1.default.createElement(components_1.MBView, { key: component.id },
                            react_1.default.createElement(react_beautiful_dnd_1.Draggable, { draggableId: component.id, index: i, children: renderDraggableComponentEditor }))); }),
                        provided.placeholder)); } })),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 4 },
                react_1.default.createElement(addComponent_1.MBWorkflowAddComponent, { isSubform: isSubform, showingSheet: componentEditorOpen === ADD_COMPONENT_ID, setShowingSheet: function (opened) { return setComponentEditorOpen(opened ? ADD_COMPONENT_ID : null); }, disableOpenSheet: disableOpenSheets })))));
}
