"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCardDefinitionTagNames = useCardDefinitionTagNames;
exports.useFieldMetadata = useFieldMetadata;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var lodash_1 = __importStar(require("lodash"));
var react_1 = require("react");
var api_1 = require("@shared/api");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var provider_1 = require("@shared/scenes/cards/actions/provider");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_3 = require("../workflows/hooks");
var hooks_4 = require("./form/create/hooks");
function useCardDefinitionTagNames(cardDefinition) {
    return (0, react_1.useMemo)(function () {
        var _a;
        if (lodash_1.default.isNil((_a = cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.definition) === null || _a === void 0 ? void 0 : _a.properties)) {
            return [];
        }
        return Object.keys(cardDefinition.definition.properties).filter(function (key) { return cardDefinition.definition.properties[key].fieldType === 'tags'; });
    }, [cardDefinition]);
}
function useFieldMetadata() {
    var _a, _b;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var excludedFieldNames = (0, react_1.useContext)(provider_1.CardFilterStateContext).excludedFieldNames;
    var _c = (0, hooks_4.useTemplateWithEditContextFallback)(channel === null || channel === void 0 ? void 0 : channel.slug, undefined, cardDefinition), template = _c.template, loading = _c.loading;
    var tagFieldNames = useCardDefinitionTagNames(cardDefinition);
    var tagFields = (0, react_1.useMemo)(function () {
        if (loading) {
            return [];
        }
        if (!template) {
            return [];
        }
        if (!cardDefinition) {
            return [];
        }
        return tagFieldNames
            .map(function (fieldName) {
            var _a;
            var block;
            block = template.cardBlocks.filter(isInputBlock).find(function (item) { return item.name === fieldName; });
            if (lodash_1.default.isNil(block)) {
                block = template.cardBlocks
                    .filter(isTagsBlock)
                    .find(function (item) { return item.names.includes(fieldName); });
            }
            if (!(block === null || block === void 0 ? void 0 : block.label) || !(block === null || block === void 0 ? void 0 : block.icon)) {
                return undefined;
            }
            return {
                name: fieldName,
                title: block.label,
                icon: block.icon,
                type: 'tag',
                required: !!((_a = cardDefinition.definition.required) === null || _a === void 0 ? void 0 : _a.includes(fieldName)),
            };
        })
            .filter(function (t) { return t; });
    }, [cardDefinition, tagFieldNames, loading, template]);
    var assigneeField = (0, react_1.useMemo)(function () { return ({
        name: 'assignees',
        title: (0, i18n_1.i18n)('card.actions.filters.fields.person.title'),
        icon: 'avatar',
        type: 'assignee',
    }); }, []);
    var startDateField = (0, react_1.useMemo)(function () { return ({
        name: 'startDate',
        title: (0, i18n_1.i18n)('card.actions.filters.fields.startDate.title'),
        icon: 'calendar',
        type: 'date',
    }); }, []);
    var _d = (0, client_1.useQuery)(api_1.Queries.channel.getRelatedChannelAssociations, {
        variables: { id: (_a = channel === null || channel === void 0 ? void 0 : channel.id) !== null && _a !== void 0 ? _a : -1 },
        skip: (0, lodash_1.isNil)(channel === null || channel === void 0 ? void 0 : channel.id),
    }), relatedChannelsData = _d.data, relatedChannelsLoading = _d.loading;
    var cardFields = (0, react_1.useMemo)(function () {
        if (loading || relatedChannelsLoading) {
            return [];
        }
        if (!template) {
            return [];
        }
        var relatedCardFieldNames = lodash_1.default.isNil(workflowTemplate)
            ? Object.keys(cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.definition.properties).filter(function (key) { return (cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.definition.properties[key]).fieldType === 'cards'; })
            : getRelatedCardFields(workflowTemplate.components);
        return (relatedCardFieldNames
            .map(function (fieldName) {
            var block;
            block = template.cardBlocks.filter(isInputBlock).find(function (item) { return item.name === fieldName; });
            if (lodash_1.default.isNil(block)) {
                block = template.cardBlocks
                    .filter(isCardsBlock)
                    .find(function (item) { return item.names.includes(fieldName); });
            }
            var title = block === null || block === void 0 ? void 0 : block.label;
            if (!title) {
                return undefined;
            }
            var icon = block === null || block === void 0 ? void 0 : block.icon;
            if (!icon) {
                return undefined;
            }
            return {
                name: fieldName,
                title: title,
                icon: icon,
                type: 'card',
            };
        })
            .filter(function (t) { return t; })
            // filter out fields that haven't been linked yet
            .filter(function (t) {
            return relatedChannelsData.getChannel.relatedChannelAssociations.some(function (rel) { return rel.field === t.name; });
        }));
    }, [
        loading,
        relatedChannelsLoading,
        template,
        workflowTemplate,
        cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.definition.properties,
        (_b = relatedChannelsData === null || relatedChannelsData === void 0 ? void 0 : relatedChannelsData.getChannel) === null || _b === void 0 ? void 0 : _b.relatedChannelAssociations,
    ]);
    return (0, react_1.useMemo)(function () {
        return __spreadArray(__spreadArray(__spreadArray([], tagFields, true), [assigneeField, startDateField], false), cardFields, true).filter(function (field) { return !excludedFieldNames.includes(field.name); });
    }, [tagFields, assigneeField, startDateField, excludedFieldNames, cardFields]);
}
function getRelatedCardFields(components) {
    return components
        .filter(function (component) { return (0, hooks_3.isRelatedCardComponent)(component); })
        .map(function (component) { return component.id; });
}
function isInputBlock(block) {
    return block.type === 'input';
}
function isTagsBlock(block) {
    return block.type === 'tags';
}
function isCardsBlock(block) {
    return block.type === 'cards';
}
