"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBUserList = MBUserList;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/util/hooks");
var userList_1 = require("../components/userList");
function MBUserList(_a) {
    var query = _a.query, selectedUsers = _a.selectedUsers, onPressUser = _a.onPressUser;
    var allUsers = (0, hooks_1.useCurrentBusinessUsers)();
    var users = (0, react_1.useMemo)(function () {
        var _a;
        var lowerCaseQuery = (_a = query === null || query === void 0 ? void 0 : query.toLowerCase()) !== null && _a !== void 0 ? _a : '';
        return (allUsers !== null && allUsers !== void 0 ? allUsers : []).filter(function (user) {
            if (user.isMe) {
                return false;
            }
            return user.fullName.toLowerCase().includes(lowerCaseQuery);
        });
    }, [allUsers, query]);
    if (!allUsers) {
        return react_1.default.createElement(components_1.MBView, { center: true, children: react_1.default.createElement(components_1.MBLoadingIndicator, null) });
    }
    return react_1.default.createElement(userList_1.MBUserList, { users: users, selectedUsers: selectedUsers, onPressUser: onPressUser });
}
