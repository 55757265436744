"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Carousel = Carousel;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var iconV2_1 = require("../image/iconV2");
var pagination_1 = require("./pagination");
var styles = react_native_1.StyleSheet.create({
    flex: {
        flex: 1,
    },
    prevButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nextButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabled: {
        opacity: 0.25,
        pointerEvents: 'none',
    },
});
function Carousel(_a) {
    var data = _a.data, style = _a.style, contentOffset = _a.contentOffset, renderItem = _a.renderItem, onChangeActiveIndex = _a.onChangeActiveIndex, _b = _a.paginationColor, paginationColor = _b === void 0 ? 'black' : _b, _c = _a.itemWidthPercentage, itemWidthPercentage = _c === void 0 ? 0.8 : _c, _d = _a.initialActiveIndex, initialActiveIndex = _d === void 0 ? 0 : _d, _e = _a.showNavButtons, showNavButtons = _e === void 0 ? false : _e;
    var scrollView = (0, react_1.useRef)();
    var _f = (0, react_1.useState)(initialActiveIndex), activeIndex = _f[0], setActiveIndex = _f[1];
    var _g = (0, react_1.useState)(0), width = _g[0], setWidth = _g[1];
    var itemWidth = width * itemWidthPercentage;
    var paddingHorizontal = (width - itemWidth) / 2;
    var onLayout = (0, react_1.useCallback)(function (event) {
        setWidth(event.nativeEvent.layout.width);
    }, []);
    var onScroll = (0, react_1.useCallback)(function (event) {
        var centerX = event.nativeEvent.contentOffset.x;
        var index = Math.round(centerX / itemWidth);
        setActiveIndex(index);
        onChangeActiveIndex === null || onChangeActiveIndex === void 0 ? void 0 : onChangeActiveIndex(index);
        contentOffset === null || contentOffset === void 0 ? void 0 : contentOffset.setValue(event.nativeEvent.contentOffset.x);
    }, [itemWidth, contentOffset, onChangeActiveIndex]);
    var scrollToIndex = (0, react_1.useCallback)(function (index, animated) {
        var _a;
        if (animated === void 0) { animated = true; }
        (_a = scrollView.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ animated: animated, x: index * itemWidth });
    }, [itemWidth]);
    var scrollToPrev = (0, react_1.useCallback)(function () { return scrollToIndex(Math.max(activeIndex - 1, 0)); }, [scrollToIndex, activeIndex]);
    var scrollToNext = (0, react_1.useCallback)(function () { return scrollToIndex(Math.min(activeIndex + 1, data.length - 1)); }, [scrollToIndex, activeIndex, data.length]);
    (0, react_1.useEffect)(function () {
        if (!!width && typeof initialActiveIndex !== 'undefined') {
            scrollToIndex(initialActiveIndex);
        }
    }, [width, initialActiveIndex, scrollToIndex]);
    var contentContainerStyle = (0, react_1.useMemo)(function () { return ({
        paddingHorizontal: paddingHorizontal,
        width: itemWidth * data.length + paddingHorizontal * 2,
    }); }, [data.length, itemWidth, paddingHorizontal]);
    var renderedItems = (0, react_1.useMemo)(function () {
        return data.map(function (item, index) { return (react_1.default.createElement(react_native_1.View, { key: index, style: { width: itemWidth, height: '100%' } }, renderItem(item))); });
    }, [data, itemWidth, renderItem]);
    var platformSpecificProps = (0, react_1.useMemo)(function () {
        return react_native_1.Platform.select({
            web: __assign({ pagingEnabled: true }, { className: 'carousel-snap-center' }),
            default: {
                snapToAlignment: 'start',
                snapToInterval: itemWidth,
            },
        });
    }, [itemWidth]);
    return (react_1.default.createElement(react_native_1.View, { onLayout: onLayout, style: style }, !!width && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.ScrollView, __assign({ ref: scrollView, horizontal: true, showsHorizontalScrollIndicator: false, decelerationRate: "fast", contentContainerStyle: contentContainerStyle, style: { width: '100%' }, scrollEventThrottle: 16, onScroll: onScroll }, platformSpecificProps), renderedItems),
        data.length > 1 && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: scrollToPrev, style: [
                    styles.prevButton,
                    { width: showNavButtons ? 100 : paddingHorizontal },
                    activeIndex === 0 && styles.disabled,
                ] }, showNavButtons && (react_1.default.createElement(iconV2_1.MBIconV2, { name: "arrow-point-left-circle", color: paginationColor }))),
            react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: scrollToNext, style: [
                    styles.nextButton,
                    { width: showNavButtons ? 100 : paddingHorizontal },
                    activeIndex === data.length - 1 && styles.disabled,
                ] }, showNavButtons && (react_1.default.createElement(iconV2_1.MBIconV2, { name: "arrow-point-right-circle", color: paginationColor }))),
            react_1.default.createElement(pagination_1.Pagination, { color: paginationColor, length: data.length, activeIndex: activeIndex, onPress: scrollToIndex })))))));
}
