"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDMChatBanner = MBDMChatBanner;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var base_1 = require("./base");
function MBDMChatBanner(_a) {
    var user = _a.user, channel = _a.channel;
    return (React.createElement(base_1.MBBaseChatBanner, { title: user.fullName, subtitle: "@".concat(user.username), channel: channel, content: React.createElement(components_1.MBText, { color: styles_1.Colors.v2.greenSecondary90 },
            "This is the beginning of a private conversation between you and",
            React.createElement(components_1.MBText, { bold: true },
                " ",
                user.fullName),
            ".",
            '\n\n',
            "You can share messages and assign cards only you two can see.") }));
}
