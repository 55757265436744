"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityWidget = useCreateEntityWidget;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var uuid_1 = require("@shared/util/uuid");
function useCreateEntityWidget() {
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var dashboards = (0, client_1.useQuery)(api_1.Queries.dashboard.listDashboards, {
        fetchPolicy: 'network-only',
    });
    var updateDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.updateDashboard)[0];
    var createDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.createDashboard)[0];
    var createEntityWidget = (0, react_1.useCallback)(function (viewTemplateId, workflowTemplateId, name, channelId) {
        var _a, _b, _c;
        if (dashboards.loading) {
            return Promise.reject('Loading');
        }
        if (!(0, lodash_1.isNil)(dashboards.error)) {
            dashboards.refetch();
            return Promise.reject((_a = dashboards.error) === null || _a === void 0 ? void 0 : _a.message);
        }
        var dashboard = (0, lodash_1.first)((_c = (_b = dashboards.data) === null || _b === void 0 ? void 0 : _b.listDashboards) !== null && _c !== void 0 ? _c : []);
        if (!(0, lodash_1.isNil)(dashboard)) {
            var entityWidgets = __spreadArray([], dashboard.entityWidgets, true);
            entityWidgets.push({
                id: (0, uuid_1.v4)(),
                workflowTemplateId: workflowTemplateId,
                viewTemplateId: viewTemplateId,
                name: name,
                channelId: channelId !== null && channelId !== void 0 ? channelId : 0,
            });
            return updateDashboard({
                variables: {
                    input: {
                        businessId: dashboard.businessId,
                        id: dashboard.id,
                        entityWidgets: entityWidgets,
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
        else {
            return createDashboard({
                variables: {
                    input: {
                        businessId: businessId !== null && businessId !== void 0 ? businessId : 0,
                        entityWidgets: [
                            {
                                workflowTemplateId: workflowTemplateId,
                                viewTemplateId: viewTemplateId,
                                name: name,
                                channelId: channelId !== null && channelId !== void 0 ? channelId : 0,
                            },
                        ],
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
    }, [dashboards, updateDashboard, createDashboard, businessId]);
    return {
        loading: dashboards.loading,
        createEntityWidget: createEntityWidget,
    };
}
