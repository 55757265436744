"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUsersContext = void 0;
exports.AddUsersProvider = AddUsersProvider;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
exports.AddUsersContext = (0, react_1.createContext)(null);
function AddUsersProvider(_a) {
    var users = _a.users, children = _a.children, onAdd = _a.onAdd;
    var _b = (0, react_1.useState)([]), selectedUsers = _b[0], setSelectedUsers = _b[1];
    var _c = (0, react_1.useState)(), accessLevel = _c[0], setAccessLevel = _c[1];
    var onToggleSelectedUser = (0, react_1.useCallback)(function (user) {
        if (selectedUsers.some(function (u) { return u.id === user.id; })) {
            setSelectedUsers(selectedUsers.filter(function (u) { return u.id !== user.id; }));
        }
        else {
            setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [user], false));
        }
    }, [selectedUsers, setSelectedUsers]);
    var onToggleAllUsers = (0, react_1.useCallback)(function () {
        if (selectedUsers.length === users.length) {
            setSelectedUsers([]);
        }
        else {
            setSelectedUsers(users);
        }
    }, [users, selectedUsers]);
    var onAddUser = (0, react_1.useCallback)(function () { return onAdd(selectedUsers, accessLevel); }, [onAdd, selectedUsers, accessLevel]);
    var contextValue = {
        users: users,
        selectedUsers: selectedUsers,
        accessLevel: accessLevel,
        onToggleSelectedUser: onToggleSelectedUser,
        onAdd: onAddUser,
        onToggleAllUsers: onToggleAllUsers,
        setAccessLevel: setAccessLevel,
    };
    return react_1.default.createElement(exports.AddUsersContext.Provider, { value: contextValue }, children);
}
