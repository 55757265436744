"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAGE_SIZE = void 0;
exports.useListUserGroupAssociations = useListUserGroupAssociations;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
exports.PAGE_SIZE = 50;
function useListUserGroupAssociations(_a) {
    var _b, _c, _d;
    var userGroupId = _a.userGroupId, userFirstOrLastName = _a.userFirstOrLastName;
    var input = (0, react_1.useMemo)(function () { return ({
        limit: exports.PAGE_SIZE,
        offset: 0,
        userGroupId: userGroupId,
        userFirstOrLastName: userFirstOrLastName,
    }); }, [userGroupId, userFirstOrLastName]);
    var _e = (0, client_1.useQuery)(api_1.Queries.user.listUserGroupUserAssociations, { variables: { input: input } }), data = _e.data, loading = _e.loading, fetchMore = _e.fetchMore;
    var handleFetchMore = (0, react_1.useCallback)(function () {
        var _a;
        fetchMore({
            variables: {
                input: __assign(__assign({}, input), { offset: (_a = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _a === void 0 ? void 0 : _a.userGroupUserAssociations.length }),
            },
        });
    }, [(_b = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _b === void 0 ? void 0 : _b.userGroupUserAssociations, fetchMore, input]);
    return (0, react_1.useMemo)(function () {
        var _a, _b;
        return ({
            userGroupAssociations: (_a = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _a === void 0 ? void 0 : _a.userGroupUserAssociations,
            hasMore: (_b = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _b === void 0 ? void 0 : _b.hasMore,
            loading: loading,
            loadMore: handleFetchMore,
        });
    }, [
        (_c = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _c === void 0 ? void 0 : _c.userGroupUserAssociations,
        (_d = data === null || data === void 0 ? void 0 : data.listUserGroupUserAssociations) === null || _d === void 0 ? void 0 : _d.hasMore,
        loading,
        handleFetchMore,
    ]);
}
