"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewTemplateListContainer = ViewTemplateListContainer;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var lib_1 = require("use-debounce/lib");
var components_1 = require("@shared/components");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_web_1 = require("@shared/util/navigation/useNavigator.web");
var hooks_1 = require("../../hooks");
var ViewTemplateList_1 = require("./components/ViewTemplateList");
function ViewTemplateListContainer(_a) {
    var workflowTemplateId = _a.workflowTemplateId, onShare = _a.onShare;
    var _b = (0, react_1.useState)(''), nameQuery = _b[0], _setNameQuery = _b[1];
    var setNameQuery = (0, lib_1.useDebouncedCallback)(_setNameQuery, 250)[0];
    var _c = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'CARD',
        name: nameQuery,
        isPublic: true,
    }), viewTemplates = _c.viewTemplates, loading = _c.loading, error = _c.error, moreLoading = _c.moreLoading, fetchMore = _c.fetchMore;
    var templates = viewTemplates.filter(function (vt) { return 'READONLY' !== vt.subtype; });
    return (react_1.default.createElement(components_1.MBView, { style: { height: '75vh' } },
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { value: nameQuery, onChangeText: setNameQuery, placeholder: (0, i18n_1.i18n)('workflowTemplate.viewTemplate.list.search') })),
        react_1.default.createElement(ViewTemplateList_1.ViewTemplateList, { loading: loading, error: error, viewTemplates: templates, onClick: useHandleClick(workflowTemplateId), onDelete: useHandleDelete(workflowTemplateId), onDuplicate: useHandleDuplicate(workflowTemplateId, viewTemplates), onShare: onShare, fetchMore: fetchMore, moreLoading: moreLoading })));
}
function useHandleClick(workflowTemplateId) {
    var navigator = (0, useNavigator_web_1.useNavigator)();
    return (0, react_1.useCallback)(function (viewTemplateId) {
        navigator.navigate('ViewTemplateEdit', {
            workflowTemplateId: workflowTemplateId,
            viewTemplateId: viewTemplateId,
        });
    }, [navigator, workflowTemplateId]);
}
function useHandleDelete(workflowTemplateId) {
    var deleteViewTemplate = (0, hooks_1.useDeleteViewTemplate)(workflowTemplateId);
    return (0, react_1.useCallback)(function (viewTemplateId) {
        deleteViewTemplate(viewTemplateId);
    }, [deleteViewTemplate]);
}
function useHandleDuplicate(workflowTemplateId, viewTemplates) {
    var duplicateViewTemplate = (0, hooks_1.useDuplicateViewTemplate)(workflowTemplateId);
    return (0, react_1.useCallback)(function (viewTemplateId) {
        var viewTemplate = viewTemplates === null || viewTemplates === void 0 ? void 0 : viewTemplates.find(function (currentTemplate) { return viewTemplateId === currentTemplate.id; });
        duplicateViewTemplate(viewTemplateId, viewTemplate.name);
    }, [duplicateViewTemplate, viewTemplates]);
}
