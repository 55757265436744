"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dashboard = Dashboard;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_native_gesture_handler_1 = require("react-native-gesture-handler");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var CalendarButton_1 = require("@shared/scenes/cards/agendaView/components/CalendarButton");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_1 = require("@shared/util/storage/hooks");
var EmptyDashboard_1 = require("./components/EmptyDashboard");
var EntityWidgetItem_1 = require("./components/EntityWidgetItem");
var useDashboardData_1 = require("./hooks/useDashboardData");
function Dashboard() {
    var _a = (0, useDashboardData_1.useDashboardData)(), loading = _a.loading, widgets = _a.widgets, userFirstName = _a.userFirstName, renameWidget = _a.renameWidget, deleteWidget = _a.deleteWidget, addFavorite = _a.addFavorite, removeFavorite = _a.removeFavorite;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var _b = (0, hooks_1.useStorage)('dashboardView', 'all'), dashboardView = _b[0], setDashboardView = _b[1];
    var showOnlyFavorites = dashboardView === 'favorites';
    var visibleWidgets = showOnlyFavorites
        ? widgets === null || widgets === void 0 ? void 0 : widgets.filter(function (entityWidget) { return entityWidget.isFavorite; })
        : widgets;
    var showEmptyState = !loading && (0, lodash_1.isEmpty)(visibleWidgets);
    var showWidgets = !loading && !(0, lodash_1.isEmpty)(visibleWidgets);
    var showDesktopWidgetGrid = isDesktop && showWidgets;
    var showNativeWidgetGrid = !isDesktop && showWidgets;
    return (react_1.default.createElement(components_1.MBView, { fill: true, fullHeight: true, style: { backgroundColor: styles_1.Colors.v2.tertiaryCoolBlue20 } },
        isDesktop && react_1.default.createElement(DesktopHeader, null),
        loading && react_1.default.createElement(LoadingSpinner, null),
        !loading && react_1.default.createElement(Subheader, { userFirstName: userFirstName, dashboardView: dashboardView, setDashboardView: setDashboardView }),
        showEmptyState && react_1.default.createElement(EmptyDashboard_1.EmptyDashboard, { showOnlyFavorites: showOnlyFavorites }),
        showDesktopWidgetGrid && (react_1.default.createElement(DesktopWidgetGrid, { widgets: visibleWidgets, renameWidget: renameWidget, deleteWidget: deleteWidget, addFavorite: addFavorite, removeFavorite: removeFavorite })),
        showNativeWidgetGrid && (react_1.default.createElement(NativeWidgetGrid, { widgets: visibleWidgets, renameWidget: renameWidget, deleteWidget: deleteWidget, addFavorite: addFavorite, removeFavorite: removeFavorite }))));
}
function DesktopHeader() {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { row: true, style: { backgroundColor: colors.background } },
        react_1.default.createElement(components_1.MBView, { row: true, flex: true, paddingHorizontal: 5, style: { height: (0, components_1.grid)(11) }, centerVertically: true },
            react_1.default.createElement(components_1.MBBoxedIcon, { source: require('@shared/assets/icons/v2/squares.png'), color: colors.avatar.foreground, backgroundColor: colors.avatar.background }),
            react_1.default.createElement(components_1.MBView, { flex: true, paddingLeft: "normal" },
                react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingRight: "normal" },
                    react_1.default.createElement(components_1.MBTextH3, { testID: "all-cards-header" }, (0, i18n_1.i18n)('channel.list.dashboard.title')))))));
}
function Subheader(_a) {
    var userFirstName = _a.userFirstName, dashboardView = _a.dashboardView, setDashboardView = _a.setDashboardView;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    return (react_1.default.createElement(components_1.MBView, { row: true, fullWidth: isDesktop, style: { justifyContent: 'center' } },
        react_1.default.createElement(components_1.MBView, { row: isDesktop, fullWidth: isDesktop, style: {
                justifyContent: isDesktop ? 'space-between' : 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                paddingVertical: (0, components_1.grid)(2),
                paddingHorizontal: (0, components_1.grid)(2),
            } },
            react_1.default.createElement(components_1.MBTextH4, { style: { marginBottom: !isDesktop ? (0, components_1.grid)(1) : 0 } }, (0, i18n_1.i18n)('channel.list.dashboard.welcome', { firstName: userFirstName !== null && userFirstName !== void 0 ? userFirstName : '' })),
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: {
                    marginLeft: isDesktop ? (0, components_1.grid)(3) : 0,
                } },
                react_1.default.createElement(ViewToggle, { dashboardView: dashboardView, setDashboardView: setDashboardView })))));
}
function ViewToggle(_a) {
    var dashboardView = _a.dashboardView, setDashboardView = _a.setDashboardView;
    return (react_1.default.createElement(react_native_1.View, { style: styles.buttonBar },
        react_1.default.createElement(CalendarButton_1.CalendarButton, { title: (0, i18n_1.i18n)('dashboard.allWidgets'), active: dashboardView === 'all', onPress: function () { return setDashboardView('all'); }, style: styles.buttonBarLeftButton }),
        react_1.default.createElement(CalendarButton_1.CalendarButton, { title: (0, i18n_1.i18n)('dashboard.favoriteWidgets'), icon: "star", iconSize: (0, components_1.grid)(3), iconColor: styles_1.Colors.v2.green30, active: dashboardView === 'favorites', onPress: function () { return setDashboardView('favorites'); }, style: styles.buttonBarRightButton })));
}
function LoadingSpinner() {
    return (react_1.default.createElement(components_1.MBView, { fill: true, fullHeight: true, centerVertically: true },
        react_1.default.createElement(components_1.MBLoadingIndicator, null)));
}
function DesktopWidgetGrid(_a) {
    var widgets = _a.widgets, renameWidget = _a.renameWidget, deleteWidget = _a.deleteWidget, addFavorite = _a.addFavorite, removeFavorite = _a.removeFavorite;
    return (react_1.default.createElement(react_native_gesture_handler_1.ScrollView, { style: { padding: (0, components_1.grid)(2) } },
        react_1.default.createElement(components_1.MBView, { row: true, style: { flexWrap: 'wrap', justifyContent: 'flex-start' } }, widgets.map(function (widget) {
            return (react_1.default.createElement(EntityWidgetItem_1.EntityWidgetItem, { key: widget.id, entityWidget: widget, deleteWidget: deleteWidget, renameWidget: renameWidget, addFavorite: addFavorite, removeFavorite: removeFavorite }));
        }))));
}
function NativeWidgetGrid(_a) {
    var widgets = _a.widgets, renameWidget = _a.renameWidget, deleteWidget = _a.deleteWidget, addFavorite = _a.addFavorite, removeFavorite = _a.removeFavorite;
    return (react_1.default.createElement(react_native_gesture_handler_1.FlatList, { numColumns: 2, style: { paddingBottom: (0, components_1.grid)(4) }, columnWrapperStyle: { flexWrap: 'wrap', justifyContent: 'center' }, data: widgets, renderItem: function (item) {
            return (react_1.default.createElement(EntityWidgetItem_1.EntityWidgetItem, { key: item.item.id, entityWidget: item.item, deleteWidget: deleteWidget, renameWidget: renameWidget, addFavorite: addFavorite, removeFavorite: removeFavorite }));
        } }));
}
var styles = react_native_1.StyleSheet.create({
    buttonBar: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonBarLeftButton: {
        marginRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: (0, components_1.grid)(2),
        paddingRight: (0, components_1.grid)(1.5),
    },
    buttonBarRightButton: {
        marginLeft: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        paddingLeft: (0, components_1.grid)(1.5),
        paddingRight: (0, components_1.grid)(2),
    },
});
