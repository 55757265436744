"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateCardEntity = useCreateCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useVisibleComponents_1 = require("../../util/useVisibleComponents");
var useCreateEntityFields_1 = require("./useCreateEntityFields");
var useSaveNewCardEntity_1 = require("./useSaveNewCardEntity");
function useCreateCardEntity(_a) {
    var _b, _c;
    var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    // If no viewTemplateId (likely since not included in the create route),
    // we need to get the card view template that starts with 'Create'.
    // We load all private card view templates in case there is not a 'Create' option,
    // which likely means the workflow was configured with only one card view template.
    // Further exploration here: https://app.shortcut.com/coast/story/46087/no-code-forms-refine-card-view-template-solution
    var cardViewTemplates = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'CARD',
        isPublic: false,
        skip: !(0, lodash_1.isNil)(viewTemplateId),
    }).viewTemplates;
    var sortedViewTemplates = (0, lodash_1.sortBy)(cardViewTemplates, 'updatedAt');
    var queriedViewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    var viewTemplate = !(0, lodash_1.isNil)(viewTemplateId)
        ? queriedViewTemplate
        : (_b = sortedViewTemplates.find(function (vt) { return vt.name.startsWith('Create '); })) !== null && _b !== void 0 ? _b : sortedViewTemplates[0];
    var _d = (0, react_1.useState)('loading'), status = _d[0], setStatus = _d[1];
    var _e = (0, useCreateEntityFields_1.useCreateEntityFields)(workflowTemplate, viewTemplate), fields = _e.fields, updateField = _e.updateField, isDirty = _e.isDirty;
    var _f = (0, useSaveNewCardEntity_1.useSaveNewEntity)(workflowTemplateId), saveEntity = _f.saveEntity, saveEntityLoading = _f.loading, errors = _f.errors;
    var visibleComponents = (0, useVisibleComponents_1.useVisibleComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: false,
    });
    var componentsWithOverrides = visibleComponents.map(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
        if (!(0, lodash_1.isNil)(viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides)) {
            return __assign(__assign({}, component), viewOptions.overrides);
        }
        return component;
    });
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (saveEntityLoading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [errors, saveEntityLoading, status]);
    var save = (0, react_1.useCallback)(function () {
        var _a;
        (_a = saveEntity(fields)) === null || _a === void 0 ? void 0 : _a.then(function (_fulfilled) {
            setStatus('success');
        }, function (_error) {
            // parse remote validation
            setStatus('error');
        });
    }, [fields, saveEntity]);
    return {
        components: componentsWithOverrides,
        fields: fields,
        errors: errors,
        viewTemplateTitle: (_c = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name) !== null && _c !== void 0 ? _c : '',
        updateField: updateField,
        onPressSave: save,
        isLoading: status === 'loading',
        status: status,
        isDirty: isDirty,
    };
}
