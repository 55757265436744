"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = Pagination;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_layout_animation_1 = require("use-layout-animation");
var styles = react_native_1.StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 8,
    },
    dotContainer: {
        width: 16,
        height: 16,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        width: 6,
        height: 6,
        borderRadius: 3,
    },
    activeDot: {
        transform: [{ scale: 1.5 }],
    },
    inactiveDot: {
        opacity: 0.5,
    },
});
function Dot(_a) {
    var active = _a.active, index = _a.index, color = _a.color, onPress = _a.onPress;
    var handlePress = (0, react_1.useCallback)(function () { return onPress === null || onPress === void 0 ? void 0 : onPress(index); }, [onPress, index]);
    return (react_1.default.createElement(react_native_1.TouchableOpacity, { disabled: active || !onPress, style: styles.dotContainer, onPress: handlePress },
        react_1.default.createElement(react_native_1.View, { style: [
                styles.dot,
                active ? styles.activeDot : styles.inactiveDot,
                { backgroundColor: color },
            ] })));
}
function Pagination(_a) {
    var length = _a.length, color = _a.color, activeIndex = _a.activeIndex, onPress = _a.onPress;
    (0, use_layout_animation_1.useLayoutAnimation)([activeIndex]);
    return (react_1.default.createElement(react_native_1.View, { style: styles.container }, Array(length)
        .fill(undefined)
        .map(function (_, index) { return (react_1.default.createElement(Dot, { key: index, index: index, color: color, active: index === activeIndex, onPress: onPress })); })));
}
