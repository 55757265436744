"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonEditField = PersonEditField;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var fieldAssignee_1 = require("@shared/components/blocks/lib/fieldAssignee");
var inputAssignee_1 = require("@shared/components/blocks/lib/inputAssignee");
var modal_1 = require("@shared/components/multiSelect/modal");
var PersonField_1 = require("@shared/data/entity/fields/PersonField");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var EditFieldContainer_1 = require("./EditFieldContainer");
function PersonEditField(_a) {
    var _b, _c;
    var field = _a.field, workflowTemplateId = _a.workflowTemplateId, component = _a.component, updateField = _a.updateField;
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var allUsers = (0, hooks_1.useAllBusinessUsers)();
    var personRefs = (0, react_1.useMemo)(function () { return allUsers.map(toPersonRef); }, [allUsers]);
    var workspaceUsers = (0, react_1.useMemo)(function () {
        var _a, _b;
        return (_b = (_a = workspaceDetails.get(workflowTemplateId)) === null || _a === void 0 ? void 0 : _a.users.map(toPersonRef)) !== null && _b !== void 0 ? _b : [];
    }, [workspaceDetails, workflowTemplateId]);
    var selectedUserIds = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : []; }, [field === null || field === void 0 ? void 0 : field.jsonBlob]);
    var selectedUsers = (0, react_1.useMemo)(function () { return personRefs.filter(function (user) { return selectedUserIds.includes(user.id); }); }, [personRefs, selectedUserIds]);
    var onChange = (0, react_1.useCallback)(function (changedUsers) {
        var userIds = changedUsers.map(function (user) { return user.id; });
        updateField(component.id, new PersonField_1.PersonField(userIds, personRefs));
    }, [component.id, personRefs, updateField]);
    var match = (0, react_1.useCallback)(function (user, query) {
        var lowercaseQuery = query.toLowerCase();
        return (user.username.toLowerCase().includes(lowercaseQuery) ||
            user.fullName.toLowerCase().includes(lowercaseQuery));
    }, []);
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var workspaceImageUrl = (_b = workspaceDetails.get(workflowTemplateId)) === null || _b === void 0 ? void 0 : _b.imageUrl;
    var isReadOnly = component.readonly;
    var hasUsers = selectedUsers.length > 0;
    if (isReadOnly) {
        return react_1.default.createElement(PersonSummary, { selectedUsers: selectedUsers, component: component });
    }
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: (_c = component.label) !== null && _c !== void 0 ? _c : '', onChange: onChange, selectAll: true, renderSelectAll: function () { return (react_1.default.createElement(components_1.MBView, { flex: true, row: true, centerVertically: true, paddingVertical: 1 },
            react_1.default.createElement(components_1.MBImage, { style: styles.workflowImage, source: {
                    uri: workspaceImageUrl !== null && workspaceImageUrl !== void 0 ? workspaceImageUrl : '',
                } }),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 2 }),
            react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('form.placeholder.everyone')))); }, renderItem: function (user) { return react_1.default.createElement(inputAssignee_1.MultiSelectUserRow, { user: user }); }, values: workspaceUsers, selectedValues: selectedUsers, match: match, keyExtractor: function (user) { return String(user.id); } },
        react_1.default.createElement(PersonSummary, { selectedUsers: selectedUsers, component: component, onClear: hasUsers ? onClear : undefined })));
}
function PersonSummary(_a) {
    var selectedUsers = _a.selectedUsers, component = _a.component, onClear = _a.onClear;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var hasUsers = selectedUsers.length > 0;
    var color = hasUsers ? colors.foregroundActive : colors.foregroundInactive;
    var text = hasUsers ? (0, fieldAssignee_1.assigneesToString)(selectedUsers) : component.placeholder;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasUsers, onClear: onClear },
        react_1.default.createElement(components_1.MBTextBody1, { color: color }, text)));
}
var styles = react_native_1.StyleSheet.create({
    workflowImage: {
        height: (0, components_1.grid)(5),
        width: (0, components_1.grid)(5),
        borderRadius: (0, components_1.grid)(2.5),
    },
});
function toPersonRef(user) {
    return {
        id: user.id,
        fullName: user.fullName,
        username: user.username,
        imageUrl: user.imageUrl,
        isMe: user.isMe,
        isPending: user.isPending,
        lastRemindedAt: user.lastRemindedAt,
        firstName: '',
    };
}
