"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormInputSubformSelect = exports.FormInputSubformDefault = void 0;
var formik_1 = require("formik");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var multiSelect_1 = require("@shared/components/multiSelect");
var modal_1 = require("@shared/components/multiSelect/modal");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var FormInputSubformDefault = function (_a) {
    var component = _a.component, index = _a.index;
    var renderDataItem = function (option) {
        return (0, lodash_1.isNil)(option) ? null : react_1.default.createElement(components_1.MBTextBody1, { key: option.value }, option.label);
    };
    return (react_1.default.createElement(formik_1.Field, { name: "componentsViewOptions.".concat(index, ".overrides.default.workflowTemplateId"), component: exports.FormInputSubformSelect, data: {
            workflowTemplateIds: component.workflowTemplateIds,
        }, renderDataItem: renderDataItem, fieldValueIsArray: false }));
};
exports.FormInputSubformDefault = FormInputSubformDefault;
var FormInputSubformSelect = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, data = _a.data, renderDataItem = _a.renderDataItem;
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var color = colors.foregroundActive;
    var selectedValues = (0, lodash_1.isEmpty)(field.value) ? [] : [field.value];
    var _c = (0, hooks_2.useDebouncedState)('', 300), query = _c[0], setQuery = _c[1];
    var _d = (0, hooks_1.useListWorkflowTemplates)({
        skip: (0, lodash_1.isEmpty)(data.workflowTemplateIds),
        input: { ids: data.workflowTemplateIds, name: query },
    }), workflowTemplates = _d.workflowTemplates, loading = _d.loading, hasMore = _d.hasMore, loadMore = _d.loadMore;
    var renderItem = (0, react_1.useCallback)(function (value) {
        var _a;
        return (react_1.default.createElement(components_1.MBView, { key: value, row: true, paddingLeft: 1 }, (_a = renderDataItem({
            value: value,
            label: workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.find(function (wft) { return wft.id === value; }).name,
        })) !== null && _a !== void 0 ? _a : ''));
    }, [renderDataItem, workflowTemplates]);
    var onChange = function (values) {
        form.setFieldValue(field.name, (0, lodash_1.isEmpty)(values) ? undefined : values[0]);
    };
    var containerRef = (0, react_1.useRef)(null);
    var _e = (0, react_1.useState)(false), visible = _e[0], setVisible = _e[1];
    var fixedWidth = isDesktop ? modal_1.FIXED_WIDTH : null;
    var renderTitleBar = (0, react_1.useCallback)(function () {
        var exitIconProps = isDesktop
            ? null
            : {
                rightIcon: 'exit',
                rightIconColor: styles_1.Colors.v2.purple,
                onRightIconPress: function () { return setVisible(false); },
            };
        return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, __assign({ title: (0, i18n_1.i18n)('workflowTemplate.responseOptions') }, exitIconProps)));
    }, [isDesktop]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { ref: containerRef, onPress: function () { return setVisible(true); }, style: { maxWidth: '100%' } },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5, paddingHorizontal: 1, style: {
                    backgroundColor: styles_1.Colors.v2.tagArchived,
                    justifyContent: 'space-between',
                    borderRadius: (0, components_1.grid)(1),
                } },
                react_1.default.createElement(components_1.MBTextBody1, null,
                    !(0, lodash_1.isEmpty)(selectedValues) &&
                        selectedValues
                            .map(function (value) { var _a; return (_a = workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.find(function (wft) { return value === wft.id; })) === null || _a === void 0 ? void 0 : _a.name; })
                            .join(','),
                    (0, lodash_1.isEmpty)(selectedValues) && (0, i18n_1.i18n)('builder.defaultRow.noDefault')),
                react_1.default.createElement(components_1.MBIconV2, { name: 'arrow-point-down', color: color, size: (0, components_1.grid)(3) }))),
        visible && (react_1.default.createElement(components_1.MBBottomSheet, { containerRef: containerRef, onClose: function () { return setVisible(false); }, renderTitleBar: renderTitleBar, layout: {
                offset: { x: isDesktop ? (0, components_1.grid)(10) : (0, components_1.grid)(2), y: 0 },
                minHeight: (0, components_1.grid)(48),
            } },
            react_1.default.createElement(components_1.MBView, { style: fixedWidth, fill: true },
                react_1.default.createElement(multiSelect_1.MBMultiSelect, { values: (_b = workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.map(function (wft) { return wft.id; })) !== null && _b !== void 0 ? _b : [], selectedValues: selectedValues, renderItem: renderItem, onClose: function () { return setVisible(false); }, onChange: onChange, onSearch: setQuery, editMode: false, loading: loading, hasMore: hasMore, loadMore: loadMore, max: 1 }))))));
};
exports.FormInputSubformSelect = FormInputSubformSelect;
exports.FormInputSubformSelect.displayName = 'FormInputSubformSelect';
