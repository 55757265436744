"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSeenByCountText = void 0;
exports.MBSeenWithoutCountText = MBSeenWithoutCountText;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var MBSeenHoverLink = function (_a) {
    var content = _a.content, color = _a.color;
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    return (react_1.default.createElement(components_1.MBHover, { hoverStyle: { textDecoration: 'underline', color: styles_1.Colors.v2.gray30 }, onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
        react_1.default.createElement(components_1.MBTextCaption2, { color: isHovered ? styles_1.Colors.v2.gray60 : color }, content)));
};
var MBSeenByCountText = function (_a) {
    var count = _a.count, _b = _a.color, color = _b === void 0 ? styles_1.Colors.v2.gray30 : _b;
    return (react_1.default.createElement(MBSeenHoverLink, { content: (0, i18n_1.i18n)('chat.seenBy', { count: count }), color: color }));
};
exports.MBSeenByCountText = MBSeenByCountText;
function MBSeenWithoutCountText(_a) {
    var _b = _a.color, color = _b === void 0 ? styles_1.Colors.v2.gray30 : _b;
    return react_1.default.createElement(MBSeenHoverLink, { content: (0, i18n_1.i18n)('chat.seen'), color: color });
}
