import { MBAuthRequiredGuard } from '@shared/scenes/auth/guards/authRequired';
import { MBNoAuthRequiredGuard } from '@shared/scenes/auth/guards/noAuthRequired';
import { MBAuthFromQuery } from '@shared/scenes/auth/signin/containers/authFromQuery';
import {
  MBInviteCodeSignUp,
  MBSignIn,
  MBSignUp,
} from '@shared/scenes/auth/signin/containers/index';
import { PersistentSignInProvider } from '@shared/scenes/auth/signin/context';
import { MBVerifyCode } from '@shared/scenes/auth/verifyCode/containers/index';
import { MBAllCardsList } from '@shared/scenes/cards/allCardsList/containers/index';
import { Dashboard } from '@shared/scenes/dashboard/Dashboard';
import { CardCollectionRoute } from '@shared/scenes/cards/collection/route.web';
import { CardDetailsRoute } from '@shared/scenes/cards/details/routes/index.web';
import { CardDetailsNoAccessRoute } from '@shared/scenes/cards/details/routes/noAccess.web';
import { CardCreateRoute } from '@shared/scenes/cards/form/create/route.web';
import { CardUpdateRoute } from '@shared/scenes/cards/form/update/route.web';
import { CardBulkUpdateRoute } from '@shared/scenes/cards/form/bulkUpdate/route.web';
import { CardHistoryRoute } from '@shared/scenes/cards/history/route.web';
import { SharedCardLinkContainer } from '@shared/scenes/channel/share/SharedCardLinkContainer';
import { MBCreateBusiness } from '@shared/scenes/onboarding/business/container';
import { MBOnboardingCreateDirectMessages } from '@shared/scenes/onboarding/createDirectMessages/container';
import { MBCreateOrJoinBusiness } from '@shared/scenes/onboarding/createOrJoinBusiness/container';
import { MBOnboardingDone } from '@shared/scenes/onboarding/done/container';
import { MBOnboardingInviteContacts } from '@shared/scenes/onboarding/inviteContacts/container';
import { MBOnboardingLastStep } from '@shared/scenes/onboarding/lastStep/container';
import { MBCreateProfile } from '@shared/scenes/onboarding/profile/container';
import { MBOnboardingSelectWorkflows } from '@shared/scenes/onboarding/workflows/container';
import { WorkflowCreateRoute } from '@shared/scenes/workflows/create/route.web';
import { WorkflowEditRoute } from '@shared/scenes/workflows/edit/route.web';
import { ViewTemplateEditFormContainer } from '@shared/scenes/workflows/view-templates/edit/ViewTemplateEditFormContainer';
import { WorkflowInstall } from '@shared/scenes/workflows/install/WorkflowInstall';
import { store } from '@shared/store';
import { useNavigator, useSharedHistory } from '@shared/util/navigation/useNavigator.web';
import { PermissionsProvider } from '@shared/util/permissions/PermissionsProvider';
import AnalyticsContainer from '@src/containers/AnalyticsContainer';
import AppLoaderContainer from '@src/containers/AppLoaderContainer';
import * as Business from '@src/containers/business';
import * as Channel from '@src/containers/channel';
import {
  ChannelNotificationSettingsContainer,
  ChannelOptionsContainer,
  ChannelUserListContainer,
  ChannelAdvancedOptionsContainer,
} from '@src/containers/ChannelInfosContainer';
import LayoutContainer from '@src/containers/LayoutContainer';
import LoginBusinessContainer from '@src/containers/LoginBusinessContainer';
import LoginPeopleListContainer from '@src/containers/LoginPeopleListContainer';
import NoBusinessContainer from '@src/containers/NoBusinessContainer';
import UserCreateContainer from '@src/containers/UserCreateContainer';
import { getBizToken, getToken } from '@src/core/AuthTokenManager';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import browserHistory from 'react-router/lib/browserHistory';
import IndexRoute from 'react-router/lib/IndexRoute';
import Router from 'react-router/lib/Router';
import { LoginSamlAuthentication } from '@shared/scenes/auth/saml/containers/LoginSamlAuthentication';
import { useFeatureFlag } from '@shared/util/featureFlags/launchDarkly';
import { isNil } from 'lodash';
import { MBSentry } from '@shared/util/sentry';

export const AppRouter = () => {
  const isDesktop = useSelector(state => state.appState.isDesktop);
  const [history, setHistory] = useState();
  const setSharedHistory = useSharedHistory();
  useEffect(() => {
    const history = syncHistoryWithStore(browserHistory, store);
    setSharedHistory(history);
    setHistory(history);
  }, []);

  const navigator = useNavigator();
  const onSafeZoneEnter = useCallback(() => {
    if (getToken()) {
      navigator.replace('Home');
    } else if (!getBizToken()) {
      navigator.navigate('LoginExistingAccount');
    }
  }, []);

  if (!history) return null;
  return (
    <Router
      history={history}
      key={String(isDesktop)}
      routes={
        <Route path="" component={AnalyticsContainer}>
          <Route path="/auth" component={LoginPeopleListContainer} onEnter={onSafeZoneEnter} />
          <Route
            path="/auth/user/create"
            component={UserCreateContainer}
            onEnter={onSafeZoneEnter}
          />
          <Route path="/workflows/install" component={WorkflowInstall} />

          <Route path="" component={MBNoAuthRequiredGuard}>
            <Route path="" component={PersistentSignInProvider}>
              <Route path="signin" component={LoginExistingAccountResolver} />
              <Route path="signin/biz" component={LoginBusinessContainer} />
              <Route path="signup" component={MBSignUp} />
              <Route path="signup/redirect" component={MBAuthFromQuery} />
              <Route path="signup/:inviteCode" component={MBInviteCodeSignUp} />
              <Route path="verify" component={MBVerifyCode} />
              <Route path="saml-auth" component={LoginSamlAuthenticationResolver} />
            </Route>
          </Route>

          <Route
            path=""
            component={props => (
              <MBAuthRequiredGuard {...props} shouldNavigateToOnboarding={false} />
            )}
          >
            <Route path="onboarding/profile" component={MBCreateProfile} />
            <Route path="onboarding/createOrJoin" component={MBCreateOrJoinBusiness} />
            <Route path="onboarding/business" component={MBCreateBusiness} />
            <Route path="onboarding/workflows" component={MBOnboardingSelectWorkflows} />
            <Route path="onboarding/dms" component={MBOnboardingCreateDirectMessages} />
            <Route path="onboarding/lastStep" component={MBOnboardingLastStep} />
            <Route path="onboarding/invite" component={MBOnboardingInviteContacts} />
            <Route path="onboarding/done" component={MBOnboardingDone} />
          </Route>

          <Route path="" component={MBAuthRequiredGuard}>
            <Route path="/" component={LayoutContainer}>
              <Route component={PermissionsProvider}>
                <Route path="channels" showChannels={true} />
                <Route
                  path="section/:workspaceSectionId/add"
                  noMobileNav
                  component={Channel.SectionAddPeople}
                />

                <Route path="channel/create" noMobileNav component={Channel.CreateOverview} />
                <Route path="channel/create/new" noMobileNav component={Channel.CreateAddPeople} />
                <Route
                  path="channel/create/workflow"
                  noMobileNav
                  component={Channel.CreateSelectWorkflow}
                />
                <Route
                  path="channel/create/details"
                  noMobileNav
                  component={Channel.CreateDetails}
                />
                <Route
                  path="channel/create/:channelSlug"
                  hideSidebar
                  component={Channel.CreateChatPreview}
                />
                <Route path="nobusiness" component={NoBusinessContainer} noMobileNav />
                <Route path="newbusiness" component={Business.Create} noMobileNav />

                {isDesktop ? (
                  <Route path="all-cards" component={MBAllCardsList} hideSidebar>
                    <Route path=":channelSlug/:cardId" component={CardDetailsRoute} />
                  </Route>
                ) : (
                  <>
                    <Route path="all-cards">
                      <IndexRoute component={MBAllCardsList} />
                      <Route path=":channelSlug/:cardId" component={CardDetailsRoute} />
                    </Route>
                  </>
                )}

                <Route path="dashboard" component={Dashboard} hideSidebar />

                <IndexRoute component={AppLoaderContainer} />
                <Route
                  path="workflowTemplates/:workflowTemplateId/viewTemplates/:viewTemplateId"
                  component={ViewTemplateEditFormContainer}
                />
                <Route
                  path=":channelSlug"
                  hideSidebar
                  noMobileNav
                  component={isDesktop ? Channel.Layout : undefined}
                >
                  <IndexRoute component={isDesktop ? CardCollectionRoute : Channel.Chat} />

                  {isDesktop ? (
                    <>
                      <Route path="cards/c/:collectionContext" component={CardCollectionRoute} />
                      <Route
                        path="cards/t/:collectionTemplateSlug"
                        component={CardCollectionRoute}
                      />
                      <Route path="cards" component={CardCollectionRoute}>
                        <Route path="infos" noMobileNav component={ChannelOptionsContainer} />
                        <Route path="infos/edit" noMobileNav component={Channel.EditDetails} />
                        <Route path="create" component={CardCreateRoute} />
                        <Route path="create/c/:cardDefinitionId" component={CardCreateRoute} />
                        <Route
                          path="create/t/:templateSlug/c/:cardDefinitionId"
                          component={CardCreateRoute}
                        />
                        <Route path="no-access" component={CardDetailsNoAccessRoute} />
                        <Route path=":cardId/t/:templateSlug" component={CardDetailsRoute} />
                        <Route path=":cardId">
                          <IndexRoute component={CardDetailsRoute} />
                          <Route path="update" component={CardUpdateRoute} />
                          <Route path="update/t/:templateSlug" component={CardUpdateRoute} />
                          <Route
                            path="update/:workflowTemplateId/bulk/:recurringEntitiesScheduleId/:allOrUpcoming"
                            component={CardBulkUpdateRoute}
                          />
                          <Route path="history" component={CardHistoryRoute} />
                        </Route>
                      </Route>
                      <Route path="workflows/:workflowId/edit" component={WorkflowEditRoute} />
                      <Route path="workflows/create" component={WorkflowCreateRoute} />
                    </>
                  ) : (
                    <Route path="cards">
                      <IndexRoute component={CardCollectionRoute} />
                      <Route path="c/:collectionContext" component={CardCollectionRoute} />
                      <Route path="t/:collectionTemplateSlug" component={CardCollectionRoute} />
                      <Route path="infos" noMobileNav component={ChannelOptionsContainer} />
                      <Route path="infos/edit" noMobileNav component={Channel.EditDetails} />
                      <Route path="create" component={CardCreateRoute} />
                      <Route path="create/c/:cardDefinitionId" component={CardCreateRoute} />
                      <Route
                        path="create/t/:templateSlug/c/:cardDefinitionId"
                        component={CardCreateRoute}
                      />
                      <Route path="no-access" component={CardDetailsNoAccessRoute} />
                      <Route path=":cardId" component={CardDetailsRoute} />
                      <Route
                        path=":cardId/update/:workflowTemplateId/bulk/:recurringEntitiesScheduleId/:allOrUpcoming"
                        component={CardBulkUpdateRoute}
                      />
                      <Route path=":cardId/t/:templateSlug" component={CardDetailsRoute} />
                      <Route path=":cardId/update" component={CardUpdateRoute} />
                      <Route path=":cardId/update/t/:templateSlug" component={CardUpdateRoute} />
                      <Route path=":cardId/history" component={CardHistoryRoute} />
                    </Route>
                  )}

                  <Redirect from="infos" to="cards/infos" />
                  <Route path="members" noMobileNav component={ChannelUserListContainer} />
                  <Route
                    path="cards/infos/advanced"
                    noMobileNav
                    component={ChannelAdvancedOptionsContainer}
                  />
                  <Redirect from="infos/edit" to="cards/infos/edit" />
                  <Route path="add" noMobileNav component={Channel.AddUsers} />
                  <Route
                    path="fragment-templates"
                    noMobileNav
                    component={Channel.FragmentTemplateList}
                  />
                  <Route
                    path="fragment-templates/create"
                    noMobileNav
                    component={Channel.FragmentTemplateCreate}
                  />
                  <Route
                    path="fragment-templates/:templateId"
                    noMobileNav
                    component={Channel.FragmentTemplateEdit}
                  />
                  <Route path="workflows/:workflowId/edit" component={WorkflowEditRoute} />
                  <Route path="workflows/create" component={WorkflowCreateRoute} />
                  <Route path="subforms" component={Channel.SubformsList} />
                  <Route path="viewTemplates" component={Channel.ViewTemplateList} />
                  <Route
                    path="sharedLinks/viewTemplates/:viewTemplateId"
                    component={SharedCardLinkContainer}
                  />
                  <Route
                    path="notifications"
                    noMobileNav
                    component={ChannelNotificationSettingsContainer}
                  />

                  <Route
                    path="messages/:selectedMessageId/:selectedMessageCreatedAt"
                    component={isDesktop ? CardCollectionRoute : Channel.Chat}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      }
    />
  );
};

const LoginExistingAccountResolver = props => {
  const params = new URLSearchParams(props.location.search);
  const error = params.get('error');

  return <MBSignIn initialError={error} />;
};

const LoginSamlAuthenticationResolver = props => {
  const params = new URLSearchParams(props.location.search);
  const code = params.get('code');
  const error = params.get('error');

  if (isNil(code) && isNil(error)) {
    props.router.replace('/signin');
    return null;
  }

  if (!isNil(error)) {
    MBSentry.exception({ error: new Error(`SAML SSO failed: ${error}`) });
    props.router.replace(`/signin?error=${encodeURIComponent(error)}`);
    return null;
  }

  return <LoginSamlAuthentication code={code} />;
};
