"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTitleBar = exports.useDimensions = void 0;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var useDimensions = function () {
    return (0, react_1.useMemo)(function () {
        var windowHeight = react_native_1.Dimensions.get('window').height - Number(react_native_1.StatusBar.currentHeight || 0);
        var initialTop = windowHeight / 2;
        return {
            fullTop: (0, components_1.grid)(6),
            initialTop: initialTop,
        };
    }, []);
};
exports.useDimensions = useDimensions;
var useTitleBar = function (title, renderTitleBar) {
    return (0, react_1.useMemo)(function () {
        if (title) {
            return react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { title: title });
        }
        if (renderTitleBar) {
            return renderTitleBar();
        }
        if (react_native_1.Platform.OS === 'web') {
            return;
        }
        // provide whitespace as a drag target
        // NOTE: android gesture do not work unless a solid background is set
        return react_1.default.createElement(react_native_1.View, { style: { backgroundColor: 'white', paddingTop: (0, components_1.grid)(2) } });
    }, [renderTitleBar, title]);
};
exports.useTitleBar = useTitleBar;
