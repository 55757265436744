"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCanDuplicateToDate = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var hooks_1 = require("../hooks");
var useCanDuplicateToDate = function () {
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    return (0, react_1.useMemo)(function () {
        var hasCalendarTemplate = cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.templates.some(function (_a) {
            var template = _a.template;
            return template.context === 'agenda';
        });
        return hasCalendarTemplate;
    }, [cardDefinition]);
};
exports.useCanDuplicateToDate = useCanDuplicateToDate;
