"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFragment = useFragment;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = require("react");
function useFragment(_a) {
    var id = _a.id, skip = _a.skip, fragment = _a.fragment, fallbackQuery = _a.fallbackQuery, fallbackQueryVariables = _a.fallbackQueryVariables;
    var fragmentDefinition = (0, react_1.useMemo)(function () { return fragment.definitions.find(function (d) { return d.kind === 'FragmentDefinition'; }); }, [fragment]);
    var apolloClient = (0, client_1.useApolloClient)();
    var readFromCache = (0, react_1.useCallback)(function () {
        if (!fragmentDefinition) {
            return undefined;
        }
        var fragmentName = fragmentDefinition.name.value;
        var idWithTypename = "".concat(fragmentDefinition.typeCondition.name.value).concat(id);
        try {
            return apolloClient.readFragment({ id: idWithTypename, fragment: fragment, fragmentName: fragmentName });
        }
        catch (error) {
            return undefined;
        }
    }, [id, fragment, fragmentDefinition, apolloClient]);
    var initialValue = (0, react_1.useMemo)(readFromCache, []);
    var _b = (0, react_1.useState)(initialValue), value = _b[0], setValue = _b[1];
    var _c = (0, react_1.useState)(!!initialValue), loadFromNetwork = _c[0], setLoadFromNetwork = _c[1];
    var data = (0, client_1.useQuery)(fallbackQuery, {
        skip: skip,
        variables: fallbackQueryVariables,
        fetchPolicy: loadFromNetwork && !value ? 'cache-first' : 'cache-only',
    }).data;
    (0, react_1.useEffect)(function () {
        var cachedValue = readFromCache();
        if (!!cachedValue) {
            setValue(cachedValue);
        }
        else {
            setLoadFromNetwork(true);
        }
    }, [data, id, fragment, readFromCache]);
    return value;
}
