"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowEditView = WorkflowEditView;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var menuRow_1 = require("@shared/scenes/cards/bulk/components/menuRow");
var styles_1 = require("@shared/styles");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var config_1 = require("../../config");
var hooks_2 = require("../../hooks");
function WorkflowEditView(_a) {
    var workflowTemplateId = _a.workflowTemplateId, setViewField = _a.setViewField, viewFields = _a.viewFields, onDeleteView = _a.onDeleteView, deleteLoading = _a.deleteLoading, viewComponents = _a.viewComponents, toggleComponentVisibility = _a.toggleComponentVisibility;
    if ((0, lodash_1.isNil)(viewFields) || (0, lodash_1.isNil)(viewComponents)) {
        return null;
    }
    var isCalendar = viewFields.subtype === 'CALENDAR';
    var calendarDateComponent = viewComponents.find(function (item) { return item.id === viewFields.calendarDateComponentId; });
    var showDateComponent = isCalendar && !(0, lodash_1.isNil)(calendarDateComponent);
    return (react_1.default.createElement(components_1.MBView, { paddingTop: 1.5 },
        react_1.default.createElement(ViewName, { viewFields: viewFields, setViewField: setViewField }),
        react_1.default.createElement(ViewType, { viewFields: viewFields, viewComponents: viewComponents }),
        showDateComponent && (react_1.default.createElement(CalendarDateComponent, { workflowTemplateId: workflowTemplateId, calendarDateComponent: calendarDateComponent })),
        react_1.default.createElement(ViewFields, { viewComponents: viewComponents, toggleComponentVisibility: toggleComponentVisibility }),
        react_1.default.createElement(ViewOptions, { viewFields: viewFields, setViewField: setViewField, onDeleteView: onDeleteView, deleteLoading: deleteLoading })));
}
function MenuSection(_a) {
    var children = _a.children;
    return react_1.default.createElement(components_1.MBView, { paddingVertical: 1 }, children);
}
function MenuSectionLabel(_a) {
    var children = _a.children;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingBottom: 0.5 },
        react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, children)));
}
function ViewName(_a) {
    var viewFields = _a.viewFields, setViewField = _a.setViewField;
    return (react_1.default.createElement(MenuSection, null,
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 3, paddingBottom: 0.5 },
            react_1.default.createElement(components_1.TextInput, { autoFocus: true, value: viewFields.name, onChangeText: function (name) { return setViewField({ name: name }); } }))));
}
function ViewType(_a) {
    var viewFields = _a.viewFields;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(MenuSection, null,
        react_1.default.createElement(MenuSectionLabel, null, (0, i18n_1.i18n)('workflowTemplate.viewType')),
        react_1.default.createElement(components_1.MBListRow, { title: (0, config_1.getViewSubtypeName)(viewFields.subtype), skipWrappingIcon: true, image: react_1.default.createElement(components_1.MBIconV2, { name: (0, config_1.getIconForViewSubtype)(viewFields.subtype), color: colors.foregroundActive, size: (0, components_1.grid)(3) }), imageStyle: { margin: 0, padding: 0 } })));
}
function CalendarDateComponent(_a) {
    var workflowTemplateId = _a.workflowTemplateId, calendarDateComponent = _a.calendarDateComponent;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var calendarComponentId = calendarDateComponent.id;
    return (react_1.default.createElement(MenuSection, null,
        react_1.default.createElement(MenuSectionLabel, null, (0, i18n_1.i18n)('workflowTemplate.selectedDateField')),
        react_1.default.createElement(components_1.MBListRow, { title: calendarDateComponent.label, skipWrappingIcon: true, image: react_1.default.createElement(components_1.MBIconV2, { name: calendarDateComponent.icon, color: colors.foregroundActive, size: (0, components_1.grid)(3) }), imageStyle: { margin: 0, padding: 0 } }),
        react_1.default.createElement(ServerManagedDateNote, { workflowTemplateId: workflowTemplateId, calendarComponentId: calendarComponentId })));
}
function ServerManagedDateNote(_a) {
    var workflowTemplateId = _a.workflowTemplateId, calendarComponentId = _a.calendarComponentId;
    var workflowTemplate = (0, hooks_2.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var calendarComponent = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.find(function (item) { return item.id === calendarComponentId; });
    if (!(calendarComponent === null || calendarComponent === void 0 ? void 0 : calendarComponent.isServerManaged)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { paddingLeft: 3, paddingRight: 2 },
        react_1.default.createElement(components_1.MBTextCaption1, { color: "foregroundInactive" }, (0, i18n_1.i18n)('workflowTemplate.calendar.serverManagedDate.editNote'))));
}
function ViewFields(_a) {
    var viewComponents = _a.viewComponents, toggleComponentVisibility = _a.toggleComponentVisibility;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, hooks_1.useBoolean)(), fieldsExpanded = _b[0], toggleFieldsExpanded = _b[3];
    var shouldDisplayShadow = viewComponents.length >= 7;
    return (react_1.default.createElement(MenuSection, null,
        react_1.default.createElement(MenuSectionLabel, null, (0, i18n_1.i18n)('workflowTemplate.showFields')),
        react_1.default.createElement(components_1.MBListRow, { title: (0, i18n_1.i18n)('workflowTemplate.fieldsShown', {
                count: viewComponents === null || viewComponents === void 0 ? void 0 : viewComponents.filter(function (_a) {
                    var visible = _a.visible;
                    return visible;
                }).length,
            }), imageStyle: { margin: 0, padding: 0 }, image: react_1.default.createElement(components_1.MBIconV2, { name: "filter-knobs", color: colors.foregroundActive, size: (0, components_1.grid)(3) }), skipWrappingIcon: true, onPress: toggleFieldsExpanded, icon: react_1.default.createElement(animation_1.MBAnimation, { duration: 200, easing: react_native_1.Easing.inOut(react_native_1.Easing.quad), disabled: !fieldsExpanded, rotate: { from: '0deg', to: '-180deg' } },
                react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "arrow-point-down", size: (0, components_1.grid)(3) })) }),
        fieldsExpanded && (react_1.default.createElement(react_native_1.ScrollView, { style: [{ maxHeight: 225 }, shouldDisplayShadow && styles.shadow] }, viewComponents === null || viewComponents === void 0 ? void 0 : viewComponents.map(function (component) { return (react_1.default.createElement(components_1.MBListRow, { key: component.id, imageStyle: { margin: 0, padding: 0 }, paddingVertical: 1, title: react_1.default.createElement(components_1.MBTextBody1, { color: component.disabled ? colors.foregroundInactive : colors.foregroundActive }, component.label), image: react_1.default.createElement(components_1.MBIconV2, { name: component.icon, color: component.disabled ? colors.foregroundInactive : colors.foregroundActive, size: (0, components_1.grid)(3) }), skipWrappingIcon: true, icon: react_1.default.createElement(components_1.Switch, { disabled: component.disabled, value: component.visible, onValueChange: function () { return toggleComponentVisibility(component); } }) })); })))));
}
function ViewOptions(_a) {
    var _b;
    var viewFields = _a.viewFields, setViewField = _a.setViewField, onDeleteView = _a.onDeleteView, deleteLoading = _a.deleteLoading;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var supportsBundleTags = getSupportsBundleTags(viewFields);
    return (react_1.default.createElement(MenuSection, null,
        react_1.default.createElement(MenuSectionLabel, null, (0, i18n_1.i18n)('workflowTemplate.options')),
        react_1.default.createElement(components_1.MBListRow, { imageStyle: { margin: 0, padding: 0 }, paddingVertical: 0.75, title: (0, i18n_1.i18n)('workflowTemplate.showLabels'), image: react_1.default.createElement(components_1.MBIconV2, { name: "eye-show", color: colors.foregroundActive, size: (0, components_1.grid)(3) }), skipWrappingIcon: true, icon: react_1.default.createElement(components_1.Switch, { value: !viewFields.defaultHiddenLabels, disabled: isShowLabelsDisabled(viewFields), onValueChange: function (value) { return setViewField({ defaultHiddenLabels: !value }); } }) }),
        supportsBundleTags && (react_1.default.createElement(components_1.MBListRow, { imageStyle: { margin: 0, padding: 0 }, paddingVertical: 0.75, title: (0, i18n_1.i18n)('workflowTemplate.bundleTags'), image: react_1.default.createElement(components_1.MBIconV2, { name: "eye-show", color: colors.foregroundActive, size: (0, components_1.grid)(3) }), skipWrappingIcon: true, icon: react_1.default.createElement(components_1.Switch, { value: (_b = viewFields.summarizeReadonlyTags) !== null && _b !== void 0 ? _b : false, onValueChange: function (value) {
                    setViewField({ summarizeReadonlyTags: value });
                } }) })),
        react_1.default.createElement(menuRow_1.MBMenuRow, { onPress: function () { return onDeleteView(viewFields.id); }, paddingVertical: 0.75, isLoading: deleteLoading, isHidingPressableIndicator: true, title: (0, i18n_1.i18n)('common.delete'), image: react_1.default.createElement(components_1.MBIconV2, { name: "trash", color: colors.foregroundActive, size: (0, components_1.grid)(3) }) })));
}
function isShowLabelsDisabled(viewFields) {
    if ((0, lodash_1.isNil)(viewFields.subtype)) {
        return false;
    }
    return ['CALENDAR', 'TABLE'].includes(viewFields.subtype);
}
function getSupportsBundleTags(viewFields) {
    if ((0, lodash_1.isNil)(viewFields.subtype)) {
        return false;
    }
    return !['CALENDAR', 'TABLE', 'LIST'].includes(viewFields.subtype);
}
var styles = react_native_1.StyleSheet.create({
    shadow: {
        boxShadow: 'inset 0px -15px 10px -15px rgba(0,0,0,0.1), inset 0px 15px 10px -15px rgba(0,0,0,0.1)',
        marginBottom: 0,
    },
});
