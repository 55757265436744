"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardList = CardList;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var useIsCardBeforePaidCutoff_1 = require("@shared/data/billing/hooks/useIsCardBeforePaidCutoff");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var emptyState_1 = require("@shared/scenes/cards/components/emptyState");
var usePaywallExperiment_1 = require("@shared/util/featureFlags/launchDarkly/hooks/usePaywallExperiment");
var i18n_1 = require("@shared/util/i18n");
var CollectionViewAnalytics_1 = require("../../collectionView/CollectionViewAnalytics");
var CardListFooter_1 = require("./CardListFooter");
var CardListItem_1 = require("./CardListItem");
function CardList(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, canUpdateCard = _a.canUpdateCard, entities = _a.entities, openEntity = _a.openEntity, hasMore = _a.hasMore, showMore = _a.showMore, isCardsLoading = _a.isCardsLoading, isMoreLoading = _a.isMoreLoading, isGroup = _a.isGroup, refetch = _a.refetch, _b = _a.hasOverflowMenu, hasOverflowMenu = _b === void 0 ? true : _b, contentContainerStyle = _a.contentContainerStyle;
    var getIsCardBeforePaidCutoff = (0, useIsCardBeforePaidCutoff_1.useIsCardBeforePaidCutoff)();
    var showUpgradeModal = (0, react_1.useContext)(provider_1.UpgradeModalContext).show;
    var reportRedactedClick = (0, usePaywallExperiment_1.useTrackPaywallExperimentClick)('redacted');
    var isRedactedGroup = (0, usePaywallExperiment_1.usePaywallExperiment)().isRedactedGroup;
    var analytics = new CollectionViewAnalytics_1.CollectionViewAnalytics(workflowTemplate.id, viewTemplate.id);
    var showModal = (0, react_1.useCallback)(function () {
        reportRedactedClick();
        showUpgradeModal({
            title: (0, i18n_1.i18n)('card.upgradeModal.title', { count: useIsCardBeforePaidCutoff_1.CARD_VIEW_HISTORY_CUTOFF_DAYS }),
            description: (0, i18n_1.i18n)('card.upgradeModal.description'),
        });
    }, [showUpgradeModal, reportRedactedClick]);
    var renderItem = function (item) {
        var isRedacted = isRedactedGroup && getIsCardBeforePaidCutoff(item.item);
        return (react_1.default.createElement(CardListItem_1.CardListItem, { key: item.item.id, viewTemplate: viewTemplate, workflowTemplate: workflowTemplate, openEntity: isRedacted ? showModal : openEntity, entity: item.item, canUpdateCard: canUpdateCard, isRedacted: isRedacted, analytics: analytics, hasOverflowMenu: hasOverflowMenu, testID: "card-list-item-".concat(item.index) }));
    };
    var isLoading = isCardsLoading || isMoreLoading;
    var isEmptyState = (0, lodash_1.isEmpty)(entities) && !isLoading;
    var testID = getTestId(isLoading, isEmptyState);
    return (react_1.default.createElement(react_native_1.FlatList, { testID: testID, data: entities, refreshControl: !(0, lodash_1.isNil)(refetch) ? (react_1.default.createElement(react_native_1.RefreshControl, { refreshing: isCardsLoading, onRefresh: refetch })) : undefined, renderItem: renderItem, contentContainerStyle: contentContainerStyle, ListFooterComponent: react_1.default.createElement(react_1.default.Fragment, null,
            isEmptyState && (react_1.default.createElement(emptyState_1.MBCardEmptyState, { emptyState: {
                    title: isGroup ? (0, i18n_1.i18n)('card.table.emptyGroup.title') : (0, i18n_1.i18n)('emptyCardList.title'),
                    message: isGroup
                        ? (0, i18n_1.i18n)('card.table.emptyGroup.description')
                        : (0, i18n_1.i18n)('emptyCardList.description'),
                } })),
            react_1.default.createElement(CardListFooter_1.CardListFooter, { showMore: showMore, hasMore: hasMore, isLoading: isLoading, isMoreLoading: isMoreLoading })) }));
}
function getTestId(isCardsLoading, isEmptyState) {
    if (isCardsLoading) {
        return 'card-list-loading';
    }
    if (isEmptyState) {
        return 'card-list-empty-state';
    }
    return 'card-list-container';
}
