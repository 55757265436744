"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBVerifyCode = MBVerifyCode;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var context_1 = require("@shared/scenes/auth/signin/context");
var util_1 = require("@shared/scenes/auth/util");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var storage_1 = require("@shared/util/storage");
var index_2 = require("../components/index");
var theme_1 = require("../theme");
function MBVerifyCode() {
    var _this = this;
    var _a = (0, react_1.useContext)(context_1.SignInContext), contact = _a.contact, inviteCode = _a.inviteCode;
    var apolloClient = (0, client_1.useApolloClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigateToOnboardingProfile = (0, useNavigator_1.useNavigateTo)('OnboardingProfile');
    var navigateToHome = (0, useNavigator_1.useNavigateTo)('Home');
    var verifyCodeMutation = (0, client_1.useMutation)(api_1.Mutations.auth.verifyCode)[0];
    var verifyCode = (0, react_1.useCallback)(function (code) { return __awaiter(_this, void 0, void 0, function () {
        var res, _a, authToken, user, isInvitedUserOnFirstVisit, defaultBusinessId;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, verifyCodeMutation({
                        variables: {
                            code: code,
                            contact: contact,
                            inviteCode: inviteCode,
                            referrer: react_native_1.Platform.select({ web: function () { return document.referrer; }, default: function () { return undefined; } })(),
                        },
                    })];
                case 1:
                    res = _c.sent();
                    _a = res.data.authentication.verifyCode, authToken = _a.authToken, user = _a.user, isInvitedUserOnFirstVisit = _a.isInvitedUserOnFirstVisit;
                    defaultBusinessId = (_b = user.businesses[0]) === null || _b === void 0 ? void 0 : _b.id;
                    if (!(react_native_1.Platform.OS === 'web')) return [3 /*break*/, 4];
                    return [4 /*yield*/, storage_1.MBStorage.set('authToken', authToken)];
                case 2:
                    _c.sent();
                    return [4 /*yield*/, storage_1.MBStorage.set('currentBusinessId', String(defaultBusinessId))];
                case 3:
                    _c.sent();
                    dispatch({ type: 'SET_USER_ID', id: user.id });
                    return [3 /*break*/, 5];
                case 4:
                    dispatch({ type: 'UPDATE_AUTHENTICATION_STATE', user: user, userAuthToken: authToken });
                    _c.label = 5;
                case 5:
                    dispatch({ type: 'UPDATE_CURRENT_BUSINESS_ID', businessId: defaultBusinessId });
                    return [4 /*yield*/, apolloClient.resetStore().catch(function () { return null; })];
                case 6:
                    _c.sent();
                    if (isInvitedUserOnFirstVisit) {
                        navigateToOnboardingProfile();
                    }
                    else if (react_native_1.Platform.OS === 'web') {
                        navigateToHome();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [
        contact,
        inviteCode,
        verifyCodeMutation,
        apolloClient,
        navigateToOnboardingProfile,
        navigateToHome,
    ]);
    var component = (react_1.default.createElement(index_2.MBVerifyCode, { title: util_1.MBAuthUtil.isPhoneNumber(contact)
            ? (0, i18n_1.i18n)('auth.verifyCode.title.phoneNumber')
            : (0, i18n_1.i18n)('auth.verifyCode.title.email'), isDesktop: (0, index_1.useIsDesktop)(), emailOrPhoneNumber: contact, onSubmit: verifyCode }));
    return (react_1.default.createElement(index_1.MBAuthContentWrapper, { logo: "white", shadow: false, children: component, background: react_1.default.createElement(components_1.MBView, { fill: true, style: { backgroundColor: styles_1.Colors.v2.purple } }), theme: theme_1.theme }));
}
