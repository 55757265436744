"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboard = useKeyboard;
exports.useKeyboardHeight = useKeyboardHeight;
exports.useKeyboardVisible = useKeyboardVisible;
var react_1 = require("react");
var react_native_1 = require("react-native");
function useKeyboard() {
    var _a = (0, react_1.useState)(false), visible = _a[0], setVisible = _a[1];
    var _b = (0, react_1.useState)(0), height = _b[0], setHeight = _b[1];
    (0, react_1.useEffect)(function () {
        var keyboardDidShowListener = react_native_1.Keyboard.addListener('keyboardDidShow', function (event) {
            setVisible(true);
            setHeight(react_native_1.Platform.select({ ios: event.endCoordinates.height, default: 0 }));
        });
        var keyboardDidHideListener = react_native_1.Keyboard.addListener('keyboardDidHide', function () {
            setVisible(false);
            setHeight(0);
        });
        return function () {
            keyboardDidShowListener.remove();
            keyboardDidHideListener.remove();
        };
    }, []);
    return (0, react_1.useMemo)(function () { return ({ visible: visible, height: height }); }, [visible, height]);
}
function useKeyboardHeight() {
    var height = useKeyboard().height;
    return height;
}
function useKeyboardVisible() {
    var visible = useKeyboard().visible;
    return visible;
}
