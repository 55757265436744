"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditCardEntity = useEditCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useVisibleComponents_1 = require("../../util/useVisibleComponents");
var useEditFields_1 = require("./useEditFields");
var useSaveCardEntity_1 = require("./useSaveCardEntity");
function useEditCardEntity(_a) {
    var _b;
    var entityId = _a.entityId, workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    // If no viewTemplateId, we use the first card view template that is not 'Create'.
    // Further exploration here: https://app.shortcut.com/coast/story/46087/no-code-forms-refine-card-view-template-solution
    var viewTemplates = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'CARD',
        isPublic: false,
        skip: !(0, lodash_1.isNil)(viewTemplateId),
    }).viewTemplates;
    var queriedViewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    var sortedViewTemplates = (0, lodash_1.sortBy)(viewTemplates.filter(function (vt) { return !vt.name.startsWith('Create '); }), 'updatedAt');
    var viewTemplate = !(0, lodash_1.isNil)(viewTemplateId) ? queriedViewTemplate : sortedViewTemplates[0];
    var _c = (0, react_1.useState)('loading'), status = _c[0], setStatus = _c[1];
    var fields = (0, useEditFields_1.useEditFields)(entityId, workflowTemplate);
    var _d = (0, useSaveCardEntity_1.useSaveEntityFields)(entityId, workflowTemplate), saveEntity = _d.saveEntity, saveEntityLoading = _d.loading, errors = _d.errors;
    var visibleComponents = (0, useVisibleComponents_1.useVisibleComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
    });
    var componentsWithOverrides = visibleComponents.map(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
        if (!(0, lodash_1.isNil)(viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.overrides)) {
            return __assign(__assign({}, component), viewOptions.overrides);
        }
        return component;
    });
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (fields.loading || saveEntityLoading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(fields.error) && !(0, lodash_1.isEmpty)(errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [errors, fields.error, fields.loading, saveEntityLoading, status]);
    var onPressSave = (0, react_1.useCallback)(function () {
        if (!saveEntityLoading && !(0, lodash_1.isNil)(fields.fields)) {
            saveEntity(fields.fields).then(function (_fulfilled) {
                setStatus('success');
            }, function (_error) {
                // parse remote validation
                setStatus('error');
            });
        }
    }, [saveEntityLoading, fields.fields, saveEntity]);
    return {
        components: componentsWithOverrides,
        fields: fields.fields,
        errors: errors,
        viewTemplateTitle: (_b = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name) !== null && _b !== void 0 ? _b : '',
        updateField: fields.updateField,
        onPressSave: onPressSave,
        isLoading: status === 'loading',
        status: status,
        isDirty: fields.isDirty,
    };
}
