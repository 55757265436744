"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeShareLink = makeShareLink;
exports.buildSmsInviteUrl = buildSmsInviteUrl;
exports.buildEmailInviteUrl = buildEmailInviteUrl;
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var i18n_1 = require("@shared/util/i18n");
var isWeb = react_native_1.Platform.OS === 'web';
var baseHost = config_1.Config.APP_URL;
function getSocialProof(senderId, members) {
    var otherUsers = members.filter(function (u) { return u.id !== senderId; });
    if (!otherUsers || otherUsers.length === 0) {
        return '';
    }
    var firstTwo = otherUsers.slice(0, 2).map(function (u) { return u.firstName; });
    var who = " with ".concat(firstTwo.join(otherUsers.length > 2 ? ', ' : ' and '));
    if (otherUsers.length > 2) {
        var othersCount = otherUsers.length - 2;
        who += " and ".concat(othersCount, " other").concat(othersCount > 1 ? 's' : '');
    }
    return who;
}
function makeShareLink(code) {
    return "".concat(baseHost, "/i/").concat(code);
}
function buildSmsInviteUrl(businessName, senderName, shareCode, members, senderId) {
    var socialProof = getSocialProof(senderId, members);
    var body = (0, i18n_1.i18n)('contacts.inviteSmsTemplate', {
        senderName: senderName,
        businessName: businessName,
        socialProof: socialProof,
        link: makeShareLink(shareCode),
    });
    var isAndroid = react_native_1.Platform.OS === 'android';
    return "sms:".concat(isAndroid ? '?' : '&', "body=").concat(encodeURIComponent(body));
}
function buildEmailInviteUrl(businessName, senderName, shareCode, members, senderId) {
    var socialProof = getSocialProof(senderId, members);
    var subject = "".concat(senderName, " added you to ").concat(businessName, " on Coast");
    var body = (0, i18n_1.i18n)('contacts.inviteEmailTemplate', {
        senderName: senderName,
        businessName: businessName,
        socialProof: socialProof,
        link: makeShareLink(shareCode),
    });
    body = isWeb ? body.replace(/\n/g, '\n\n') : body;
    body = body.replace(/&/g, 'and');
    return "mailto:?subject=".concat(subject, "&body=").concat(body);
}
