"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditTag = AuditTag;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/components/form/styles");
var styles_2 = require("@shared/styles");
var tag_1 = require("../../../../components/blocks/lib/tags/tag");
function AuditTag(_a) {
    var tagOptions = _a.tagOptions, tags = _a.tags, isMultiselect = _a.isMultiselect, onSearch = _a.onSearch, onChange = _a.onChange, onClose = _a.onClose;
    var colors = (0, react_1.useContext)(styles_2.ColorThemeContext);
    var _b = (0, react_1.useState)(''), query = _b[0], _setQuery = _b[1];
    var _c = (0, react_1.useState)(null), error = _c[0], setError = _c[1];
    var setQuery = (0, react_1.useCallback)(function (keyword) {
        _setQuery(keyword);
        onSearch === null || onSearch === void 0 ? void 0 : onSearch(keyword);
    }, [onSearch]);
    var matchesSearchString = function (option) {
        return option.label.toLowerCase().includes(query.toLowerCase());
    };
    var filteredItems = tagOptions === null || tagOptions === void 0 ? void 0 : tagOptions.filter(matchesSearchString).map(function (tagOption) { return ({
        value: tagOption,
        selected: tags === null || tags === void 0 ? void 0 : tags.some(function (selectedValue) { return selectedValue === tagOption.value; }),
        archived: tagOption.archived,
    }); });
    function newMultiselectSelections(newSelection, existingSelections) {
        if (existingSelections.includes(newSelection)) {
            return existingSelections.filter(function (item) { return item !== newSelection; });
        }
        return [newSelection].concat(existingSelections !== null && existingSelections !== void 0 ? existingSelections : []);
    }
    function newSingleSelectSelection(newSelection, existingSelections) {
        if (existingSelections === null || existingSelections === void 0 ? void 0 : existingSelections.includes(newSelection)) {
            return [];
        }
        return [newSelection];
    }
    var toggleItem = (0, react_1.useCallback)(function (value, _component) {
        var newValues = isMultiselect
            ? newMultiselectSelections(value.value, tags !== null && tags !== void 0 ? tags : [])
            : newSingleSelectSelection(value.value, tags !== null && tags !== void 0 ? tags : []);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValues);
        if (!isMultiselect) {
            onClose();
        }
    }, [isMultiselect, onChange, onClose, tags]);
    var renderListItem = (0, react_1.useCallback)(function (listItem) { return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function (component) { return toggleItem(listItem.value, component); }, key: listItem.value.value },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5 },
            react_1.default.createElement(components_1.MBView, { style: { alignContent: 'flex-start', flexWrap: 'wrap', flex: 4 }, flex: true, row: true, wrap: true },
                react_1.default.createElement(tag_1.Tag, { color: listItem.value.backgroundColor, textStyle: react_native_1.Platform.OS === 'web' ? components_1.MBTypographyStyles.body2 : components_1.MBTypographyStyles.body1, label: listItem.value.label })),
            react_1.default.createElement(components_1.MBView, { style: {
                    alignItems: 'flex-end',
                    flex: 1,
                } },
                react_1.default.createElement(components_1.MBSelectableDot, { selected: listItem.selected, radio: !isMultiselect }))))); }, [isMultiselect, toggleItem]);
    var searchInputRef = (0, react_1.useRef)(null);
    var onChangeSearchText = (0, react_1.useCallback)(function (searchText) {
        setQuery(searchText);
        setError(null);
    }, [setQuery, setError]);
    return (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, fill: true, paddingBottom: 1 },
        react_1.default.createElement(components_1.MBView, { style: styles_1.Styles.whiteBackground, paddingTop: 2, paddingBottom: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { style: { paddingLeft: (0, components_1.grid)(2) }, forwardRef: searchInputRef, value: query, onChangeText: onChangeSearchText, returnKeyType: "go", autoFocus: react_native_1.Platform.OS === 'web' }),
            !!error && (react_1.default.createElement(components_1.MBTextBody2, { color: colors.error.foreground, style: { padding: (0, components_1.grid)(1) } }, error))),
        react_1.default.createElement(components_1.MBView, { fill: true },
            react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always" }, filteredItems === null || filteredItems === void 0 ? void 0 : filteredItems.map(renderListItem)))));
}
