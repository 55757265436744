"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomBatch = useCustomBatch;
var react_1 = require("react");
var rrule_1 = require("rrule");
var i18n_1 = require("@shared/util/i18n");
var utils_1 = require("../utils");
function useCustomBatch(_a) {
    var _b, _c;
    var setRruleOptions = _a.setRruleOptions, rruleOptions = _a.rruleOptions;
    var _d = (0, react_1.useState)((_b = rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.freq) !== null && _b !== void 0 ? _b : rrule_1.RRule.WEEKLY), freq = _d[0], setFreq = _d[1];
    var _e = (0, react_1.useState)((_c = rruleOptions === null || rruleOptions === void 0 ? void 0 : rruleOptions.interval) !== null && _c !== void 0 ? _c : 1), interval = _e[0], setInterval = _e[1];
    var setCustomRrule = function (newOptions) {
        setRruleOptions(function (prev) { return (0, utils_1.buildCustomRRule)(prev, newOptions); });
    };
    var handleIntervalChange = function (newInterval) {
        if (newInterval === '') {
            setInterval(undefined);
            setCustomRrule({ interval: 1 });
            return;
        }
        var intervalCount = Number(newInterval);
        var isNumber = !isNaN(intervalCount);
        if (isNumber && intervalCount > 0) {
            setInterval(intervalCount);
            setCustomRrule({ interval: intervalCount });
        }
    };
    var freqOptions = [
        {
            title: getFrequencyLabel(rrule_1.RRule.DAILY, interval !== null && interval !== void 0 ? interval : 0),
            onPress: function () {
                setFreq(rrule_1.RRule.DAILY);
                setCustomRrule({ freq: rrule_1.RRule.DAILY });
            },
        },
        {
            title: getFrequencyLabel(rrule_1.RRule.WEEKLY, interval !== null && interval !== void 0 ? interval : 0),
            onPress: function () {
                setFreq(rrule_1.RRule.WEEKLY);
                setCustomRrule({ freq: rrule_1.RRule.WEEKLY });
            },
        },
        {
            title: getFrequencyLabel(rrule_1.RRule.MONTHLY, interval !== null && interval !== void 0 ? interval : 0),
            onPress: function () {
                setFreq(rrule_1.RRule.MONTHLY);
                setCustomRrule({ freq: rrule_1.RRule.MONTHLY });
            },
        },
        {
            title: getFrequencyLabel(rrule_1.RRule.YEARLY, interval !== null && interval !== void 0 ? interval : 0),
            onPress: function () {
                setFreq(rrule_1.RRule.YEARLY);
                setCustomRrule({ freq: rrule_1.RRule.YEARLY });
            },
        },
    ];
    return {
        freq: freq,
        frequencyLabel: getFrequencyLabel(freq, interval !== null && interval !== void 0 ? interval : 0),
        setFreq: setFreq,
        freqOptions: freqOptions,
        interval: interval,
        setInterval: setInterval,
        setCustomRrule: setCustomRrule,
        handleIntervalChange: handleIntervalChange,
    };
}
var getFrequencyLabel = function (freq, interval) {
    if (freq === rrule_1.RRule.DAILY) {
        return (0, i18n_1.i18n)('workflowTemplate.component.dateAndRepeat.frequencyWithCount.day', {
            count: interval,
        });
    }
    if (freq === rrule_1.RRule.WEEKLY) {
        return (0, i18n_1.i18n)('workflowTemplate.component.dateAndRepeat.frequencyWithCount.week', {
            count: interval,
        });
    }
    if (freq === rrule_1.RRule.MONTHLY) {
        return (0, i18n_1.i18n)('workflowTemplate.component.dateAndRepeat.frequencyWithCount.month', {
            count: interval,
        });
    }
    return (0, i18n_1.i18n)('workflowTemplate.component.dateAndRepeat.frequencyWithCount.year', {
        count: interval,
    });
};
