"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkUpdateOverflowButton = BulkUpdateOverflowButton;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var useOnPressBulkDelete_1 = require("./useOnPressBulkDelete");
function BulkUpdateOverflowButton(props) {
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerRef = (0, react_1.useRef)(null);
    var onPress = useBulkUpdateOverflowMenu(props);
    return (react_1.default.createElement(components_1.MBView, { ref: containerRef },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "dots" }))));
}
function useBulkUpdateOverflowMenu(props) {
    var onPressBulkDelete = (0, useOnPressBulkDelete_1.useOnPressBulkDelete)(props);
    return (0, react_1.useCallback)(function (anchor) {
        return actionSheet_1.MBActionSheet.show({
            parent: anchor,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'right',
            options: [
                {
                    title: (0, i18n_1.i18n)('common.delete'),
                    destructive: true,
                    onPress: function () { return onPressBulkDelete(anchor); },
                },
            ],
        });
    }, [onPressBulkDelete]);
}
