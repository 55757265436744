"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUserGroup = AddUserGroup;
var react_1 = __importStar(require("react"));
var button_1 = require("@shared/components/button");
var Dropdown_1 = require("@shared/components/dropdown/Dropdown");
var MBView_1 = require("@shared/components/view/MBView");
var UserGroupAdminOptions_1 = require("@shared/scenes/userGroups/UserGroupAdminOptions");
var colors_1 = require("@shared/styles/colors");
var i18n_1 = require("@shared/util/i18n");
function AddUserGroup(_a) {
    var onAddGroup = _a.onAddGroup;
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setIsOpen = _b[1];
    return (react_1.default.createElement(MBView_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
        react_1.default.createElement(Dropdown_1.Dropdown, { onVisibleChange: setIsOpen, visible: isOpen, button: react_1.default.createElement(button_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.addGroup'), leftIcon: "plus", leftIconSize: 16, leftIconColor: colors_1.Colors.v2.purple, backgroundColor: "transparent", titleColor: colors_1.Colors.v2.purple, onPress: function () { return setIsOpen(function (prev) { return !prev; }); }, size: "small" }) },
            react_1.default.createElement(MBView_1.MBView, null,
                react_1.default.createElement(UserGroupAdminOptions_1.UserGroupNameForm, { onSubmit: onAddGroup, onCancel: function () { return setIsOpen(false); } })))));
}
