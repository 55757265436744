"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputButtonEditField = InputButtonEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var provider_1 = require("@shared/components/bottomSheet/provider");
var styles_1 = require("@shared/components/button/styles");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_2 = require("@shared/styles");
var EditFieldContainer_1 = require("./EditFieldContainer");
var SingleComponentForm_1 = require("./InputButtonForms/SingleComponentForm");
function InputButtonEditField(_a) {
    var component = _a.component, inputField = _a.inputField, workflowTemplateId = _a.workflowTemplateId, updateField = _a.updateField;
    var buttonRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(false), showForm = _b[0], setShowForm = _b[1];
    var colors = (0, react_1.useContext)(styles_2.ColorThemeContext);
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId);
    var onPress = function () {
        setShowForm(function (prev) { return !prev; });
    };
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: false },
        react_1.default.createElement(react_native_1.View, { style: { width: '100%' }, ref: buttonRef },
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress },
                react_1.default.createElement(components_1.MBView, { center: true, paddingHorizontal: 'normal', style: [
                        styles_1.Styles.button,
                        styles_1.Styles.buttonSizeSmall,
                        {
                            backgroundColor: colors.button.backgroundInverted,
                            borderColor: showForm ? styles_2.Colors.v2.gray30 : colors.button.foregroundInverted,
                            alignSelf: 'flex-start',
                            width: '100%',
                        },
                    ] },
                    react_1.default.createElement(components_1.MBTextBody2Bold, { numberOfLines: 1, ellipsizeMode: 'tail', color: showForm ? styles_2.Colors.v2.gray30 : colors.button.foregroundInverted }, component.buttonText))),
            showForm && !(0, lodash_1.isNil)(workflowTemplate.workflowTemplate) && (react_1.default.createElement(provider_1.MBBottomSheetProvider, null,
                react_1.default.createElement(SingleComponentForm_1.SingleComponentForm, { fieldName: component.inputComponentId, field: inputField, buttonRef: buttonRef, workflowTemplate: workflowTemplate.workflowTemplate, onClose: function () { return setShowForm(false); }, loading: workflowTemplate.loading, onUpdateField: updateField }))))));
}
