"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowScheduledAutomationComponentLowCode = WorkflowScheduledAutomationComponentLowCode;
exports.WorkflowScheduledAutomationComponent = WorkflowScheduledAutomationComponent;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var form_1 = require("@shared/components/blocks/contexts/form");
var ScheduledAutomationField_1 = require("@shared/data/entity/fields/ScheduledAutomationField");
var ScheduledAutomationEditField_1 = require("@shared/scenes/entity/components/fields/ScheduledAutomationEditField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function WorkflowScheduledAutomationComponentLowCode(props) {
    var wasDefaultUsed = (0, react_1.useRef)(false);
    var _a = (0, react_1.useContext)(form_1.CardFormContext), setFieldError = _a.setFieldError, errors = _a.errors, mode = _a.mode;
    var component = props.component;
    (0, react_1.useEffect)(function () {
        var _a;
        if (mode === 'CREATE' &&
            (0, hooks_1.isScheduledAutomationComponent)(component) &&
            (0, lodash_1.isNil)(props.initialData) &&
            !wasDefaultUsed.current) {
            wasDefaultUsed.current = true;
            (_a = props.onDataChanged) === null || _a === void 0 ? void 0 : _a.call(props, component.id, component.default);
        }
    }, [props, component, mode]);
    return (react_1.default.createElement(WorkflowScheduledAutomationComponent, __assign({}, props, { setFieldError: setFieldError, errors: errors })));
}
function WorkflowScheduledAutomationComponent(props) {
    var updateField = (0, react_1.useCallback)(function (componentId, newField) {
        var _a, _b;
        if ((0, lodash_1.isNil)(newField)) {
            (_a = props.onDataChanged) === null || _a === void 0 ? void 0 : _a.call(props, componentId, null);
        }
        else {
            (_b = props.onDataChanged) === null || _b === void 0 ? void 0 : _b.call(props, componentId, {
                automationTriggerDurations: newField.durations,
            });
        }
    }, [props]);
    var field = (0, react_1.useMemo)(function () {
        return (0, lodash_1.isNil)(props.initialData) ? null : new ScheduledAutomationField_1.ScheduledAutomationField(props.initialData);
    }, [props.initialData]);
    return (react_1.default.createElement(ScheduledAutomationEditField_1.ScheduledAutomationEditField, __assign({ field: field }, props, { component: props.component, updateField: updateField, errors: props.errors })));
}
