"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockTags = MBCardInputBlockTags;
var lodash_1 = __importStar(require("lodash"));
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var field_1 = require("@shared/components/blocks/lib/field");
var tags_1 = require("@shared/components/blocks/lib/tags");
var hooks_1 = require("@shared/components/blocks/lib/tags/hooks");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var tagList_1 = require("@shared/components/blocks/lib/tags/tagList");
var modal_1 = require("@shared/components/multiSelect/modal");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var render_1 = require("../../contexts/render");
var base_1 = require("../input/base");
var utils_1 = require("../input/utils");
var createTagRow_1 = require("../tags/createTagRow");
var hooks_2 = require("./hooks");
function MBCardInputBlockTags(_a) {
    var _this = this;
    var _b, _c;
    var block = _a.block;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var _d = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _d.setFieldValue, submitForm = _d.submitForm, values = _d.values;
    var _e = (0, react_1.useState)([]), recentlyCreatedTagOptions = _e[0], setRecentlyCreatedTagOptions = _e[1];
    var _f = (0, hooks_1.useTagOptions)(block.name), options = _f.options, loading = _f.loading, refetch = _f.refetch;
    var optionsToRender = (0, react_1.useMemo)(function () { return lodash_1.default.uniqBy(__spreadArray(__spreadArray([], recentlyCreatedTagOptions, true), options, true), function (t) { return t.slug; }); }, [options, recentlyCreatedTagOptions]);
    var labelExtractor = (0, react_1.useCallback)(function (value) { var _a; return (_a = (0, tags_1.getTagOption)(value, options)) === null || _a === void 0 ? void 0 : _a.label; }, [options]);
    var idExtractor = (0, react_1.useCallback)(function (value) { var _a; return (_a = (0, tags_1.getTagOption)(value, options)) === null || _a === void 0 ? void 0 : _a.id; }, [options]);
    var tagValues = (0, react_1.useMemo)(function () { return values[block.name] || []; }, [values, block.name]);
    var optionSlugs = (0, react_1.useMemo)(function () { return optionsToRender.map(function (opt) { return opt.slug; }); }, [optionsToRender]);
    var archivedValues = (0, react_1.useMemo)(function () { return optionSlugs.filter(function (value) { var _a; return !!((_a = (0, tags_1.getTagOption)(value, options)) === null || _a === void 0 ? void 0 : _a.archivedAt); }); }, [optionSlugs, options]);
    var onChange = (0, react_1.useCallback)(function (selectedTags) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFieldValue(block.name, (0, lodash_1.isEmpty)(selectedTags) ? null : selectedTags);
                    if (!(renderContext !== 'form')) return [3 /*break*/, 2];
                    return [4 /*yield*/, submitForm()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [block.name, setFieldValue, renderContext, submitForm]);
    var renderRight = (0, react_1.useMemo)(function () {
        var _a;
        if (!tagValues.length || block.required || ((_a = block.validation) === null || _a === void 0 ? void 0 : _a.min) > 0) {
            return;
        }
        return utils_1.renderClearButton;
    }, [tagValues, block]);
    var createCardTag = (0, hooks_2.useCreateCardTag)(block.name);
    var updateCardTag = (0, hooks_2.useUpdateCardTag)();
    var archiveCardTag = (0, hooks_2.useArchiveCardTag)();
    var createAndSelectCardTag = (0, react_1.useCallback)(function (name) { return __awaiter(_this, void 0, void 0, function () {
        var tag, newTagSlugs;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, createCardTag(name)];
                case 1:
                    tag = _b.sent();
                    newTagSlugs = __spreadArray(__spreadArray([], tagValues, true), [tag.slug], false);
                    if (!!((_a = block.validation) === null || _a === void 0 ? void 0 : _a.max)) {
                        onChange(newTagSlugs.slice(newTagSlugs.length - block.validation.max));
                    }
                    else {
                        onChange(newTagSlugs);
                    }
                    setRecentlyCreatedTagOptions(function (prevValue) { return __spreadArray([tag], prevValue, true); });
                    return [2 /*return*/];
            }
        });
    }); }, [createCardTag, onChange, tagValues, (_b = block.validation) === null || _b === void 0 ? void 0 : _b.max]);
    var onChangeLabel = (0, react_1.useCallback)(function (value, label) { return __awaiter(_this, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = idExtractor(value);
                    return [4 /*yield*/, updateCardTag(id, label)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [idExtractor, updateCardTag]);
    var onArchive = (0, react_1.useCallback)(function (value) { return __awaiter(_this, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = idExtractor(value);
                    return [4 /*yield*/, archiveCardTag(id, false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [archiveCardTag, idExtractor]);
    var onUnarchive = (0, react_1.useCallback)(function (value) { return __awaiter(_this, void 0, void 0, function () {
        var id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = idExtractor(value);
                    return [4 /*yield*/, archiveCardTag(id, true)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [archiveCardTag, idExtractor]);
    var renderLabel = (0, react_1.useMemo)(function () { return (!!tagValues.length && block.appearance !== 'button' ? utils_1.renderLabel : null); }, [tagValues, block.appearance]);
    var content = (0, react_1.useMemo)(function () {
        if (loading) {
            return react_1.default.createElement(components_1.MBLoadingIndicator, null);
        }
        if (block.appearance === 'button') {
            return react_1.default.createElement(components_1.MBButtonCappedWidth, { size: "small", theme: "outline", title: block.label });
        }
        if (tagValues === null || tagValues === void 0 ? void 0 : tagValues.length) {
            return (react_1.default.createElement(components_1.MBView, { row: true, wrap: true, style: { flexWrap: 'wrap' }, paddingVertical: 0.5 },
                react_1.default.createElement(tagList_1.TagList, { fieldName: block.name, tagValues: tagValues })));
        }
        return (0, field_1.defaultFieldPresenter)(block.placeholder, 'text', block.textStyle, colors.foregroundInactive);
    }, [block, tagValues, colors, loading]);
    var renderItem = (0, react_1.useCallback)(function (value) {
        var option = (0, tags_1.getTagOption)(value, options);
        return (react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, flex: true },
            react_1.default.createElement(tag_1.Tag, { color: option.colorName, label: option.label })));
    }, [options]);
    var renderArchivedItem = (0, react_1.useCallback)(function (value) {
        var option = (0, tags_1.getTagOption)(value, options);
        return (react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 4, centerVertically: true, flex: true },
            react_1.default.createElement(tag_1.Tag, { archived: true, label: option.label })));
    }, [options]);
    var renderCreateNew = (0, react_1.useCallback)(function (query, creating) {
        if (creating) {
            return (react_1.default.createElement(components_1.MBView, { style: { height: (0, components_1.grid)(8) }, center: true },
                react_1.default.createElement(components_1.MBLoadingIndicator, null)));
        }
        return react_1.default.createElement(createTagRow_1.MBCreateTagRow, { query: query, numberOfOptions: options.length });
    }, [options.length]);
    var match = (0, react_1.useCallback)(function (value, query, exact) {
        var label = labelExtractor(value);
        if ((0, lodash_1.isNil)(label)) {
            return false;
        }
        if (exact) {
            return label.toLowerCase() === query.toLowerCase();
        }
        return (0, lodash_1.includes)(label.toLowerCase(), query.toLowerCase());
    }, [labelExtractor]);
    return (react_1.default.createElement(modal_1.MBMultiSelectModal, { label: block.label, onOpen: refetch, onChange: onChange, onCreate: block.readOnly === false && createAndSelectCardTag, onChangeLabel: onChangeLabel, onArchive: onArchive, onUnarchive: onUnarchive, selectAll: true, selectAllLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.selectAllLabel'), max: (_c = block.validation) === null || _c === void 0 ? void 0 : _c.max, isRequired: block.required, renderItem: renderItem, renderArchivedItem: renderArchivedItem, renderCreateNew: renderCreateNew, values: optionSlugs, selectedValues: tagValues, labelExtractor: labelExtractor, match: match, archivedValues: archivedValues, itemLabel: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabel'), itemLabelPlural: (0, i18n_1.i18n)('card.blocks.tagsInput.itemLabelPlural'), errorLabel: (0, i18n_1.i18n)('errors.tag.enterName'), itemsEditable: block.readOnly === false },
        react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, renderRight: renderRight, renderLabel: renderLabel }, content)));
}
