"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListUserGroups = useListUserGroups;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
var PAGE_SIZE = 50;
function useListUserGroups(_a) {
    var _b, _c, _d;
    var ids = _a.ids, name = _a.name;
    var input = (0, react_1.useMemo)(function () { return ({
        limit: PAGE_SIZE,
        offset: 0,
        ids: ids,
        name: name,
    }); }, [ids, name]);
    var _e = (0, client_1.useQuery)(api_1.Queries.user.listUserGroups, { variables: { input: input } }), data = _e.data, loading = _e.loading, fetchMore = _e.fetchMore;
    var handleFetchMore = (0, react_1.useCallback)(function () {
        var _a;
        fetchMore({
            variables: { input: __assign(__assign({}, input), { offset: (_a = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _a === void 0 ? void 0 : _a.userGroups.length }) },
        });
    }, [(_b = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _b === void 0 ? void 0 : _b.userGroups, fetchMore, input]);
    return (0, react_1.useMemo)(function () {
        var _a, _b;
        return ({
            userGroups: (_a = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _a === void 0 ? void 0 : _a.userGroups,
            hasMore: (_b = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _b === void 0 ? void 0 : _b.hasMore,
            loading: loading,
            loadMore: handleFetchMore,
        });
    }, [(_c = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _c === void 0 ? void 0 : _c.userGroups, (_d = data === null || data === void 0 ? void 0 : data.listUserGroups) === null || _d === void 0 ? void 0 : _d.hasMore, loading, handleFetchMore]);
}
