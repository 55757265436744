"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardListCard = MBCardListCard;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var form_1 = require("@shared/components/blocks/contexts/form");
var card_1 = require("@shared/components/card");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var hooks_1 = require("@shared/scenes/cards/hooks");
var useFieldMetadata_1 = require("../../useFieldMetadata");
var nameBlock = {
    fieldType: 'text',
    name: 'name',
    type: 'field',
    textStyle: 'body1bold',
    width: '30%',
};
var channelBlock = {
    fieldType: 'channel',
    name: null,
    type: 'field',
    textStyle: 'body2',
    width: '15%',
};
var assigneeBlock = {
    fieldType: 'assignee',
    name: 'assignees',
    type: 'field',
    textStyle: 'body2',
    width: '15%',
};
var startDateBlock = {
    type: 'field',
    fieldType: 'relativeTime',
    name: 'startDate',
    mode: 'relative',
    textStyle: 'body2',
    icon: 'calendar',
    width: '20%',
}; // mode does not exist on FieldBlock
var tagsBlock = {
    type: 'tags',
    names: [],
    mode: 'relative',
    textStyle: 'body2',
    width: '20%',
}; // mode does not exist on TagsBlock
function MBCardListCard(_a) {
    var card = _a.card, channel = _a.channel;
    var isDesktop = (0, index_1.useIsDesktop)();
    var openDetails = (0, hooks_1.useOpenCardFromAllCardsView)();
    var onPress = (0, react_1.useCallback)(function () { return openDetails(card, channel.slug); }, [card, channel, openDetails]);
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel.id, channel.workflowTemplateId, card.cardDefinitionId).cardDefinition;
    var tagFieldNames = (0, useFieldMetadata_1.useCardDefinitionTagNames)(cardDefinition);
    var blocks = (0, react_1.useMemo)(function () { return [
        nameBlock,
        assigneeBlock,
        startDateBlock,
        channelBlock,
        __assign(__assign({}, tagsBlock), { names: tagFieldNames }),
    ]; }, [tagFieldNames]);
    return (react_1.default.createElement(channel_1.CardChannelContext.Provider, { value: channel },
        react_1.default.createElement(form_1.MBCardFormProvider, { blocks: blocks, card: card, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId },
            react_1.default.createElement(components_1.MBView, { paddingTop: isDesktop ? 0 : 1, paddingBottom: isDesktop ? 0.5 : 1, paddingLeft: isDesktop ? 2 : 1, paddingRight: isDesktop ? 1 : 1 },
                react_1.default.createElement(card_1.MBCard, { card: card, blocks: blocks, onPress: onPress })))));
}
