"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBBottomSheetInputBlock = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var bottomSheet_1 = require("@shared/components/bottomSheet");
var titleBar_1 = require("@shared/components/bottomSheet/titleBar");
var opacity_1 = require("@shared/components/touchable/opacity");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var styles_1 = require("@shared/styles");
var utils_1 = require("../utils");
var base_1 = require("./input/base");
/**
 * Wraps {@link MBCardInputBlockBase} and adds bottom sheet functionality.
 *
 */
var MBBottomSheetInputBlock = function (_a) {
    var block = _a.block, stateless = _a.stateless, renderEditForm = _a.renderEditForm, renderPlaceholder = _a.renderPlaceholder, renderInput = _a.renderInput, onReset = _a.onReset;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var containerRef = (0, react_1.useRef)();
    var _b = (0, react_1.useContext)(form_1.CardFormContext), setFieldValue = _b.setFieldValue, handleBlur = _b.handleBlur, values = _b.values, mode = _b.mode;
    var _c = (0, react_1.useState)(false), sheetVisible = _c[0], setSheetVisible = _c[1];
    var value = values[block.name];
    var _d = (0, react_1.useState)(value), innerValue = _d[0], setInnerValue = _d[1];
    var onBlur = (0, react_1.useMemo)(function () { return handleBlur(block.name); }, [handleBlur, block.name]);
    var onChange = (0, react_1.useCallback)(function (updatedValue) { return setFieldValue(block.name, updatedValue); }, [setFieldValue, block.name]);
    var hideSheet = (0, react_1.useCallback)(function () { return setSheetVisible(false); }, []);
    var hideSheetAndSave = (0, react_1.useCallback)(function () {
        if (!stateless) {
            setInnerValue(innerValue);
            onChange(innerValue);
        }
        setSheetVisible(false);
    }, [stateless, innerValue, onChange]);
    var showSheet = (0, react_1.useCallback)(function () { return setSheetVisible(true); }, []);
    var reset = (0, react_1.useCallback)(function () {
        hideSheet();
        onReset === null || onReset === void 0 ? void 0 : onReset();
        if (!stateless) {
            setInnerValue(value);
        }
    }, [hideSheet, stateless, value, onReset]);
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    /**
     * We only use the bottom sheet on:
     *
     * 1) Mobile (Web and Native)
     * 2) For forms in Edit mode
     * 3) Except for the 'name' block, which is always in autofocus
     *
     */
    var shouldUseBottomSheet = !isDesktop && mode === 'EDIT' && !block.autoFocus;
    var TypoComponent = (0, utils_1.getTypoComponent)(block.textStyle);
    var editForm = (0, react_1.useMemo)(function () { return renderEditForm({ value: innerValue, setValue: setInnerValue, onSave: hideSheetAndSave }); }, [hideSheetAndSave, innerValue, renderEditForm]);
    var input = (0, react_1.useMemo)(function () { return renderInput({ value: value, setValue: onChange, onBlur: onBlur, mode: mode }); }, [mode, onBlur, onChange, renderInput, value]);
    var placeholder = (0, react_1.useMemo)(function () {
        var defaultValue = value !== null && value !== void 0 ? value : block.placeholder;
        var renderedPlacedholder = renderPlaceholder ? renderPlaceholder() : defaultValue;
        return renderedPlacedholder || defaultValue;
    }, [renderPlaceholder, value, block]);
    return (react_1.default.createElement(base_1.MBCardInputBlockBase, { block: block, onPress: shouldUseBottomSheet ? showSheet : undefined },
        react_1.default.createElement(opacity_1.MBTouchableOpacity, { ref: containerRef }),
        shouldUseBottomSheet ? (react_1.default.createElement(TypoComponent, { color: !!value ? colors.foregroundActive : colors.foregroundInactive, allowFontScaling: false, ellipsizeMode: "tail", onPress: showSheet }, placeholder)) : (input),
        sheetVisible && (react_1.default.createElement(bottomSheet_1.MBBottomSheet, { initialSize: "half", onClose: reset, renderTitleBar: function () { return (react_1.default.createElement(titleBar_1.MBBottomSheetTitleBar, { onRightIconPress: reset, title: block.label, rightIcon: "exit", rightIconColor: colors.foregroundActive, rightIconSize: (0, components_1.grid)(4) })); }, containerRef: containerRef, layout: {
                offset: { x: 0, y: 0 },
                minWidth: 320,
                minHeight: (0, components_1.grid)(48),
            } }, editForm))));
};
exports.MBBottomSheetInputBlock = MBBottomSheetInputBlock;
