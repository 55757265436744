"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_native_1 = require("react-native");
var image_1 = require("@shared/util/media/image");
var BASE_DPI = 72;
/**
 * Given an image object and its container width/height,
 * returns an Imgix src and the width and height of the image.
 *
 * Accounts for current pixel ratio (dpr), and provides fallback width/height
 * when they are not provided in the image object.
 *
 * Will return the fullsize image src or an src for the max image size
 * that will fit in the given container dimensions.
 *
 * Returns ratio, isLandscape, and isPortrait convenience values.
 */
var useImageSrcAndDimensions = function (_a) {
    var image = _a.image, containerWidth = _a.containerWidth, containerHeight = _a.containerHeight, _b = _a.size, size = _b === void 0 ? 'full' : _b, _c = _a.grayscale, grayscale = _c === void 0 ? false : _c, _d = _a.widthOffset, widthOffset = _d === void 0 ? 0 : _d, _e = _a.heightOffset, heightOffset = _e === void 0 ? 0 : _e;
    var _f = (0, react_1.useState)([image.width, image.height]), intrinsicDimensions = _f[0], setIntrinsicDimensions = _f[1];
    var _g = (0, react_1.useState)([]), dimensions = _g[0], setDimensions = _g[1];
    var _h = (0, react_1.useState)(), ppi = _h[0], setPpi = _h[1];
    var ratio = intrinsicDimensions[0] / intrinsicDimensions[1];
    var isLandscape = ratio >= 1;
    var isPortrait = ratio < 1;
    var dpr = react_native_1.PixelRatio.get();
    var SCREEN_BASE_DPI = BASE_DPI * dpr;
    var colorUrl = grayscale ? (0, image_1.greyscaleImageUri)(image.url) : image.url;
    var src = size === 'full'
        ? (0, image_1.fullSizeImageUri)(colorUrl)
        : (0, image_1.containedImageUrl)(colorUrl, [containerWidth, containerHeight]);
    var jsonUrl = (0, image_1.jsonImageUri)(image.url);
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(ppi)) {
            return;
        }
        if (!jsonUrl) {
            setPpi(BASE_DPI);
        }
        fetch(jsonUrl)
            .then(function (res) {
            return res.json();
        })
            .then(function (data) {
            setPpi(data.DPIWidth ? Math.min(data.DPIWidth, SCREEN_BASE_DPI) : BASE_DPI);
        })
            .catch(function () {
            setPpi(BASE_DPI);
        });
    }, [ppi, jsonUrl, setPpi, SCREEN_BASE_DPI]);
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(dimensions[0]) && !(0, lodash_1.isNil)(dimensions[1])) {
            return;
        }
        react_native_1.Image.getSize(src, function (w, h) {
            setIntrinsicDimensions([w, h]);
        });
    }, [dimensions, src]);
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(intrinsicDimensions[0]) || (0, lodash_1.isNil)(intrinsicDimensions[1])) {
            return;
        }
        if ((0, lodash_1.isNil)(ppi)) {
            return;
        }
        var displayDpr = Math.min(dpr, Math.round(dpr / (SCREEN_BASE_DPI / ppi)));
        var win = react_native_1.Dimensions.get('window');
        var maxWidth = !(0, lodash_1.isNil)(containerWidth) ? containerWidth : win.width;
        var maxHeight = !(0, lodash_1.isNil)(containerHeight) ? containerHeight : win.height;
        var minWidth = Math.min(maxWidth, intrinsicDimensions[0] / displayDpr) + widthOffset;
        var minHeight = Math.min(maxHeight, intrinsicDimensions[1] / displayDpr) + heightOffset;
        setDimensions([
            isLandscape ? minWidth : minHeight * ratio,
            !isLandscape ? minHeight : minWidth / ratio,
        ]);
    }, [
        intrinsicDimensions,
        containerWidth,
        containerHeight,
        widthOffset,
        heightOffset,
        ratio,
        isLandscape,
        ppi,
        image,
        SCREEN_BASE_DPI,
        dpr,
    ]);
    return {
        src: src,
        width: dimensions[0],
        height: dimensions[1],
        ratio: ratio,
        isLandscape: isLandscape,
        isPortrait: isPortrait,
    };
};
exports.default = useImageSrcAndDimensions;
