"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenEntityById = useOpenEntityById;
var lodash_1 = require("lodash");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
function useOpenEntityById(baseNavigationPath, workflowTemplateId) {
    var navigator = (0, useNavigator_1.useNavigator)();
    return function (entityId, navigationViewTemplateId) {
        var navEndpoint = 'CardDetails';
        var navOptions = {
            cardId: entityId,
            channelSlug: baseNavigationPath,
            workflowTemplateId: workflowTemplateId,
        };
        if (!(0, lodash_1.isNil)(navigationViewTemplateId)) {
            navEndpoint = 'CardUpdate';
            navOptions.templateSlug = navigationViewTemplateId;
        }
        navigator.navigate(navEndpoint, navOptions);
    };
}
