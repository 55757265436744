"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatBanner = MBChatBanner;
// @ts-strict-ignore
var React = __importStar(require("react"));
var channel_1 = require("./channel");
var dm_1 = require("./dm");
var general_1 = require("./general");
var me_1 = require("./me");
var support_1 = require("./support");
/*
    Chat channel banner at the top of every chat thread.
*/
function MBChatBanner(_a) {
    var channel = _a.channel, creator = _a.creator, currentUserId = _a.currentUserId;
    if (channel.isGeneralChannel) {
        return React.createElement(general_1.MBGeneralChatBanner, { channel: channel });
    }
    // Note: the support banner is currently not used
    if (channel.type === 'SUPPORT') {
        return React.createElement(support_1.MBSupportChatBanner, { channel: channel });
    }
    // If chatting with myself
    if (channel.type === 'DM' && channel.users.length === 1) {
        return React.createElement(me_1.MBMeChatBanner, { channel: channel, user: channel.users[0] });
    }
    if (channel.type === 'DM' && channel.users.length === 2) {
        var otherUser = channel.users.find(function (u) { return u.id !== currentUserId; });
        return React.createElement(dm_1.MBDMChatBanner, { channel: channel, user: otherUser });
    }
    return React.createElement(channel_1.MBChannelChatBanner, { channel: channel, creator: creator });
}
