"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupAdminOptions = UserGroupAdminOptions;
exports.UserGroupNameForm = UserGroupNameForm;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var actionSheet_1 = require("@shared/util/actionSheet");
var i18n_1 = require("@shared/util/i18n");
var useDeleteUserGroup_1 = require("./hooks/useDeleteUserGroup");
var useUpdateUserGroup_1 = require("./hooks/useUpdateUserGroup");
function UserGroupAdminOptions(_a) {
    var _this = this;
    var group = _a.group;
    var containerRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(null), view = _b[0], setView = _b[1];
    var updateUserGroup = (0, useUpdateUserGroup_1.useUpdateUserGroup)();
    var deleteUserGroup = (0, useDeleteUserGroup_1.useDeleteUserGroup)();
    var handlePress = (0, react_1.useCallback)(function (parent) {
        actionSheet_1.MBActionSheet.show({
            parent: parent,
            webVerticalAlignment: 'below',
            webHorizontalAlignment: 'right',
            options: [
                {
                    title: 'Rename',
                    onPress: function () {
                        setView('rename');
                    },
                    hidden: false,
                },
                {
                    title: 'Delete',
                    destructive: true,
                    onPress: function () {
                        setView('delete');
                    },
                    hidden: false,
                },
            ],
        });
    }, [setView]);
    var handleRename = function (name) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateUserGroup({
                        id: group.id,
                        name: name,
                    })];
                case 1:
                    _a.sent();
                    setView(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteUserGroup(group.id)];
                case 1:
                    _a.sent();
                    setView(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function () {
        setView(null);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handlePress, ref: containerRef },
            react_1.default.createElement(components_1.MBBoxedIcon, { color: styles_1.Colors.v2.gray30, backgroundColor: "transparent", source: require('@shared/assets/icons/v2/dots.png') })),
        !(0, lodash_1.isNil)(view) && (react_1.default.createElement(components_1.MBBottomSheet, { initialSize: "full", onClose: function () { return setView(null); }, 
            // renderTitleBar={renderTitleBar}
            containerRef: containerRef, layout: {
                offset: { x: 0, y: 0 },
                maxWidth: 250,
            } },
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingVertical: 2 },
                view === 'rename' && (react_1.default.createElement(UserGroupNameForm, { group: group, onSubmit: handleRename, onCancel: handleCancel })),
                view === 'delete' && (react_1.default.createElement(UserGroupDeleteConfirmation, { group: group, onSubmit: handleDelete, onCancel: handleCancel })))))));
}
function UserGroupNameForm(_a) {
    var _b;
    var group = _a.group, onSubmit = _a.onSubmit, onCancel = _a.onCancel;
    var _c = (0, react_1.useState)((_b = group === null || group === void 0 ? void 0 : group.name) !== null && _b !== void 0 ? _b : ''), name = _c[0], setName = _c[1];
    var handleSubmit = function () {
        onSubmit(name.trim());
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBTextOverline, null, (0, i18n_1.i18n)('userGroups.nameLabel')),
        react_1.default.createElement(components_1.MBTextInput, { value: name, onChangeText: setName, placeholder: (0, i18n_1.i18n)('userGroups.namePlaceholder'), style: { marginTop: (0, components_1.grid)(1) } }),
        react_1.default.createElement(components_1.MBView, { row: true, centerHorizontally: true, style: { marginTop: (0, components_1.grid)(1.5) } },
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.cancel'), onPress: onCancel, size: "small", theme: "transparent" }),
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.nameFormButton'), onPress: handleSubmit, size: "small" }))));
}
function UserGroupDeleteConfirmation(_a) {
    var group = _a.group, onSubmit = _a.onSubmit, onCancel = _a.onCancel;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBText, null, (0, i18n_1.i18n)('userGroups.deleteConfirmation', { groupName: group.name })),
        react_1.default.createElement(components_1.MBView, { row: true, centerHorizontally: true, style: { marginTop: (0, components_1.grid)(1.5) } },
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('common.cancel'), onPress: onCancel, size: "small", theme: "transparent" }),
            react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.deleteFormButton'), onPress: onSubmit, size: "small" }))));
}
