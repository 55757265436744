"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AGENDA_CALENDAR_HEIGHT = void 0;
exports.MBAgendaCalendarPager = MBAgendaCalendarPager;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_native_calendars_1 = require("react-native-calendars");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/cards/agenda/hooks");
var utils_1 = require("@shared/scenes/cards/agenda/utils");
react_native_calendars_1.LocaleConfig.locales[''].dayNamesShort = (0, utils_1.getShortDayNames)();
exports.AGENDA_CALENDAR_HEIGHT = components_1.GRID_UNIT * 40;
function MBAgendaCalendarPager(_a) {
    var month = _a.month, markedDates = _a.markedDates, onChange = _a.onChange, onDayPress = _a.onDayPress, width = _a.width, height = _a.height, panHandlers = _a.panHandlers;
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var scrollViewRef = (0, react_1.useRef)();
    var theme = (0, hooks_1.useCalendarTheme)();
    var months = (0, react_1.useMemo)(function () {
        return isDesktop ? [month] : [(0, utils_1.lastMonth)(month), month, (0, utils_1.nextMonth)(month)];
    }, [month, isDesktop]);
    var scrollToCurrentMonth = (0, react_1.useCallback)(function () {
        var _a;
        var index = months.findIndex(function (m) { return m === month; });
        if (index !== -1) {
            (_a = scrollViewRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ x: index * width, animated: false });
        }
    }, [months, width, month]);
    (0, react_1.useEffect)(scrollToCurrentMonth, [scrollToCurrentMonth]);
    (0, react_1.useEffect)(function () {
        requestAnimationFrame(scrollToCurrentMonth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onMomentumScrollEnd = (0, react_1.useCallback)(function (event) {
        var x = event.nativeEvent.contentOffset.x;
        var index = x <= 0 ? 0 : Math.round(x / width);
        var newMonth = months[index];
        if (newMonth !== month) {
            onChange(newMonth);
        }
    }, [months, month, onChange, width]);
    var onScroll = (0, hooks_1.useSimulatedMomentumScrollEnd)(onMomentumScrollEnd, width);
    var handleOnDayPress = (0, react_1.useCallback)(function (_a) {
        var dateString = _a.dateString;
        return onDayPress(dateString);
    }, [onDayPress]);
    var content = (0, react_1.useMemo)(function () {
        return months.map(function (m) { return (react_1.default.createElement(react_native_1.View, { style: { width: width, height: exports.AGENDA_CALENDAR_HEIGHT }, key: m },
            react_1.default.createElement(react_native_calendars_1.Calendar, { markingType: "multi-dot", current: m, markedDates: markedDates, theme: theme, hideArrows: true, hideExtraDays: true, renderHeader: function () { return null; }, onDayPress: handleOnDayPress }))); });
    }, [months, markedDates, theme, width, handleOnDayPress]);
    return (react_1.default.createElement(react_native_1.Animated.View, __assign({ style: { height: height, overflow: 'hidden' } }, panHandlers),
        react_1.default.createElement(react_native_1.View, { style: styles.scrollView },
            react_1.default.createElement(react_native_1.ScrollView, { ref: scrollViewRef, bounces: false, scrollEventThrottle: 0, onMomentumScrollEnd: onMomentumScrollEnd, onScroll: onScroll, horizontal: !isDesktop, pagingEnabled: !isDesktop, showsHorizontalScrollIndicator: false, decelerationRate: "fast" }, content))));
}
var styles = react_native_1.StyleSheet.create({
    scrollView: {
        height: exports.AGENDA_CALENDAR_HEIGHT,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
    },
});
