"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_WIDTH_SHEET = void 0;
exports.MBFieldTagOptions = MBFieldTagOptions;
exports.MBFieldTags = MBFieldTags;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var tag_1 = require("@shared/components/blocks/lib/tags/tag");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var tagOptions_1 = require("../../contexts/tagOptions");
exports.MAX_WIDTH_SHEET = 320;
function MBFieldTagOptions(_a) {
    var onChange = _a.onChange, selectedOption = _a.selectedOption, onCreateCustom = _a.onCreateCustom;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var availableTagGroups = (0, tagOptions_1.useTagOptionGroups)().availableTagGroups;
    return (react_1.default.createElement(components_1.MBView, { fill: true, fullWidth: true, paddingVertical: 1, style: isDesktop && { maxWidth: exports.MAX_WIDTH_SHEET } },
        react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always" },
            Object.keys(availableTagGroups).map(function (key) { return (react_1.default.createElement(components_1.MBSelectableRow, { key: key, radio: true, selected: selectedOption === key, onPress: function () { return onChange(key); }, title: react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 1, style: styles.tagsContainer },
                    react_1.default.createElement(MBFieldTags, { tags: availableTagGroups[key].options })) })); }),
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onCreateCustom },
                react_1.default.createElement(components_1.MBView, { row: true, paddingHorizontal: 5, paddingVertical: 2 },
                    react_1.default.createElement(components_1.MBIconV2, { size: (0, components_1.grid)(2), name: "plus-small-heavy", color: colors.foregroundActive }),
                    react_1.default.createElement(components_1.MBTextBody1, { style: { paddingLeft: (0, components_1.grid)(2) } }, (0, i18n_1.i18n)('channel.options.fragmentTemplates.fieldTypeLabel.createCustom')))))));
}
function MBFieldTags(_a) {
    var tags = _a.tags;
    return (react_1.default.createElement(react_1.default.Fragment, null, tags.map(function (tag) { return (react_1.default.createElement(tag_1.Tag, { color: tag.theme.backgroundColor, key: tag.label, label: tag.label })); })));
}
var styles = react_native_1.StyleSheet.create({
    tagsContainer: {
        flexWrap: 'wrap',
    },
});
