"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroup = UserGroup;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var use_debounce_1 = require("use-debounce");
var api_1 = require("@shared/api");
var components_1 = require("@shared/components");
var button_1 = require("@shared/components/button");
var searchBar_1 = require("@shared/components/searchBar");
var view_1 = require("@shared/components/view");
var UserOptions_1 = require("@shared/scenes/userGroups/UserOptions");
var useUserGroup_1 = require("@shared/scenes/userGroups/hooks/useUserGroup");
var colors_1 = require("@shared/styles/colors");
var i18n_1 = require("@shared/util/i18n");
var AddUserToGroup_1 = require("./AddUserToGroup");
var useCreateUserGroupAssociation_1 = require("./hooks/useCreateUserGroupAssociation");
var useListUserGroupAssociations_1 = require("./hooks/useListUserGroupAssociations");
function UserGroup(_a) {
    var _this = this;
    var _b, _c, _d;
    var groupId = _a.groupId;
    var _e = (0, react_1.useState)(''), _query = _e[0], setQuery = _e[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var _f = (0, useUserGroup_1.useUserGroup)(groupId), group = _f.userGroup, loading = _f.loading;
    var createUserGroupAssociation = (0, useCreateUserGroupAssociation_1.useCreateUserGroupAssociation)();
    var firstUserBatchLoaded = (0, react_1.useRef)(false);
    var _g = (0, useListUserGroupAssociations_1.useListUserGroupAssociations)({
        userGroupId: groupId,
        userFirstOrLastName: !(0, lodash_1.isEmpty)(query) ? query.trim() : undefined,
    }), userGroupAssociations = _g.userGroupAssociations, userGroupAssociationsLoading = _g.loading, hasMore = _g.hasMore, loadMore = _g.loadMore;
    var _h = (0, client_1.useLazyQuery)(api_1.Queries.user.listUsers, {
        variables: {
            input: {
                ids: userGroupAssociations === null || userGroupAssociations === void 0 ? void 0 : userGroupAssociations.map(function (a) { return a.userId; }),
            },
            customCacheKey: groupId,
        },
    }), loadUsers = _h[0], _j = _h[1], data = _j.data, usersLoading = _j.loading, fetchMore = _j.fetchMore;
    var users = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.listUsers) === null || _b === void 0 ? void 0 : _b.users) === null || _c === void 0 ? void 0 : _c.filter(function (u) { return userGroupAssociations === null || userGroupAssociations === void 0 ? void 0 : userGroupAssociations.some(function (a) { return (a === null || a === void 0 ? void 0 : a.userId) === (u === null || u === void 0 ? void 0 : u.id); }); })) !== null && _d !== void 0 ? _d : [];
    var unloadedUsers = userGroupAssociations === null || userGroupAssociations === void 0 ? void 0 : userGroupAssociations.filter(function (a) { return !(users === null || users === void 0 ? void 0 : users.some(function (u) { return (u === null || u === void 0 ? void 0 : u.id) === (a === null || a === void 0 ? void 0 : a.userId); })); }).map(function (ug) { return ug === null || ug === void 0 ? void 0 : ug.userId; });
    var hasUserGroupAssociations = !(0, lodash_1.isEmpty)(userGroupAssociations);
    var handleUpdateGroup = function (userIds) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createUserGroupAssociation(userIds.map(function (user) { return ({ userGroupId: groupId, userId: user }); }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLoadMore = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            loadMore();
            return [2 /*return*/];
        });
    }); };
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isEmpty)(unloadedUsers) || usersLoading) {
            return;
        }
        if (!firstUserBatchLoaded.current) {
            firstUserBatchLoaded.current = true;
            loadUsers({
                variables: {
                    input: {
                        ids: unloadedUsers,
                    },
                    customCacheKey: groupId,
                },
            });
        }
        else {
            fetchMore({
                variables: {
                    input: {
                        ids: unloadedUsers,
                    },
                    customCacheKey: groupId,
                },
            });
        }
    }, [unloadedUsers, usersLoading, fetchMore, loadUsers, groupId]);
    var isLoading = loading || userGroupAssociationsLoading || usersLoading;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(view_1.MBView, { paddingHorizontal: "small", paddingTop: "small" },
            react_1.default.createElement(searchBar_1.MBSearchBar, { onChangeText: function (val) { return setQuery(val); }, placeholder: (0, i18n_1.i18n)('userGroups.userGroup.search'), value: query })),
        react_1.default.createElement(AddUserToGroup_1.AddUserToGroup, { onUpdateGroup: handleUpdateGroup }),
        isLoading && react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true, center: true }),
        !hasUserGroupAssociations && !isLoading && (react_1.default.createElement(view_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextBody1, null, (0, i18n_1.i18n)('userGroups.userGroup.emptyState')))),
        hasUserGroupAssociations && (react_1.default.createElement(react_native_1.ScrollView, { style: { paddingBottom: (0, view_1.grid)(2), flex: 1, alignSelf: 'stretch' } }, users === null || users === void 0 ? void 0 :
            users.map(function (user) {
                var _a;
                return (react_1.default.createElement(components_1.MBListRow, { key: user === null || user === void 0 ? void 0 : user.id, title: (_a = user === null || user === void 0 ? void 0 : user.fullName) !== null && _a !== void 0 ? _a : '', image: react_1.default.createElement(react_native_1.View, { style: {
                            width: (0, view_1.grid)(5.5),
                            height: (0, view_1.grid)(5.5),
                            display: 'flex',
                            borderRadius: (0, view_1.grid)(2.75),
                            overflow: 'hidden',
                        } },
                        react_1.default.createElement(components_1.MBUserImage, { user: user, style: { width: (0, view_1.grid)(5.5), height: (0, view_1.grid)(5.5) } })), icon: react_1.default.createElement(UserOptions_1.UserOptions, { group: group, user: user }) }));
            }),
            hasMore && (react_1.default.createElement(view_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
                react_1.default.createElement(button_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.loadMore'), onPress: handleLoadMore, size: "small", titleColor: colors_1.Colors.v2.gray60, borderColor: colors_1.Colors.v2.gray10, theme: "transparent" })))))));
}
