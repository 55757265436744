"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMenu = MBMenu;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var config_1 = require("@shared/config");
var container_1 = require("@shared/scenes/billing/metricsCounter/container");
var containers_1 = require("@shared/scenes/business/switcher/containers");
var inviteActionHook_1 = require("@shared/scenes/directory/util/inviteActionHook");
var styles_1 = require("@shared/styles");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var i18n_1 = require("@shared/util/i18n");
var intercom_1 = require("@shared/util/intercom");
var linking_1 = require("@shared/util/linking");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var envSwitcher_1 = require("../containers/envSwitcher");
var currentBusinessRow_1 = require("./currentBusinessRow");
var hooks_1 = require("./hooks");
var menuRow_1 = require("./menuRow");
var textButton_1 = require("./textButton");
var onHelp = function () { return linking_1.MBLinking.open(config_1.Config.FAQ_URL); };
var onTermsOfService = function () { return linking_1.MBLinking.open(config_1.Config.TERMS_OF_SERVICE_URL); };
var onPrivacyPolicy = function () { return linking_1.MBLinking.open(config_1.Config.PRIVACY_POLICY_URL); };
function MBMenu(_a) {
    var user = _a.user, business = _a.business, isBusinessAdmin = _a.isBusinessAdmin, isBusinessSwitcherEnabled = _a.isBusinessSwitcherEnabled, selectedItem = _a.selectedItem, onLogOut = _a.onLogOut, onLinks = _a.onLinks, onBilling = _a.onBilling, onDirectory = _a.onDirectory, onSwitchBusiness = _a.onSwitchBusiness, onUserGroups = _a.onUserGroups;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, hooks_1.useDevMode)(7), isDevMode = _b[0], incrementTapCount = _b[1];
    var bundleInfo = (0, hooks_1.useBundleInfo)();
    var _c = (0, react_1.useState)(false), isShowingSwitcher = _c[0], setShowingSwitcher = _c[1];
    var hideSwitcher = (0, react_1.useCallback)(function () { return setShowingSwitcher(false); }, [setShowingSwitcher]);
    var toggleSwitcher = (0, react_1.useCallback)(function () { return setShowingSwitcher(!isShowingSwitcher); }, [setShowingSwitcher, isShowingSwitcher]);
    var userGroupsEnabled = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.USER_GROUPS);
    var businessRow = (react_1.default.createElement(currentBusinessRow_1.MBCurrentBusinessRow, { business: business, expanded: isShowingSwitcher, onPress: isBusinessSwitcherEnabled ? toggleSwitcher : undefined }));
    var handleInvite = (0, inviteActionHook_1.useInviteAction)();
    var navigateToEditBusiness = (0, useNavigator_1.useNavigateTo)('BusinessEdit');
    var navigateToBusinessSettings = (0, useNavigator_1.useNavigateTo)('BusinessSettings');
    var navigateToEditProfile = (0, useNavigator_1.useNavigateTo)('ProfileEdit');
    var navigateToNotificationSettings = (0, useNavigator_1.useNavigateTo)('NotificationSettings');
    var showIntercomMessenger = (0, intercom_1.useShowIntercomMessenger)();
    if (isShowingSwitcher) {
        return (react_1.default.createElement(react_native_1.ScrollView, { contentInsetAdjustmentBehavior: "automatic" },
            react_1.default.createElement(react_native_1.SafeAreaView, null,
                businessRow,
                react_1.default.createElement(containers_1.MBBusinessSwitcher, { onSelectedBusiness: hideSwitcher }))));
    }
    return (react_1.default.createElement(react_native_1.ScrollView, { contentInsetAdjustmentBehavior: "automatic" },
        react_1.default.createElement(react_native_1.SafeAreaView, null,
            businessRow,
            !!onSwitchBusiness && (react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.switchBusiness'), onPress: onSwitchBusiness, icon: require('@shared/assets/icons/v2/arrows-up-down.png'), selected: selectedItem === 'business-switcher' })),
            isBusinessAdmin && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.editBusiness'), onPress: navigateToEditBusiness, icon: require('@shared/assets/icons/v2/storefront.png'), selected: selectedItem === 'business' }),
                react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.businessSettings'), onPress: navigateToBusinessSettings, icon: require('@shared/assets/icons/v2/gear.png'), selected: selectedItem === 'settings' }),
                react_native_1.Platform.OS === 'web' && (react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.billing'), onPress: onBilling, icon: require('@shared/assets/icons/v2/credit-card.png'), selected: selectedItem === 'billing' })))),
            react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.directory'), onPress: onDirectory, icon: require('@shared/assets/icons/v2/people.png'), selected: selectedItem === 'directory' }),
            userGroupsEnabled && (react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.userGroups'), onPress: onUserGroups, 
                // TODO icon is placeholder
                icon: require('@shared/assets/icons/v2/avatar-multi.png'), selected: selectedItem === 'user-groups' })),
            react_native_1.Platform.OS === 'web' && (react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.sharedLinks'), onPress: onLinks, icon: require('@shared/assets/icons/v2/link.png'), selected: selectedItem === 'links' })),
            react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.inviteSomeone'), onPress: handleInvite, icon: require('@shared/assets/icons/v2/invite.png'), selected: selectedItem === 'invite' }),
            react_1.default.createElement(components_1.MBView, { padding: "normal" },
                react_1.default.createElement(components_1.MBSeparator, null)),
            react_1.default.createElement(components_1.MBUserRow, { user: user, subtitle: "@".concat(user.username), isHidingPressableIndicator: true, imageShadow: true }),
            react_1.default.createElement(menuRow_1.MBMenuRow, { title: (0, i18n_1.i18n)('menu.editProfile'), onPress: navigateToEditProfile, icon: require('@shared/assets/icons/v2/avatar.png'), selected: selectedItem === 'profile' }),
            react_1.default.createElement(components_1.MBSnoozeRow, { title: (0, i18n_1.i18n)('menu.notifications'), titleWhenSnoozed: (0, i18n_1.i18n)('menu.snoozed'), isHidingPressableIndicator: true, color: colors.foregroundActive, onPress: navigateToNotificationSettings, snoozeUntil: !!user.snoozeUntil && new Date(user.snoozeUntil), isSelected: selectedItem === 'notifications' }),
            react_1.default.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingTop: "normal" },
                react_1.default.createElement(container_1.MBMetricsCounter, null)),
            react_1.default.createElement(components_1.MBView, { padding: "normal", paddingBottom: "large" },
                react_1.default.createElement(components_1.MBSeparator, null)),
            react_1.default.createElement(textButton_1.MBTextButton, { text: (0, i18n_1.i18n)('menu.help'), onPress: onHelp }),
            react_1.default.createElement(textButton_1.MBTextButton, { text: (0, i18n_1.i18n)('menu.termsOfService'), onPress: onTermsOfService }),
            react_1.default.createElement(textButton_1.MBTextButton, { text: (0, i18n_1.i18n)('menu.privacyPolicy'), onPress: onPrivacyPolicy }),
            react_1.default.createElement(textButton_1.MBTextButton, { text: (0, i18n_1.i18n)('menu.support'), onPress: showIntercomMessenger }),
            react_1.default.createElement(textButton_1.MBTextButton, { text: (0, i18n_1.i18n)('menu.logOut'), onPress: onLogOut }),
            react_1.default.createElement(textButton_1.MBTextButton, { onPress: incrementTapCount, text: "".concat(config_1.Config.VERSION_STRING).concat((bundleInfo === null || bundleInfo === void 0 ? void 0 : bundleInfo.version) ? " / ".concat(bundleInfo === null || bundleInfo === void 0 ? void 0 : bundleInfo.version) : '') }),
            react_1.default.createElement(components_1.MBView, { paddingBottom: "small" }),
            isDevMode && react_1.default.createElement(envSwitcher_1.MBEnvironmentSwitcher, null))));
}
