"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dedupeDurations = exports.unitListItems = exports.DEFAULT_COUNT_STR = exports.DEFAULT_COUNT = exports.TIME_OF_EVENT_DURATION = void 0;
exports.parseDurationString = parseDurationString;
var iso8601_duration_1 = require("iso8601-duration");
var lodash_1 = require("lodash");
exports.TIME_OF_EVENT_DURATION = 'PT0M0S';
exports.DEFAULT_COUNT = 1;
exports.DEFAULT_COUNT_STR = String(exports.DEFAULT_COUNT);
function parseDurationString(duration) {
    if ((0, lodash_1.isNil)(duration)) {
        return null;
    }
    if (duration === exports.TIME_OF_EVENT_DURATION) {
        return { number: '0', unit: exports.TIME_OF_EVENT_DURATION };
    }
    var isNegative = duration.startsWith('-');
    var str = !isNegative ? duration : duration.slice(1);
    var matches = (0, iso8601_duration_1.parse)(str);
    // PnD
    if (str.includes('D')) {
        return { number: String(matches.days), unit: isNegative ? '-PnD' : 'PnD' };
    }
    // PnH
    if (str.includes('H')) {
        return { number: String(matches.hours), unit: isNegative ? '-PTnH' : 'PTnH' };
    }
    // PTnM
    var minuteRegex = /PT\d+M/;
    if (minuteRegex.test(str)) {
        return { number: String(matches.minutes), unit: isNegative ? '-PTnM' : 'PTnM' };
    }
    return null;
}
exports.unitListItems = [
    {
        id: exports.TIME_OF_EVENT_DURATION,
        label: 'builder.scheduledAutomation.units.timeOfEvent',
        labelWithCount: 'builder.scheduledAutomation.units.timeOfEvent',
    },
    {
        id: '-PTnM',
        label: 'builder.scheduledAutomation.units.minutesBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.minutesBefore',
    },
    {
        id: '-PTnH',
        label: 'builder.scheduledAutomation.units.hoursBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.hoursBefore',
    },
    {
        id: '-PnD',
        label: 'builder.scheduledAutomation.units.daysBefore',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.daysBefore',
    },
    {
        id: 'PTnM',
        label: 'builder.scheduledAutomation.units.minutesAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.minutesAfter',
    },
    {
        id: 'PTnH',
        label: 'builder.scheduledAutomation.units.hoursAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.hoursAfter',
    },
    {
        id: 'PnD',
        label: 'builder.scheduledAutomation.units.daysAfter',
        labelWithCount: 'builder.scheduledAutomation.unitsWithCount.daysAfter',
    },
];
/*
  Removes manually entered 'time of event' durations in favor of TIME_OF_EVENT_DURATION.
  Also removes general duplicates.
*/
var timeOfEventDuplicates = ['PT0M', 'PT0H', 'P0D', '-PT0M', '-PT0H', '-P0D'];
var dedupeDurations = function (durations) {
    var newDurations = __spreadArray([], durations, true);
    var durationsContainsTimeOfEvent = durations.some(function (v) { return timeOfEventDuplicates.includes(v); });
    if (durationsContainsTimeOfEvent) {
        newDurations.unshift(exports.TIME_OF_EVENT_DURATION);
    }
    var filteredDurations = newDurations.filter(function (v, i) {
        if (timeOfEventDuplicates.includes(v)) {
            return false;
        }
        return newDurations.indexOf(v) === i;
    });
    return filteredDurations;
};
exports.dedupeDurations = dedupeDurations;
