"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBActionButtons = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var messageReactionButton_1 = require("@shared/scenes/chat/thread/containers/messageReactionButton");
var channelType_1 = require("@shared/scenes/chat/thread/util/channelType");
var styles_1 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
// TODO(fant): consider abstracting this and putting it in @shared/components/button
var MBActionButton = function (_a) {
    var onPress = _a.onPress, source = _a.source, hoverColor = _a.hoverColor, style = _a.style;
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    return (react_1.default.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress, style: { overflow: 'hidden' } },
            react_1.default.createElement(animation_1.MBAnimation, { scale: { from: 1, to: 1.15 }, disabled: !isHovered, type: "spring" },
                react_1.default.createElement(components_1.MBIcon, { color: isHovered && !!hoverColor ? hoverColor : styles_1.Colors.v2.gray30, source: source, style: style, noPadding: true })))));
};
var MBActionButtons = function (_a) {
    var message = _a.message, isHovering = _a.isHovering, channelSlug = _a.channelSlug, onEdit = _a.onEdit, onDelete = _a.onDelete;
    var isThread = channelType_1.MBChannelTypeUtil.isThread(channelSlug);
    // TODO(nf): reactions won't work in threads https://app.shortcut.com/coast/story/30746/threads-fix-reaction-mutation-in-chat-threads
    var showReact = !isThread && (isHovering || (!message.meta.reactions.length && !message.sender.isMe));
    var showEdit = isHovering && message.sender.isMe && !!message.content.length;
    var showDelete = isHovering && message.sender.isMe;
    var confirmDelete = (0, react_1.useCallback)(function () {
        return alert_1.MBAlert.confirm({
            title: 'Delete message',
            message: 'Are you sure you want to delete this message? This action cannot be undone.',
            confirmText: 'Yes, delete',
            confirmStyle: 'destructive',
            onConfirm: onDelete,
        });
    }, [onDelete]);
    if (!showReact && !showEdit && !showDelete) {
        return react_1.default.createElement(components_1.MBView, { paddingHorizontal: "small" });
    }
    return (react_1.default.createElement(animation_1.MBAnimationFadeIn, null,
        react_1.default.createElement(components_1.MBView, { row: true, wrap: true, centerVertically: true, paddingHorizontal: "small" },
            showReact && react_1.default.createElement(messageReactionButton_1.MBMessageReactionButton, { message: message, channelSlug: channelSlug }),
            showEdit && (react_1.default.createElement(MBActionButton, { onPress: onEdit, source: require('@shared/assets/icons/v2/pencil.png'), style: { width: 28, height: 28 } })),
            showDelete && (react_1.default.createElement(MBActionButton, { onPress: confirmDelete, hoverColor: styles_1.Colors.v2.red, source: require('@shared/assets/icons/v2/trash.png'), style: { width: 28, height: 28 } })))));
};
exports.MBActionButtons = MBActionButtons;
