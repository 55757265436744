"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteView = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var toast_1 = require("@shared/components/toast");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_web_1 = require("@shared/util/navigation/useNavigator.web");
var useDeleteView = function (_a) {
    var workflowTemplateId = _a.workflowTemplateId, channelSlug = _a.channelSlug, viewTemplates = _a.viewTemplates, selectedView = _a.selectedView, onFinishDeleteView = _a.onFinishDeleteView;
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    var deleteViewTemplate = (0, hooks_1.useDeleteViewTemplate)(workflowTemplateId);
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigator = (0, useNavigator_web_1.useNavigator)();
    var onDeleteView = (0, react_1.useCallback)(function (viewTemplateId) {
        var isUniqueCollectionView = viewTemplates.filter(function (view) { return view.type === 'COLLECTION'; }).length <= 1;
        if (isUniqueCollectionView) {
            return alert_1.MBAlert.alert({ message: (0, i18n_1.i18n)('workflowTemplate.deleteOnlyViewError') });
        }
        alert_1.MBAlert.confirm({
            message: (0, i18n_1.i18n)('workflowTemplate.deleteConfirmation'),
            confirmText: (0, i18n_1.i18n)('common.delete'),
            onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                var view, isDeletingCurrentView, firstCollectionView;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            view = viewTemplates.find(function (item) { return item.id === viewTemplateId; });
                            setLoading(true);
                            return [4 /*yield*/, deleteViewTemplate(viewTemplateId)];
                        case 1:
                            _a.sent();
                            setLoading(false);
                            onFinishDeleteView();
                            toast.show({
                                icon: 'trash',
                                message: (0, i18n_1.i18n)('workflowTemplate.viewDeleted', { viewName: view === null || view === void 0 ? void 0 : view.name }),
                            });
                            isDeletingCurrentView = selectedView === viewTemplateId;
                            if (isDeletingCurrentView) {
                                firstCollectionView = viewTemplates.filter(function (_a) {
                                    var type = _a.type, id = _a.id;
                                    return type === 'COLLECTION' && id !== viewTemplateId;
                                })[0];
                                navigator.replace('Channel', { channelSlug: channelSlug, templateSlug: firstCollectionView.id });
                                navigator.navigate('ChannelCardList', {
                                    channelSlug: channelSlug,
                                    templateSlug: firstCollectionView.id,
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); },
        });
    }, [
        channelSlug,
        deleteViewTemplate,
        navigator,
        onFinishDeleteView,
        selectedView,
        setLoading,
        toast,
        viewTemplates,
    ]);
    return (0, react_1.useMemo)(function () { return ({
        onDeleteView: onDeleteView,
        loading: loading,
    }); }, [loading, onDeleteView]);
};
exports.useDeleteView = useDeleteView;
