"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChatThreadBlock = MBChatThreadBlock;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var typography_1 = require("@shared/components/typography");
var card_1 = require("@shared/scenes/chat/thread/containers/thread/card");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
function MBChatThreadBlock(_a) {
    var threadId = _a.threadId;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var isFocused = (0, useNavigator_1.useNavigationFocus)();
    return (react_1.default.createElement(components_1.MBView, { style: styles.container },
        react_1.default.createElement(components_1.MBView, { style: styles.header },
            react_1.default.createElement(typography_1.MBTextOverlineBold, { color: colors.input.label }, (0, i18n_1.i18n)('card.blocks.chatThread.title').toUpperCase())),
        !(0, lodash_1.isNil)(channelSlug) && (react_1.default.createElement(card_1.MBCardChatThread, { threadId: threadId, isFocused: isFocused, channelSlug: channelSlug }))));
}
var styles = react_native_1.StyleSheet.create({
    container: {
        paddingHorizontal: (0, components_1.grid)(1),
    },
    header: {
        marginHorizontal: (0, components_1.grid)(2),
        borderBottomWidth: 1,
        borderBottomColor: styles_1.Colors.v2.gray10,
        paddingTop: (0, components_1.grid)(3),
        paddingBottom: (0, components_1.grid)(1),
    },
});
