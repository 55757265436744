"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBSeenBy = MBSeenBy;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var sheet_1 = require("@shared/scenes/chat/thread/components/seenBy/sheet");
var images_1 = require("./images");
var text_1 = require("./text");
function MBSeenBy(_a) {
    var onShowDetails = _a.onShowDetails, users = _a.users, sender = _a.sender, showImages = _a.showImages, isSeenByCountHidden = _a.isSeenByCountHidden, color = _a.color;
    var _b = (0, react_1.useState)(false), isShowingList = _b[0], setIsShowingList = _b[1];
    var hideList = (0, react_1.useCallback)(function () { return setIsShowingList(false); }, []);
    var showList = (0, react_1.useCallback)(function () {
        setIsShowingList(true);
        if (!!onShowDetails) {
            onShowDetails();
        }
    }, [onShowDetails]);
    var renderContent = (0, react_1.useCallback)(function () {
        if (!!showImages) {
            return react_1.default.createElement(images_1.MBSeenByImages, { users: users, isCountVisible: !isSeenByCountHidden });
        }
        if (isSeenByCountHidden) {
            return react_1.default.createElement(text_1.MBSeenWithoutCountText, { color: color });
        }
        return react_1.default.createElement(text_1.MBSeenByCountText, { count: users.length, color: color });
    }, [color, isSeenByCountHidden, showImages, users]);
    var ref = (0, react_1.useRef)(null);
    return (react_1.default.createElement(components_1.MBView, null,
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: showList, ref: ref }, renderContent()),
        isShowingList && (react_1.default.createElement(sheet_1.MBSeenBySheet, { onClose: hideList, users: users, sender: sender, containerRef: ref }))));
}
