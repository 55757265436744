"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUserToGroup = AddUserToGroup;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var use_debounce_1 = require("use-debounce");
var button_1 = require("@shared/components/button");
var Dropdown_1 = require("@shared/components/dropdown/Dropdown");
var PersonOptions_1 = require("@shared/components/filter/filterOptions/PersonOptions");
var MultiSelect_1 = require("@shared/components/filter/filterOptions/multiSelect/MultiSelect");
var view_1 = require("@shared/components/view");
var colors_1 = require("@shared/styles/colors");
var useUsers_1 = require("@shared/util/hooks/useUsers");
var i18n_1 = require("@shared/util/i18n");
function AddUserToGroup(_a) {
    var onUpdateGroup = _a.onUpdateGroup;
    var _b = (0, react_1.useState)(false), visible = _b[0], setVisible = _b[1];
    var _c = (0, react_1.useState)([]), options = _c[0], setOptions = _c[1];
    var _d = (0, react_1.useState)([]), selectedUserIds = _d[0], setSelectedUserIds = _d[1];
    var _e = (0, react_1.useState)(undefined), _query = _e[0], setQuery = _e[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var users = (0, useUsers_1.useUsers)({
        input: { name: !(0, lodash_1.isNil)(query) && !(0, lodash_1.isEmpty)(query) ? query : undefined },
    }).users;
    (0, react_1.useEffect)(function () {
        var _a, _b;
        setOptions((_b = (_a = users === null || users === void 0 ? void 0 : users.filter(function (u) { return !(0, lodash_1.isNil)(u); })) === null || _a === void 0 ? void 0 : _a.map(function (u) { return ({
            value: u.id,
            label: u.fullName,
            user: u,
        }); })) !== null && _b !== void 0 ? _b : []);
    }, [users]);
    var handleUpdateUsers = function (data) {
        setSelectedUserIds(data.map(function (d) { return Number(d.value); }));
    };
    var handleVisibilityChange = function (isOpen) {
        setVisible(isOpen);
        if (!isOpen) {
            setQuery(undefined);
            setSelectedUserIds([]);
        }
    };
    var handleSearch = function (val) {
        setQuery(val);
    };
    return (react_1.default.createElement(view_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
        react_1.default.createElement(Dropdown_1.Dropdown, { visible: visible, onVisibleChange: handleVisibilityChange, button: react_1.default.createElement(button_1.MBButton, { title: (0, i18n_1.i18n)('userGroups.userGroup.addPeople'), leftIcon: "plus", leftIconSize: 16, leftIconColor: colors_1.Colors.v2.purple, backgroundColor: "transparent", titleColor: colors_1.Colors.v2.purple, onPress: function () { return setVisible(function (prev) { return !prev; }); }, size: "small" }) },
            react_1.default.createElement(MultiSelect_1.OpenView
            // this maps to react-select's key and ensures internal values
            // are cleared on close of menu
            , { 
                // this maps to react-select's key and ensures internal values
                // are cleared on close of menu
                componentId: "user-group-association-".concat(visible ? 'open' : 'closed'), options: options, onSearch: handleSearch, onChange: handleUpdateUsers, hideSelectedOptions: true, selectedOptions: [], components: { Option: PersonOptions_1.OptionWithoutCheckbox, MultiValue: PersonOptions_1.MultiValue } }),
            !(0, lodash_1.isEmpty)(selectedUserIds) && (react_1.default.createElement(view_1.MBView, { centerHorizontally: true, paddingVertical: 2 },
                react_1.default.createElement(button_1.MBButton, { title: "Add to User Group", size: "small", onPress: function () {
                        onUpdateGroup(selectedUserIds);
                        setVisible(false);
                        setSelectedUserIds([]);
                    } }))))));
}
