"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBChannelNotificationSettings = MBChannelNotificationSettings;
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var description_1 = require("@shared/scenes/user/notificationSettings/components/description");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var snooze_1 = require("../containers/snooze");
function MBChannelNotificationSettings(_a) {
    var channel = _a.channel, setting = _a.setting, onSelect = _a.onSelect;
    return (React.createElement(components_1.MBView, { fill: true, paddingBottom: "large" },
        React.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingTop: "normal" },
            React.createElement(components_1.MBText, { bold: true, color: styles_1.Colors.v2.purple }, (0, i18n_1.i18n)('channel.options.notifications.muteHeading'))),
        React.createElement(snooze_1.MBSnoozeRow, { channel: channel }),
        React.createElement(components_1.MBView, { paddingHorizontal: "normal", paddingTop: "normal" },
            React.createElement(components_1.MBText, { bold: true, color: styles_1.Colors.v2.purple }, (0, i18n_1.i18n)('channel.options.notifications.notifyAboutHeading'))),
        React.createElement(components_1.MBSelectableRow, { title: "All new messages & cards", selected: setting === 'ALL', paddingVertical: "none", onChange: function () { return onSelect('ALL'); } }),
        React.createElement(components_1.MBSelectableRow, { title: "Direct messages, mentions, assignments", selected: setting === 'MENTIONS', paddingVertical: "none", onChange: function () { return onSelect('MENTIONS'); } }),
        React.createElement(components_1.MBSelectableRow, { title: "Nothing", selected: setting === 'NONE', paddingVertical: "none", onChange: function () { return onSelect('NONE'); } }),
        React.createElement(description_1.MBNotificationSettingDescription, { setting: setting })));
}
