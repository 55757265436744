"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannelDetails = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var Actions = __importStar(require("../store/actions"));
var emoji_1 = require("../utils/emoji");
var useChannelDetails = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var name = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.name; });
    var description = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.description; });
    var imageUrl = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.imageUrl; });
    var emoji = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.emoji; });
    var workflowTemplateName = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.workflowTemplateName; });
    var hasWorkflowTemplate = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.hasWorkflowTemplate; });
    var isImageUploading = (0, react_redux_1.useSelector)(function (state) { return state.channelDetailsForm.isImageUploading; });
    var updateName = (0, react_1.useCallback)(function (updatedName) { return dispatch(Actions.updateName(updatedName)); }, [dispatch]);
    var updateEmoji = (0, react_1.useCallback)(function (updatedEmoji) { return dispatch(Actions.updateEmoji(updatedEmoji)); }, [dispatch]);
    var updateDescription = (0, react_1.useCallback)(function (value) { return dispatch(Actions.updateDescription(value)); }, [dispatch]);
    var updateImage = (0, react_1.useCallback)(function (publicUrl) { return dispatch(Actions.updateImageUrl(publicUrl)); }, [dispatch]);
    var updateIsImageUploading = (0, react_1.useCallback)(function (isUploading) { return dispatch(Actions.updateIsImageUploading(isUploading)); }, [dispatch]);
    var updateWorkflowTemplateName = (0, react_1.useCallback)(function (updatedName) { return dispatch(Actions.updateWorkflowTemplateName(updatedName)); }, [dispatch]);
    var startImageUpload = (0, react_1.useCallback)(function () { return updateIsImageUploading(true); }, [updateIsImageUploading]);
    var endImageUpload = (0, react_1.useCallback)(function () { return updateIsImageUploading(false); }, [updateIsImageUploading]);
    var removeImage = (0, react_1.useCallback)(function () {
        endImageUpload();
        updateImage(null);
    }, [endImageUpload, updateImage]);
    var removeEmoji = (0, react_1.useCallback)(function () { return updateEmoji(''); }, [updateEmoji]);
    var changeName = (0, react_1.useCallback)(function (inputString) {
        var updatedName = (0, emoji_1.getNameWithoutEmoji)(inputString);
        var updatedEmoji = (0, emoji_1.getEmojiFromString)(inputString);
        updateName(updatedName);
        if (updatedEmoji) {
            updateEmoji(updatedEmoji);
        }
    }, [updateEmoji, updateName]);
    var changeImage = (0, react_1.useCallback)(function (image) {
        removeEmoji();
        updateImage(image);
    }, [removeEmoji, updateImage]);
    var changeEmoji = (0, react_1.useCallback)(function (updatedEmoji) {
        removeImage();
        updateEmoji(updatedEmoji);
    }, [removeImage, updateEmoji]);
    var setDetails = (0, react_1.useCallback)(function (details) { return dispatch(Actions.setDetails(details)); }, [dispatch]);
    return {
        name: name,
        emoji: emoji,
        imageUrl: imageUrl,
        description: description,
        isImageUploading: isImageUploading,
        workflowTemplateName: workflowTemplateName,
        hasWorkflowTemplate: hasWorkflowTemplate,
        updateName: changeName,
        updateDescription: updateDescription,
        updateEmoji: changeEmoji,
        updateImage: changeImage,
        removeImage: removeImage,
        removeEmoji: removeEmoji,
        setDetails: setDetails,
        startImageUpload: startImageUpload,
        endImageUpload: endImageUpload,
        updateWorkflowTemplateName: updateWorkflowTemplateName,
    };
};
exports.useChannelDetails = useChannelDetails;
