"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBLinkPreview = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var styles_2 = require("./styles");
/*
    Component to show a link preview. If no preview is set, a loading indicator
    is shown. If an image is provided in the link details, it is rendered.
    The reason we have the link prop nullable (as opposed to checking that in
    the container) is because our loading indicator requires styling. It would
    not be nice to have to do that in a container.

import { CSSProperties } from 'react';
    Note: the reason we have this as a PureComponent is because we call MBLinking
*/
var MBLinkPreview = /** @class */ (function (_super) {
    __extends(MBLinkPreview, _super);
    function MBLinkPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MBLinkPreview.prototype.render = function () {
        var _a = this.props, link = _a.link, style = _a.style, isFetched = _a.isFetched;
        if (!link && isFetched) {
            return null;
        }
        if (!link) {
            return React.createElement(components_1.MBLoadingIndicator, { style: [styles_2.Styles.container, style] });
        }
        var linkStyle = {
            color: 'inherit',
            font: 'inherit',
            textDecoration: 'inherit',
            // @ts-ignore
            userSelect: 'none',
        };
        // Note: we wrap everything in a view with white background to make sure that
        // onPress doesn't show the background below (eg everything becoming blue in
        // a blue message bubble).
        return (React.createElement(components_1.MBView, { style: [styles_2.Styles.container, style] },
            React.createElement("a", { href: link.url, target: "_blank", rel: "noreferrer", style: linkStyle },
                React.createElement(components_1.MBView, { fill: true, row: true },
                    !!link.imageUrl && React.createElement(components_1.MBImage, { style: styles_2.Styles.imageWeb, source: { uri: link.imageUrl } }),
                    !!link.imageUrl && React.createElement(components_1.MBSeparator, { vertical: true }),
                    React.createElement(components_1.MBView, { fill: true, padding: "small" },
                        React.createElement(components_1.MBText, { bold: true, numberOfLines: 1 }, link.title),
                        React.createElement(components_1.MBView, { fill: true, style: styles_2.Styles.overflowHidden },
                            React.createElement(components_1.MBText, { color: styles_1.Colors.gray.normal, numberOfLines: 2 }, link.description)))))));
    };
    return MBLinkPreview;
}(React.PureComponent));
exports.MBLinkPreview = MBLinkPreview;
