"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallMessage = CallMessage;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/components/list/row/styles");
var index_1 = require("@shared/scenes/calling/provider/index");
var styles_2 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function CallMessage(_a) {
    var channelSlug = _a.channelSlug, message = _a.message;
    var joinCall = (0, react_1.useContext)(index_1.CallContext).joinCall;
    return (react_1.default.createElement(components_1.MBView, { style: { justifyContent: 'center', marginTop: 16, flexDirection: 'row' } },
        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return joinCall(channelSlug); } },
            react_1.default.createElement(components_1.MBView, { style: {
                    flexGrow: 0,
                    height: 72,
                    borderWidth: 1,
                    borderColor: styles_2.Colors.v2.actionFreshGreen,
                    backgroundColor: '#EFFDF7',
                    borderRadius: 8,
                }, paddingHorizontal: 3, paddingVertical: 2, center: true, row: true },
                react_1.default.createElement(components_1.MBView, { paddingRight: 2 },
                    react_1.default.createElement(components_1.MBUserImage, { size: "small", style: [
                            styles_1.Styles.image,
                            styles_1.Styles.imageRound,
                            { borderWidth: 2, borderColor: 'white', width: 32, height: 32 },
                        ], user: message.sender }),
                    react_1.default.createElement(components_1.MBView, { style: {
                            position: 'absolute',
                            bottom: -4,
                            left: -4,
                            width: 20,
                            height: 20,
                            borderRadius: 10,
                            backgroundColor: styles_2.Colors.v2.actionFreshGreen,
                        }, center: true },
                        react_1.default.createElement(components_1.MBIconV2, { name: "phone", size: 20, color: styles_2.Colors.v2.purple }))),
                react_1.default.createElement(components_1.MBView, null,
                    react_1.default.createElement(components_1.MBTextBody2Bold, null, message.content),
                    react_1.default.createElement(components_1.MBTextCaption1Bold, { color: styles_2.Colors.v2.gray30 }, (0, i18n_1.i18n)('calling.message.subtitle')))))));
}
