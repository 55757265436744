"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVisibleComponents = useVisibleComponents;
var react_1 = require("react");
var Utils_1 = require("@shared/data/cards/Utils");
function useVisibleComponents(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, _b = _a.includeServerManagedComponents, includeServerManagedComponents = _b === void 0 ? true : _b;
    return (0, react_1.useMemo)(function () {
        var _a;
        return getVisibleComponents((_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) !== null && _a !== void 0 ? _a : [], viewTemplate).filter(function (component) { return includeServerManagedComponents || !component.isServerManaged; });
    }, [workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components, viewTemplate, includeServerManagedComponents]);
}
function getVisibleComponents(components, viewTemplate) {
    return components.filter(function (component) {
        var _a;
        var viewOptions = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.componentId === component.id; });
        return !(0, Utils_1.isComponentHidden)(viewTemplate, viewOptions, component);
    });
}
