"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToastFling = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var react_native_1 = require("react-native");
var config_1 = require("@shared/config");
var index_1 = require("@shared/scenes/auth/contentWrapper/index");
var useToastFling = function (onExit, translateX) {
    var isDesktop = (0, index_1.useIsDesktop)();
    var screenWidth = react_native_1.Dimensions.get('screen').width;
    var didFlingLeft = function (vx) { return vx < -config_1.Config.GESTURE_EXIT_VELOCITY; };
    var didFlingRight = function (vx) { return vx > config_1.Config.GESTURE_EXIT_VELOCITY; };
    var slideOutLeft = (0, react_1.useRef)(react_native_1.Animated.spring(translateX, { toValue: -screenWidth, useNativeDriver: true })).current;
    var slideOutRight = (0, react_1.useRef)(react_native_1.Animated.spring(translateX, { toValue: screenWidth, useNativeDriver: true })).current;
    var panResponder = (0, react_1.useRef)(react_native_1.PanResponder.create({
        onStartShouldSetPanResponder: function () { return false; },
        onMoveShouldSetPanResponder: function (_event, gestureState) {
            return Math.abs(gestureState.dy) < 2 && Math.abs(gestureState.dx) >= 1;
        },
        onPanResponderGrant: function () {
            translateX.stopAnimation();
            translateX.setOffset(0);
        },
        onPanResponderMove: function (_event, gestureState) { return translateX.setValue(gestureState.dx); },
        onPanResponderRelease: function (_event, gestureState) {
            var dx = gestureState.dx, vx = gestureState.vx;
            if (dx === 0 && vx === 0) {
                return;
            }
            translateX.flattenOffset();
            if (didFlingLeft(vx) || dx < -(screenWidth / 2)) {
                slideOutLeft.start(onExit);
            }
            else if (didFlingRight(vx) || dx > screenWidth / 2) {
                slideOutRight.start(onExit);
            }
            else {
                translateX.setValue(0);
            }
        },
    })).current;
    return { translateX: translateX, panHandlers: isDesktop ? null : panResponder.panHandlers };
};
exports.useToastFling = useToastFling;
