"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBEmojiButton = MBEmojiButton;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var overlay_1 = require("@shared/util/overlay");
var styles_2 = require("./styles");
function useEmojiPicker(onSelect) {
    var _a = (0, react_1.useState)(false), nativeEmojiPickerVisible = _a[0], setNativeEmojiPickerVisible = _a[1];
    var hideNativeEmojiPicker = (0, react_1.useCallback)(function () { return setNativeEmojiPickerVisible(false); }, []);
    var nativeSelectedEmoji = react_1.default.useCallback(function (emoji) {
        onSelect(emoji);
        hideNativeEmojiPicker();
    }, [onSelect, hideNativeEmojiPicker]);
    var webOpenPicker = (0, react_1.useCallback)(function (button) {
        return overlay_1.MBOverlay.show({
            parent: button,
            closeOnBackdropPress: true,
            position: function (_a) {
                var top = _a.top, right = _a.right;
                return ({
                    position: 'absolute',
                    bottom: window.innerHeight - top,
                    right: window.innerWidth - right,
                });
            },
            component: function () { return react_1.default.createElement(components_1.MBEmojiPicker, { onSelect: onSelect }); },
        });
    }, [onSelect]);
    var open = react_1.default.useCallback(function (button) {
        if (react_native_1.Platform.OS === 'web') {
            webOpenPicker(button);
        }
        else {
            setNativeEmojiPickerVisible(true);
        }
    }, [webOpenPicker]);
    var component = react_native_1.Platform.OS !== 'web' && (react_1.default.createElement(components_1.MBModalFromBottom, { visible: nativeEmojiPickerVisible, padding: "none", onClose: hideNativeEmojiPicker },
        react_1.default.createElement(components_1.MBView, { paddingTop: "small", style: { height: 400 } },
            react_1.default.createElement(components_1.MBEmojiPicker, { onSelect: nativeSelectedEmoji }))));
    return { open: open, component: component };
}
function MBEmojiButton(_a) {
    var onSelect = _a.onSelect;
    // Note(fant): hacky to use useSelector here in a "dumb" component
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var emojiPicker = useEmojiPicker(onSelect);
    if (!isDesktop) {
        return null;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { style: styles_2.Styles.alignBottom },
            react_1.default.createElement(components_1.MBTouchableIcon, { source: require('@shared/assets/icons/v2/smile.png'), color: styles_1.Colors.v2.purple, noPadding: true, style: { width: 40, height: 40 }, onPress: emojiPicker.open })),
        emojiPicker.component));
}
