"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBLinkPreviewFromTokens = MBLinkPreviewFromTokens;
// @ts-strict-ignore
var React = __importStar(require("react"));
var index_1 = require("@shared/scenes/chat/linkPreview/containers/index");
/*
    Render link preview based on a message's tokens. It shows the first link, if any.
*/
function MBLinkPreviewFromTokens(_a) {
    var message = _a.message, style = _a.style, onLongPress = _a.onLongPress;
    var urlToken = message.meta.tokens.find(function (token) { return token.type === 'url'; });
    if (!urlToken) {
        return null;
    }
    return React.createElement(index_1.MBLinkPreview, { url: urlToken.value, style: style, onLongPress: onLongPress });
}
