"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = exports.FEATURE_FLAGS = void 0;
// @ts-strict-ignore
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/developerMenu/hooks");
var hooks_2 = require("@shared/util/storage/hooks");
/*
On web, to toggle a flag, in console:

toggleFeatureFlag('NO_CODE_BOARD_VIEW_ENABLED')

Replace NO_CODE_BOARD_VIEW_ENABLED with desired option
from FEATURE_FLAGS below.
*/
// @ts-ignore
window.toggleFeatureFlag = function (flagKey) {
    if (!exports.FEATURE_FLAGS[flagKey]) {
        throw new Error('Invalid feature flag');
    }
    var existingFlags = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
    if (existingFlags.includes(flagKey)) {
        localStorage.setItem(key, JSON.stringify(existingFlags.filter(function (t) { return t !== flagKey; })));
    }
    else {
        localStorage.setItem(key, JSON.stringify(__spreadArray(__spreadArray([], existingFlags, true), [flagKey], false)));
    }
};
exports.FEATURE_FLAGS = (_a = {},
    _a[hooks_1.DEV_TOOLS_FEATURE_FLAG] = 'Developer Tools',
    _a);
var key = "FeatureFlags.v1";
var useFeatureFlag = function (name) {
    var _a = (0, hooks_2.useFromStorage)(key, '[]'), refetch = _a.refetch, value = _a.value;
    var persist = (0, hooks_2.usePersistInStorage)(key);
    var enabled = (0, react_1.useMemo)(function () { return JSON.parse(value).includes(name); }, [name, value]);
    var toggle = (0, react_1.useCallback)(function () {
        var flags = JSON.parse(value);
        persist(JSON.stringify(enabled ? flags.filter(function (flag) { return flag !== name; }) : __spreadArray(__spreadArray([], flags, true), [name], false))).then(refetch);
    }, [enabled, name, persist, refetch, value]);
    return (0, react_1.useMemo)(function () { return ({ enabled: enabled, toggle: toggle }); }, [enabled, toggle]);
};
exports.useFeatureFlag = useFeatureFlag;
