"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardAgendaView = CardAgendaView;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var CollectionViewBanner_1 = require("@shared/components/billing/CollectionViewBanner");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var useCanViewCollectionView_1 = require("@shared/data/billing/hooks/useCanViewCollectionView");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var date_1 = require("@shared/util/date");
var usePaywallExperiment_1 = require("@shared/util/featureFlags/launchDarkly/hooks/usePaywallExperiment");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/storage/hooks");
var CollectionViewMetrics_1 = require("../collection/CollectionViewMetrics");
var CollectionViewAnalytics_1 = require("../collectionView/CollectionViewAnalytics");
var CollectionViewControls_1 = require("../collectionView/components/CollectionViewControls");
var AgendaGrid_1 = require("./components/AgendaGrid");
var CalendarGrid_1 = require("./components/CalendarGrid");
var CalendarGridControls_1 = require("./components/CalendarGridControls");
var CalendarGridGrouped_1 = require("./components/CalendarGridGrouped");
var styles = react_native_1.StyleSheet.create({
    agendaView: {
        backgroundColor: styles_1.Colors.v2.backgroundGrayLight,
        flex: 1,
    },
});
function CardAgendaView(props) {
    var workflowTemplate = props.workflowTemplate, viewTemplate = props.viewTemplate;
    var workflowTemplateId = workflowTemplate.id;
    (0, react_1.useEffect)(function () {
        (0, CollectionViewMetrics_1.trackCollectionViewLoad)(CollectionViewMetrics_1.CollectionViewType.CALENDAR);
    }, []);
    var dateComponent = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.filter(hooks_1.isDateComponent).find(function (component) { return component.id === viewTemplate.calendarDateComponentId; });
    if ((0, lodash_1.isNil)(dateComponent)) {
        return null;
    }
    return (react_1.default.createElement(ControlsProvider_1.ControlsProvider, { workflowTemplateId: workflowTemplateId, viewTemplate: viewTemplate },
        react_1.default.createElement(CardAgendaViewContent, __assign({}, props, { dateComponent: dateComponent }))));
}
function CardAgendaViewContent(props) {
    var dateComponent = props.dateComponent, workflowTemplate = props.workflowTemplate, viewTemplate = props.viewTemplate;
    var workflowTemplateId = workflowTemplate.id;
    var viewTemplateId = viewTemplate.id;
    var calendarDateComponentId = viewTemplate.calendarDateComponentId;
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    var groups = (0, ControlsProvider_1.useControlsState)().groups;
    var groupFilterOption = getGroupFilterOption(groups);
    var hasGroups = !!groupFilterOption;
    var _a = (0, react_1.useState)(function () { return (0, date_1.today)(); }), date = _a[0], setDate = _a[1];
    var _b = (0, react_1.useState)(function () { return date; }), selectedDate = _b[0], setSelectedDate = _b[1];
    var calendarModeKey = "calendarMode-".concat(workflowTemplateId, "-").concat(viewTemplateId);
    var _c = (0, hooks_2.useStorage)(calendarModeKey, 'month'), calendarMode = _c[0], setCalendarMode = _c[1];
    var _d = (0, react_1.useState)({ width: 0, height: 0 }), viewSize = _d[0], setViewSize = _d[1];
    var _e = (0, react_1.useState)(false), isLoading = _e[0], setIsLoading = _e[1];
    var isPaid = (0, useCanViewCollectionView_1.useCanViewCollectionView)();
    var _f = (0, usePaywallExperiment_1.usePaywallExperiment)(), isBannerGroup = _f.isBannerGroup, groupName = _f.groupName;
    var showUpgradeModal = (0, react_1.useContext)(provider_1.UpgradeModalContext).show;
    var updateViewTemplate = (0, hooks_1.useUpdateViewTemplate)(workflowTemplateId);
    (0, usePaywallExperiment_1.useTrackPaywallExperimentView)(groupName);
    var analytics = (0, react_1.useMemo)(function () { return new CollectionViewAnalytics_1.CollectionViewAnalytics(workflowTemplateId, viewTemplateId); }, [workflowTemplateId, viewTemplateId]);
    var showModal = (0, react_1.useCallback)(function () {
        showUpgradeModal({
            title: (0, i18n_1.i18n)('card.calendar.upgradeModal.title'),
            description: (0, i18n_1.i18n)('card.calendar.upgradeModal.description'),
        });
    }, [showUpgradeModal]);
    (0, react_1.useEffect)(function () {
        // set selected date to current date when date changes
        setSelectedDate(date);
    }, [date]);
    var width = viewSize.width;
    var isLoadingSize = width === 0;
    var isSplitMode = mode === 'split-chat' || mode === 'split-workflow';
    var isWeek = calendarMode === 'week';
    var showAgendaGrid = !!isSplitMode;
    var showCalendarGridGrouped = !showAgendaGrid && isWeek && hasGroups;
    var showCalendarGrid = !showAgendaGrid && !showCalendarGridGrouped;
    var showCalenderControls = showCalendarGrid || showCalendarGridGrouped;
    var showBanner = isBannerGroup && !isPaid;
    var showGroupMenu = isWeek && !isSplitMode;
    return (react_1.default.createElement(components_1.MBView, { fill: true, style: styles.agendaView, onSize: setViewSize },
        isLoadingSize && react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true }),
        react_1.default.createElement(CollectionViewControls_1.CollectionViewControls, { showGroup: showGroupMenu, viewTemplate: viewTemplate, updateViewTemplate: updateViewTemplate, workflowTemplateId: workflowTemplateId, calendarDateComponentId: calendarDateComponentId }),
        showCalenderControls && (react_1.default.createElement(CalendarGridControls_1.CalendarGridControls, { date: date, setDate: setDate, calendarMode: calendarMode, setCalendarMode: setCalendarMode })),
        showAgendaGrid && react_1.default.createElement(CalendarGridControls_1.AgendaGridControls, { date: date, setDate: setDate, selectedDate: selectedDate, isLoading: isLoading }),
        showBanner && react_1.default.createElement(CollectionViewBanner_1.CollectionViewBanner, { onPress: showModal }),
        showCalendarGrid && (react_1.default.createElement(CalendarGrid_1.CalendarGrid, __assign({}, props, { dateComponent: dateComponent, analytics: analytics, date: date, calendarMode: calendarMode, width: width }))),
        showCalendarGridGrouped && (react_1.default.createElement(CalendarGridGrouped_1.CalendarGridGrouped, __assign({}, props, { groups: groups, groupFilterOption: groupFilterOption, dateComponent: dateComponent, analytics: analytics, date: date, calendarMode: calendarMode, width: width }))),
        showAgendaGrid && (react_1.default.createElement(AgendaGrid_1.AgendaGrid, __assign({}, props, { dateComponent: dateComponent, analytics: analytics, date: date, selectedDate: selectedDate, setSelectedDate: setSelectedDate, setIsLoading: setIsLoading })))));
}
function getGroupFilterOption(groups) {
    var _a, _b;
    return (_b = (_a = groups === null || groups === void 0 ? void 0 : groups[0]) === null || _a === void 0 ? void 0 : _a.values().next().value) === null || _b === void 0 ? void 0 : _b.filter;
}
