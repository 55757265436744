"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefetchOnReconnect = useRefetchOnReconnect;
var react_1 = require("react");
var provider_1 = require("@shared/scenes/connection/provider");
var hooks_1 = require("@shared/util/hooks");
function useRefetchOnReconnect(refetch) {
    var status = (0, react_1.useContext)(provider_1.ConnectionContext);
    var prevStatus = (0, hooks_1.usePrevious)(status);
    (0, react_1.useEffect)(function () {
        if (prevStatus === 'offline' && status === 'online') {
            refetch();
        }
    }, [status, prevStatus, refetch]);
}
