"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateWorkflowEntity = useUpdateWorkflowEntity;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var api_1 = require("@shared/api");
function useUpdateWorkflowEntity() {
    var update = (0, client_1.useMutation)(api_1.Mutations.entity.updateWorkflowEntity)[0];
    return (0, react_1.useCallback)(function (id, fields) {
        return update({
            variables: {
                input: {
                    id: id,
                    fields: fields,
                },
            },
        });
    }, [update]);
}
