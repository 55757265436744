"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentBusinessUsers = useCurrentBusinessUsers;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var notNil_1 = require("../notNil");
function useCurrentBusinessUsers() {
    var _a, _b, _c;
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var data = (0, client_1.useQuery)(api_1.Queries.user.directory, {
        variables: {
            businessId: businessId !== null && businessId !== void 0 ? businessId : -1,
        },
        skip: (0, lodash_1.isNil)(businessId),
    }).data;
    return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.users) === null || _c === void 0 ? void 0 : _c.filter(notNil_1.notNil);
}
