"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityFields = useCreateEntityFields;
var lodash_1 = require("lodash");
var react_1 = require("react");
var CardField_1 = require("@shared/data/cards/CardField");
var isFieldRecordModified_1 = require("@shared/scenes/entity/util/isFieldRecordModified");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useCreateEntityFields(workflowTemplate, viewTemplate) {
    var _a = (0, react_1.useState)(null), originalFields = _a[0], setOriginalFields = _a[1];
    var _b = (0, react_1.useState)({}), fields = _b[0], setFields = _b[1];
    var modifiedFields = (0, react_1.useMemo)(function () { return (0, isFieldRecordModified_1.getModifiedFields)(originalFields, fields); }, [originalFields, fields]);
    var updateField = (0, react_1.useCallback)(function (componentId, field) {
        setFields(function (previousFields) {
            var _a;
            var previousField = previousFields === null || previousFields === void 0 ? void 0 : previousFields[componentId];
            if ((0, isFieldRecordModified_1.isFieldModified)(previousField, field)) {
                return __assign(__assign({}, previousFields), (_a = {}, _a[componentId] = field, _a));
            }
            else {
                return previousFields;
            }
        });
    }, []);
    var isDirty = !(0, lodash_1.isNil)(modifiedFields) && Object.keys(modifiedFields).length > 0;
    (0, react_1.useEffect)(function () {
        var fieldsBuilder = {};
        workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components.forEach(function (component) {
            var _a, _b;
            var fieldOverrides = (_b = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) {
                return option.componentId === component.id;
            })) === null || _b === void 0 ? void 0 : _b.overrides;
            var builtField = (0, CardField_1.buildCardField)(null, component, {
                personRefs: null,
                workflowEntityRefs: null,
                workflowTemplateRefs: null,
                recurringEntitiesScheduleRefs: null,
            }, getFieldDefault(component, fieldOverrides));
            if (!(0, lodash_1.isNil)(builtField)) {
                fieldsBuilder[component.id] = builtField;
            }
        });
        setOriginalFields(fieldsBuilder);
        setFields(fieldsBuilder);
    }, [viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components]);
    return (0, react_1.useMemo)(function () {
        return {
            fields: fields,
            originalFields: originalFields,
            modifiedFields: modifiedFields,
            isDirty: isDirty,
            updateField: updateField,
        };
    }, [fields, originalFields, modifiedFields, isDirty, updateField]);
}
function getFieldDefault(component, fieldOverrides) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var hasOverrideDefaults = !(0, lodash_1.isNil)(fieldOverrides) && 'default' in fieldOverrides;
    var hasComponentDefaults = !(0, lodash_1.isNil)(component) && 'default' in component;
    var componentToUse = hasOverrideDefaults
        ? fieldOverrides
        : hasComponentDefaults
            ? component
            : null;
    if ((0, lodash_1.isNil)(componentToUse)) {
        return null;
    }
    if ((0, hooks_1.isTextComponent)(componentToUse)) {
        return (_b = (_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : null;
    }
    if ((0, hooks_1.isTagComponent)(componentToUse) || (0, hooks_1.isAuditTagComponent)(componentToUse)) {
        return (_d = (_c = componentToUse.default) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null;
    }
    if ((0, hooks_1.isScheduledAutomationComponent)(componentToUse)) {
        return {
            automationTriggerDurations: (_g = (_f = (_e = componentToUse.default) === null || _e === void 0 ? void 0 : _e.automationTriggerDurations) === null || _f === void 0 ? void 0 : _f.filter(function (v, i) {
                var _a, _b;
                return !(0, lodash_1.isNil)((_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.automationTriggerDurations)
                    ? ((_b = componentToUse.default) === null || _b === void 0 ? void 0 : _b.automationTriggerDurations.indexOf(v)) === i
                    : false;
            })) !== null && _g !== void 0 ? _g : null,
        };
    }
    if ((0, hooks_1.isRelatedCardComponent)(componentToUse)) {
        return (_j = (_h = componentToUse.default) === null || _h === void 0 ? void 0 : _h.cardIds) !== null && _j !== void 0 ? _j : null;
    }
    if ((0, hooks_1.isPersonComponent)(componentToUse)) {
        return (_o = (_l = (_k = componentToUse.default) === null || _k === void 0 ? void 0 : _k.userIds) !== null && _l !== void 0 ? _l : (_m = componentToUse.default) === null || _m === void 0 ? void 0 : _m.token) !== null && _o !== void 0 ? _o : null;
    }
    if ((0, hooks_1.isSubformComponent)(componentToUse)) {
        return (_q = (_p = componentToUse.default) === null || _p === void 0 ? void 0 : _p.workflowTemplateId) !== null && _q !== void 0 ? _q : null;
    }
    return null;
}
