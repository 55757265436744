"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBDuplicateOptions = MBDuplicateOptions;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var calendar_1 = require("@shared/components/dateAndTime/calendar");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
function MBDuplicateOptions(_a) {
    var selectedDate = _a.selectedDate, onSelectDate = _a.onSelectDate, onDuplicate = _a.onDuplicate, loading = _a.loading, selectedCount = _a.selectedCount;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var selectedDates = (0, react_1.useMemo)(function () { return [selectedDate]; }, [selectedDate]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { style: { backgroundColor: colors.backgroundInactive }, padding: 2 },
            react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('card.actions.duplicateToWarning'))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, style: { marginBottom: (0, components_1.grid)(2) } },
            react_1.default.createElement(calendar_1.MBCalendar, { onSelectDate: onSelectDate, selectedDates: selectedDates }),
            react_1.default.createElement(components_1.MBView, { centerHorizontally: true, paddingTop: 1.5 },
                react_1.default.createElement(components_1.MBButton, { onPress: onDuplicate, loading: loading, disabled: !selectedCount || !selectedDate, title: (0, i18n_1.i18n)('card.actions.duplicateCount', { count: selectedCount || '' }) })))));
}
