"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanBreakdownItem = PlanBreakdownItem;
exports.PlanBreakdownItemCount = PlanBreakdownItemCount;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var util_1 = require("@shared/components/blocks/lib/inputNumber/util");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var heights_1 = require("./heights");
var util_2 = require("./util");
function PlanBreakdownItem(_a) {
    var product = _a.product, interval = _a.interval;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var yearly = (0, react_1.useMemo)(function () { return product.prices.find(util_2.yearlyPrice).unitAmount / 12; }, [product]);
    var monthly = (0, react_1.useMemo)(function () { return product.prices.find(util_2.monthlyPrice).unitAmount; }, [product]);
    var formatCurrentyAndOmitCentsIfIsRoundAmount = function (amount) {
        return (0, util_1.formatNumber)(amount, 'currency', true);
    };
    return (react_1.default.createElement(components_1.MBView, { center: true, style: { height: heights_1.BREAKDOWN_ITEM_HEIGHT } },
        react_1.default.createElement(components_1.MBTextH4, null,
            interval === 'year' && (react_1.default.createElement(components_1.MBTextH4
            // @ts-expect-error
            , { 
                // @ts-expect-error
                style: { textDecoration: 'line-through', marginRight: (0, components_1.grid)(2) }, color: colors.input.label, align: "center" }, formatCurrentyAndOmitCentsIfIsRoundAmount(monthly))),
            formatCurrentyAndOmitCentsIfIsRoundAmount(interval === 'year' ? yearly : monthly)),
        react_1.default.createElement(components_1.MBTextCaption2, { color: colors.foregroundInactive, align: "center" }, (0, i18n_1.i18n)('billing.plans.planBreakdown.perUserMonth'))));
}
function PlanBreakdownItemCount(_a) {
    var count = _a.count, title = _a.title, align = _a.align, subtitle = _a.subtitle, style = _a.style;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBView, { row: true, style: style },
        react_1.default.createElement(components_1.MBView, { row: true, style: { width: (0, components_1.grid)(5) } },
            react_1.default.createElement(components_1.MBView, { paddingTop: 0.25 },
                react_1.default.createElement(components_1.MBIconV2, { color: colors.accentInactive, size: (0, components_1.grid)(2), name: "person-tiny-heavy" })),
            react_1.default.createElement(components_1.MBView, { paddingLeft: 0.5 }),
            react_1.default.createElement(components_1.MBTextBody1Bold, { color: colors.foregroundActive }, count)),
        react_1.default.createElement(components_1.MBView, { paddingRight: 2 }),
        react_1.default.createElement(components_1.MBView, { flex: true },
            !!title && (react_1.default.createElement(components_1.MBTextBody1Bold, { color: colors.foregroundActive, align: align }, title.toUpperCase())),
            !!subtitle && (react_1.default.createElement(components_1.MBTextCaption2, { color: colors.foregroundInactive, align: align }, subtitle)))));
}
