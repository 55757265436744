"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBAgendaEvent = MBAgendaEvent;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var card_1 = require("@shared/components/card");
var hooks_1 = require("@shared/scenes/cards/agenda/hooks");
var ChannelEventHandlers_1 = require("../../../../workflows/hooks/ChannelEventHandlers");
function MBAgendaEvent(_a) {
    var card = _a.card, blocks = _a.blocks;
    var onOpenCard = (0, ChannelEventHandlers_1.useChannelEventHandlers)().onOpenCard;
    var calculateHeight = (0, hooks_1.useCalculateCardHeight)(blocks);
    var height = (0, react_1.useMemo)(function () { return calculateHeight(card); }, [calculateHeight, card]);
    return (react_1.default.createElement(components_1.MBView, { paddingLeft: 1, paddingRight: 1, paddingTop: 1, style: { height: height } },
        react_1.default.createElement(form_1.MBCardFormProvider, { card: card, blocks: blocks, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId },
            react_1.default.createElement(card_1.MBCard, { card: card, blocks: blocks, onPress: onOpenCard }))));
}
